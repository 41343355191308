import React from "react";
import {
  useOutsideClick,
  useToggle,
} from "../../../FeeManagementModule/FeeStructureModule/hooks";
import "./SubjectActions.css";
import { RiDeleteBin2Line } from "react-icons/ri";
// import { AiOutlineEdit } from "react-icons/ai";

const SubjectActions = () => {
  const actionsRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const { state: isOpen, handlers } = useToggle();

  useOutsideClick(actionsRef, handlers.off);

  return (
    <>
      <button
        onClick={handlers.toggle}
        className="subject-actions__button"
        ref={buttonRef}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
        </svg>
      </button>
      {isOpen && (
        <div className="subject-actions__menu" ref={actionsRef}>
          <button className="subject-actions__menu-item">
            <RiDeleteBin2Line size={"1rem"} />
            Delete
          </button>
        </div>
      )}
    </>
  );
};

export default SubjectActions;
