import React, { useEffect, useState } from "react";
// import { SlArrowDown, SlArrowRight } from "react-icons/sl";
import DateSelector from "../Components/DateDropDown";
import { CustomDropdownWithCustomStyle } from "../Components/CustomDropdownWithCustomStyle";
// import { editpersonalinfo } from "../../../api/api";
// import { IoEllipseSharp } from "react-icons/io5";

export const PersonalInfo = ({ id, progressstate, findmandatorychecks }) => {
  const [editdata, setEditdata] = useState({
    dob: "",
    gender: "",
    blood_group: "",
    aadhaar_number: "",
    address: "",
    health_conditions: "",
    remarks: "",
  });

  const handledatachange = (field, value) => {
    if (field==="aadhaar_number") {
      if(/^\d*\.?\d*$/.test(value)){}
        else return;
      
    }
    const updatedData = { ...editdata, [field]: value };
    setEditdata(updatedData);
    sessionStorage.setItem("studentInfo", JSON.stringify(updatedData));
    findmandatorychecks();
  };

  useEffect(() => {
    const savedData = sessionStorage.getItem("studentInfo");
    const savedData1 = JSON.parse(savedData);
    if (savedData1.dob !== undefined) {
      // console.log("filled");
      setEditdata(JSON.parse(savedData));
    } else {
      setEditdata({ ...JSON.parse(savedData), ...editdata });
    }
    // console.log({ ...JSON.parse(savedData), ...editdata });
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <div>
        <div className="detailsHeader">Personal Info</div>
        <div className="detailsHeaderspan">Enter the details to proceed </div>
        <div
          className="StudentInfoEditSilderdivbody"
          style={{ paddingBottom: "5rem" }}
        >
          <div className="Details">
            <label style={{ display: "flex" }}>Date of Birth</label>
            <DateSelector
              handledatachange={handledatachange}
              field={"dob"}
              width={"28.5rem"}
              value={editdata.dob} // Add value binding here
            />
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <div className="Details">
              <label style={{ display: "flex" }}>Gender</label>
              <CustomDropdownWithCustomStyle
                defaultText={
                  editdata.gender === "" ? "Gender" : editdata.gender
                }
                handledatachange={handledatachange}
                items={["male", "female"]}
                width={"12.9rem"}
                dropdownName={"gender"}
                height="1.1rem"
              />
            </div>
            <div className="Details">
              <label style={{ display: "flex" }}>Blood Group</label>
              <CustomDropdownWithCustomStyle
                defaultText={
                  editdata.blood_group === ""
                    ? "Blood Group"
                    : editdata.blood_group
                }
                handledatachange={handledatachange}
                items={["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"]}
                width={"12.9rem"}
                dropdownName={"blood_group"}
                scrollenable={true}
                height="1.1rem"
              />
            </div>
          </div>
          <div className="Details">
            <label style={{ display: "flex" }}>
              Aadhar Number
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </label>
            <input
              type="text"
              className="inputbox"
              style={{ width: "28.5rem" }}
              placeholder="123456789"
              value={editdata.aadhaar_number} // Add value binding here
              onChange={(event) => {
                handledatachange("aadhaar_number", event.target.value);
              }}
            />
          </div>
          <div
            style={{
              position: "relative",
              width: "28.5rem",
              display: "flex",
              gap: "0.5rem",
              flexDirection: "column",
            }}
          >
            Address
            <textarea
              placeholder="Placeholder text..."
              value={editdata.address} // Add value binding here
              onChange={(e) => handledatachange("address", e.target.value)}
              style={{
                width: "100%",
                height: "100px",
                padding: "12px",
                borderRadius: "8px",
                border: "1px solid #e0e0e0",
                outline: "none",
                resize: "none",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#333",
                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
              }}
            />
            <div
              style={{
                position: "absolute",
                bottom: "8px",
                right: "12px",
                fontSize: "12px",
                color: "#A0A0A0",
              }}
            >
              {/* {`${text.length}/${maxLength}`} */}
            </div>
          </div>
          <div className="Details">
            <label style={{ display: "flex" }}>Health Conditions</label>
            <input
              type="text"
              className="inputbox"
              style={{ width: "28.5rem" }}
              placeholder="Health conditions"
              value={editdata.health_conditions} // Add value binding here
              onChange={(event) => {
                handledatachange("health_conditions", event.target.value);
              }}
            />
          </div>
          <div className="Details">
            <label style={{ display: "flex" }}>
              Remarks
              <div style={{ color: "rgba(153, 160, 174, 1)" }}>(Optional)</div>
            </label>
            <input
              type="text"
              className="inputbox"
              style={{ width: "28.5rem" }}
              placeholder="Remarks"
              value={editdata.remarks} // Add value binding here
              onChange={(event) => {
                handledatachange("remarks", event.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
