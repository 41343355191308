import React, { useEffect } from "react";
import {
  formatDate,
  formatPrice,
  useOutsideClick,
  useSelectState,
  useToggle,
} from "../../FeeStructureModule/hooks";
import "./CollectFeeTable.css";
import { RemindDueAmount } from "./DueAmount";
import { toast } from "sonner";
import MasterCard from "../../../../assets/masterCard";
import { addFeePayment } from "../../../../api/api";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/Table";

const CollectFeeTable = ({
  data,
  updateTableData,
  is_second_page,
  setIsSecondPage,
  user_id,
  setReceiptId,
  fee_structure_id,
  setPaymentMethod,
}) => {
  const [collectionAmounts, setCollectionAmounts] = React.useState({});
  const initialEnabledRows = data.map((_, index) => index === 0);
  const [enabledRows, setEnabledRows] = React.useState(initialEnabledRows);
  const { value: selectedOption, handleSelectChange: setSelectedOption } =
    useSelectState("");
  const dropdownRef = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [paymentDate, setPaymentDate] = React.useState("");
  const upiApps = ["Google Pay", "PhonePe", "Paytm", "BHIM", "Others"];
  const { state: isDropdownOpen, handlers: dropdownHandler } = useToggle();
  useOutsideClick(dropdownRef, dropdownHandler.off);
  useEffect(() => {
    const initialEnabledRows1 = data.map((_, index) => index === 0);
    setEnabledRows(initialEnabledRows1);
  }, [data]);

  const [formData, setFormData] = React.useState({
    student_user_id: user_id,
    payment_method: "",
    reference_id: "",
    feeCollections: [
      {
        academic_year: "2024-2025",
        fee_structure_id: fee_structure_id,
        term_id: 0,
        amount_paid: 0,
        payment_date: "",
      },
    ],
  });

  const handleAmountChange = (e, dueAmount, index, term_Id) => {
    const amount = e.target.value;
    if (amount === "") {
      setCollectionAmounts((prev) => {
        const updatedCollectionAmounts = { ...prev };
        delete updatedCollectionAmounts[index];
        return updatedCollectionAmounts;
      });
    } else if (parseFloat(amount) > dueAmount) {
      toast.error("Amount paid cannot be greater than due amount", {
        position: "top-right",
        autoClose: 2000,
        action: {
          text: "Close",
          onClick: () => toast.dismiss(),
        },
      });
    } else {
      setCollectionAmounts((prev) => {
        const updatedAmounts = { ...prev };
        updatedAmounts[index] = { term_Id, amount: parseFloat(amount) };
        return updatedAmounts;
      });
      if (parseFloat(amount) === dueAmount) {
        if (index !== data.length) {
          const temp = enabledRows;
          temp[index + 1] = true;
          setEnabledRows(temp);
        }
      } else {
        if (index !== data.length) {
          const temp = enabledRows;
          temp[index + 1] = false;
          setEnabledRows(temp);
        }
      }
    }
  };

  useEffect(() => {
    setFormData((prevFormData) => {
      const updatedFeeCollections = data.map((item, index) => {
        const { term_Id, amount } = collectionAmounts[index] || {};
        return {
          academic_year: "2024-2025",
          fee_structure_id: item.fee_structure_id,
          term_id: term_Id || item.term_id,
          amount_paid: parseFloat(amount) || 0,
          payment_date: paymentDate || "",
        };
      });

      return {
        ...prevFormData,
        feeCollections: updatedFeeCollections,
      };
    });
  }, [collectionAmounts, data, enabledRows, paymentDate]);

  const handleUPIAppClick = (app) => {
    if (selectedOption === "online") {
      setFormData(() => {
        return { ...formData, payment_method: app };
      });
    }
    dropdownHandler.off();
  };

  const handleSubmit = () => {
    const totalAmountPaid = Object.values(collectionAmounts).reduce(
      (acc, entry) => acc + (parseFloat(entry.amount) || 0),
      0
    );

    if (selectedOption === "") {
      toast.error("Please select a payment method");
      return;
    }

    if (formData.payment_method !== "cash" && formData.reference_id === "") {
      toast.error("Please enter UTR number");
      return;
    }

    if (totalAmountPaid === 0) {
      toast.error("Please enter amount to be collected");
      return;
    }
    const paymentMethod =
      selectedOption === "cash" ? "cash" : formData.payment_method;

    setIsLoading(true);
    addFeePayment(formData, paymentMethod)
      .then((res) => {
        if (!res.errors) {
          setIsSecondPage(true);
          updateTableData(formData, data);
          setPaymentMethod(selectedOption);
          setReceiptId(res.response.receipt_id);
        } else {
          throw res.errors.error;
        }
      })
      .catch((error) => {
        toast.error(error, {
          position: "top-right",
          autoClose: 2000,
          action: {
            text: "Close",
            onClick: () => toast.dismiss(),
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleKeydown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <section>
        <div className="collect-fee_container2">
          <Table>
            <TableHeader className="collect-fee__table-header">
              <TableRow className="collect-fee__table-row">
                <TableHead className="collect-fee__table-heading">
                  Term Name
                </TableHead>
                <TableHead className="collect-fee__table-heading">
                  Amount Paid
                </TableHead>
                <TableHead className="collect-fee__table-heading">
                  Due Date
                </TableHead>
                <TableHead className="collect-fee__table-heading">
                  Status
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data?.map((item, index) => (
                <TableRow key={index} className="collect-fee__table-row">
                  <TableCell className="collect-fee__table-data">
                    <span>Term {item.term}</span>
                  </TableCell>
                  <TableCell className="collect-fee__table-data">
                    <span>
                      <RemindDueAmount
                        amount={Number(item.amount_paid)}
                        total={Number(item.term_fee)}
                        concession={Number(item.term_concession)}
                      />
                    </span>
                  </TableCell>
                  <TableCell className="collect-fee__table-data">
                    {formatDate(item.due_date)}
                  </TableCell>
                  <TableCell className="collect-fee__table-data">
                    <div className="terms-paid">
                      {item.payment_completed ? (
                        <svg
                          className="terms-paid__icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path d="M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM5.4018 8.4L9.6438 4.1574L8.7954 3.309L5.4018 6.7032L3.7044 5.0058L2.856 5.8542L5.4018 8.4Z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                          fill="none"
                          className="terms-paid__icon"
                        >
                          <path
                            d="M6.49364 0.622248L11.9236 10.1358C11.9737 10.2234 12 10.3229 12 10.4241C12 10.5253 11.9737 10.6247 11.9236 10.7124C11.8736 10.8 11.8017 10.8728 11.715 10.9234C11.6283 10.974 11.5301 11.0006 11.43 11.0007H0.570004C0.469946 11.0006 0.371652 10.974 0.285 10.9234C0.198348 10.8728 0.126392 10.8 0.0763644 10.7124C0.0263365 10.6247 -6.35474e-07 10.5253 0 10.4241C6.35497e-07 10.3229 0.0263391 10.2234 0.076368 10.1358L5.50636 0.622248C5.5564 0.534604 5.62835 0.461824 5.715 0.411224C5.80165 0.360623 5.89995 0.333984 6 0.333984C6.10005 0.333984 6.19835 0.360623 6.285 0.411224C6.37165 0.461824 6.4436 0.534604 6.49364 0.622248ZM5.42998 8.11776V9.27092H6.57002V8.11776H5.42998ZM5.42998 4.08172V6.96461H6.57002V4.08172H5.42998Z"
                            fill="#FF8447"
                          />
                        </svg>
                      )}
                      <span className="terms-paid__status">
                        {item.payment_completed ? "Partial" : "Pending"}
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <div className="collect-fee__amount--container">
          <span className="collect-fee__amount--text">
            Select amount to be displayed here
          </span>
          <div className="collect-fee_container">
            <Table className="collect-fee__amount--table">
              <TableHeader>
                <TableRow>
                  <TableHead className="collect-fee__amount--table-header"></TableHead>
                  <TableHead className="collect-fee__amount--table-header">
                    Term Name
                  </TableHead>
                  <TableHead className="collect-fee__amount--table-header">
                    Amount Due
                  </TableHead>
                  <th className="collect-fee__amount--table-header">
                    Enter amount to be collected
                  </th>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data
                  .filter(
                    (item) =>
                      item.term_fee -
                        item.amount_paid -
                        item.term_concession !==
                      0
                  )
                  .map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell className="collect-fee__amount--table-cell">
                        <input
                          type="checkbox"
                          className="fee-structure__table-checkbox"
                          checked={enabledRows[index]}
                          disabled={!enabledRows[index]}
                          readOnly
                        />
                      </TableCell>
                      <TableCell className="collect-fee__amount--table-cell">
                        <span>Term {item.term}</span>
                      </TableCell>
                      <TableCell className="collect-fee__amount--table-cell">
                        {formatPrice(
                          item.term_fee -
                            item.amount_paid -
                            item.term_concession
                        )}
                      </TableCell>
                      <TableCell className="collect-fee__amount--table-cell">
                        <input
                          type="text"
                          className={`collect-fee__amount--table-input`}
                          placeholder="Ex. 15,000"
                          value={collectionAmounts[index]?.amount || ""}
                          onChange={(e) =>
                            handleAmountChange(
                              e,
                              item.term_fee -
                                item.amount_paid -
                                item.term_concession,
                              index,
                              item.term_id
                            )
                          }
                          disabled={!enabledRows[index]}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow className="collect-fee__amount--table-last-row">
                  <TableCell
                    className="collect-fee__amount--table-cell"
                    colSpan={2}
                  >
                    Total Fee Amount
                  </TableCell>
                  <TableCell className="collect-fee__amount--table-cell">
                    Rs.{" "}
                    {formatPrice(
                      data.reduce(
                        (acc, item) =>
                          acc +
                          (item.term_fee -
                            item.amount_paid -
                            item.term_concession),
                        0
                      )
                    )}
                  </TableCell>
                  <TableCell className="collect-fee__amount--table-cell">
                    {formatPrice(
                      Object.values(collectionAmounts).reduce(
                        (acc, item) => acc + (parseFloat(item.amount) || 0),
                        0
                      )
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
        <div className="collect-fee__payment">
          <span className="collect-fee__amount--text">Enter Date</span>
          <div className="payment-option--container">
            <input
              type="date"
              onChange={(e) => setPaymentDate(e.target.value)}
            />
          </div>
        </div>
        <div className="collect-fee__payment">
          <span className="collect-fee__amount--text">
            Choose a payment method
          </span>
          <div className="payment-option--container">
            <div
              className={`payment-option ${
                selectedOption === "cash" ? "payment-option__selected" : ""
              }`}
            >
              <input
                type="checkbox"
                id="cash"
                name="payment"
                value="cash"
                checked={selectedOption === "cash"}
                onChange={(e) => {
                  setSelectedOption("cash");
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    payment_method: e.target.value,
                  }));
                }}
              />
              <MasterCard />
              <label htmlFor="cash" className="payment--option__text">
                Cash Payment
              </label>
            </div>
            <div
              className={`payment-option ${
                selectedOption === "online" ? "payment-option__selected" : ""
              }`}
            >
              <input
                type="checkbox"
                id="online"
                name="payment"
                value="online"
                checked={selectedOption === "online"}
                onChange={(e) => {
                  setSelectedOption("online");
                }}
              />
              <MasterCard />
              <label htmlFor="online" className="payment--option__text">
                Online Payment
              </label>
            </div>
          </div>
        </div>
        {selectedOption === "online" && (
          <>
            <div className="collect-fee__payment-utr">
              <span className="collect-fee__amount--text">
                Select UPI App <span className="important">*</span>
              </span>
              <div className="collect-fee__utr--dropdown" ref={dropdownRef}>
                <div
                  className="custom-dropdown__selected"
                  onClick={dropdownHandler.toggle}
                >
                  {formData.payment_method && formData.payment_method !== "cash"
                    ? formData.payment_method
                    : "Select UPI App"}
                </div>
                {isDropdownOpen && (
                  <div className="custom-dropdown__menu">
                    {upiApps.map((app) => (
                      <div
                        key={app}
                        className="custom-dropdown__item"
                        onClick={() => handleUPIAppClick(app)}
                      >
                        {app}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {selectedOption === "online" && (
          <div className="collect-fee__payment-utr">
            <span className="collect-fee__amount--text">
              Enter UTR Number <span className="important">*</span>
            </span>
            <input
              type="text"
              className="collect-fee__utr--input"
              placeholder="Enter UTR Number"
              value={formData.reference_id}
              onChange={(e) =>
                setFormData((prev) => {
                  return { ...prev, reference_id: e.target.value };
                })
              }
            />
          </div>
        )}
      </section>
      {!is_second_page ? (
        <footer className="collect-fee__footer">
          <div className="collect-fee__footer-btn--container">
            <button
              className={`collect-fee__footer--btn ${
                isLoading && "btn--disabled"
              }`}
              onClick={handleSubmit}
              onKeyDown={handleKeydown}
              disabled={isLoading}
            >
              Collect and Validate{" "}
              {formatPrice(
                Object.values(collectionAmounts).reduce(
                  (acc, item) => acc + (parseFloat(item.amount) || 0),
                  0
                )
              )}{" "}
              Fee amount
            </button>
          </div>
        </footer>
      ) : null}
    </>
  );
};

export default CollectFeeTable;
