import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineCalendar } from "react-icons/ai"; // Using react-icons for the calendar icon
import "./DateDropDown.css";
import { DatePickerWrapper } from "../../FeeManagementModule/FeeStructureModule/components/AddFeeShedule";
// import { format } from "date-fns";
const DateSelector = ({ width, handledatachange= () => {}, field,value }) => {
    console.log(value);
    const [selectedDate, setSelectedDate] = useState(null);
    useEffect(() => {
        if (value && value !== "0000-00-00") {
          const [year, month, day] = value.split("-");
          setSelectedDate(`${month}/${day}/${year}`);
        }
  }, [value])
  
    const dateformattodaymonthyear = (date1) => {
      if (date1 === "Select a date") return date1;
      const date = new Date(date1);

      let day = String(date.getDate()).padStart(2, "0");
      let month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
      let year = date.getFullYear();
    handledatachange(field, `${year}-${month}-${day}`);
      return `${day}-${month}-${year}`;
    };
  return (
    <div
      className="DateDropdown"
      style={{ display: "flex", alignItems: "center" }}
    >
      <DatePickerWrapper>
      <DatePicker
        selected={selectedDate}
        onChange={(date) => {
          setSelectedDate(date.toISOString().substring(0, 10));
          dateformattodaymonthyear(date);
        }}
        customInput={
          <CustomInput
            width={width}
            
          />
        }
      />
      </DatePickerWrapper>
    </div>
  );
};

const CustomInput = React.forwardRef(
  ({ value = "Select a date", onClick, width }, ref) => (
    <div
      onClick={onClick}
      style={{ width: width }}
      ref={ref}
      className="inputbox"
    >
      <span style={{ color: "rgba(153, 160, 174, 1)" }}>
        {value || "Select a date"}
      </span>
      <AiOutlineCalendar
        style={{ marginLeft: "10px", color: "#9EA1B0" }}
        size={"1.3rem"}
      />
    </div>
  )
);

export default DateSelector;
