import React from "react";
import "./FeeStructure.css";
import { useSearch } from "./hooks";
import RouteHeader from "../components/RouteHeader";
import FeeHeader from "../components/FeeHeader";
import Search from "../components/Search";
import FeeTable from "../components/FeeTable";
import Pagination from "../../../home/Pagination";
import GradesDropdown from "../components/GradesDropdown";
import StatusDropdown from "../components/Dropdown";
import { getAllFeeStructures } from "../../../api/api";
import { useLocation } from "react-router-dom";
import UserContext from "../../../context/UserContext";
import loading from "../../../assets/loading.gif";

const FeeStructure = () => {
  const [feeData, setFeeData] = React.useState([]);
  const { searchTerm, filteredItems, handleSearchChange } = useSearch(
    feeData,
    "name"
  );
  const location = useLocation();
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageSize = 6;
  const totalPages = Math.ceil(filteredItems.length / pageSize);
  const queryParams = new URLSearchParams(location.search);
  const statusFilter = queryParams.get("status");
  const gradesFilter = queryParams.get("grades");
  const [isLoading, setIsLoading] = React.useState(false);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = filteredItems.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const { userInfo } = React.useContext(UserContext);

  React.useEffect(() => {
    if (userInfo.branch_id !== undefined && userInfo.branch_id !== null) {
      try {
        setIsLoading(true);
        getAllFeeStructures(
          statusFilter,
          userInfo.branch_id,
          gradesFilter
        ).then((res) => {
          const sortedData = res.response.fee_structures.sort(
            (a, b) => b.id - a.id
          );
          setFeeData(sortedData);
        });
      } catch (error) {
        console.log("Error fetching fee structures", error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [gradesFilter, statusFilter, userInfo.branch_id]);

  const handleDelete = (deletedId) => {
    setFeeData((prevData) => prevData.filter((item) => item.id !== deletedId));
  };

  return (
    <section className="fee-structure">
      <RouteHeader
        routeHeader="Fee Management"
        routes={[{ name: "Fee Structure", link: "/fee/structure/?status=all&grades=all" }]}
      />
      <FeeHeader
        text="Manage Fee Structures"
        subText="Add and manage fee structures for your school"
        showButton={userInfo.role === "accountant" ? false : true}
      />

      <>
        <section className="fee-structure__structure">
          <div className="fee-structure__container">
            <div className="fee-structure__dropdowns">
              <GradesDropdown />
              <StatusDropdown />
            </div>
            <Search
              searchTerm={searchTerm}
              onSearchChange={handleSearchChange}
              placeholder={"Search for fee structure..."}
            />
          </div>
          {isLoading ? (
            <>
              <img
                className="LoadImage"
                src={loading}
                alt="Loading"
                style={{
                  width: "40%",
                  height: "40%",
                  marginLeft: "280%",
                  padding: "100%",
                }}
              />
            </>
          ) : (
            <FeeTable items={paginatedData} onDelete={handleDelete} />
          )}
        </section>
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        )}
      </>
    </section>
  );
};

export default FeeStructure;
