import React, { useEffect, useState } from 'react'
import "./components/EditDetailsSlider.css"

import { CustomDropdownWithCustomStyle } from '../FeeManagementModule/components/CustomDropTemp';
import { addExistingConcessionRefereForaStudent, createnewConcessionRefere, getConcessionRefereeNames } from '../../api/api';
// import { HiOutlinePlusSmall } from 'react-icons/hi2';

export const FeeConcessionEditSlider = ({sliderstate,editConcessionAmount,
    handleInputChange,createConcession,userid ,handlerefresh,concessiontype,refereee}) => {
      console.log(concessiontype);
      const [refereobject,setrefereebjects]=useState([]);
      const [referenames,setreferenames]=useState([]);
      const [newrefereepayload,setnewreferepayload]=useState(null);
      useEffect(() => {
        const loaddata=async()=>{
          const res=await getConcessionRefereeNames();
          console.log(res);
          let names=[]
          res.map((item)=>{
            names.push(item.first_name+" "+item.last_name);
            return item;
          })
          setreferenames(names);
          console.log(names)
          setrefereebjects(res);
        }
       loaddata();
      }, [])
      const handlesavechanges=async()=>{
        console.log(newrefereepayload);
        // let res="";
        try{
          // console.log(createConcession());
          if(await createConcession()==="done")
       { 
        console.log("Refereeeee update");
        if(newrefereepayload!==null){
          if(newrefereepayload["new_reference_name"] && newrefereepayload["new_reference_name"] !=="")
          {
            console.log("creating neww concession member");
            await createnewConcessionRefere({
              student_user_id:userid,
              reference_name:newrefereepayload["new_reference_name"],
              concession_type:newrefereepayload["concession_type"]
            });
          }
          else{
            await addExistingConcessionRefereForaStudent(newrefereepayload);
            console.log("waitng");
          }}}
         
        }
        finally
         { // setTimeout(handlerefresh(), 2000);
          handlerefresh();
          // setIsLoading(false);
          sliderstate(false);}
         
       
        }
    const handledatachange =(value)=>{
      console.log(value);
      let temp={...newrefereepayload,student_user_id:userid}
      temp[value[0]]=value[1];
      if(value[0]==="reference_name"){
        temp["reference_id"]=refereobject[value[2]].id;
      }
      setnewreferepayload(temp);
      // console.log(newrefereepayload);
      console.log(temp);
    }
    return (
        <div className="EditSlider" >
          <div className="EditSliderdiv" style={{height:'100%'}}>
            <div className="EditSliderdivheader">
              Edit Fee Concession and Reference
              <svg
                onClick={() => {
                  sliderstate(false);
                }}
                style={{ cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <path
                  d="M4.99999 3.93906L8.71249 0.226562L9.77299 1.28706L6.06049 4.99956L9.77299 8.71206L8.71249 9.77256L4.99999 6.06006L1.28749 9.77256L0.22699 8.71206L3.93949 4.99956L0.22699 1.28706L1.28749 0.226562L4.99999 3.93906Z"
                  fill="#525866"
                ></path>
              </svg>
            </div>
            <div className="StudentInfoEditSilderdivbody">
              <div className="Details">
                <label style={{ display: "flex" }}>
                 Concession Amount
                  <div
                    className="mandatory"
                    style={{ color: "rgba(251, 55, 72, 1)" }}
                  >
                    *
                  </div>
                </label>
                <input
                  type="text"
                  className="inputbox"
                  style={{ width: "32.5rem" }}
                  placeholder="select concession amount"
                  
              
                 
                  value={editConcessionAmount}
                //   placeholder={concessionAmount}
                  onChange={handleInputChange}
                />
              </div>
    
              <div className="Details">
                <label style={{ display: "flex" }}>
                Concession type
                  <div
                    className="mandatory"
                    style={{ color: "rgba(251, 55, 72, 1)" }}
                  >
                    *
                  </div>
                </label>
                <CustomDropdownWithCustomStyle
                defaultText={concessiontype.length ?concessiontype[0].concession_type===null?"Select Concession Type":concessiontype[0].concession_type:'Select Concession Type'}
                // icon={<FilterIcon />}
                handledatachange={handledatachange}
                items={["Merit", "Local Influence","Good Will",
                  "Old Fee Structure",
                  "Old Parent Reference",
                  "Directors Reference",
                  "Political Influence",
                  "Old Parent Kid",
                  "Staff Reference"]}
                width={"32rem"}
                dropdownName={"concession_type"}
                scrollenable={true}
                
              />
              </div>
              <div className="Details">
                <label style={{ display: "flex" }}>
                  Referree Name
                  <div
                    className="mandatory"
                    style={{ color: "rgba(251, 55, 72, 1)" }}
                  >
                    *
                  </div>
                </label>
                <CustomDropdownWithCustomStyle
                defaultText={refereee!=='-'?refereee:"Select a Referee name"}
                // icon={<FilterIcon />}
                handledatachange={handledatachange}
                items={referenames}
                width={"32rem"}
                dropdownName={"reference_name"}
                needaddicon={true}
                scrollenable={true}
              />
              </div>
    
              
              <div className="footerbutton" style={{
                    position: 'fixed',
                    bottom: 0,
                    // left: 0,
                    // width: '100%',
                    padding: '10px',
                    backgroundColor: '#fff',
                    // borderTop: '1px solid #ddd',
                    zIndex:999,
                    display:'flex',
                    justifyContent:'space-between',
                    width:'34rem'
                }}>
                <div
                  className="cancel"
                    onClick={() => {
                      sliderstate(false);
                    }}
                >
                  Cancel
                </div>
                <div
                  className="Proceed"
                  //   onClick={() => {
                  //     navigate("./createquestions");
                  //   }}
                  onClick={()=>{handlesavechanges();}}
                >
                  Save Changes
                </div>
              </div>
            </div>
          </div>
          
        </div>
      );
}
