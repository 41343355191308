import { toggleStatus } from "../api/api";
import { useToggle } from "../modules/FeeManagementModule/FeeStructureModule/hooks";
import "./Modal.css";
import ToggleButton from "./ToggleButton";
import PropTypes from "prop-types";
import { toast } from "sonner";
import React from "react";

Modal.propTypes = {
  status: PropTypes.string.isRequired,
};

function Modal({ status, id }) {
  const { state: isOpen, handlers } = useToggle();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = async () => {
    const formData = {
      active: status === "Active" ? 0 : 1,
      id: id,
    };

    try {
      setIsLoading(true);
      toggleStatus(formData).then((res) => {
        showMessage();
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const showMessage = () => {
    toast(
      <div className="modal__success">
        <div className="modal__success-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8 15.5C3.85775 15.5 0.5 12.1423 0.5 8C0.5 3.85775 3.85775 0.5 8 0.5C12.1423 0.5 15.5 3.85775 15.5 8C15.5 12.1423 12.1423 15.5 8 15.5ZM7.25225 11L12.5548 5.69675L11.4943 4.63625L7.25225 8.879L5.1305 6.75725L4.07 7.81775L7.25225 11Z"
              fill="#1FC16B"
            />
          </svg>
        </div>
        <div className="modal__success-message">
          <span className="modal__success-text">
            Fee Structure Created successfully!
          </span>
          <span className="modal__success-subtext">
            {/* Please insert modal description here. */}
          </span>
        </div>
        <button
          className="modal__cancel-toast-button"
          onClick={() => toast.dismiss()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <path
              d="M4.99956 3.93955L8.71206 0.227051L9.77256 1.28755L6.06006 5.00005L9.77256 8.71255L8.71206 9.77305L4.99956 6.06055L1.28706 9.77305L0.226562 8.71255L3.93906 5.00005L0.226562 1.28755L1.28706 0.227051L4.99956 3.93955Z"
              fill="#525866"
            />
          </svg>
        </button>
      </div>,
      {
        position: "top-right",
      }
    );
    handlers.off();
  };

  return (
    <div>
      <button
        onClick={handlers.toggle}
        className="fee-structure__actions-menu-item--active"
      >
        {status === "Active" ? "Mark as Inactive" : "Mark as Active"}
        <ToggleButton checked={status === "Active" ? true : false} />
      </button>
      {isOpen && (
        <div className="Edit-modal">
          <div className="Edit-modal__content">
            <div className="Edit-modal__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M13.2405 3.9324L21.3854 18.2027C21.4605 18.3342 21.5 18.4833 21.5 18.6351C21.5 18.7869 21.4605 18.9361 21.3855 19.0676C21.3104 19.199 21.2025 19.3082 21.0725 19.3841C20.9425 19.46 20.7951 19.5 20.645 19.5H4.35501C4.20492 19.5 4.05748 19.46 3.9275 19.3841C3.79752 19.3082 3.68959 19.199 3.61455 19.0676C3.5395 18.9361 3.5 18.7869 3.5 18.6351C3.5 18.4833 3.53951 18.3342 3.61455 18.2027L11.7595 3.9324C11.8346 3.80093 11.9425 3.69176 12.0725 3.61586C12.2025 3.53996 12.3499 3.5 12.5 3.5C12.6501 3.5 12.7975 3.53996 12.9275 3.61586C13.0575 3.69176 13.1654 3.80093 13.2405 3.9324ZM11.645 15.1757V16.9054H13.355V15.1757H11.645ZM11.645 9.1216V13.4459H13.355V9.1216H11.645Z"
                  fill="#FF8447"
                />
              </svg>
            </div>
            <p className="Edit-modal__text">
              Are you sure you want to mark as Inactive?
            </p>
            <p className="Edit-modal__subtext5">
              All classes assigned to this fee structure will be affected. Its
              recommended to review before making the changes
            </p>
            <div className="Edit-modal__warning-text">
              <p className="">This action can cause issues in fee collection</p>
            </div>
            <div className="Edit-modal__divider"></div>
            <div className="Edit-modal__buttons">
              <button
                onClick={handlers.off}
                className="Edit-modal__button"
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                className="Edit-modal__button"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                Mark as Inactive
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Modal;
