import React from "react";
import "./SetupComponents.css";
import { NavLink } from "react-router-dom";
import {
  useOutsideClick,
  useToggle,
} from "../../../modules/FeeManagementModule/FeeStructureModule/hooks";

export const SetupDivider = ({ text, width }) => {
  return (
    <div className="setup-admin__divider" style={{ width }}>
      <span className="divider-text">{text}</span>
    </div>
  );
};

export const SetupInformation = ({ text, link }) => {
  return (
    <div className="setup-admin__info-box">
      <span>{text}</span>
      <NavLink to={link}>Learn More</NavLink>
    </div>
  );
};

export const GrayBox = ({ text }) => {
  return (
    <div className="gray-box">
      <span>{text}</span>
    </div>
  );
};

export const SetupDropdown = () => {
  const { state: isOpen, handlers: dropdownHandlers } = useToggle();
  const dropdownRef = React.useRef(null);
  useOutsideClick(dropdownRef, dropdownHandlers.off);

  return (
    <div className="setup-admin__dropdown" ref={dropdownRef}>
      <button
        className="setup-admin__dropdown-button"
        onClick={(e) => {
          e.preventDefault();
          dropdownHandlers.toggle();
        }}
      >
        Select Academic year
      </button>
      <div className={`setup-admin__dropdown-menu ${isOpen ? "show" : ""}`}>
        <div className="setup-admin__dropdown-menu-item">Option 1</div>
        <div className="setup-admin__dropdown-menu-item">Option 2</div>
        <div className="setup-admin__dropdown-menu-item">Option 3</div>
      </div>
    </div>
  );
};

export const TextBox = ({ text, icon }) => {
  return (
    <div className="text-box font-12">
      {icon && icon}
      <span>{text}</span>
    </div>
  );
};
