import React, { useEffect, useRef, useState } from "react";
import "./Custom-Dropdown.css";
import {
  // useSelectState,
  useOutsideClick,
  useToggle,
} from "../FeeStructureModule/hooks";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";

const CustomDropdown = ({
  defaultText,
  items,
  icon,
  width,
  dropdownName,
  padding,
  dropdownwidth = "",
  scrollenable = false,
}) => {
  const { state: isDropdownOpen, handlers } = useToggle();
  const dropdownRef = useRef(null);
  const [selectedTerm, setSelectedTerm] = useState(defaultText);
  const scrollstylingcustom = {
    "max-height": "10rem",
    overflow: "auto",
    width: dropdownwidth,
  };
  useOutsideClick(dropdownRef, handlers.off);
  const location = useLocation();

  useEffect(() => {
    setSelectedTerm(defaultText);
  }, [defaultText]);

  const handleSelectedItem = (item) => {
    if (selectedTerm === item) {
      setSelectedTerm(defaultText);
    } else {
      setSelectedTerm(item);
    }
    handlers.off();
  };

  const buildLinkWithQuery = (item) => {
    const searchParams = new URLSearchParams(location.search);
    if (dropdownName === "sort") {
      searchParams.set(dropdownName, item === "low to high" ? "asc" : "desc");
    } else if (dropdownName === "filter") {
      if (selectedTerm === item) {
        searchParams.delete("low");
        searchParams.delete("high");
      } else {
        if (item.split(" ")[0] === "Less") {
          const cleanedItem = item.split(" ")[2].replace(/[^0-9]/g, "");
          searchParams.set("low", 0);
          searchParams.set("high", cleanedItem);
        } else if (item.split(" ")[0] === "More") {
          searchParams.set("low", item.split(" ")[2].replace(/[^0-9]/g, ""));
          searchParams.set("high", 10000000);
        } else {
          searchParams.set("low", item.split(" ")[0].replace(/[^0-9]/g, ""));
          searchParams.set("high", item.split(" ")[2].replace(/[^0-9]/g, ""));
        }
      }
    } else {
      searchParams.set("term", item === "Term-wise" ? "term" : "annual");
    }
    return `${location.pathname}?${searchParams.toString()}`;
  };

  return (
    <div>
      <div className="custom__dropdown" ref={dropdownRef}>
        <div
          className="custom__dropdown--btn"
          style={{
            width: width,
            padding: padding,
          }}
          onClick={handlers.toggle}
        >
          {icon}
          {selectedTerm}
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="custom__dropdown--icon"
          >
            <path
              d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z"
              fill="#99A0AE"
            ></path>
          </svg>
        </div>
        {isDropdownOpen && (
          <div
            className="custom__dropdown--menu"
            style={scrollenable ? scrollstylingcustom : {}}
          >
            {items.map((item) => (
              <NavLink
                key={item}
                className="custom__dropdown--item"
                onClick={() => handleSelectedItem(item)}
                to={buildLinkWithQuery(item)}
              >
                {item}
                {selectedTerm === item && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    fill="none"
                  >
                    <path
                      d="M5.50005 7.37912L12.3941 0.484375L13.4553 1.54488L5.50005 9.50012L0.727051 4.72712L1.78755 3.66663L5.50005 7.37912Z"
                      fill="#335CFF"
                    />
                  </svg>
                )}
              </NavLink>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

CustomDropdown.propTypes = {
  defaultText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.element,
  width: PropTypes.string,
  dropdownName: PropTypes.string,
  padding: PropTypes.string,
};

export default CustomDropdown;