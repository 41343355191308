import React, { useState, useEffect } from 'react';
import { Feesheduleicon } from './Feeicon';
import loading from "../../../../assets/loading.gif"
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
// import {  useMoreOptionDropdown } from '../hooks';
import { FaCalendarDay } from "react-icons/fa6";
import "./AddFeeShedule.css";
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import { EditFeeSheduleDetails, submitFeeSheduleDetails } from '../../../../api/api';

export const DatePickerWrapper = styled.div`
  position: relative;
  width: 368px;

  .react-datepicker {
    border: none;
    border-radius: 4px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    width: 368px;
    height: 400px;
    gap: 0px;
    border-radius: 4px;
    border: 1px 0px 0px 0px;
    background: rgba(255, 255, 255, 1);
    margin-top: -120px;
    z-index: 1;
    border-bottom: 0;
    border: 1px solid var(--stroke-soft-200, rgba(225, 228, 234, 1));
  }
  
  .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
    width: 334px
    height: 36px;
    // box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1); 
    // gap: 6px;
    // border-radius: 8px;
    // //
    // display:flex;
    
    //
 
  }
  
  .react-datepicker__current-month {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    
    width: 320px;
  height: 20px;
  padding: 6px;
  gap: 6px;
  border-radius: 8px;
  ////
  display:flex;
  justify-content:center;
  margin-top:10px;
  background-color:  rgba(245, 247, 250, 1);
  margin-left:16px;
  // background-color:red;
  padding-top:10px;
  // padding-down:-100px;
  // color:red;
  color: rgba(82, 88, 102, 1);

  }
  // days
  .react-datepicker__day-name, 
  .react-datepicker__day, 
  .react-datepicker__time-name {
    width: 2.75rem;
    line-height: 2.75rem;
    margin: 0.166rem;
    color: rgba(153, 160, 174, 1);
    // background-color: red;
    // background: var(--text-soft-400, );
    
  }
  .react-datepicker__day{
    color: rgba(82, 88, 102, 1);
font-size: 14px;
font-weight: 500;
// line-height: 20px;
// letter-spacing: -0.006em;
// text-align: center;


  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #007bff;
    color: #fff;
    border-radius: 50%;
    // width: 40px;
    // height: 40px;
    // padding: 10px 0px 10px 0px;
    // gap: 8px;
    border-radius: 8px;
    // //
  
font-size: 14px;
font-weight: 500;
// line-height: 20px;
// letter-spacing: -0.006em;
// text-align: center;


  }

  .react-datepicker__day--in-selecting-range, 
  .react-datepicker__day--in-range {
    // background-color: red;
    color: #000;
    
  }
  
  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    top: 23px;
    background: var(--bg-white-0, rgba(255, 255, 255, 1));
    box-shadow: 0px 1px 2px 0px rgba(10, 13, 20, 0.03);
    width: 24px;
    height: 24px;
    padding: 2px ;
    gap: 2px;
    border-radius: 6px;
    ////
    
  }
  
  .react-datepicker__navigation--previous {
    left: 20px;
    
  }

  .react-datepicker__navigation--next {
    right: 28px;
  }
  .button-group {
    position: absolute;
    // top: -50px; /* Adjust as needed to position above DatePicker */
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding: 20px;
    gap: 16px;
    // width: 100%;
    background: rgba(255, 255, 255, 1);
    z-index: 2;
    top:250px;
    border: 1px solid var(--stroke-soft-200, rgba(225, 228, 234, 1));
    width: 338px;
    padding-right:10px;
  }
  
  .button-group button {
    width: 160px;
    height: 36px;
    padding: 8px 0px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
  }

  .button-group .cancel-button {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(225, 228, 234, 1);
    box-shadow: 0px 1px 2px 0px rgba(10, 13, 20, 0.03);
  }

  .button-group .apply-button {
    background: rgba(51, 92, 255, 1);
    color: #fff;
  }
`;
const saveTosessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

const loadFromsessionStorage = (key, initialValue,value) => {
  if(!value) return initialValue;
  const savedValue = sessionStorage.getItem(key);
  if (savedValue) {
    return JSON.parse(savedValue);
  } else {
    return initialValue;
  }
};

export const AddFeeShedule = ({ handleprogress, terms,value2,handlefeedata,total }) => {
  console.log(value2,"edit check");
  const [error, setError] = useState('');
  const [selecteddatelist, setselecteddatelist] = useState(loadFromsessionStorage('selecteddatelist', new Array(terms).fill("Select a date"), value2));
  const [opencalendarlist, setopencalendarlist] = useState(loadFromsessionStorage('opencalendarlist', new Array(terms).fill(false), value2));
  const [termfeepercent, setTermfeepercent] = useState(loadFromsessionStorage('termfeepercent', new Array(terms).fill(null), value2));
  const [nextpageloading, setnextpageloading] = useState(false);
  const [showbutton, setshowbutton] = useState(new Array(terms).fill(false));
  const [dateerrorlist, setdateerrorlist] = useState(new Array(terms).fill(false));

  function toISODateString(dateString) {
    const date = new Date(dateString);
    return date.toISOString();
  }

  useEffect(() => {
    for (let i = 1; i < terms; i++) {
      if (selecteddatelist[i] !== "Select a date" && selecteddatelist[i - 1] !== "Select a date") {
        let date1 = new Date(toISODateString(selecteddatelist[i])).getTime();
        let date2 = new Date(toISODateString(selecteddatelist[i - 1])).getTime();
        if (date1 < date2) {
          const temp = [...dateerrorlist];
          temp[i] = true;
          setdateerrorlist(temp);
        } else {
          const temp = [...dateerrorlist];
          temp[i] = false;
          setdateerrorlist(temp);
        }
      }
    }
    // eslint-disable-next-line
  }, [selecteddatelist])


  useEffect(() => {
    saveTosessionStorage('selecteddatelist', selecteddatelist);
  }, [selecteddatelist]);

  useEffect(() => {
    if (terms === 1) {
      const temp = [...termfeepercent];
      temp[0] = 100;
      setTermfeepercent(temp);
    }
  // eslint-disable-next-line
}, [terms])

 

  useEffect(() => {
    saveTosessionStorage('opencalendarlist', opencalendarlist);
  }, [opencalendarlist]);

  useEffect(() => {
    saveTosessionStorage('termfeepercent', termfeepercent);
  }, [termfeepercent]);

  const handleInputChange = (index, value) => {
    const temp = [...termfeepercent];
    temp[index] = value;
    setTermfeepercent(temp);
  };

  const handlesenddata = async () => {
    let p = 0;

    for (let i = 0; i < terms; i++) {
      if (!value2) {
        const data = await submitFeeSheduleDetails({
          "term": i + 1,
          "fee_percent": Number(termfeepercent[i]),
          "fee_amount": (termfeepercent[i] / 100) * total,
          "due_date": selecteddatelist[i]
        });
        if (data.status === 200) {
          console.log("success");
        } else {
          p = 1;
        }
      } else {
        const data = await EditFeeSheduleDetails({
          "term": i + 1,
          "fee_percent": Number(termfeepercent[i]),
          "fee_amount": (termfeepercent[i] / 100) * total,
          "due_date": selecteddatelist[i]
        }, terms - (i + 1));
        if (data.status === 200) {
          console.log("success");
        } else {
          p = 1;
        }
      }
    }
    if (p === 0) handleprogress(3);
  };

  const handlecancelcalendar = (index) => {
    const temp = [...selecteddatelist];
    temp[index] = "Select a date";
    setselecteddatelist(temp);
  };

  const handledateselect = (index, date) => {
    const temp = [...selecteddatelist];
    temp[index] = date.toISOString().substring(0, 10);
    setselecteddatelist(temp);
  };

  const handlecalendaropenandclose = (index) => {
    const temp = [...opencalendarlist];
    temp[index] = !temp[index];
    setopencalendarlist(temp);
  };

  const datformattodaymonthyear=(date1)=>{
    if(date1==="Select a date")return date1;
    const date = new Date(date1);
    
    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    let year = date.getFullYear();

    return `${day}-${month}-${year}`;

  }

  const totalFeePercent = termfeepercent.reduce((acc, curr) => acc + (curr !== "NA" ? parseFloat(curr) : 0), 0);
  const totalFeeAmount = (totalFeePercent / 100) * total;

  useEffect(() => {

    if(error || totalFeePercent!==100){
      setshowbutton(false);
      return ;
    }
  if(termfeepercent.length && selecteddatelist.length)
    {console.log(termfeepercent, selecteddatelist)
      const termfeepercentNotNull = termfeepercent.every(item => item !== null);
      if (!termfeepercentNotNull) {
        setshowbutton(false);
        return;
      }

    // Check if all values in selecteddatelist are not null
      const selecteddatelistNotNull = selecteddatelist.every(item => item !== null && item !== 'Select a date');
      if (!selecteddatelistNotNull) {
        setshowbutton(false);
        return;
      }

      const checkerrors = dateerrorlist.every(item => item !== true);
      if (!checkerrors) {
        setshowbutton(false);
        return;
      }
      setshowbutton(true);
    }
  }, [termfeepercent, selecteddatelist, error, totalFeePercent, dateerrorlist]);

  useEffect(() => {
   if(totalFeePercent>100){
     setError("value cannot be greater than 100")
   }
   else if(totalFeePercent<100)setError("value cannot be less than 100")
   else setError(null);
  }, [totalFeePercent])
  if(nextpageloading){
    return <img className='LoadImage' src={loading} alt='LoadImage' style={{width:'50px',height:'50px',paddingTop:'140px'}} />
  }

  return (
    <div className="container">
      <div className="header">
        <div className="headericon">
          <Feesheduleicon />
        </div>
        <div className="headertextbody">
          <div className="headertext1">
            Add Schedule to Fee Structure
          </div>
          <div className="headertext2">
            Add Due dates and Fee amount for each item
          </div>
        </div>
      </div>
      <div className="wholegrid">
        <div className="grid-header">
          <div className="grid-item1">Name of Term</div>
          <div className="grid-item">Fee Amount %</div>
          <div className="grid-item">Fee In Amount</div>
          <div className="grid-item">Due Date</div>
        </div>
        {Array.from({ length:terms}).map((_, index) => (
          <div className="grid" key={index}>
            <div className="grid-item" style={{ width: '280px' }}>
              <span className="sec-tag1">TERM {index + 1}</span>
            </div>
            <div className="grid-item">
              <input
                type="text"
                placeholder="Enter amount in %"
                pattern="[0-9]*"
                value={termfeepercent[index]}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  handleInputChange(index, e.target.value);
                }}
              />
              {termfeepercent[index]>100 && <div style={{ color: 'red', fontSize: '10px', marginTop: '5px',whiteSpace: 'nowrap' }}>value cannot be greater than 100</div>}
            </div>
            <div className="grid-item">
              {termfeepercent[index] === null ? "NA" : ((total * termfeepercent[index]) / 100)}
            </div>
            <div className="grid-item">
              <div className="griddate">
                <div className="calendaricon">
                  {opencalendarlist[index] &&
                    <DatePickerWrapper>
                      <DatePicker
                        onChange={date => { handledateselect(index, date); }}
                        selected={selecteddatelist[index]==="Select a date" ?new Date():selecteddatelist[index]}
                        inline
                      />
                      <div className="button-group">
                        <button className="cancel-button" onClick={() => { handlecalendaropenandclose(index); handlecancelcalendar(index); }}>Cancel</button>
                        <button className="apply-button" onClick={() => { handlecalendaropenandclose(index); }}>Apply</button>
                      </div>
                    </DatePickerWrapper>}
                  {!opencalendarlist[index] && <FaCalendarDay style={{ cursor: 'pointer' }} onClick={() => handlecalendaropenandclose(index)} />}
                </div>
                {!opencalendarlist[index] && <div onClick={() => handlecalendaropenandclose(index)} style={{ cursor: 'pointer' }}>{datformattodaymonthyear(selecteddatelist[index])}</div>}
              </div>
              {dateerrorlist && dateerrorlist[index] && <div style={{ color: 'red', fontSize: '10px', marginTop: '5px', whiteSpace: 'nowrap' }}>term date cannot be less than previous</div>}
            </div>
          </div>
        ))}
        <div className="total-row">
          <span className='Totalfee'>Total Fee Amount</span>
          <span className='Totalper'>{totalFeePercent}%
            {totalFeePercent > 100 && <div style={{ color: 'red', fontSize: '10px', marginTop: '5px', whiteSpace: 'nowrap' }}>{error}</div>}
            {totalFeePercent < 100 && <div style={{ color: 'red', fontSize: '10px', marginTop: '5px', whiteSpace: 'nowrap' }}>value is less than 100</div>}
          </span>
          <span className='TotalRupees'>₹{totalFeeAmount.toFixed(2)}</span>
        </div>
      </div>
      <div className='buttons' style={{marginTop:'14px'}}>
        <div className="backbutton">
          <button type='submit' className='back-button' onClick={() => { handleprogress(1, true) }}>
            <div className="lefticon"><SlArrowLeft size={10} strokeWidth={90} /> </div>
            Back
          </button>
        </div>
        {!showbutton &&
          <button type="submit" className="continue-button1">Save and Continue
            <div>
              <SlArrowRight size={9} strokeWidth={70} />
            </div>
          </button>
        }
        {showbutton &&
          <button type="submit" className="continue-button1" style={{ background: 'rgba(51, 92, 255, 1)', color: 'rgba(255, 255, 255, 1)' }} onClick={() => { setnextpageloading(true); handlesenddata(); handlefeedata([totalFeePercent, totalFeeAmount]); }}>Save and Continue
            <div>
              <SlArrowRight size={9} strokeWidth={70} />
            </div>
          </button>
        }
      </div>
    </div>
  );
};