const MasterCard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="24"
      viewBox="0 0 32 24"
      fill="none"
    >
      <rect width="32" height="24" rx="4" fill="#252525" />
      <path
        d="M19.2451 6.14844H12.7554V17.85H19.2451V6.14844Z"
        fill="#FF5F00"
      />
      <path
        d="M13.1674 12.0004C13.1663 10.8735 13.4208 9.76109 13.9116 8.74743C14.4024 7.73377 15.1166 6.84543 16.0001 6.14966C14.906 5.28675 13.592 4.75011 12.2083 4.6011C10.8246 4.45208 9.42698 4.69669 8.17526 5.30697C6.92355 5.91725 5.86821 6.86859 5.12987 8.05224C4.39154 9.23588 4 10.6041 4 12.0004C4 13.3968 4.39154 14.765 5.12987 15.9486C5.86821 17.1323 6.92355 18.0836 8.17526 18.6939C9.42698 19.3042 10.8246 19.5488 12.2083 19.3998C13.592 19.2508 14.906 18.7141 16.0001 17.8512C15.1166 17.1554 14.4024 16.2671 13.9116 15.2534C13.4209 14.2398 13.1663 13.1274 13.1674 12.0004Z"
        fill="#EB001B"
      />
      <path
        d="M28 12.0004C28.0001 13.3968 27.6086 14.765 26.8703 15.9486C26.132 17.1323 25.0767 18.0836 23.825 18.6939C22.5733 19.3042 21.1757 19.5488 19.792 19.3998C18.4083 19.2508 17.0943 18.7141 16.0002 17.8512C16.883 17.1547 17.5966 16.2663 18.0873 15.2528C18.578 14.2393 18.833 13.1272 18.833 12.0004C18.833 10.8737 18.578 9.76161 18.0873 8.74812C17.5966 7.73462 16.883 6.84613 16.0002 6.14966C17.0943 5.28674 18.4083 4.75011 19.792 4.60109C21.1757 4.45208 22.5733 4.6967 23.825 5.30699C25.0767 5.91728 26.132 6.86861 26.8703 8.05226C27.6086 9.2359 28.0001 10.6041 28 12.0004Z"
        fill="#F79E1B"
      />
    </svg>
  );
};

export default MasterCard;
