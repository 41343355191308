import React from "react";
import CustomIcon from "./components/CustomIcon";
import { BiSolidBook } from "react-icons/bi";
import { GrayBox, SetupInformation } from "./components/SetupComponents";
import { DropdownItem, Dropdown, DropdownMenu } from "./components/Dropdown";
import {
  getAffiliateBoard,
  getAllAffiliatedSubjectsWithId,
  getSchoolLevels,
} from "../../api/api";
import Skeleton from "react-loading-skeleton";
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const SetupAcademic = () => {
  const navigate = useNavigate();
  const [academicDetails, SetAcademicDetails] = React.useState([]);
  const [branchDetails, setBranchDetails] = React.useState([]);
  const [selectedLevels, setSelectedLevels] = React.useState(new Set());
  const [selectedAcademicYear, setSelectedAcademicYear] = React.useState(
    sessionStorage.getItem("academicYear") || ""
  );
  const [buttonLoading, setButtonLoading] = React.useState(false);

  const [selectedAffiliateBoard, setSelectedAffiliateBoard] =
    React.useState("");
  const [loading, setLoading] = React.useState(false);
  //eslint-disable-next-line
  const [isLoading, setIsLoading] = React.useState(false);
  //eslint-disable-next-line
  const [subjectsData, setSubjects] = React.useState([]);
  const [errors, setErrors] = React.useState({
    academicYear: "",
    affiliateBoard: "",
    levels: "",
  });

  React.useEffect(() => {
    const storedAffiliateBoard = sessionStorage.getItem("affiliateBoard");
    if (storedAffiliateBoard) {
      setSelectedAffiliateBoard(JSON.parse(storedAffiliateBoard));
    }

    const storedSelectedLevels = sessionStorage.getItem("selectedLevels");
    if (storedSelectedLevels) {
      setSelectedLevels(new Set(JSON.parse(storedSelectedLevels)));
    }
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await getAffiliateBoard();
        await new Promise((resolve) => setTimeout(resolve, 1000));
        SetAcademicDetails(res.response.affiliate_boards);

        const res2 = await getSchoolLevels();
        setBranchDetails(res2.response.school_levels);
      } catch (error) {
        console.error("Error fetching academic details", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchSubjects = async () => {
      setIsLoading(true);
      try {
        if (selectedAffiliateBoard.id) {
          const res = await getAllAffiliatedSubjectsWithId(
            selectedAffiliateBoard.id
          );
          setSubjects(res.response.results);
        }
      } catch (error) {
        console.error("Error fetching subjects", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubjects();
  }, [selectedAffiliateBoard]);

  const validateForm = () => {
    const newErrors = { academicYear: "", affiliateBoard: "", levels: "" };
    let isValid = true;

    if (!selectedAcademicYear) {
      newErrors.academicYear = "Academic year is required";
      isValid = false;
    }

    if (!selectedAffiliateBoard || !selectedAffiliateBoard.id) {
      newErrors.affiliateBoard = "Affiliate board is required";
      isValid = false;
    }

    if (selectedLevels.size === 0) {
      newErrors.levels = "At least one school level must be selected";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleLevelChange = (levelId) => {
    setSelectedLevels((prevSelectedLevels) => {
      const newSelectedLevels = new Set(prevSelectedLevels);
      if (newSelectedLevels.has(levelId)) {
        newSelectedLevels.delete(levelId);
      } else {
        newSelectedLevels.add(levelId);
      }
      return newSelectedLevels;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    setButtonLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));

      sessionStorage.setItem(
        "selectedLevels",
        JSON.stringify(Array.from(selectedLevels))
      );
      sessionStorage.setItem(
        "affiliateBoard",
        JSON.stringify(selectedAffiliateBoard)
      );

      sessionStorage.setItem("academicYear", selectedAcademicYear || "");

      navigate("/signup/finalise");
    } catch (error) {
      console.error("Error submitting academic setup", error);
      alert("Failed to submit setup.");
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <section className="setup-admin">
      <div className="setup-admin__header">
        <CustomIcon svg={<BiSolidBook />} />
        <div className="setup-admin__header-info">
          <span className="setup-admin__title">Setup Academic Details</span>
          <span
            className="setup-admin__subtitle"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <span>Provide essential academic details to tailor the</span>
            <span>system to your needs. </span>
          </span>
        </div>
      </div>
      <form className="setup-admin--form" onSubmit={handleSubmit}>
        <label className="admin__text-label">Academic year</label>
        <Dropdown
          trigger={
            <span>
              {selectedAcademicYear ? (
                <span
                  style={{
                    color: "var(--black-color)",
                    textTransform: "capitalize",
                    fontWeight: "500",
                  }}
                >
                  {selectedAcademicYear}
                </span>
              ) : (
                "Select an Academic year"
              )}
            </span>
          }
        >
          <DropdownMenu>
            <DropdownItem onClick={() => setSelectedAcademicYear("2023-2024")}>
              2023-2024
              <span>
                {selectedAcademicYear === "2023-2024" && (
                  <FaCheck color="var(--blue-color)" />
                )}
              </span>
            </DropdownItem>
            <DropdownItem onClick={() => setSelectedAcademicYear("2024-2025")}>
              2024-2025
              {selectedAcademicYear === "2024-2025" && (
                <FaCheck color="var(--blue-color)" />
              )}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        {errors.academicYear && (
          <span className="error__message">{errors.academicYear}</span>
        )}

        <label className="admin__text-label">Select Affiliate Board</label>
        <Dropdown
          trigger={
            <span>
              {selectedAffiliateBoard ? (
                <span
                  style={{
                    color: "var(--black-color)",
                    textTransform: "capitalize",
                    fontWeight: "500",
                  }}
                >
                  {selectedAffiliateBoard.board}
                </span>
              ) : (
                "Select an Affiliate Board"
              )}
            </span>
          }
        >
          <DropdownMenu>
            {loading ? (
              <>
                <DropdownItem>
                  <Skeleton height={10} />
                </DropdownItem>
                <DropdownItem>
                  <Skeleton height={10} />
                </DropdownItem>
                <DropdownItem>
                  <Skeleton height={10} />
                </DropdownItem>
              </>
            ) : (
              academicDetails.map((academicDetail) => (
                <DropdownItem
                  key={academicDetail.id}
                  onClick={() => setSelectedAffiliateBoard(academicDetail)}
                >
                  {academicDetail.board}
                  <span>
                    {selectedAffiliateBoard === academicDetail && (
                      <FaCheck color="var(--blue-color)" />
                    )}
                  </span>
                </DropdownItem>
              ))
            )}
          </DropdownMenu>
        </Dropdown>
        {errors.affiliateBoard && (
          <span className="error__message">{errors.affiliateBoard}</span>
        )}

        <SetupInformation
          text={"Manage subjects once setup is done"}
          link={"#"}
        />
        <div
          style={{
            margin: "1rem 0",
          }}
        >
          <label className="admin__text-label">Select School Levels</label>
          <div
            className="setup-school__levels"
            style={{
              border: errors.levels ? "1.4px solid red" : "",
            }}
          >
            {loading ? (
              <>
                <label className="setup-school__level--loading">
                  <Skeleton height={10} />
                </label>
                <label className="setup-school__level--loading">
                  <Skeleton height={10} />
                </label>
                <label className="setup-school__level--loading">
                  <Skeleton height={10} />
                </label>
                <label className="setup-school__level--loading">
                  <Skeleton height={10} />
                </label>
              </>
            ) : (
              branchDetails.map((grade) => (
                <label key={grade.id} className="setup-school__level">
                  <div>
                    <input
                      type="checkbox"
                      onChange={() => handleLevelChange(grade.id)}
                      checked={selectedLevels.has(grade.id)}
                    />
                    <span>{grade.level_name}</span>
                  </div>
                  <GrayBox
                    text={`${grade.start_class} to ${grade.end_class}`}
                  />
                </label>
              ))
            )}
          </div>
          {errors.levels && (
            <span className="error__message">{errors.levels}</span>
          )}
        </div>
        <button
          className={`setup-admin__button ${
            buttonLoading && "button__loading"
          }`}
          type="submit"
        >
          Continue
        </button>
      </form>
    </section>
  );
};

export default SetupAcademic;
