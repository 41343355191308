import React from "react";
import "./SearchBar.css";
import { CiSearch } from "react-icons/ci";
// import { TbSortDescending } from 'react-icons/tb'
// import { BsFilter } from 'react-icons/bs'

export const SearchBar = ({ placeholder="Search for a Student", data, handlesearchdata,width='' }) => {
  const filterByKeyValue = (key = "full_name", value) => {
    console.log(key, value);
    // return initialItems.filter((item) =>
    //   item[searchKey].toLowerCase().includes(searchTerm.toLowerCase())
    // );
    // if(item[key])
    // console.log(data);
    if(data.length && (typeof data[0] !== 'object')){
     const temp= data.filter((item) =>{return item.toLowerCase().includes(value.toLowerCase())});
     handlesearchdata(temp);
     return;
    }
    // console.log(data);
    {const temp = data.filter((item) => {
      return item[key].toLowerCase().includes(value.toLowerCase());
    });
    handlesearchdata(1,temp);

  }
    // con}sole.log(temp);
  };
  return (
    <div className="search-filter-sort">
      <div className="search-bar" style={{width:width}}>
        <div className="search-icon">
          <CiSearch size={19} />
        </div>
        <input
          type="text"
          style={{ color: "#99A0AE" }}
          placeholder={placeholder}
          onChange={(e) => filterByKeyValue("full_name", e.target.value)}
        />
        {/* <div className="shortcut-icon">⌘ 1</div> */}
      </div>
    </div>
  );
};
