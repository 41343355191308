import React from "react";
import "./Dropdown.css";
import PropTypes from "prop-types";
import {
  useOutsideClick,
  useToggle,
} from "../../FeeManagementModule/FeeStructureModule/hooks";

const Dropdown = React.forwardRef(
  ({ className, options = [], onSelect, defaultText, ...props }, ref) => {
    const [selectedOption, setSelectedOption] = React.useState(null);
    const { state: isOpen, handlers: dropdownHandler } = useToggle();
    const dropdownRef = React.useRef(null);
    useOutsideClick(dropdownRef, dropdownHandler.off);

    const handleSelect = (option) => {
      setSelectedOption(option);
      dropdownHandler.off();
      if (onSelect) {
        onSelect(option);
      }
    };

    return (
      <div className={`dropdown ${className}`} ref={dropdownRef} {...props}>
        <button
          onClick={() => dropdownHandler.toggle()}
          className="dropdown-button"
        >
          {selectedOption ? selectedOption.label : defaultText}
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="custom__dropdown--icon"
          >
            <path
              d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z"
              fill="#99A0AE"
            ></path>
          </svg>
        </button>
        {isOpen && (
          <div className="dropdown-menu">
            {options.map((option) => (
              <DropDownOption
                key={option.value}
                option={option}
                onClick={() => handleSelect(option)}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
);

Dropdown.displayName = "Dropdown";

const DropDownOption = React.forwardRef(({ option, ...props }, ref) => {
  return (
    <div className="dropdown-option" ref={ref} {...props}>
      {option.label}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
      >
        <path
          d="M5.50005 7.37912L12.3941 0.484375L13.4553 1.54488L5.50005 9.50012L0.727051 4.72712L1.78755 3.66663L5.50005 7.37912Z"
          fill="#335CFF"
        />
      </svg>
    </div>
  );
});

DropDownOption.displayName = "DropDownOption";

Dropdown.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  onSelect: PropTypes.func,
  defaultText: PropTypes.string,
};

DropDownOption.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

export { Dropdown, DropDownOption };
