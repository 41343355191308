import React from "react";
import CustomIcon from "./components/CustomIcon";
import { RiAccountPinBoxFill } from "react-icons/ri";
import "./SetupAdmin.css";
import {
  useToggle,
  useForm,
  useFormErrors,
} from "../../modules/FeeManagementModule/FeeStructureModule/hooks";
import { LockerSvg, MailSvg } from "../../assets/svgs";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { SetupDivider, SetupInformation } from "./components/SetupComponents";

const SetupAdmin = () => {
  const { state: showPassword, handlers } = useToggle();
  const [data, handleChange] = useForm({
    adminName: sessionStorage.getItem("adminName") || "",
    phoneNumber: sessionStorage.getItem("phoneNumber") || "",
    email: sessionStorage.getItem("email") || "",
    password: sessionStorage.getItem("password") || "",
  });
  const [errors, setFieldError, resetErrors] = useFormErrors({
    adminName: "",
    phoneNumber: "",
    email: "",
    password: "",
  });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[0-9]{10,}$/;
    return phoneRegex.test(phoneNumber);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;
    resetErrors();

    if (data.adminName.trim() === "") {
      setFieldError("adminName", "Admin name is required");
      valid = false;
    }

    if (!validatePhoneNumber(data.phoneNumber)) {
      setFieldError("phoneNumber", "Please enter a valid phone number");
      valid = false;
    }

    if (!validateEmail(data.email)) {
      setFieldError("email", "Please enter a valid email address");
      valid = false;
    }

    if (!validatePassword(data.password)) {
      setFieldError("password", "Password must be at least 6 characters long");
      valid = false;
    }

    if (valid) {
      setIsSubmitting(true);

      try {
        await new Promise((resolve) => setTimeout(resolve, 2000));

        sessionStorage.setItem("adminName", data.adminName);
        sessionStorage.setItem("phoneNumber", data.phoneNumber);
        sessionStorage.setItem("email", data.email);
        sessionStorage.setItem("password", data.password);

        sessionStorage.setItem("secondPage", true);
        navigate("/signup/setup/school");
      } catch (error) {
        console.error("Submission failed", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <section className="setup-admin">
      <div className="setup-admin__header">
        <CustomIcon svg={<RiAccountPinBoxFill />} />
        <div className="setup-admin__header-info">
          <span className="setup-admin__title">Setup Admin Details</span>
          <span className="setup-admin__subtitle">
            Let's start by setting up your admin details{" "}
          </span>
        </div>
      </div>
      <SetupDivider text={"super admin"} width={"75%"} />
      <form className="setup-admin--form" onSubmit={handleSubmit}>
        <label className="admin__text-label">Enter Name of the Admin</label>
        <input
          type="text"
          name="adminName"
          placeholder="Ex. Arjun Kumar"
          className={`admin__text-input ${
            errors.adminName ? "error__input" : ""
          }`}
          value={data.adminName}
          onChange={handleChange}
          autoComplete="off"
          disabled={isSubmitting}
        />
        {errors.adminName && (
          <span className="error__message">{errors.adminName}</span>
        )}

        <label className="admin__text-label">Phone Number</label>
        <input
          type="number"
          name="phoneNumber"
          placeholder="+91-(555) 000-0000"
          className={`admin__text-input ${
            errors.phoneNumber ? "error__input" : ""
          }`}
          value={data.phoneNumber}
          onChange={handleChange}
          autoComplete="off"
          disabled={isSubmitting}
        />
        {errors.phoneNumber && (
          <span className="error__message">{errors.phoneNumber}</span>
        )}

        <div className="setupAdmin__container--input-box">
          <label htmlFor="email" className="admin__text-label">
            Email Address
          </label>
          <div className="setup-admin__input-wrapper">
            <MailSvg className="input-icon" />
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Admin@gmail.com"
              className={`setup-admin__input setup-admin__input-with-icon ${
                errors.email ? "error__input" : ""
              }`}
              value={data.email}
              onChange={handleChange}
              autoComplete="off"
              disabled={isSubmitting}
            />
          </div>
          {errors.email && (
            <span className="error__message">{errors.email}</span>
          )}
        </div>

        <div className="setupAdmin__container--input-box">
          <label htmlFor="password" className="admin__text-label">
            Password
          </label>
          <div className="setup-admin__input-wrapper">
            <LockerSvg className="input-icon" />
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              placeholder="• • • • • • • • • •"
              className={`setup-admin__input setup-admin__input-with-icon ${
                errors.password ? "error__input" : ""
              }`}
              value={data.password}
              onChange={handleChange}
              autoComplete="off"
              disabled={isSubmitting}
            />
            {showPassword ? (
              <FiEye className="input-icon-right" onClick={handlers.toggle} />
            ) : (
              <FiEyeOff
                className="input-icon-right"
                onClick={handlers.toggle}
              />
            )}
          </div>
          {errors.password && (
            <span className="error__message">{errors.password}</span>
          )}
        </div>

        <SetupInformation
          text={"You can add other roles after you setup your school"}
          link={"#"}
          paddingRight={"0.5rem"}
        />
        <button
          className={`setup-admin__button ${
            isSubmitting ? "button__loading" : ""
          }`}
          disabled={isSubmitting}
        >
          Continue
        </button>
      </form>
    </section>
  );
};

export default SetupAdmin;
