import { useState, useEffect } from "react";
import UserContext from "./UserContext";
import { getUserInfo } from "../api/api";

export const UserContextProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(true);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken !== undefined && authToken !== "" && authToken !== null) {
      getUserInfo().then((user) => {
        if (!localStorage.getItem("branch_id")) {
          localStorage.setItem("branch_id", user.branch_id);
        }
        setUserInfo({ ...user, branch_id: localStorage.getItem("branch_id") });
      });
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
      localStorage.clear();
    }
  }, [loggedIn]);

  return (
    <UserContext.Provider
      value={{ loggedIn, setLoggedIn, userInfo, setUserInfo }}
    >
      {children}
    </UserContext.Provider>
  );
};
