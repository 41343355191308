import React, { useEffect, useRef, useState } from 'react'
import { Profileicon } from './Profileicon';
import "./AddFeeStructure.css";
import { Tooltip } from 'react-tooltip'
import { SlArrowRight } from "react-icons/sl";
import { SlArrowDown } from "react-icons/sl";
import { useName, useSelectedClasses, useDropdown, useOption } from '../hooks';
import { toast } from "sonner";
import { EditFeeStructureDetails, getClasses, getSectionsBasedOnclassID, handleContinue,getAllFeeStructuresNames,getFeeAssociatedClassSection } from '../../../../api/api';
import UserContext from "../../../../context/UserContext";
 import loading from "../../../../assets/loading.gif"
export const AddFeeDetails = ({ handleprogress, handletermdata,value2 }) => {

  
  const [name, setName] = useName(sessionStorage.getItem('name') || ""); 
  const [selectedClasses, setSelectedClasses] = useSelectedClasses(JSON.parse(sessionStorage.getItem('selectedClasses')) || []);
  const [isDropdownOpen, setIsDropdownOpen] = useDropdown(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useDropdown(false);
  const [option, setOption] = useOption(sessionStorage.getItem('option') || "Select an option");
  const [classes, setClasses] = useState([]);

  const terms = ['1 Terms', '2 Terms', '3 Terms', '4 Terms'];
  const [checkedItems, setCheckedItems] = useState(JSON.parse(sessionStorage.getItem('checkedItems'))  || new Array(classes.length).fill(false));
  const [sections1, setSectionData] = useState(JSON.parse(sessionStorage.getItem('sections1')) || []);
  const dropdownRef = useRef(null);
  const [nextpageloading,setnextpageloading]=useState(false);
  const [selectedclasssection,setselectedclasssection]=useState([]);
  const [feestructures,setfeestructures]=useState([]);
  const handleData = async () => {
    
    console.log("handling data")
    try{
     
      console.log(nextpageloading);
      handletermdata(Number(option[0]),selectedclasssection);
    let classesData = [];
    for (let i = 0; i < selectedClasses.length; i++) {
      for (let j = 0; j < sections1[selectedClasses[i].id].length; j++) {
        if (sections1[selectedClasses[i].id][j].checked) {
          classesData.push({
            "class_id": selectedClasses[i].id,
            "section_id": sections1[selectedClasses[i].id][j].section_id
          });
        }
      }
    }
    // editing api
    if(value2===true){
      const res=await EditFeeStructureDetails({
        "academic_program_id": 2,
        "branch_id": userInfo.branch_id,
        classes: classesData,
        terms: Number(option[0]),
        name: name
      });
     
      if(res.status === 200) {
        
        handleprogress(1);}
      return 
    }
    else
    handleContinue(handleprogress, "FeeStructureDetails", {
      "academic_program_id": 2,
      "branch_id": userInfo.branch_id,
      classes: classesData,
      terms: option,
      name: name,
      "active": 1
    });}
    finally{
      console.log(nextpageloading)
     
    }
  }

  const { userInfo } = React.useContext(UserContext);
  const [alreadyincludedsections,setalreadyincludedsection]=useState([]);
  useEffect(() => {
    const loadData = async () => {
      const data = await getClasses(userInfo.branch_id);
      const feestructures1= await getAllFeeStructuresNames(userInfo.branch_id); 
      const alreadyincludedsections=await getFeeAssociatedClassSection(userInfo.branch_id);
      setalreadyincludedsection(alreadyincludedsections);
      console.log(alreadyincludedsections,"Already included")
      // console.log(alreadyincludedsections);
      setfeestructures(feestructures1);
      setClasses(data);
      const result = {};
      let d={};
      if(alreadyincludedsections){
        for(let item of alreadyincludedsections){
            d[item.class_section_mapping_id]=true;
        }
      
      }
      if(data)
      for (let item of data) {
        const secData = await getSectionsBasedOnclassID(item.id);
        // console.log(secData);
        console.log(item);
      
        for(let item1 of secData){
          if(d[item1.class_section_mapping_id]===undefined)
            d[item1.class_section_mapping_id]=false;
   
        }
        let value=0;
        const newData = secData.map(section => {
          console.log("sections",section); // Print each section
          if(!d[section.class_section_mapping_id]) value=1;
          return { ...section, checked: !d[section.class_section_mapping_id] };
        });
        if(value)d[item.class]=false;
        else d[item.class]=true;
        result[item.id] = newData;
      }
      console.log("d    ",d)
      setSectionData(result);
      
    
        // console.log(d);
        setalreadyincludedsection(d);
        // console.log(d);
    };

    loadData();
    // eslint-disable-next-line
  },[userInfo.branch_id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setIsDropdownOpen2(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleSections = (index1, section_id,index) => {
    let newTempData = sections1[index1];
    let count=0;
    newTempData.forEach(section => {
      if (section.section_id === section_id) {
        section.checked = !section.checked;
      }
      if(section.checked) count++;
    });
    if(count===0) {
      
    setCheckedItems((prevState) => {
      const newCheckedItems = [...prevState];
      newCheckedItems[index] = !newCheckedItems[index];
      return newCheckedItems;
    });
    }
    setSectionData({
      ...sections1,
      [index1]: newTempData
    });
  }

  const handleOptionSelect = (termName) => {
    setOption(termName);
    setIsDropdownOpen2(!isDropdownOpen2);
  }
useEffect(() => {
  handleselectsectionclass(); 
  // eslint-disable-next-line
}, [selectedClasses])

  const handleClassChange = async (id, className) => {
    setSelectedClasses(prevSelected => {
      if (prevSelected.some(item => item.className === className)) {
        return prevSelected.filter(item => item.className !== className);
      } else {
        return [...prevSelected, { className, id }];
      }
    });
  
  };
  const showMessage = (value) => {
    if(value)
    { toast(
      <div className="modal__success">
        <div className="modal__success-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8 15.5C3.85775 15.5 0.5 12.1423 0.5 8C0.5 3.85775 3.85775 0.5 8 0.5C12.1423 0.5 15.5 3.85775 15.5 8C15.5 12.1423 12.1423 15.5 8 15.5ZM7.25225 11L12.5548 5.69675L11.4943 4.63625L7.25225 8.879L5.1305 6.75725L4.07 7.81775L7.25225 11Z"
              fill="#1FC16B"
            />
          </svg>
        </div>
        <div className="modal__success-message">
          <span className="modal__success-text">
            Fee Structure Present with this name
          </span>
          <span className="modal__success-subtext">
            {/* Please insert delete__modal description here. */}
          </span>
        </div>
        <button
          className="modal__cancel-toast-button"
          onClick={() => toast.dismiss()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <path
              d="M4.99956 3.93955L8.71206 0.227051L9.77256 1.28755L6.06006 5.00005L9.77256 8.71255L8.71206 9.77305L4.99956 6.06055L1.28706 9.77305L0.226562 8.71255L3.93906 5.00005L0.226562 1.28755L1.28706 0.227051L4.99956 3.93955Z"
              fill="#525866"
            />
          </svg>
        </button>
      </div>,
      {
        position: "top-right",
        autoClose: false,
        // closeOnClick: false,
        draggable: false
      }
    );}
    else toast.dismiss();
    // handlers.off();
  };
  const handleClickCheckbox = async (index, id, className) => {

    if (checkedItems[index]) setSelectedClasses([...selectedClasses, { className, id }]);
    setCheckedItems((prevState) => {
      const newCheckedItems = [...prevState];
      newCheckedItems[index] = !newCheckedItems[index];
      return newCheckedItems;
    });
    await handleClassChange(id, className);
  };
const handleselectsectionclass=()=>{
  if(!selectedClasses || !sections1)return;
  let classesData = [];
  // console.log(selectedClasses)
  for (let i = 0; i < selectedClasses.length; i++) {
    for (let j = 0; j < sections1[selectedClasses[i].id].length; j++) {
      if (sections1[selectedClasses[i].id][j].checked) {
        console.log(sections1[selectedClasses[i].id][j]);
        console.log("selected",selectedClasses[i].className+"-"+((sections1[selectedClasses[i].id][j]).section))
        classesData.push(selectedClasses[i].className+"-"+(sections1[selectedClasses[i].id][j].section));
      }
    }
  }
  setselectedclasssection(classesData);
}
// const [warning,setwarning] =useState(false)
useEffect(()=>{
if(feestructures.length){
  let i=0;
  feestructures.forEach(item =>{
    if(item===name){
      i=1;
    }
  });
  if(i===1){
    showMessage(true);
  }
  else showMessage(false);
}
// eslint-disable-next-line
},[name])
  useEffect(() => { 
    sessionStorage.setItem('name', name);
    sessionStorage.setItem('selectedClasses', JSON.stringify(selectedClasses));
    sessionStorage.setItem('option', option);
    sessionStorage.setItem('sections1', JSON.stringify(sections1));
    sessionStorage.setItem('checkedItems', JSON.stringify(checkedItems));
  }, [name, selectedClasses, option, sections1,checkedItems]);
  const chunkSize = 5; // Number of items per row

  // Function to chunk the array into arrays of specified size
  const chunkArray = (arr, size) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (_, index) =>
      arr.slice(index * size, index * size + size)
    );
  };

  // Chunk the classNames array
  const classChunks = chunkArray(selectedclasssection, chunkSize);
if(nextpageloading){
  return <img className='LoadImage' src={loading} alt='LoadImage' style={{width:'50px',height:'50px',paddingTop:'140px'}} />
}
  return (
    <div className="form-container" >
      
      <div className="form-header">
        <Profileicon />
        <h2> Create New Fee Structure </h2>
        <p>Enter the details to define a new fee structure</p>
      </div>
      <form>
        <div className="form-group">
          <label htmlFor="name"> Fee Structure Name <div className="mandatory" style={{ color: "rgba(251, 55, 72, 1)" }}> *</div></label>
          <input
            type="text"
            id="name"
            placeholder='Enter Name of Fee Structure'
            value={name}
            onChange={(e) => {
              const { value } = e.target;
              if (/^[a-zA-Z0-9 ]*$/.test(value)) { // Allow characters and spaces
                setName(value);
              }
            }}
          />
        </div>
        <Tooltip id="my-tooltip"  style={{ maxWidth: '371px' }}/>
        <label >Applicable Classes <div className="mandatory" style={{ color: "rgba(251, 55, 72, 1)" }}>*</div></label>
        <div className="select-classes-container">
          <div className="selected-classes" style={{  alignContent: 'left', justifyContent: 'left' }} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <div className="divselectclass">

            
            {classChunks.map((chunk, rowIndex) => (
              
          <div key={rowIndex} className="class-row" >
            {chunk.map((item,index) => (
              <span key={index} className="class-tag" style={{ width: '64px' }}>{item}</span>
            ))}
          </div>
         
        ))}
        </div>
        <span className="dropdown-icon1"><SlArrowDown size={10} strokeWidth={78} /></span>
          </div>
          {isDropdownOpen && (
            <div className="dropdown-menu1" ref={dropdownRef}>
              {( sections1.length===0) &&<img className='LoadImage' src={loading} alt='LoadImage' style={{width:'50px',height:'50px',paddingLeft:'40%',paddingTop:'40px'}} />}
              {sections1.length!==0 && classes  && classes.map((item, index) => (
                <div className="" key={index}  style={{cursor:alreadyincludedsections[item.class]? 'not-allowed':'pointer'}}>
                  <div className="dropdown-item"  data-tooltip-id="my-tooltip"
                              data-tooltip-content={alreadyincludedsections[item.class]?"A fee structure is already defined for this class. Please delete the previous one to create a new fee structure":''}
                              data-tooltip-place="left" 
                              style={{cursor:alreadyincludedsections[item.class]? 'not-allowed':'pointer'}} >

                    
                    {!checkedItems[index] && (
                      <div className="emptycheckbox" onClick={() => {if(!alreadyincludedsections[item.class])handleClickCheckbox(index, item.id, item.class);}}></div>
                    )}
                    {checkedItems[index] && (
                      <div className="checkbox-minus" onClick={() => { handleClickCheckbox(index, item.id, item.class);}}></div>
                    )}
                    <div className='dropdownrightelements'>
                      <label htmlFor={item.class} style={{ fontWeight: 400, width: '212px' }}>{item.class} </label>
                      <div className="righticon1" style={{ marginLeft: '170px' }}>
                        {checkedItems[index] && <span className="dropdown-icon" style={{ marginLeft: '4px' }}><SlArrowDown size={10} strokeWidth={78} /></span>}
                        {!checkedItems[index] && <SlArrowRight size={9} strokeWidth={70} />}
                      </div>
                    </div>
                  </div>
                  <div className="subdropdown">
                    {checkedItems[index] && !sections1[item.id] && <img className='LoadImage' src={loading} alt='LoadImage' style={{width:'50px',height:'50px',paddingLeft:'40%',paddingTop:'40px'}} />}
                    {checkedItems[index] && (
                      <div className="subclass-dropdown">
                        {sections1[item.id]?.map((subclass, idx) => (
                          <div key={idx} className="subclass-item" style={{ marginTop: '10px', marginLeft: '13px' }} >
                            <div style={{ display: 'flex', alignContent: 'center' }}>
                              { !subclass.checked && <span className={'emptycheckbox'} data-tooltip-id="my-tooltip"
                              data-tooltip-content={alreadyincludedsections[item.class]?"A fee structure is already defined for this class. Please delete the previous one to create a new fee structure":''}
                              data-tooltip-place="left"  style={{ marginTop: '4px',cursor:alreadyincludedsections[subclass.class_section_mapping_id]?'not-allowed':'pointer' }}   
                             
                              onClick={() => { if (!alreadyincludedsections[subclass.class_section_mapping_id]) {
                                                                                                                                                                                                            handleSections(item.id, subclass.section_id,index);
                                                                                                                                                                                                          };handleselectsectionclass() }}></span>}
                              { subclass.checked && <span className={'checkbox checked'} style={{ marginTop: '4px', cursor: 'pointer' }} onClick={() => { handleSections(item.id, subclass.section_id,index);handleselectsectionclass() }}></span>}
                              <label>{subclass.section}</label>
                            </div>
                            <span className="sec-tag" >Name of programme</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <label> Number of Fee Term(s)  <div className="mandatory" style={{ color: "rgba(251, 55, 72, 1)" }}>*</div></label>
        <div className="select-classes-container">
          <div className="AcademicDropdown" onClick={() => setIsDropdownOpen2(!isDropdownOpen2)}>
            {option}
            <span className="dropdown-icon1"><SlArrowDown size={10} strokeWidth={78} /></span>
          </div>
          {isDropdownOpen2 && (
            <div className="dropdown-menu" ref={dropdownRef}>
              {terms.map((termName, index) => (
                <div key={index} className="dropdown-item" >
                  <label style={{ fontWeight: 400, width: '212px' }} onClick={() => { handleOptionSelect(termName) }}>{termName} </label>
                </div>
              ))}
            </div>
          )}
        </div>
        {selectedClasses.length !== 0 && name !== "" && option !== "Select an option" && <button className="continue-button" style={{ marginLeft: '140px' }} onClick={(e) => { e.preventDefault(); setnextpageloading(true);handleData(); }}>Save and Continue
          <div className="righticon" style={{marginLeft:'6px'}}>
            <SlArrowRight size={9} strokeWidth={70} />
          </div>
        </button>
        }
        {(selectedClasses.length === 0 || name === "" || option === "Select an option") && <button className="continue-button" onClick={(e) => { e.preventDefault() }} style={{ display:'flex',justifyContent:'center',marginLeft: '140px', background: 'rgba(245, 247, 250, 1)', color: 'rgba(202, 207, 216, 1)' }}
        >Save and Continue
          <div className="righticon" style={{marginLeft:'6px'}}>
            <SlArrowRight size={9} strokeWidth={70} />
          </div>
        </button>}
      </form>
    </div>
  );
}
