import PropTypes from "prop-types";
import "./Sheet.css";
import React, { useEffect } from "react";
import { useOutsideClick } from "../FeeStructureModule/hooks";

const Sheet = ({
  width,
  children,
  headerTitle,
  isSheetOpen,
  handlers,
  className,
}) => {
  const sheetRef = React.useRef(null);
  useOutsideClick(sheetRef, handlers.off);

  useEffect(() => {
    if (isSheetOpen) {
      document.body.classList.add("no-scroll", "sheet-open");
    } else {
      document.body.classList.remove("no-scroll", "sheet-open");
    }

    return () => {
      document.body.classList.remove("no-scroll", "sheet-open");
    };
  }, [isSheetOpen]);

  return (
    <section
      className={`sheet ${isSheetOpen ? "sheet--open" : ""} ${className}`}
      style={{
        width: `${width}%`,
      }}
      ref={sheetRef}
    >
      <header className="collect-fee__header">
        <h2 className="collect-fee__title">{headerTitle}</h2>
        <button className="collect-fee__close-btn" onClick={handlers.toggle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <path
              d="M4.99999 3.93906L8.71249 0.226562L9.77299 1.28706L6.06049 4.99956L9.77299 8.71206L8.71249 9.77256L4.99999 6.06006L1.28749 9.77256L0.22699 8.71206L3.93949 4.99956L0.22699 1.28706L1.28749 0.226562L4.99999 3.93906Z"
              fill="#525866"
            />
          </svg>
        </button>
      </header>
      <div className="sheet__content-wrap">{children}</div>
    </section>
  );
};

const Content = ({ children }) => {
  return <div className="sheet__content">{children}</div>;
};

const Footer = ({ children, className }) => {
  return <footer className={`sheet__footer ${className}`}>{children}</footer>;
};

Sheet.propTypes = {
  width: PropTypes.number.isRequired,
  children: PropTypes.node,
  headerTitle: PropTypes.string,
  isSheetOpen: PropTypes.bool.isRequired,
  handlers: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Content.propTypes = {
  children: PropTypes.node.isRequired,
};

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export { Sheet, Content, Footer };
