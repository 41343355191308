import React from "react";
import {
  useOutsideClick,
  useToggle,
} from "../modules/FeeManagementModule/FeeStructureModule/hooks";
import "./Sidebar.css";
import {
  SidebarSupportConstants,
  SidebarItems,
} from "./components/SidebarItems";
import UserContext from "../context/UserContext";
import { getBranchDetails } from "../api/api";

const Sidebar = () => {
  const { state: isDropdownOpen, handlers: dropdownHandler } = useToggle();
  const { userInfo, setUserInfo } = React.useContext(UserContext);
  const [branchData, setBranchData] = React.useState([]);
  const [selectedBranch, setSelectedBranch] = React.useState(null);
  const dropdownRef = React.useRef(null);

  useOutsideClick(dropdownRef, dropdownHandler.off);

  React.useEffect(() => {
    if (userInfo.branch_id != null && userInfo.branch_id !== undefined) {
      const storedBranchId = localStorage.getItem("branch_id");
      if (userInfo.role === "super_admin") {
        if (
          userInfo.organization_id != null &&
          userInfo.organization_id !== undefined
        ) {
          getBranchDetails("org", userInfo.organization_id).then((res) => {
            const branches = res.response.results;
            setBranchData(branches);

            if (storedBranchId) {
              const selectedBranchFromStorage = branches.find(
                (branch) => branch.branch_id === parseInt(storedBranchId, 10)
              );
              if (selectedBranchFromStorage) {
                setSelectedBranch(selectedBranchFromStorage);
              } else {
                setSelectedBranch(branches[0]);
              }
            } else {
              setSelectedBranch(branches[0]);
            }
          });
        }
      } else {
        getBranchDetails("branch", userInfo.branch_id).then((res) => {
          const branch = res.response.results[0];
          setSelectedBranch(branch);
        });
      }
    }
  }, [userInfo.branch_id, userInfo.organization_id, userInfo.role]);

  React.useEffect(() => {
    if (selectedBranch) {
      localStorage.setItem("branch_id", selectedBranch.branch_id);
      localStorage.setItem("board_id", selectedBranch.board_id);
      setUserInfo((prev) => ({
        ...prev,
        branch_id: selectedBranch.branch_id,
        branch_name: selectedBranch.branch_name,
      }));
    }
  }, [selectedBranch, setUserInfo]);

  const handleBranchSelect = (branch) => {
    setSelectedBranch(branch);
    dropdownHandler.toggle();
  };

  return (
    <aside id="default-sidebar" className="main-sidebar">
      <div className="sidebar__content">
        <div className="sidebar__dropdown__container" ref={dropdownRef}>
          <div className="sidebar__logo">
            <img
              src={`https://api.dicebear.com/8.x/initials/svg?seed=${selectedBranch?.branch_name}`}
              alt={selectedBranch?.branch_name}
              className="sidebar__logo-image"
            />
            <div className="sidebar__school-info">
              <span className="sidebar__school-name">
                {selectedBranch?.branch_name}
              </span>
              <span className="sidebar__other-detail">HR Management</span>
            </div>
            <div
              className={`sidebar__toggle-icon ${
                userInfo.role !== "super_admin" &&
                "sidebar__toggle-icon-disabled"
              }`}
              onClick={dropdownHandler.toggle}
            >
              <svg
                width="10"
                height="14"
                viewBox="0 0 10 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="sidebar__icon"
              >
                <path
                  d="M9.31052 4.94721L5.00003 0.636719L0.689557 4.94721L1.67165 5.92933L5.00003 2.6009L8.32843 5.92933L9.31052 4.94721ZM0.689453 9.0544L4.99996 13.365L9.31045 9.0544L8.32836 8.07232L4.99996 11.4007L1.67154 8.07232L0.689453 9.0544Z"
                  fill="#111"
                />
              </svg>
            </div>
          </div>
          <div className="sidebar__underline"></div>
          {isDropdownOpen && userInfo.role === "super_admin" && (
            <div className="sidebar__dropdown--menu">
              {branchData.map((branch_item) => (
                <div
                  className="sidebar__dropdown--menu-item"
                  key={branch_item.branch_id}
                  onClick={() => handleBranchSelect(branch_item)}
                >
                  <div className="sidebar__dropdown--menu__item">
                    <img
                      src={`https://api.dicebear.com/8.x/initials/svg?seed=${branch_item.branch_name}`}
                      alt={branch_item.branch_name}
                      className="sidebar__logo-image--item"
                    />
                    <span className="sidebar__dropdown--menu-text">
                      {branch_item.branch_name}
                    </span>
                  </div>
                  {selectedBranch?.branch_id === branch_item.branch_id && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="10"
                      viewBox="0 0 14 10"
                      fill="none"
                    >
                      <path
                        d="M5.50005 7.37912L12.3941 0.484375L13.4553 1.54488L5.50005 9.50012L0.727051 4.72712L1.78755 3.66663L5.50005 7.37912Z"
                        fill="#335CFF"
                      />
                    </svg>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="sidebar__main-menu">
          <SidebarItems />
          <SidebarSupportConstants />
        </div>
        <div className="sidebar__footer">
          <div className="sidebar__divider"></div>
          <div className="sidebar__profile">
            <img
              src={`https://api.dicebear.com/9.x/initials/svg?seed=${userInfo.user_name}`}
              alt="Logo"
              className="sidebar__profile-image"
            />
            <div className="sidebar__profile-info">
              <span className="sidebar__profile-name">
                {userInfo.user_name}
              </span>
              <span className="sidebar__profile-email">
                {userInfo.user_email}
              </span>
            </div>
            <div className="sidebar__toggle-icon">
              <svg
                width="25"
                height="25"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="sidebar__icon"
              >
                <path
                  d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z"
                  fill="#111"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
