import React from "react";
import "./RouteHeader.css";
import PropTypes from "prop-types";
import Search from "./Search";
import { IoIosArrowForward } from "react-icons/io";
import { useOutsideClick, useToggle } from "../FeeStructureModule/hooks";
import { NavLink, useNavigate } from "react-router-dom";

const RouteHeader = ({ mainroute=null, routeHeader, routes, search }) => {
  const navigate = useNavigate();
  const [dropdownPosition, setDropdownPosition] = React.useState({
    top: 0,
    left: 0,
  });
  const dotsRef = React.useRef(null);
  const dropdownRef = React.useRef(null);
  const { state: showDropdown, handlers: dropdownHandlers } = useToggle();
  useOutsideClick(dropdownRef, dropdownHandlers.off);

  const displayLimit = 3;

  const handleDotsClick = () => {
    if (dotsRef.current) {
      const rect = dotsRef.current.getBoundingClientRect();
      setDropdownPosition({ top: rect.bottom, left: rect.left });
    }
    dropdownHandlers.toggle();
  };

  return (
    <React.Fragment>
      <header className="routeHeader__parent">
        <div className="routeHeader-container">
          <div className="routeHeader">
            <p className="routeHeader__text" style={{cursor:mainroute!==null?'pointer':''}} onClick={()=>{if(mainroute!==null)navigate(mainroute)}}>{routeHeader}</p>
            {routes.length <= displayLimit ? (
              routes.map(({ name, link }, index) => (
                <React.Fragment key={index}>
                  <span className="route-header__separator">
                    <IoIosArrowForward size={"0.85rem"} />
                  </span>
                  <NavLink to={link} className="routeHeader__text">
                    {name}
                  </NavLink>
                </React.Fragment>
              ))
            ) : (
              <>
                <span className="route-header__separator">
                  <IoIosArrowForward size={"0.85rem"} />
                </span>
                <NavLink to={routes[0].link} className="routeHeader__text">
                  {routes[0].name}
                </NavLink>
                <span
                  className="route-header__separator"
                  ref={dotsRef}
                  onClick={handleDotsClick}
                  style={{ cursor: "pointer" }}
                >
                  <IoIosArrowForward size={"0.85rem"} />
                </span>
                <p
                  className="routeHeader__text"
                  onClick={handleDotsClick}
                  style={{ cursor: "pointer" }}
                >
                  ...
                </p>
                <span className="route-header__separator">
                  {routes.length!==0 && <IoIosArrowForward size={"0.85rem"} />}
                </span>
                <NavLink
                  to={routes[routes.length - 1].link}
                  className="routeHeader__text"
                >
                  {routes[routes.length - 1].name}
                </NavLink>
              </>
            )}
            {showDropdown && (
              <div
                className="routeHeader__dropdown"
                style={{
                  position: "absolute",
                  top: dropdownPosition.top,
                  left: dropdownPosition.left,
                }}
                ref={dropdownRef}
              >
                {routes.map(({ name, link }, index) => (
                  <NavLink
                    key={index}
                    to={link}
                    className="routeHeader__dropdown-item"
                  >
                    {name}
                  </NavLink>
                ))}
              </div>
            )}
          </div>
          {search && (
            <div className="routeHeader__search">
              <Search placeholder={"Search.."} shortcut />
            </div>
          )}
        </div>
        <div className="routeHeader__divider"></div>
      </header>
    </React.Fragment>
  );
};

RouteHeader.propTypes = {
  routeHeader: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
  search: PropTypes.bool,
};

export default RouteHeader;
