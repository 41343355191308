import React, { useState } from "react";
import CustomIcon from "./components/CustomIcon";
import { RiBuildingFill } from "react-icons/ri";
import "./SetupAdmin.css";
import { SetupDivider, SetupInformation } from "./components/SetupComponents";
import { HiExclamationCircle } from "react-icons/hi2";
import { MailSvg } from "../../assets/svgs";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const SetupSchool = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    admin_phone_number: `${sessionStorage.getItem("phoneNumber")}`,
    admin_email: `${sessionStorage.getItem("email")}`,
    admin_password: `${sessionStorage.getItem("password")}`,
    admin_full_name: `${sessionStorage.getItem("adminName")}`,
    multiple_branches:
      parseInt(sessionStorage.getItem("multiple_branches"), 10) || 0,
    total_branches: parseInt(sessionStorage.getItem("total_branches"), 10) || 0,
    main_branch_address: sessionStorage.getItem("main_branch_address") || "",
    main_branch_name: sessionStorage.getItem("main_branch_name") || "",
    main_branch_phone_number:
      sessionStorage.getItem("main_branch_phone_number") || "",
    main_branch_email: sessionStorage.getItem("main_branch_email") || "",
    logo_url: "",
  });

  const [selectedImages, setSelectedImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [branchType, setBranchType] = useState("single");

  React.useEffect(() => {
    const multipleBranches = sessionStorage.getItem("multiple_branches");
    const totalBranches = sessionStorage.getItem("total_branches");
    if (multipleBranches && totalBranches) {
      if (multipleBranches === "0") {
        console.log("I am in this");
        setBranchType("single");
      } else {
        setBranchType("multiple");
      }
    } else {
      setBranchType("single");
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "branchType") {
      setBranchType(value);
      if (value === "single") {
        setFormData((prevData) => ({
          ...prevData,
          total_branches: 1,
          multiple_branches: 0,
        }));
      } else if (value === "multiple") {
        setFormData((prevData) => ({
          ...prevData,
          total_branches: 0,
          multiple_branches: 1,
        }));
      }
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages(files.map((file) => URL.createObjectURL(file)));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.main_branch_name.trim()) {
      newErrors.schoolName = "School name is required";
    }
    if (!formData.main_branch_address.trim()) {
      newErrors.address = "Address is required";
    }
    if (!formData.main_branch_phone_number.match(/^[0-9]{10,}$/)) {
      newErrors.phoneNumber = "Please enter a valid phone number";
    }
    if (!formData.main_branch_email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      newErrors.email = "Please enter a valid email address";
    }
    if (
      branchType === "multiple" &&
      (!formData.total_branches || formData.total_branches <= 1)
    ) {
      newErrors.branchCount =
        "Please enter a number of branches greater than 1";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSubmitting(true);

      try {
        await new Promise((resolve) => {
          setTimeout(resolve, 2000);
        });
        Object.keys(formData).forEach((key) => {
          sessionStorage.setItem(key, formData[key]);
        });
        sessionStorage.setItem("thirdPage", true);
        navigate("/signup/setup/academic");
      } catch (error) {
        toast.error("Try again!!!");
      } finally {
        setSelectedImages([]);
        setErrors({});
        setIsSubmitting(false);
      }
    }
  };

  return (
    <section className="setup-admin">
      <div className="setup-admin__header">
        <CustomIcon svg={<RiBuildingFill />} />
        <div className="setup-admin__header-info">
          <span className="setup-admin__title">Setup your School</span>
          <span className="setup-admin__subtitle">
            Let's gather some basic information about your school
          </span>
        </div>
      </div>
      <form className="setup-admin--form" onSubmit={handleSubmit}>
        <label className="admin__text-label">Select an option</label>
        <div className="setup-school__options">
          <label className="setup-school__option">
            <input
              type="checkbox"
              name="branchType"
              value="single"
              checked={branchType === "single"}
              onChange={handleChange}
            />
            <span>Single Branch</span>
          </label>
          <label className="setup-school__option">
            <input
              type="checkbox"
              name="branchType"
              value="multiple"
              checked={branchType === "multiple"}
              onChange={handleChange}
            />
            <span>Multiple Branch</span>
          </label>
        </div>
        {branchType === "multiple" && (
          <>
            <label className="admin__text-label">
              Select how many branches
            </label>
            <input
              type="number"
              name="total_branches"
              className={`admin__text-input ${
                errors.branchCount ? "error__input" : ""
              }`}
              autoComplete="off"
              value={formData.total_branches}
              onChange={handleChange}
              onWheel={() => document.activeElement.blur()}
            />
            {errors.branchCount && (
              <span className="error__message">{errors.branchCount}</span>
            )}
          </>
        )}
        <SetupInformation
          text={"You can add other branches after you setup the main branch"}
          link={"#"}
        />
        <SetupDivider text={"main branch"} width={"100%"} />
        <label className="admin__text-label">School Logo</label>
        <div className="logo-container">
          <label className="custom-file-input">
            <input
              type="file"
              multiple
              onChange={handleImageChange}
              aria-label="Upload School Logo"
            />
            <FaPlus />
          </label>
          <div className="logo-images">
            {selectedImages.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`Selected ${index}`}
                width={50}
                height={50}
                className="selected-logo_images"
              />
            ))}
            <div className="logo_images"></div>
            <div className="logo_images"></div>
            <div className="logo_images"></div>
            <div className="logo_images"></div>
          </div>
        </div>

        <label className="admin__text-label">Enter Name of the school</label>
        <input
          type="text"
          name="main_branch_name"
          className={`admin__text-input ${
            errors.schoolName ? "error__input" : ""
          }`}
          autoComplete="off"
          placeholder="Ex. Grahith Vidyalaya"
          value={formData.main_branch_name}
          onChange={handleChange}
        />
        {errors.schoolName && (
          <span className="error__message">{errors.schoolName}</span>
        )}

        <label className="admin__text-label">Address</label>
        <div className="textarea-wrapper">
          <textarea
            name="main_branch_address"
            className={`admin__text-input admin__text-textarea ${
              errors.address ? "error__input" : ""
            }`}
            autoComplete="off"
            placeholder="Add your address here"
            rows={4}
            maxLength={200}
            value={formData.main_branch_address}
            onChange={handleChange}
            disabled={isSubmitting}
          />
          <div className="char-count">
            {formData.main_branch_address.length} / 200
          </div>
        </div>
        {errors.address && (
          <span className="error__message">{errors.address}</span>
        )}

        <label className="admin__text-label">Phone Number</label>
        <input
          type="number"
          name="main_branch_phone_number"
          className={`admin__text-input ${
            errors.phoneNumber ? "error__input" : ""
          }`}
          autoComplete="off"
          placeholder="+91-(555) 000-0000"
          value={formData.main_branch_phone_number}
          onChange={handleChange}
          disabled={isSubmitting}
        />
        {errors.phoneNumber && (
          <span className="error__message">{errors.phoneNumber}</span>
        )}
        <span className="exclamation-sentence">
          <HiExclamationCircle color="#99A0AE" size={"0.85rem"} />
          Add single point of contact for your school, Ex: Reception Contact
        </span>
        <div className="setupAdmin__container--input-box">
          <label htmlFor="email" className="admin__text-label">
            Email Address
          </label>
          <div className="setup-admin__input-wrapper">
            <MailSvg className="input-icon" />
            <input
              type="email"
              name="main_branch_email"
              id="email"
              placeholder="hello@alignui.com"
              className={`setup-admin__input setup-admin__input-with-icon ${
                errors.email ? "error__input" : ""
              }`}
              autoComplete="off"
              value={formData.main_branch_email}
              onChange={handleChange}
              disabled={isSubmitting}
            />
          </div>
          {errors.email && (
            <span className="error__message">{errors.email}</span>
          )}
        </div>
        <button
          type="submit"
          className={`setup-admin__button ${isSubmitting && "button__loading"}
            `}
          disabled={isSubmitting}
        >
          Continue
        </button>
      </form>
    </section>
  );
};

export default SetupSchool;
