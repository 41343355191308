import React from "react";
import PropTypes from "prop-types";
import "./Dropdown.css";
import {
  useOutsideClick,
  useToggle,
} from "../../../modules/FeeManagementModule/FeeStructureModule/hooks";
import { MdKeyboardArrowDown } from "react-icons/md";

const DropdownTrigger = ({ onClick, children, className, ...props }) => (
  <div
    className={`custom__select-trigger ${className || ""}`}
    onClick={onClick}
    {...props}
  >
    {children}
    <MdKeyboardArrowDown size={"1rem"} />
  </div>
);

DropdownTrigger.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const Dropdown = ({ children, trigger }) => {
  const { state: isOpen, handlers } = useToggle();
  const dropdownRef = React.useRef(null);

  useOutsideClick(dropdownRef, handlers.off);

  return (
    <div>
      <DropdownTrigger
        onClick={(e) => {
          e.preventDefault();
          handlers.toggle();
        }}
      >
        {trigger}
      </DropdownTrigger>
      {isOpen && (
        <div className="custom__select--overlay" ref={dropdownRef}>
          {React.cloneElement(children, { isOpen, handlers })}
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  children: PropTypes.element.isRequired,
  trigger: PropTypes.node.isRequired,
};

// DropdownMenu Component
const DropdownMenu = ({ children, isOpen }) => (
  <div className={`custom__select-menu ${isOpen ? "show-select" : ""}`}>
    {children}
  </div>
);

DropdownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
};

const DropdownItem = ({ onClick, children, className, ...props }) => (
  <div
    className={`custom__select-menu-item ${className || ""}`}
    onClick={onClick}
  >
    {children}
  </div>
);

DropdownItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export { Dropdown, DropdownMenu, DropdownTrigger, DropdownItem };
