import React, { useEffect, useState } from "react";
import "./StudentGeneralInfo.css";
import { FiEdit3 } from "react-icons/fi";
import StatusButton from "../FeeManagementModule/FeeStructureModule/components/StatusButton";
import { StudentInfoEditSilder } from "./Components/StudentInfoEditSilder";
import { PersonalInfoEditSilder } from "./Components/PersonalInfoEditSlider";
import { GuardianInfoEditSlider } from "./Components/GuardianInfoEditSlider";
import { useLocation } from "react-router-dom";
import { getStudentDetailsStudentDirectory } from "../../api/api";
import Skeleton from "react-loading-skeleton";
export const findnull = (value) => {
  if (value === null || value === undefined || value === "") {
    return "-";
  }
  return value;
};
export const StudentGeneralInfo = () => {
  const location = useLocation();
  const userid = location.pathname.split("/")[3];
  const [studentinfoedit,setstudentinfoedit]=useState(false);
  const [personalinfoedit, setpersonalinfoedit] = useState(false);
  const [guardianinfoedit, setguardianinfoedit] = useState(false);
  const [data,setdata]=useState([]);
  const [loading,setloading]=useState(true);
  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        setloading(true);
        const data = await getStudentDetailsStudentDirectory(userid);
        setdata(data);

        console.log(data);
      } finally {
        setloading(false);
      }
    };
    fetchStudentDetails();
    //eslint-disable-next-line
  }, [studentinfoedit, personalinfoedit, guardianinfoedit]);
  function formatDate(dateString) {
    const date = new Date(dateString);
    // console.log(date);

    
    const options = { day: "numeric", month: "long", year: "numeric" };
    if (date.toLocaleDateString("en-GB", options) === "Invalid Date") return "000-00-00"
      return date.toLocaleDateString("en-GB", options);
  }
  
  return (
    <div className="StudentGeneralInfodiv">
      <div className="StudentGeneralInfodivCard">
        <div className="header">
          Student info
          <FiEdit3
            size={"1rem"}
            className="edit-icon"
            color="#335CFF"
            onClick={() => {
              setstudentinfoedit(true);
            }}
          />
        </div>
        <div className="StudentGeneralInfodivCardItem">
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>FIRST NAME</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && data.first_name}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>LAST NAME</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.last_name)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>CLASS & SECTION</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && `${data.class}-${data.section}`}
            </div>
            {/* <div className="StudentGeneralInfodivCardItembody">
              <span>ROLL NUMBER</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && data.student_id === null ? "null" : data.student_id}
            </div> */}
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>PEN NUMBER</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.pen_number) }
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>ADMISSION DATE</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.admission_date)}
            </div>
            <div
              className="StudentGeneralInfodivCardItembody"
              style={{ gap: "0.5rem" }}
            >
              <span>ENROLLMENT STATUS</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && (
                <StatusButton
                  status={
                    data.enrolment_status === "active" ? "Active" : "Not Active"
                  }
                />
              )}
            </div>
            {/* <div className="StudentGeneralInfodivCardItembody">
              <span>SIBLINGS</span>
              <div style={{ display: "flex", gap: "0.4rem" }}>
                K Manoj Verma <div className="classspan">VI-A</div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="header">
          Personal info
          <FiEdit3
            size={"1rem"}
            className="edit-icon"
            color="#335CFF"
            onClick={() => {
              setpersonalinfoedit(true);
            }}
          />
        </div>
        <div className="StudentGeneralInfodivCardItem">
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>DATE OF BIRTH</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && formatDate(data.dob)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>GENDER</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.gender)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Aadhar Number</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.aadhaar_number)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>BLOOD GROUP</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.blood_group)}
            </div>
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>HEALTH CONDITIONS</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.health_conditions)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>REMARKS</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.remarks)}
            </div>
          </div>
        </div>
        <div className="header">
          Guardian info
          <FiEdit3
            size={"1rem"}
            className="edit-icon"
            color="#335CFF"
            onClick={() => {
              setguardianinfoedit(true);
            }}
          />
        </div>
        <div
          className="StudentGeneralInfodivCardItem"
          style={{ borderBottom: "0" }}
        >
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>NAME</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading &&
                `${findnull(data.guardian_first_name)} ${data.guardian_last_name}`}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>RELATION</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && (
                <div className="relationspan">
                  {findnull(data.guardian_relationship)}
                </div>
              )}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>OCCUPATION</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.guardian_occupation)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>CONTACT NUMBER</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.guardian_contact_number)}
            </div>
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>EMAIL ID</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              {!loading && findnull(data.guardian_email)}
            </div>
            {/* <div className="StudentGeneralInfodivCardItembody">
              <span>Communication preferences</span>
              {loading && <Skeleton width={"5rem"} height={"1.5rem"} />}
              Whatsapp
            </div> */}
          </div>
        </div>
      </div>
      {studentinfoedit && (
        <StudentInfoEditSilder data={data} sliderstate={setstudentinfoedit} />
      )}
      {personalinfoedit && (
        <PersonalInfoEditSilder data={data}  sliderstate={setpersonalinfoedit} />
      )}
      {guardianinfoedit && (
        <GuardianInfoEditSlider data={data} sliderstate={setguardianinfoedit} />
      )}
    </div>
  );
};
