import React, { useContext, useEffect, useState } from "react";
import StatusButton from "../../FeeManagementModule/FeeStructureModule/components/StatusButton";
import { NameAvatar } from "../../FeeManagementModule/FeeCollectionModule/components/NameAvatar";
// import { SlArrowRight } from "react-icons/sl";
import UserContext from "../../../context/UserContext";

export const PreviewAndFinalise = ({getfinaldata}) => {
  const [data, setdata] = useState({});
  const { userInfo } = useContext(UserContext);
  // const [finaliseddata, setfinaliseddata] = useState([]);

  function extractEmergencyContact(data) {
    let temp = {};

    // Check father
    if (data.father.mark_as_emergency_contact) {
      temp.emergency_contact_name =
        data.father.first_name + " " + data.father.last_name;
      temp.emergency_contact_number = data.father.phone_number;
      temp.emergency_contact_relationship = "Father";
    }

    // Check mother
    if (data.mother.mark_as_emergency_contact) {
      temp.emergency_contact_name =
        data.mother.first_name + " " + data.mother.last_name;
      temp.emergency_contact_number = data.mother.phone_number;
      temp.emergency_contact_relationship = "Mother";
    }

    // Check guardian
    if (data.guardian.mark_as_emergency_contact) {
      temp.emergency_contact_name =
        data.guardian.first_name + " " + data.guardian.last_name;
      temp.emergency_contact_number = data.guardian.phone_number;
      temp.emergency_contact_relationship = data.guardian.relationship;
    }

    return temp;
  }
  function extractGuardianDetails(data) {
    let temp = {};

    // Check if father is marked as a guardian
    if (data.father.mark_as_guardian) {
      temp.guardian_relationship = "Father";
      temp.guardian_occupation = data.father.occupation || "";
      temp.guardian_contact_number = data.father.phone_number || "";
      temp.guardian_email = data.father.email || "";
      temp.guardian_name = data.father.first_name + " " + data.father.last_name;
    }
    // Check if mother is marked as a guardian
    else if (data.mother.mark_as_guardian) {
      temp.guardian_relationship = "Mother";
      temp.guardian_occupation = data.mother.occupation || "";
      temp.guardian_contact_number = data.mother.phone_number || "";
      temp.guardian_email = data.mother.email || "";
      temp.guardian_name = data.mother.first_name + " " + data.mother.last_name;
    }
    // Check if guardian is marked as a guardian
    else if (data.guardian.mark_as_guardian) {
      temp.guardian_relationship = data.guardian.relationship || "";
      temp.guardian_occupation = data.guardian.occupation || "";
      temp.guardian_name =
        data.guardian.first_name + " " + data.guardian.last_name;
      temp.guardian_contact_number = data.guardian.phone_number || "";
      temp.guardian_email = data.guardian.email || ""; // Fixed value as per your requirement
    } else {
      // Return a message or an empty object if no one is marked as a guardian
      temp.message = "No guardian is marked as guardian.";
    }

    return temp;
  }
  const find_communication_id=(value)=>{
    if(value==="Phone")return 1;
    if(value==="Whatsapp")return 2;
    if(value==="Email")return 3;
    return null;
  }
  useEffect(() => {
    const savedData = sessionStorage.getItem("studentInfo");
    const savedData1 = JSON.parse(savedData);
    let tempdata = {
      branch_id: userInfo.branch_id,
      first_name: savedData1.first_name,
      last_name: savedData1.last_name,
      //   "phone_number": "5551234567",
      //   "email": "e11m1ily.smith@example.com",
      gender: savedData1.gender,
      dob: savedData1.dob,
      address: savedData1.address,
      // city: savedData1.gender,
      // state: savedData1.gender,
      // pincode: savedData1.gender,
      aadhaar_number: savedData1.aadhaar_number,
      blood_group: savedData1.blood_group,
      health_conditions: savedData1.health_conditions,
      remarks: savedData1.remarks,
      new_class_section_mapping_id: savedData1.class_section_mapping_id,
      pen_number: savedData1.pen_number,
      admission_date: savedData1.admission_date,
      enrolment_status:
        savedData1.enrolment_status === ""
          ? "active"
          : savedData1.enrolment_status,
      father_name: savedData1.father.first_name + savedData1.father.last_name,
      previous_school_attended: savedData1.school_name,
      parent_info: [
        {
          first_name: savedData1.father.first_name,
          last_name: savedData1.father.last_name,
          relationship: "Father",
          contact_number: savedData1.father.phone_number,
          email: savedData1.father.email,
          occupation: savedData1.father_occupation,
          is_emergency_contact: savedData1.father.mark_as_emergency_contact,
          is_guardian: savedData1.father.mark_as_guardian,
          // communication_preferences: savedData1.father.communication_preferences,
          annual_income: Number(savedData1.father_income),
          communication_preference_id: find_communication_id(
            savedData1.father_communication
          ),
        },
        {
          first_name: savedData1.mother.first_name,
          last_name: savedData1.mother.last_name,
          relationship: "Mother",
          contact_number: savedData1.mother.phone_number,
          email: savedData1.mother.email,
          occupation: savedData1.mother_occupation,
          is_emergency_contact: savedData1.mother.mark_as_emergency_contact,
          is_guardian: savedData1.mother.mark_as_guardian,
          communication_preference_id: find_communication_id(
            savedData1.mother_communication
          ),
          annual_income: Number(savedData1.mother_income),
        },
      ],
      previous_school_address: savedData1.school_address,
      prev_scl_last_passed_class: savedData1.last_passed_class,
      prev_scl_last_passed_perc: Number(savedData1.last_grade_marks),
      // guardian_name: savedData1.guardian.first_name,
      // "father_name": "Sathvik Father",
      mother_name: savedData1.mother.first_name + savedData1.mother.last_name,
      // guardian_relationship: savedData1.guardian.relationship,
      // guardian_occupation: savedData1.guardian.occupation,
      // guardian_contact_number: savedData1.guardian.phone_number,
      // guardian_email: "saaaa@gmai.com",
      // emergency_contact_name: "emergencontact_name",
      // emergency_contact_number: "emergenact_number",
      // emergency_contact_relationship: "emergency_contact_relation",
      ...extractEmergencyContact(savedData1),
      ...extractGuardianDetails(savedData1),
    };
    setdata(savedData1);
    getfinaldata(tempdata);
    // setfinaliseddata(tempdata);
    console.log(tempdata);
    //eslint-disable-next-line
  }, [userInfo.branch_id]);
  const findnull = (value) => {
    if (value === null || value === undefined || value === "") {
      return "-";
    }
    return value;
  };
  if (data.father === undefined) return <>Loading</>;
  return (
    <div>
      <NameAvatar
        name={"Chaitanya Pavan"}
        image={`https://api.dicebear.com/8.x/initials/svg?seed=Chaitanya Pavan`}
        id={
          findnull(data.class_section) !== "-"
            ? data.class_section.slice(1, data.class_section.length - 1)
            : "-"
        }
      />
      <div className="line"></div>
      <div
        className="StudentGeneralInfodivCard"
        style={{ paddingBottom: "5rem" }}
      >
        <div className="header">Student info</div>
        <div className="StudentGeneralInfodivCardItem">
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>FIRST NAME</span>
              {data.first_name}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>LAST NAME</span>
              {findnull(data.last_name)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>CLASS & SECTION</span>
              {findnull(data.class_section) !== "-"
                ? data.class_section.slice(1, data.class_section.length - 1)
                : "-"}
            </div>
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            {/* <div className="StudentGeneralInfodivCardItembody">
              <span>ROLL NUMBER</span>
              R1329293
            </div> */}
            <div className="StudentGeneralInfodivCardItembody">
              <span>PEN NUMBER</span>
              {findnull(data.pen_number)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>ADMISSION DATE</span>
              {data.admission_date}
            </div>
            <div
              className="StudentGeneralInfodivCardItembody"
              style={{ gap: "0.5rem" }}
            >
              <span>ENROLLMENT STATUS</span>
              <StatusButton status={data.enrolment_status} />
            </div>
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>SIBLINGS</span>
              <div style={{ display: "flex", gap: "0.4rem" }}>
                {findnull(data.sibling_name)}{" "}
                {/* <div className="classspan">-</div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="header">Personal info</div>
        <div className="StudentGeneralInfodivCardItem">
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>DATE OF BIRTH</span>
              {findnull(data.dob)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>GENDER</span>
              {findnull(data.gender)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Aadhar Number</span>
              {findnull(data.aadhaar_number)}
            </div>
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>BLOOD GROUP</span>
              {findnull(data.blood_group)}
            </div>
            <div className="StudentGeneralInfodivCardItembody" style={{whiteSpace:'normal',overflowWrap:'break-word',paddingRight:'0.3rem'}}>
              <span>HEALTH CONDITIONS</span>
              {findnull(data.health_conditions)}
            </div>
            <div className="StudentGeneralInfodivCardItembody"  style={{whiteSpace:'normal',overflowWrap:'break-word',paddingRight:'0.3rem'}}>
              <span>REMARKS</span>
              {findnull(data.remarks)}
            </div>
          </div>
        </div>
        <div className="header">Father's Info</div>
        <div
          className="StudentGeneralInfodivCardItem"
          style={{ marginBottom: "0.7rem" }}
        >
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>NAME</span>
              {findnull(data.father.first_name)}
            </div>

            <div className="StudentGeneralInfodivCardItembody">
              <span>OCCUPATION</span>
              {findnull(data.father_occupation)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Annual Income</span>
              {findnull(data.father_income)}
            </div>
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>CONTACT NUMBER</span>
              {findnull(data.father.phone_number)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>EMAIL ID</span>
              {findnull(data.father.email)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Communication preferences</span>
              {findnull(data.father_communication)}
            </div>
          </div>
        </div>
        <div className="header">Mother's Info</div>
        <div
          className="StudentGeneralInfodivCardItem"
          style={{ marginBottom: "0.7rem" }}
        >
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>NAME</span>
              {findnull(data.mother.first_name)}
            </div>

            <div className="StudentGeneralInfodivCardItembody">
              <span>OCCUPATION</span>
              {findnull(data.mother_occupation)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Annual Income</span>
              {findnull(data.mother_income)}
            </div>
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>CONTACT NUMBER</span>
              {findnull(data.mother.phone_number)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>EMAIL ID</span>
              {findnull(data.mother.email)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Communication preferences</span>
              {findnull(data.mother_communication)}
            </div>
          </div>
        </div>
        <div className="header">Guardian's Info</div>
        <div
          className="StudentGeneralInfodivCardItem"
          style={{ marginBottom: "0.7rem" }}
        >
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>NAME</span>
              {findnull(data.guardian.first_name)}
            </div>

            <div className="StudentGeneralInfodivCardItembody">
              <span>OCCUPATION</span>
              {findnull(data.guardian_occupation)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Annual Income</span>
              {findnull(data.guardian_income)}
            </div>
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>CONTACT NUMBER</span>
              {findnull(data.guardian.phone_number)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>EMAIL ID</span>
              {findnull(data.guardian.email)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>Communication preferences</span>
              {findnull(data.guardian_communication)}
            </div>
          </div>
        </div>
        <div className="header">Previous School Details</div>
        <div
          className="StudentGeneralInfodivCardItem"
          style={{ marginBottom: "0.7rem" }}
        >
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>SCHOOL NAME</span>
              {findnull(data.school_name)}
            </div>

            <div className="StudentGeneralInfodivCardItembody">
              <span>SCHOOL ADDRESS</span>
              {findnull(data.school_address)}
            </div>
            <div className="StudentGeneralInfodivCardItembody">
              <span>LAST PASSED CLASS</span>
              {findnull(data.last_passed_class)}
            </div>
          </div>
          <div className="StudentGeneralInfodivCardItemRow">
            <div className="StudentGeneralInfodivCardItembody">
              <span>LAST GRADE MARKS</span>
              {findnull(data.last_grade_marks)}
            </div>
          </div>
        </div>
      </div>
      {/* <button
        className="continue-button"
        style={{ marginLeft: "140px" }}
        onClick={(e) => {
          e.preventDefault();
          // progressstate(3);
        }}
      >
        Save to create profile
        <div className="righticon" style={{ marginLeft: "6px" }}>
          <SlArrowRight size={9} strokeWidth={70} />
        </div>
      </button> */}
    </div>
  );
};
