import React from "react";
import SetupAdminHeader from "./components/SetupHeader";
import { Outlet } from "react-router-dom";

const SetupAdminLayout = () => {
  return (
    <>
      <SetupAdminHeader />
      <section
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "1rem",
        }}
      >
        <Outlet />
      </section>
    </>
  );
};

export default SetupAdminLayout;
