import React from "react";
import { NameAvatar } from "./NameAvatar";
import { DueDate } from "./DueDate";
import { RemindTermsPaid } from "./TermsPaid";
import "./BulkRemindFees.css";
import { RemindDueAmount } from "./DueAmount";
import RemainderMethods from "./RemainderMethods";
import { Footer, Sheet } from "../../components/Sheet";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/Table";
import UserContext from "../../../../context/UserContext";
import { sendBulkSMS } from "../../../../api/api";
import { toast } from "sonner";

const BulkRemindFees = ({ isSheetOpen, handlers, items }) => {
  const [selectAll, setSelectAll] = React.useState(true);
  const [checkedItems, setCheckedItems] = React.useState(
    new Array(items.length).fill(true)
  );
  const [selectedItems, setSelectedItems] = React.useState(items);
  const { userInfo } = React.useContext(UserContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const timeoutRef = React.useRef(null);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const updatedCheckedItems = new Array(items.length).fill(newSelectAll);
    setCheckedItems(updatedCheckedItems);
    setSelectedItems(newSelectAll ? items : []);
  };

  const handleCheckboxChange = (index) => {
    const updatedCheckedItems = checkedItems.map((item, idx) =>
      idx === index ? !item : item
    );
    setCheckedItems(updatedCheckedItems);
    setSelectAll(updatedCheckedItems.every((item) => item === true));
    const updatedSelectedItems = updatedCheckedItems[index]
      ? [...selectedItems, items[index]]
      : selectedItems.filter((item) => item !== items[index]);
    setSelectedItems(updatedSelectedItems);
  };

  const handleSubmit = () => {
    setIsLoading(true);

    if (selectedItems.length === 0) {
      toast.error("Please select atleast one student", {
        position: "top-right",
        autoClose: 5000,
      });
      setIsLoading(false);
    }

    const updatedMessages = selectedItems.map((item) => ({
      student_name: item.full_name,
      grade: `${item.class} ${item.section}`,
      term: `Term ${item.terms}`,
      amount_due: item.total_amount_paid,
      due_date: item.next_due_date,
      to: item.phone_number,
    }));

    sendBulkSMS(updatedMessages, userInfo.branch_name, "bulk_remind_fee").then(
      (res) => {
        if (res.response.error) {
          toast.error(res.response.error, {
            position: "top-right",
            autoClose: 5000,
          });
          setIsLoading(false);
        }

        if (res.response.message === "MESSAGES_SENT") {
          toast.success("Messages sent successfully", {
            position: "top-right",
            autoClose: 5000,
          });
          setIsLoading(false);
          timeoutRef.current = setTimeout(() => {
            handlers.toggle();
          }, 1000);
        }
      }
    );
  };

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Sheet
      width={60}
      isSheetOpen={isSheetOpen}
      headerTitle={"Bulk Remind for Fee Collection"}
      handlers={handlers}
      className={"remind__fee--sheet"}
    >
      <section className="remind__fee--section">
        <div className="remind__fee--parent">
          <Table className="bulk-remind__table">
            <TableHeader className="bulk-remind__table-header">
              <TableRow className="bulk-remind__table-row">
                <TableHead>
                  <input
                    type="checkbox"
                    className="bulk-remind__table-checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </TableHead>
                <TableHead>Member Name</TableHead>
                <TableHead>Term</TableHead>
                <TableHead>Amount Due</TableHead>
                <TableHead>Due Date</TableHead>
                <TableHead>Terms Paid</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {items?.map((item, index) => (
                <TableRow key={index} className="bulk-remind__table-row">
                  <TableCell>
                    <input
                      type="checkbox"
                      checked={checkedItems[index]}
                      onChange={() => handleCheckboxChange(index)}
                    />
                  </TableCell>
                  <TableCell className="bulk-remind__table-data">
                    <NameAvatar
                      name={item.full_name}
                      image={`https://api.dicebear.com/8.x/initials/svg?seed=${item.full_name}`}
                      id={item.student_id}
                      route={"remind"}
                    />
                  </TableCell>
                  <TableCell className="bulk-remind__table-data">
                    <span className="remind-fee__term">Term {item.terms}</span>
                  </TableCell>
                  <TableCell className="bulk-remind__table-data">
                    <RemindDueAmount
                      amount={item.total_amount_paid}
                      total={item.total_term_fee}
                      concession={item.total_term_concession}
                    />
                  </TableCell>
                  <TableCell className="bulk-remind__table-data">
                    <span>
                      <DueDate
                        date={item.next_due_date}
                        term={item.terms}
                        route={"remind"}
                      />
                    </span>
                  </TableCell>
                  <TableCell className="bulk-remind__table-data">
                    <RemindTermsPaid
                      term={item.terms_completed}
                      totalTerms={item.terms}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <RemainderMethods />
        </div>
        <Footer className="remind-fee__footer">
          <button
            className={`remind__fee--btn 
          ${isLoading ? "remind__fee--btn--loading" : ""}
            `}
            onClick={handleSubmit}
          >
            Bulk Remind
          </button>
          <button
            className={`remind__fee--cancel 
          ${isLoading ? "remind__fee--btn--loading" : ""}
            
            `}
            onClick={handlers.toggle}
          >
            Cancel
          </button>
        </Footer>
      </section>
    </Sheet>
  );
};

export default BulkRemindFees;
