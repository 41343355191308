import React from "react";
import PropTypes from "prop-types";
import "./Button.css";

/**
 * @typedef {object} ButtonProps
 * @property {string} [className]
 * @property {React.ButtonHTMLAttributes<HTMLButtonElement>} [props]
 */

/**
 * Button component
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<ButtonProps> & React.RefAttributes<HTMLButtonElement>>}
 */

const Button = React.forwardRef(({ className, style, ...props }, ref) => (
  <button
    ref={ref}
    className={`button ${className || ""}`}
    style={style}
    {...props}
  />
));
Button.displayName = "Button";

/**
 * PrimaryButton component
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<ButtonProps> & React.RefAttributes<HTMLButtonElement>>}
 */
const PrimaryButton = React.forwardRef(
  ({ className, style, ...props }, ref) => (
    <Button
      ref={ref}
      className={`button-primary ${className || ""}`}
      style={style}
      {...props}
    />
  )
);
PrimaryButton.displayName = "PrimaryButton";

/**
 * SecondaryButton component
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<ButtonProps> & React.RefAttributes<HTMLButtonElement>>}
 */
const SecondaryButton = React.forwardRef(({ className, ...props }, ref) => (
  <Button
    ref={ref}
    className={`button-secondary ${className || ""}`}
    {...props}
  />
));
SecondaryButton.displayName = "SecondaryButton";

/**
 * IconButton component
 * @typedef {object} IconButtonProps
 * @property {string} [className]
 * @property {React.ReactNode} icon
 * @property {React.ButtonHTMLAttributes<HTMLButtonElement>} [props]
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<IconButtonProps> & React.RefAttributes<HTMLButtonElement>>}
 */
const IconButton = React.forwardRef(({ className, icon, ...props }, ref) => (
  <Button ref={ref} className={`button-icon ${className || ""}`} {...props}>
    <span className="icon">{icon}</span>
  </Button>
));
IconButton.displayName = "IconButton";

const BorderButton = React.forwardRef(
  ({ className, icon, onClick, ...props }, ref) => (
    <Button
      ref={ref}
      className={`border-button ${className || ""}`}
      {...props}
      onClick={onClick}
    />
  )
);
BorderButton.displayName = "IconButton";

Button.propTypes = {
  className: PropTypes.string,
};

PrimaryButton.propTypes = {
  className: PropTypes.string,
};

SecondaryButton.propTypes = {
  className: PropTypes.string,
};

IconButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export { Button, PrimaryButton, SecondaryButton, IconButton, BorderButton };
