import React from "react";
import {
  GrayBox,
  SetupInformation,
  TextBox,
} from "./components/SetupComponents";
import "./SetupAdmin.css";
import { createOrganizationSetup, schoolAcadSetup } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import UserContext from "../../context/UserContext";

const SetupFinalize = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const { userInfo } = React.useContext(UserContext);
  const handleSubmit = async () => {
    setIsLoading(true);
    if (sessionStorage.getItem("error_page")) {
      try {
        const storedBoardId = sessionStorage.getItem("affiliateBoard");

        const teacherFormData = {
          academic_year: sessionStorage.getItem("academicYear"),
          organization_id: userInfo.organization_id,
          branch_id: userInfo.result.branch_id,
          affiliate_board_id: storedBoardId.id,
          level_ids: sessionStorage.getItem("selectedLevels"),
        };
        const academicResponse = await schoolAcadSetup(teacherFormData);

        if (academicResponse.error) {
          sessionStorage.setItem("error_page", true);
          toast.error(academicResponse.errors.error, {
            position: "top-right",
            autoClose: 5000,
          });
        } else {
          sessionStorage.clear();
          navigate("/");
        }
      } catch (error) {
        console.error("Error finalizing setup: ", error);
      } finally {
        setIsLoading(false);
      }
    }
    try {
      const adminFormData = {
        admin_phone_number: `${sessionStorage.getItem("phoneNumber")}`,
        admin_email: `${sessionStorage.getItem("email")}`,
        admin_password: `${sessionStorage.getItem("password")}`,
        admin_full_name: `${sessionStorage.getItem("adminName")}`,
        multiple_branches: parseInt(
          sessionStorage.getItem("multiple_branches"),
          10
        ),
        total_branches: parseInt(sessionStorage.getItem("total_branches"), 10),
        main_branch_address: sessionStorage.getItem("main_branch_address"),
        main_branch_name: sessionStorage.getItem("main_branch_name"),
        main_branch_phone_number: sessionStorage.getItem(
          "main_branch_phone_number"
        ),
        main_branch_email: sessionStorage.getItem("main_branch_email"),
        logo_url: "",
      };
      const response = await createOrganizationSetup(adminFormData);
      if (response.errors) {
        toast.error(response.errors.error, {
          position: "top-right",
          autoClose: 5000,
        });
      } else {
        if (response.response.message === "RESOURCE_CREATED_SUCCESSFULLY") {
          localStorage.setItem("authToken", response.response.result.token);

          await new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            }, 1000);
          });

          const storedBoardId = sessionStorage.getItem("affiliateBoard");

          const teacherFormData = {
            academic_year: sessionStorage.getItem("academicYear"),
            organization_id: response.response.result.organization_id,
            branch_id: response.response.result.branch_id,
            affiliate_board_id: storedBoardId.id,
            level_ids: JSON.parse(sessionStorage.getItem("selectedLevels")),
          };
          const academicResponse = await schoolAcadSetup(teacherFormData);
          console.log("Academic Response", academicResponse);

          if (academicResponse.error) {
            sessionStorage.setItem("error_page", true);
            toast.error(academicResponse.errors.error, {
              position: "top-right",
              autoClose: 5000,
            });
          } else {
            sessionStorage.clear();
            navigate("/");
          }
        }
      }
    } catch (error) {
      console.error("Error finalizing setup: ", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <section className="setup-finalize">
      <div className="setup-finalize__header">
        <div className="setup-finalize__logo-container">
          <div className="">
            <img
              src={`https://api.dicebear.com/8.x/initials/svg?seed=${sessionStorage.getItem(
                "main_branch_name"
              )}`}
              alt={sessionStorage.getItem("main_branch_name")}
              className="setup-finalize__logo-image"
            />
          </div>
          <p className="setup-finalize__school-name">
            {sessionStorage.getItem("main_branch_name")}
          </p>
        </div>
        <GrayBox text={"Main Branch"} />
      </div>
      <div className="setup-finalize__info">
        <p className="font-12">
          Address{" "}
          <span className="font-14">
            {sessionStorage.getItem("main_branch_address")}
          </span>
        </p>
        <div className="setup-finalize__rows">
          <p className="font-12">
            Email
            <span className="font-14">
              {sessionStorage.getItem("main_branch_email")}
            </span>
          </p>
          <p className="font-12">
            Phone Number
            <span className="font-14">
              +91- {sessionStorage.getItem("main_branch_phone_number")}
            </span>
          </p>
        </div>
      </div>
      <SetupInformation
        text={
          "Additional branches can be setup after completing this main setup"
        }
        link={"#"}
      />
      <p className="setup-finalize--subsection">Admin Info</p>
      <section className="setup-finalize__admin-container">
        <div className="setup-finalize__admin-header">
          <div className="setup-finalize__logo-admin-container">
            <img
              src={`
                https://api.dicebear.com/8.x/initials/svg?seed=${sessionStorage.getItem(
                  "admin_full_name"
                )}`}
              className="setup-finalize__admin-logo"
              alt={sessionStorage.getItem("admin_full_name")}
              width={50}
              height={50}
            />

            <p className="setup-finalize__admin-name">
              {sessionStorage.getItem("admin_full_name")}
            </p>
          </div>
          <TextBox text={"Super admin"} />
        </div>
        <div className="setup-finalize__admin-rows">
          <p className="font-12">
            Email
            <span className="font-14">{sessionStorage.getItem("email")}</span>
          </p>
          <p className="font-12">
            Phone Number
            <span className="font-14">
              +91- {sessionStorage.getItem("phoneNumber")}
            </span>
          </p>
        </div>
      </section>
      <p className="setup-finalize--subsection">Academic Info</p>
      <section className="setup-finalize__admin-container">
        <div className="setup-finalize__admin-header">
          <p className="setup-finalize__admin-name">Higher Secondary</p>
          <TextBox text={"Class VI to VIII"} />
        </div>
        <div className="setup-finalize__admin-rows">
          <p className="font-12">
            Academic Year
            <span className="font-14">2023-2024</span>
          </p>
          <p className="font-12">
            Affiliate Board
            <span className="font-14">SSC</span>
          </p>
          <p className="font-12">
            Affiliate Board
            <span className="font-14">SSC</span>
          </p>
        </div>
      </section>
      <button
        className={`setup-admin__button ${isLoading && "button__loading"}`}
        onClick={handleSubmit}
      >
        Continue to Dashboard
      </button>
    </section>
  );
};

export default SetupFinalize;
