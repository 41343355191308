import React, { useState, useRef, useEffect } from "react";
import "./ActionsMenu.css";
import { useNavigate } from "react-router-dom";

const ActionsMenu = ({ selectmenuitems, dir = "student", id, classname }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="Actions-menu-container" ref={menuRef}>
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => {
          navigate(`/${dir}/transactions/${id}/general`);
        }}
      >
        <path
          d="M8.99999 0.25C13.044 0.25 16.4085 3.16 17.1142 7C16.4092 10.84 13.044 13.75 8.99999 13.75C4.95599 13.75 1.59149 10.84 0.885742 7C1.59074 3.16 4.95599 0.25 8.99999 0.25ZM8.99999 12.25C10.5296 12.2497 12.0138 11.7301 13.2096 10.7764C14.4055 9.82264 15.2422 8.49121 15.5827 7C15.2409 5.50998 14.4037 4.18 13.208 3.22752C12.0122 2.27504 10.5287 1.7564 8.99999 1.7564C7.47126 1.7564 5.98776 2.27504 4.79202 3.22752C3.59629 4.18 2.75907 5.50998 2.41724 7C2.75781 8.49121 3.5945 9.82264 4.79035 10.7764C5.9862 11.7301 7.47039 12.2497 8.99999 12.25ZM8.99999 10.375C8.10489 10.375 7.24644 10.0194 6.61351 9.38649C5.98057 8.75355 5.62499 7.89511 5.62499 7C5.62499 6.10489 5.98057 5.24645 6.61351 4.61351C7.24644 3.98058 8.10489 3.625 8.99999 3.625C9.8951 3.625 10.7535 3.98058 11.3865 4.61351C12.0194 5.24645 12.375 6.10489 12.375 7C12.375 7.89511 12.0194 8.75355 11.3865 9.38649C10.7535 10.0194 9.8951 10.375 8.99999 10.375ZM8.99999 8.875C9.49727 8.875 9.97419 8.67746 10.3258 8.32582C10.6774 7.97419 10.875 7.49728 10.875 7C10.875 6.50272 10.6774 6.02581 10.3258 5.67417C9.97419 5.32254 9.49727 5.125 8.99999 5.125C8.50271 5.125 8.0258 5.32254 7.67417 5.67417C7.32254 6.02581 7.12499 6.50272 7.12499 7C7.12499 7.49728 7.32254 7.97419 7.67417 8.32582C8.0258 8.67746 8.50271 8.875 8.99999 8.875Z"
          fill="#335CFF"
        />
      </svg>
      <button className="actions__button" onClick={toggleMenu}>
        &#x22EE; {/* Vertical Actions */}
      </button>
      {isOpen && (
        <div className="actions-menu">
          {dir === "student" && classname === null && (
            <div
              className="menu-item"
              onClick={() => {
                selectmenuitems(1, id);
              }}
            >
              {" "}
              <div className="menu-icon">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.129 5.24952L5.106 1.22652L6.1665 0.166016L12 5.99952L6.1665 11.833L5.106 10.7725L9.129 6.74952H0V5.24952H9.129Z"
                    fill="#0E121B"
                  />
                </svg>
              </div>{" "}
              Assign class
            </div>
          )}
          {dir === "student" && classname !== null && (
            <div
              className="menu-item"
              onClick={() => {
                selectmenuitems(2, id);
              }}
            >
              {" "}
              <div className="menu-icon">
                <svg
                  width="15"
                  height="17"
                  viewBox="0 0 15 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 10.689V12.2565C6.82119 12.0165 6.09471 11.9429 5.38155 12.0418C4.6684 12.1408 3.9894 12.4094 3.40157 12.8252C2.81375 13.2409 2.33426 13.7916 2.00337 14.4311C1.67249 15.0705 1.49986 15.78 1.5 16.5L1.93842e-07 16.4993C-0.000232586 15.5834 0.209191 14.6797 0.612224 13.8573C1.01526 13.0349 1.6012 12.3157 2.32516 11.7548C3.04912 11.1939 3.89188 10.8061 4.78887 10.6212C5.68585 10.4364 6.61325 10.4593 7.5 10.6883V10.689ZM6 9.75C3.51375 9.75 1.5 7.73625 1.5 5.25C1.5 2.76375 3.51375 0.75 6 0.75C8.48625 0.75 10.5 2.76375 10.5 5.25C10.5 7.73625 8.48625 9.75 6 9.75ZM6 8.25C7.6575 8.25 9 6.9075 9 5.25C9 3.5925 7.6575 2.25 6 2.25C4.3425 2.25 3 3.5925 3 5.25C3 6.9075 4.3425 8.25 6 8.25ZM10.5 12.75V10.125L14.25 13.5L10.5 16.875V14.25H8.25V12.75H10.5Z"
                    fill="#0E121B"
                  />
                </svg>
              </div>{" "}
              Move between class
            </div>
          )}
          {/* <div className="menu-item">
            {" "}
            <div className="menu-icon">
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.25 13.25H13.75V14.75H0.25V13.25ZM7.75 8.879L12.3032 4.325L13.3638 5.3855L7 11.75L0.63625 5.38625L1.69675 4.325L6.25 8.8775V0.5H7.75V8.879Z"
                  fill="#0E121B"
                />
              </svg>
            </div>{" "}
            Export Profile
          </div> */}

          <div
            className="menu-item"
            onClick={() => {
              selectmenuitems(3, id);
            }}
          >
            <div className="menu-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.75 3.5H15.5V5H14V14.75C14 14.9489 13.921 15.1397 13.7803 15.2803C13.6397 15.421 13.4489 15.5 13.25 15.5H2.75C2.55109 15.5 2.36032 15.421 2.21967 15.2803C2.07902 15.1397 2 14.9489 2 14.75V5H0.5V3.5H4.25V1.25C4.25 1.05109 4.32902 0.860322 4.46967 0.71967C4.61032 0.579018 4.80109 0.5 5 0.5H11C11.1989 0.5 11.3897 0.579018 11.5303 0.71967C11.671 0.860322 11.75 1.05109 11.75 1.25V3.5ZM12.5 5H3.5V14H12.5V5ZM9.0605 9.5L10.3865 10.826L9.326 11.8865L8 10.5605L6.674 11.8865L5.6135 10.826L6.9395 9.5L5.6135 8.174L6.674 7.1135L8 8.4395L9.326 7.1135L10.3865 8.174L9.0605 9.5ZM5.75 2V3.5H10.25V2H5.75Z"
                  fill="#0E121B"
                />
              </svg>
            </div>{" "}
            Delete Profile
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionsMenu;
