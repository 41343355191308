import "./Pagination.css";
import PropTypes from "prop-types";

const Pagination = ({ currentPage, totalPages, pageSize, onPageChange }) => {
  const maxPage = Math.min(totalPages, Math.max(currentPage + 4, 10));
  const minPage = Math.max(1, Math.min(currentPage - 5, maxPage - 9));

  const numberedPageItems = [];

  for (let page = minPage; page <= maxPage; page++) {
    numberedPageItems.push(
      <span
        key={page}
        className={`pagination__page-number${
          page === currentPage ? " pagination__page-number--active" : ""
        }${page === 1 && currentPage === 1 ? " pagination__page-first" : ""}${
          page === totalPages ? " pagination__page-last" : ""
        }${
          page === totalPages && currentPage === totalPages
            ? " pagination__current-last"
            : ""
        }`}
        onClick={() => onPageChange(page)}
      >
        {page}
      </span>
    );
  }

  return (
    <section className="pagination">
      <div className="pagination__info">
        <p className="pagination__text">
          Page {currentPage} of {totalPages}
        </p>
      </div>
      <div className="pagination__controls">
        {currentPage > 1 && (
          <span
            className="pagination__control pagination__control--prev"
            onClick={() => onPageChange(currentPage - 1)}
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="pagination__icon--prev"
            >
              <path d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z"></path>
            </svg>
          </span>
        )}
        {numberedPageItems}
        {currentPage < totalPages && (
          <span
            className="pagination__control pagination__control--next"
            onClick={() => onPageChange(currentPage + 1)}
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="pagination__icon--next"
            >
              <path d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z"></path>
            </svg>
          </span>
        )}
      </div>
      <div className="pagination__page-size">
        <button className="pagination__button">{pageSize} / page</button>
      </div>
    </section>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
