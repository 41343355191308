import React from "react";
import "./CustomIcon.css";
const CustomIcon = ({ svg }) => {
  return (
    <div className="custom-icon__container--logo">
      <div className="custom-icon--svg">{svg}</div>
    </div>
  );
};

export default CustomIcon;
