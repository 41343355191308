// import PropTypes from "prop-types";
import "./StatusButton.css";

const StatusButton = ({ status }) => {
  return (
    <div
      className={
        status === "Active" || status === "active"
          ? "active-button"
          : "inactive-button"
      }
    >
      <span
        className={
          status === "Active" || status === "active"
            ? "active-button__indicator"
            : "inactive-button__indicator"
        }
      ></span>
      <p className="button__label">{status}</p>
    </div>
  );
};

// StatusButton.propTypes = {
//   status: PropTypes.string.isRequired,
// };

export default StatusButton;
