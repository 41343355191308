import React from "react";
import "./FeeTable.css";
import PropTypes from "prop-types";
import ActionsButton from "./ActionsButton";
import StatusButton from "../FeeStructureModule/components/StatusButton";
import { getSectionsBasedOnclassID } from "../../../api/api";
import { NavLink } from "react-router-dom";
import loading from "../../../assets/loading.gif";
import UserContext from "../../../context/UserContext";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./Table";
import { formatPrice } from "../FeeStructureModule/hooks";

const FeeTable = ({ items, onDelete }) => {
  const { userInfo } = React.useContext(UserContext);
  const [selectAll, setSelectAll] = React.useState(false);
  const [checkedItems, setCheckedItems] = React.useState(
    new Array(items.length).fill(false)
  );
  const [openActionsIndex, setOpenActionsIndex] = React.useState(null);
  const [secDic, setSecDic] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setCheckedItems(new Array(items.length).fill(!selectAll));
  };

  const handleCheckboxChange = (index) => {
    const updatedCheckedItems = checkedItems.map((item, idx) =>
      idx === index ? !item : item
    );
    setCheckedItems(updatedCheckedItems);
    setSelectAll(updatedCheckedItems.every((item) => item === true));
  };

  const handleActionsClick = (index) => {
    setOpenActionsIndex(openActionsIndex === index ? null : index);
  };

  const toRoman = (num) => {
    const romanNumeral = [
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
      "X",
    ];
    return romanNumeral[num - 1] || num;
  };

  const generateRomanTerms = (num) => {
    const terms = [];
    for (let i = 1; i <= num; i++) {
      terms.push(toRoman(i));
    }
    return terms.join(", ");
  };

  const getSectionData = async (id) => {
    const data1 = await getSectionsBasedOnclassID(id);
    return data1;
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (!items || !items.length) {
        return;
      }
      try {
        setIsLoading(true);
        let tempSecDic = [];
        let uniqueSecDic = [];
        await Promise.all(
          items.map(async (item) => {
            await Promise.all(
              item.classes.map(async (cls) => {
                try {
                  const sectionData = await getSectionData(cls.class_id);
                  if (!Array.isArray(sectionData)) {
                    throw new Error(
                      `Invalid section data received for class_id: ${cls.class_id}`
                    );
                  }
                  tempSecDic = [
                    ...tempSecDic,
                    ...sectionData.map((section) => ({
                      class_id: cls.class_id,
                      ...section,
                    })),
                  ];
                  tempSecDic.forEach((element) => {
                    if (
                      !uniqueSecDic.some(
                        (e) =>
                          e.class_section_mapping_id ===
                          element.class_section_mapping_id
                      )
                    ) {
                      uniqueSecDic.push(element);
                    }
                  });
                } catch (error) {
                  console.error(
                    `Error fetching section data for class_id ${cls.class_id}:`,
                    error
                  );
                }
              })
            );
          })
        );
        setSecDic(uniqueSecDic);
      } catch (error) {
        console.error("Error fetching section data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [items]);

  return (
    <Table className="fee-structure__table">
      <TableHeader className="fee-structure__table-header">
        <TableRow className="fee-structure__table-row">
          <TableHead className={"fee-structure-table-checkbox"}>
            <input
              type="checkbox"
              className="fee-structure__table-checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
          </TableHead>
          <TableHead className="fee-structure__table-heading">
            Name of Fee Structure
          </TableHead>
          <TableHead className="fee-structure__table-heading">ID</TableHead>
          <TableHead className="fee-structure__table-heading">
            Classes
          </TableHead>
          <TableHead className="fee-structure__table-heading">Terms</TableHead>
          <TableHead className="fee-structure__table-heading">
            Fee amount
          </TableHead>
          <TableHead className="fee-structure__table-heading">Status</TableHead>
          {userInfo.role !== "accountant" && (
            <TableHead className="fee-structure__table-heading">
              Actions
            </TableHead>
          )}
        </TableRow>
      </TableHeader>
      {isLoading ? (
        <img
          className="LoadImage"
          src={loading}
          alt="Loading"
          style={{
            width: "40%",
            height: "40%",
            marginLeft: "280%",
            padding: "100%",
          }}
        />
      ) : (
        <TableBody>
          {items?.map((item, index) => {
            const itemSections = secDic.filter((section) =>
              item.classes.some((cls) => cls.class_id === section.class_id)
            );

            return (
              <TableRow key={index} className="fee-structure__table-row">
                <TableCell>
                  <input
                    type="checkbox"
                    checked={checkedItems[index]}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </TableCell>
                <TableCell className="fee-structure__table-data">
                  <NavLink
                    to={`/fee-structure/details/${item.id}`}
                    className="fee-structure__table-link"
                  >
                    {item.name}
                  </NavLink>
                </TableCell>
                <TableCell className="fee-structure__table-data">
                  <span>{item.id}</span>
                </TableCell>
                <TableCell className="fee-structure__table-data">
                  {itemSections.length !== 0 ? (
                    <>
                      {itemSections.slice(0, 2).map((section, index) => (
                        <span key={index} className="fee-structure__class">
                          {section.class}-{section.section}
                        </span>
                      ))}
                      {itemSections.length > 2 && (
                        <NavLink
                          to={`/fee-structure/details/${item.id}`}
                          className="fee-structure__table-link"
                        >
                          <span className="fee-structure__more">
                            +{itemSections.length - 2} more
                          </span>
                        </NavLink>
                      )}
                    </>
                  ) : (
                    <span>No classes found</span>
                  )}
                </TableCell>
                <TableCell className="fee-structure__table-data">
                  <span>Term {generateRomanTerms(item.terms)}</span>
                </TableCell>
                <TableCell className="fee-structure__table-data">
                  {formatPrice(item.total_fee_amount ?? 0)}
                </TableCell>
                <TableCell className="fee-structure__table-data">
                  <StatusButton status={item.status} />
                </TableCell>
                {userInfo.role !== "accountant" && (
                  <TableCell className="fee-structure__table-data">
                    <ActionsButton
                      status={item.status}
                      isOpen={openActionsIndex === index}
                      onClick={() => handleActionsClick(index)}
                      id={item.id}
                      onDelete={onDelete}
                    />
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      )}
    </Table>
  );
};

FeeTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      classes: PropTypes.arrayOf(
        PropTypes.shape({
          class_id: PropTypes.number.isRequired,
          section_id: PropTypes.number.isRequired,
        })
      ).isRequired,
      terms: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FeeTable;
