import { Navigate, Outlet, useLocation } from "react-router-dom";
import "./App.css";
import Sidebar from "./home/Sidebar";
import React from "react";
import UserContext from "./context/UserContext";

const Layout = () => {
  const { loggedIn } = React.useContext(UserContext);
  const location = useLocation();

  if (location.pathname === "/") {
    return <Navigate to="/fee/dashboard" />;
  }

  if (location.pathname === "/directory/school") {
    return <Navigate to="/directory/school/students" />;
  }

  return loggedIn ? (
    <div className="app">
      <Sidebar />
      <main className="app__main-content">
        <Outlet />
      </main>
    </div>
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default Layout;
