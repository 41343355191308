import React, { useEffect, useState } from "react";
import "./AddStudent.css";
import { toast } from "sonner";
import RouteHeader from "../../FeeManagementModule/components/RouteHeader";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { RxCross1 } from "react-icons/rx";
import { CancelButton } from "../../FeeManagementModule/FeeStructureModule/components/CancelButton";
import { StudentInfo } from "./StudentInfo";
import { PersonalInfo } from "./PersonalInfo";
import { GuardianInfo } from "./GuardianInfo";
import { PreviewAndFinalise } from "./PreviewAndFinalise";
// import SetupAdminHeader from "../Components/SetupHeader/setupheader";
import { IoIosArrowForward } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import { createStudent } from "../../../api/api";
import { useNavigate } from "react-router-dom";

export const AddStudent = () => {
  const navigate = useNavigate();
  const [progress, setprogress] = useState(0);
  const [finaldata, setfinaldata] = useState([]);
  const getfinaldata = (value) => {
    setfinaldata(value);
  };
  const senddata = async () => {
    const res = await createStudent(finaldata);
    console.log(res);
    if (res === "RESOURCE_CREATED_SUCCESSFULLY") {
      toast.success("Successfully Added Student", {
        position: "top-right",
        autoClose: 5000,
      });
      sessionStorage.clear();
      navigate("/studentdirectory");
    }
    else  {toast.success("Student Addition unsuccessfull", {
      position: "top-right",
      autoClose: 5000,
    });}
  };
  const routes = [
    { number: 1, text: "Student Info", path: "/signup/setup/admin" },
    { number: 2, text: "Personal Info", path: "/signup/setup/school" },
    {
      number: 3,
      text: "Parental Info",
      path: "/signup/setup/academic",
    },
    { number: 4, text: "Finalise", path: "/signup/finalise" },
  ];

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  // const [edit, setedit] = useState([false, false, false, false]);
  // const handleprogress = (value, value2 = false) => {
  //   if (!edit[value]) {
  //     const temp = edit;
  //     temp[value] = value2;
  //     setedit(temp);
  //   }
  //   console.log(edit);
  //   setprogress(value);
  //   console.log(value);
  // };


  const [buttonstate, setbuttonstate] = useState(false);
  const findmandatorychecks = async (data = null) => {
    console.log("checksss");
    if (progress >= 3) return true;
    const savedData1 = await sessionStorage.getItem("studentInfo");
    let savedData = JSON.parse(savedData1);
    console.log(savedData);
    if (data !== null) {
      savedData = data;
    }
    if (savedData) {
      if (
        progress === 0 &&
        savedData["first_name"] !== "" &&
        savedData["admission_date"] !== ""
      ) {
        console.log("dateee", savedData["first_name"]);
        setbuttonstate(true);
        return;
      } else if (
        progress === 1 &&
        savedData.aadhaar_number &&
        savedData.aadhaar_number !== ""
      ) {
        setbuttonstate(true);
        return;
      } else if (progress === 2) {
        let count = 0;
        if (
          savedData.father &&
          savedData.father.mark_as_emergency_contact === true &&
          savedData.father.first_name !== "" &&
          savedData.father.phone_number !== ""
        ) {
          console.log("cheking");
          count += 1;
        } else if (
          savedData.mother &&
          savedData.mother.mark_as_emergency_contact === true &&
          savedData.mother.first_name !== "" &&
          savedData.mother.phone_number !== ""
        ) {
          count += 1;
        } else if (
          savedData.guardian &&
          savedData.guardian.mark_as_emergency_contact === true &&
          savedData.guardian.first_name !== "" &&
          savedData.guardian.phone_number !== ""
        ) {
          count += 1;
        }
        if (
          savedData.father &&
          savedData.father.mark_as_guardian === true &&
          savedData.father.first_name !== "" &&
          savedData.father.phone_number !== ""
        ) {
          count += 1;
          console.log("cheking");
        } else if (
          savedData.mother &&
          savedData.mother.mark_as_guardian === true &&
          savedData.mother.first_name !== "" &&
          savedData.mother.phone_number !== ""
        ) {
          count += 1;
        } else if (
          savedData.guardian &&
          savedData.guardian.mark_as_guardian === true &&
          savedData.guardian.first_name !== "" &&
          savedData.guardian.phone_number !== ""
        ) {
          count += 1;
        }
        if (count >= 2) {
          setbuttonstate(true);
          return;
        }
        // if (savedData.father && savedData.father.mark_as_emergency_contact && savedData.father.mark_as_guardian &&
        //   (savedData.father.mark_as_emergency_contact ||
        //   savedData.father.mark_as_guardian)
        // ) {
        //   if (
        //     savedData.father.first_name != "" &&
        //     savedData.father.phone_number != ""
        //   ) {
        //     setbuttonstate(true);
        //     return;
        //   }
        // } else if (savedData.father &&
        //   (savedData.mother.mark_as_emergency_contact ||
        //   savedData.mother.mark_as_guardian)
        // ) {
        //   if (savedData.father &&
        //     savedData.mother.first_name != "" &&
        //     savedData.mother.phone_number != ""
        //   ) {
        //     setbuttonstate(true);
        //     return;
        //   }
        // } else if (savedData.guardian &&
        //   (savedData.guardian.mark_as_emergency_contact ||
        //   savedData.guardian.mark_as_guardian)
        // ) {
        //   if (
        //     savedData.guardian.first_name != "" &&
        //     savedData.guardian.phone_number != ""
        //   ) {
        //     setbuttonstate(true);
        //     return;
        //   }
        // }
      }
    }
    setbuttonstate(false);
    return;
  };
  useEffect(() => {
    console.log(progress);
    findmandatorychecks();
    //eslint-disable-next-line
  }, [progress]);

  const dynamicStyles = {
    display: "flex",
    background: "rgb(245, 247, 250)",
    color: "black",
    // pointerEvents: "none",
    cursor: "not-allowed",
  };

  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <RouteHeader
        routeHeader="StudentDirectory"
        routes={[{ name: "Add Student", link: "/studentdirectory/addstudent" }]}
        search
      />
      <div className="headeradd">Add Student</div>
      <div
        className="addstudentdiv"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "80%",
          overflow: "hidden",
        }}
      >
        <div
          className="AddStudentBody"
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            height: "10rem",
          }}
        >
          <div className="SetupBarHeader" style={{ flexShrink: 0 }}>
            <div className="backbutton">
              <header className="setup-header">
                <div className="setup-header__routes">
                  {routes.map((route, index) => {
                    const isCompleted = index < progress;
                    const isCurrentRoute = index === progress;

                    return (
                      <div
                        key={route.number}
                        className={`setup-header__route ${
                          isCurrentRoute ? "current-route" : ""
                        }`}
                      >
                        {isCompleted ? (
                          <span className="setup-header__route-number route--checked">
                            <FaCheck size={"0.65rem"} />
                          </span>
                        ) : (
                          <span
                            className={`setup-header__route-number ${
                              isCurrentRoute ? "current-route-number" : ""
                            }`}
                          >
                            {route.number}
                          </span>
                        )}
                        <div
                          className={`setup-header__route-text ${
                            isCompleted || isCurrentRoute ? "checked-text" : ""
                          }`}
                          onClick={() => {
                            if (progress > index) setprogress(index);
                          }}
                        >
                          {route.text}
                        </div>
                        {index < routes.length - 1 && (
                          <IoIosArrowForward
                            size={"0.85rem"}
                            className="setup-header__route-arrow"
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </header>
            </div>
            <div>
              <button
                onClick={() => {
                  setIsCancelModalOpen(!isCancelModalOpen);
                }}
                style={{ cursor: "pointer" }}
                className="Cancel"
              >
                Cancel{" "}
                <RxCross1 className="crossicon" size={11} strokeWidth={0.5} />
              </button>
              <CancelButton
                isOpen={isCancelModalOpen}
                onClose={() => {
                  setIsCancelModalOpen(!isCancelModalOpen);
                }}
                navto={"/studentdirectory"}
              />
            </div>
          </div>
          <div
            className="AddStudentBodyComponents"
            style={{ flex: "1", overflowY: "auto", padding: "16px" }}
          >
            <button
              type="submit"
              className="back-button"
              onClick={() => {
                if (progress > 0) setprogress(progress - 1);
              }}
            >
              <div className="lefticon">
                <SlArrowLeft size={10} strokeWidth={90} />{" "}
              </div>
              Back
            </button>
            {progress === 0 && (
              <StudentInfo
                findmandatorychecks={findmandatorychecks}
                progressstate={setprogress}
              />
            )}
            {progress === 1 && (
              <PersonalInfo
                findmandatorychecks={findmandatorychecks}
                progressstate={setprogress}
              />
            )}
            {progress === 2 && (
              <GuardianInfo
                findmandatorychecks={findmandatorychecks}
                progressstate={setprogress}
              />
            )}
            {progress === 3 && (
              <PreviewAndFinalise getfinaldata={getfinaldata} />
            )}
            <div> </div>
          </div>
          {/* <div className="buttonpopup-container">
            <div className="buttonpopup">
              <h2>buttonpopup Content</h2>
              <p>This is the content of the buttonpopup.</p>
              <button >Close</button>
            </div>
          </div> */}
          <div style={{ position: "relative" }}>
            <div className="buttonpopup-container">
              <button
                className="buttonpopup"
                style={
                  buttonstate === false ? dynamicStyles : { display: "flex" }
                }
                onClick={async (e) => {
                  if (progress >= 3) {
                    e.preventDefault();

                    await senddata();
                  } else if (buttonstate === true) {
                    setprogress(progress + 1);
                  }
                }}
              >
                Save and Continue
                <div className="righticon" style={{ marginLeft: "6px",color: buttonstate === false ?'black':'' }}>
                  <SlArrowRight size={9} strokeWidth={70} />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
