import React from "react";
import Skeleton from "react-loading-skeleton";

export const FIlterbarSkeleton = () => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Skeleton width={"7.8rem"} height={"2.3rem"} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "0.5rem",
        }}
      >
        <Skeleton width={"8.5rem"} height={"2.3rem"} />
        <Skeleton width={"7.8rem"} height={"2.3rem"} />
        <Skeleton width={"19.4rem"} height={"2.3rem"} />
        {/* <Skeleton width={'2.8rem'} height={'2.3rem'}/> */}
      </div>
    </div>
  );
};
