import React, { useRef } from "react";
import "./Dropdown.css";
import { useOutsideClick, useToggle } from "../FeeStructureModule/hooks";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const StatusDropdown = () => {
  const dropdownRef = useRef(null);
  const { state: isOpen, handlers } = useToggle();
  useOutsideClick(dropdownRef, handlers.off);

  const location = useLocation();
  const history = useNavigate();

  const getCheckedListFromParams = () => {
    const params = new URLSearchParams(location.search);
    const status = params.get("status");
    if (status) {
      return status
        .split(",")
        .map((item) => item.charAt(0).toUpperCase() + item.slice(1));
    }
    return ["All"];
  };

  const checkedList = getCheckedListFromParams();
  const checkAll = checkedList.includes("All");
  const list = checkedList.filter((item) => item !== "All");

  const updateQueryParams = (updatedCheckedList) => {
    const params = new URLSearchParams(location.search);
    if (updatedCheckedList.includes("All")) {
      params.set("status", "all");
    } else if (updatedCheckedList.length === 0) {
      params.delete("status");
    } else {
      params.set("status", updatedCheckedList.join(",").toLowerCase());
    }
    history(`?${params.toString()}`);
  };

  const onChange = (option) => {
    let updatedCheckedList = [...checkedList];

    if (option === "All") {
      if (updatedCheckedList.includes("All")) {
        updatedCheckedList = [];
      } else {
        updatedCheckedList = ["All", "Active", "Inactive"];
      }
    } else {
      if (!updatedCheckedList.includes(option)) {
        updatedCheckedList.push(option);
      } else {
        updatedCheckedList = updatedCheckedList.filter(
          (item) => item !== option
        );
      }

      if (updatedCheckedList.includes("All")) {
        updatedCheckedList = updatedCheckedList.filter(
          (item) => item !== "All"
        );
      }

      if (
        updatedCheckedList.includes("Active") &&
        updatedCheckedList.includes("Inactive")
      ) {
        updatedCheckedList = ["All", ...updatedCheckedList];
      }
    }

    updateQueryParams(updatedCheckedList);
  };

  const onCheckAllChange = () => {
    const updatedCheckedList = checkAll ? [] : ["All", "Active", "Inactive"];
    updateQueryParams(updatedCheckedList);
  };

  const getUpdatedStatus = (option) => {
    let updatedCheckedList = [...checkedList];
    if (!updatedCheckedList.includes(option)) {
      updatedCheckedList.push(option);
    } else {
      updatedCheckedList = updatedCheckedList.filter((item) => item !== option);
    }
    if (updatedCheckedList.includes("All")) {
      updatedCheckedList = updatedCheckedList.filter((item) => item !== "All");
    }
    if (updatedCheckedList.length === 0) {
      return "";
    }
    return updatedCheckedList.join(",").toLowerCase();
  };

  return (
    <div className="checkbox__container" ref={dropdownRef}>
      <div>
        <button className="checkbox__dropdown-button" onClick={handlers.toggle}>
          Status
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z"
              fill="#525866"
            />
          </svg>
        </button>
      </div>
      <div
        className={`checkbox__dropdown ${
          isOpen ? "" : "checkbox__dropdown--open"
        }`}
      >
        <div className="checkbox__checkbox-group">
          <NavLink
            to={`?status=${checkAll ? "all" : list.join(",").toLowerCase()}`}
            className="checkbox__checkbox-label"
          >
            <div>
              <input
                type="checkbox"
                checked={checkAll}
                className="checkbox__table-checkbox"
                onChange={onCheckAllChange}
              />
              <span className="checkbox__label-text">All</span>
            </div>
            {list.length !== 0 && (
              <span className="checkbox__checked-count">{list.length}</span>
            )}
          </NavLink>

          <NavLink
            to={`?status=${getUpdatedStatus("Active")}`}
            className="checkbox__checkbox-label"
          >
            <input
              type="checkbox"
              className="checkbox__table-checkbox"
              checked={checkedList.includes("Active")}
              onChange={() => onChange("Active")}
            />
            Active
          </NavLink>
          <NavLink
            to={`?status=${getUpdatedStatus("Inactive")}`}
            className="checkbox__checkbox-label"
          >
            <input
              type="checkbox"
              className="checkbox__table-checkbox"
              checked={checkedList.includes("Inactive")}
              onChange={() => onChange("Inactive")}
            />
            Inactive
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default StatusDropdown;
