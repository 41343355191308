import "./Login.css";
import Grahith_logo from "../assets/Grahith_Logo.png";
import {
  EyeHideSvg,
  EyeSvg,
  InformationFill,
  LockerSvg,
  MailSvg,
  UserFill,
} from "../assets/svgs";
import { NavLink, Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  useFormErrors,
  useForm,
  useToggle,
} from "../modules/FeeManagementModule/FeeStructureModule/hooks";
import { login } from "../api/api";
import React from "react";
import UserContext from "../context/UserContext";

const LoginPage = () => {
  const { state: showPassword, handlers } = useToggle();
  const [data, handleChange] = useForm({
    emailOrPhone: "",
    password: "",
  });
  const { loggedIn, setLoggedIn } = React.useContext(UserContext);
  const [errors, setFieldError, resetErrors] = useFormErrors({
    emailOrPhone: "",
    password: "",
  });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[0-9]{10,}$/;
    return phoneRegex.test(phoneNumber);
  };

  if (loggedIn) {
    return <Navigate to={"/fee/dashboard"} state={{ from: location }} />;
  }

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;
    resetErrors();
    // Validate email or phone number
    if (
      !validateEmail(data.emailOrPhone) &&
      !validatePhoneNumber(data.emailOrPhone)
    ) {
      setFieldError(
        "emailOrPhone",
        "Please enter a valid email address or phone number"
      );
      valid = false;
    }
    // Validate password
    if (!validatePassword(data.password)) {
      setFieldError("password", "Password must be at least 6 characters long");
      valid = false;
    }
    if (valid) {
      setIsSubmitting(true);
      const formData = validateEmail(data.emailOrPhone)
        ? { email: data.emailOrPhone, password: data.password }
        : { phone_number: data.emailOrPhone, password: data.password };
      try {
        const loginResponse = await login(formData);
        if (
          loginResponse.errors &&
          loginResponse.errors.error === "INVALID_CREDENTIALS"
        ) {
          setFieldError(
            "password",
            "Please check your credentials and try again."
          );
          setLoggedIn(false);
        } else if (loginResponse.response && loginResponse.response.token) {
          localStorage.setItem("authToken", loginResponse.response.token);
          setLoggedIn(true);
          navigate("/fee/dashboard");
        } else {
          setFieldError(
            "password",
            "Login failed. Please check your credentials and try again."
          );
          setLoggedIn(false);
        }
      } catch (error) {
        setFieldError(
          "password",
          "Login failed. Please check your credentials and try again."
        );
        console.error("Login failed", error);
        setLoggedIn(false);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <section className="login">
      <header className="login__header">
        <img
          src={Grahith_logo}
          alt="Grahith"
          width={50}
          height={50}
          className="login__header--logo"
        />
      </header>
      <div className="login-container">
        <div className="login__grid"></div>
        <div className="login__grid--cover"></div>
        <div className="login__container--main">
          <div className="login__container--header">
            <div className="login__container--logo">
              <UserFill className={"login__user--svg"} />
            </div>
            <div className="login__container-header--info">
              <span className="login__container--title">
                Login to your account
              </span>
              <p className="login__container--subtitle">
                Enter your details to login.
              </p>
            </div>
          </div>
          <span className="login-divider"></span>
          <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
            <div className="login__container--input-box">
              <label htmlFor="emailOrPhone" className="login__label">
                Email Address or Phone Number
              </label>
              <div className="input-wrapper">
                <MailSvg className="input-icon" />
                <input
                  type="text"
                  name="emailOrPhone"
                  id="emailOrPhone"
                  placeholder="Enter your Email or Phone Number"
                  className="login__input"
                  value={data.emailOrPhone}
                  onChange={handleChange}
                  autoComplete="off"
                  disabled={isSubmitting}
                />
              </div>
              {errors.emailOrPhone && (
                <span className="error__message">
                  <InformationFill />
                  {errors.emailOrPhone}
                </span>
              )}
            </div>
            <div className="login__container--input-box">
              <label htmlFor="password" className="login__label">
                Password
              </label>
              <div className="input-wrapper">
                <LockerSvg className="input-icon" />
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="• • • • • • • • • •"
                  className="login__input"
                  value={data.password}
                  onChange={handleChange}
                  autoComplete="off"
                  disabled={isSubmitting}
                />
                {showPassword ? (
                  <EyeSvg
                    className="input-icon-right"
                    onClick={handlers.toggle}
                  />
                ) : (
                  <EyeHideSvg
                    className={"input-icon-right"}
                    onClick={handlers.toggle}
                  />
                )}
              </div>
              {errors.password && (
                <span className="error__message">
                  <InformationFill />
                  {errors.password}
                </span>
              )}
            </div>
            <div className="login--section__part">
              <div className="login__loggedIn">
                <input
                  type="checkbox"
                  name="loggedIn"
                  id="loggedIn"
                  disabled={isSubmitting}
                  value={true}
                  checked={true}
                  readOnly
                />
                <label htmlFor="loggedIn" className="login__label">
                  Keep me logged in
                </label>
              </div>
              <NavLink to={"#"} className="forgot-password">
                Forgot password?
              </NavLink>
            </div>
            <button className="login__button" disabled={isSubmitting}>
              Login
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
