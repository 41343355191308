import React from "react";
import RouteHeader from "../../FeeManagementModule/components/RouteHeader";
import { IoMdInformationCircleOutline } from "react-icons/io";
import "./SchoolDirectory.css";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { RiFileUserLine, RiUser6Line } from "react-icons/ri";
import { FiBookOpen } from "react-icons/fi";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTrigger,
} from "../components/Modal";
import {
  BorderButton,
  Button,
} from "../../FeeManagementModule/components/Button";
import {
  formatRomanNumber,
  useToggle,
} from "../../FeeManagementModule/FeeStructureModule/hooks";
import {
  addTeacherAndIncharge,
  getAllSchoolTeachers,
  getSchoolStructureById,
} from "../../../api/api";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
} from "../../../auth/SetupAdminPage/components/Dropdown";
import Skeleton from "react-loading-skeleton";
import { FaCheck } from "react-icons/fa6";

const SchoolDirectoryLayout = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[4];
  const { state: teacherOpen, handlers: teacherHandlers } = useToggle();
  const { state: inChargeOpen, handlers: inChargeHandlers } = useToggle();
  const school_structure_id = location.pathname.split("/")[3];
  const [isLoading, setIsLoading] = React.useState(false);
  const [classData, setClassData] = React.useState({});
  const [sectionData, setSectionData] = React.useState([]);
  const [teacherData, setTeacherData] = React.useState([]);
  const [selectedTeacher, setSelectedTeacher] = React.useState(null);
  const [selectedInCharge, setSelectedInCharge] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(null);

  const handleTeacherSelect = (teacher) => {
    setSelectedTeacher(teacher);
  };

  const handleInChargeSelect = (inCharge) => {
    setSelectedInCharge(inCharge);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getSchoolStructureById(school_structure_id);
        console.log(response);
        setClassData(response.response.results[0]);
        setSectionData(response.response.results[0].sections[0]);

        const teacherResponse = await getAllSchoolTeachers();
        setTeacherData(teacherResponse.response.users);
      } catch (error) {
        console.error("Error fetching academic details", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [school_structure_id]);

  const handleTeacherSubmit = async () => {
    if (!selectedTeacher)
      return setSubmitError("Please select a teacher to continue.");

    setIsSubmitting(true);
    setSubmitError(null);

    const formData = {
      class_section_mapping_id: school_structure_id,
      class_teacher_id: selectedTeacher.id,
    };

    try {
      const response = await addTeacherAndIncharge(formData);
      if (response.response.message === "RESOURCE_CREATED_SUCCESSFULLY") {
        setSectionData((prevSectionData) => ({
          ...prevSectionData,
          class_teacher_name: selectedTeacher.full_name,
        }));
        teacherHandlers.off();
      } else {
        setSubmitError("Failed to assign teacher. Please try again.");
      }
    } catch (error) {
      setSubmitError("Failed to assign teacher. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInChargeSubmit = async () => {
    if (!selectedInCharge)
      return setSubmitError("Please select an academic incharge to continue.");

    setIsSubmitting(true);
    setSubmitError(null);

    const formData = {
      class_section_mapping_id: school_structure_id,
      academic_incharge_id: selectedInCharge.id,
    };

    try {
      const response = await addTeacherAndIncharge(formData);
      if (response.response.message === "RESOURCE_CREATED_SUCCESSFULLY") {
        setSectionData((prevSectionData) => ({
          ...prevSectionData,
          academic_incharge_name: selectedInCharge.full_name,
        }));
        inChargeHandlers.off();
      } else {
        setSubmitError("Failed to assign academic incharge. Please try again.");
      }
    } catch (error) {
      setSubmitError("Failed to assign academic incharge. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <section className="school-directory">
        <div
          className="school-directory__info"
          style={{
            border: "none",
          }}
        >
          <header className="school-directory__info--header">
            <span className="school-directory__text">
              <Skeleton width={200} />
            </span>
          </header>
          <div className="school-directory__info--content">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="school-directory__info--student">
                <Skeleton width={20} height={20} />
                <Skeleton width={200} />
                <Skeleton width={50} />
              </div>
            ))}
          </div>
        </div>
        <section>
          <Skeleton width={200} height={30} />
          <div className="school-directory__class--topBar">
            <div className="school-directory__class--topBar--heading">
              <Skeleton width={100} />
              <Skeleton width={80} />
            </div>
            <div className="school-directory__class--topBar--heading">
              <Skeleton width={100} />
              <Skeleton width={150} />
            </div>
            <div className="school-directory__class--topBar--heading">
              <Skeleton width={100} />
              <Skeleton width={150} />
            </div>
          </div>
          <div className="school-directory__top-bar">
            <ul className="school-directory__nav">
              {[...Array(3)].map((_, i) => (
                <li key={i}>
                  <Skeleton width={150} height={20} />
                </li>
              ))}
            </ul>
          </div>
        </section>
        <section>
          <Skeleton height={400} />
        </section>
      </section>
    );
  }

  const className = classData.class_name?.split(" ")[1] || "";

  return (
    <>
      <RouteHeader
        routeHeader="School Directory"
        mainroute="/classroom"
        routes={[
          {
            name: `Class ${formatRomanNumber(className)}-${
              sectionData.section_name
            }`,
            link: "/directory/school",
          },
        ]}
      />
      <section className="school-directory" style={{display:'flex',flexDirection:'column',gap:'1.3rem'}}>
        {sectionData.no_of_subjects === 0 ||
        sectionData.no_of_teachers === 0 ||
        sectionData.no_of_students === 0 ? (
          <div className="school-directory__info">
            <header className="school-directory__info--header">
              <IoMdInformationCircleOutline
                className="school-directory__icon"
                size={"1.5rem"}
                color="#335CFF"
              />
              <span className="school-directory__text">
                3 pending actions to setup class
              </span>
            </header>
            <div className="school-directory__info--content">
              <div className="school-directory__info--student">
                <span
                  className="school-directory__info--number"
                  style={{
                    backgroundColor: sectionData.no_of_subjects
                      ? "var(--green-color)"
                      : "",
                    border: sectionData.no_of_subjects ? "none" : "",
                  }}
                >
                  {sectionData.no_of_subjects ? (
                    <FaCheck color="white" />
                  ) : (
                    <>1</>
                  )}
                </span>
                <span className="school-directory__info--text">
                  Assign subjects
                </span>
                <span className="school-directory__info--underline"></span>
              </div>
              <div className="school-directory__info--student">
                <span
                  className="school-directory__info--number"
                  style={{
                    backgroundColor: sectionData.no_of_teachers
                      ? "var(--green-color)"
                      : "",
                    border: sectionData.no_of_teachers ? "none" : "",
                  }}
                >
                  {sectionData.no_of_teachers ? (
                    <FaCheck color="white" />
                  ) : (
                    <>2</>
                  )}
                </span>{" "}
                <span className="school-directory__info--text">
                  Assign Teacher
                </span>
                <span className="school-directory__info--underline"></span>
              </div>{" "}
              <div className="school-directory__info--student">
                <span
                  className="school-directory__info--number"
                  style={{
                    backgroundColor: sectionData.no_of_students
                      ? "var(--green-color)"
                      : "",
                    border: sectionData.no_of_students ? "none" : "",
                  }}
                >
                  {sectionData.no_of_students ? (
                    <FaCheck color="white" />
                  ) : (
                    <>3</>
                  )}
                </span>{" "}
                <span className="school-directory__info--text">
                  Add students to the class
                </span>
              </div>
            </div>
          </div>
        ) : null}
        <section style={{display:'flex',flexDirection:'column',gap:'0.8rem'}}>
          <p className="school-directory__class-name" >{`Class ${formatRomanNumber(
            className
          )}-${sectionData.section_name}`}</p>
          <div className="school-directory__class--topBar">
            <div className="school-directory__class--topBar--heading">
              <span style={{color:'rgba(153, 160, 174, 1)'}}>No. Of students</span>
              <span className="no---class">
                {sectionData.no_of_students} Students
              </span>
            </div>
            <div className="school-directory__class--topBar--heading">
              <span style={{color:'rgba(153, 160, 174, 1)'}}>Class Teacher(s)</span>
              {sectionData.class_teacher_name === "" ? (
                <>
                  <ModalTrigger
                    onClick={teacherHandlers.toggle}
                    className={
                      "school-directory__class--topBar--heading--assign"
                    }
                  >
                    + Assign Teacher
                  </ModalTrigger>
                  <Modal isOpen={teacherOpen} onClose={teacherHandlers.off}>
                    <ModalHeader onClose={teacherHandlers.off}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "2rem",
                            height: "2rem",
                            borderRadius: "50%",
                            border: "1.4px solid var(--gray-color-200)",
                          }}
                        >
                          <RiUser6Line
                            size={"1rem"}
                            color={"var(--gray-color-600)"}
                          />
                        </span>
                        Assign Class Teacher
                      </div>
                    </ModalHeader>
                    <ModalBody>
                      <p>Select a Class teacher *</p>
                      <Dropdown
                        trigger={
                          <span>
                            {selectedTeacher ? (
                              <span
                                style={{
                                  color: "var(--black-color)",
                                  textTransform: "capitalize",
                                  fontWeight: "500",
                                }}
                              >
                                {selectedTeacher.full_name}
                              </span>
                            ) : (
                              "Select a user..."
                            )}
                          </span>
                        }
                      >
                        {isLoading ? (
                          <DropdownMenu>
                            <DropdownItem>
                              <Skeleton width={150} />
                            </DropdownItem>
                            <DropdownItem>
                              <Skeleton width={150} />
                            </DropdownItem>
                            <DropdownItem>
                              <Skeleton width={150} />
                            </DropdownItem>
                          </DropdownMenu>
                        ) : (
                          <DropdownMenu>
                            {teacherData.map((teacher, index) => (
                              <DropdownItem
                                key={index}
                                onClick={() => handleTeacherSelect(teacher)}
                              >
                                {teacher.full_name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        )}
                      </Dropdown>
                      <span
                        style={{
                          color: "var(--error-color)",
                          fontSize: "0.8rem",
                          textTransform: "capitalize",
                        }}
                      >
                        {submitError}
                      </span>
                    </ModalBody>
                    <ModalFooter
                      style={{
                        display: "flex",
                      }}
                    >
                      <BorderButton
                        onClick={teacherHandlers.off}
                        style={{
                          width: "50%",
                        }}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </BorderButton>
                      <Button
                        style={{
                          width: "50%",
                        }}
                        onClick={handleTeacherSubmit}
                        disabled={isSubmitting}
                        className={isSubmitting && "button__loading"}
                      >
                        Continue
                      </Button>
                    </ModalFooter>
                  </Modal>
                </>
              ) : (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textTransform: "capitalize",
                  }}
                >
                  <img
                    src={`https://api.dicebear.com/8.x/initials/svg?seed=${sectionData.class_teacher_name}`}
                    alt={sectionData.class_teacher_name}
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      borderRadius: "50%",
                      marginRight: "0.5rem",
                    }}
                  />
                  {sectionData.class_teacher_name}
                </span>
              )}
            </div>{" "}
            <div className="school-directory__class--topBar--heading">
              <span style={{color:'rgba(153, 160, 174, 1)'}}>Academic Incharge</span>
              {sectionData.academic_incharge_name === "" ? (
                <>
                  <ModalTrigger
                    onClick={inChargeHandlers.toggle}
                    className={
                      "school-directory__class--topBar--heading--assign"
                    }
                  >
                    + Assign Incharge
                  </ModalTrigger>
                  <Modal isOpen={inChargeOpen} onClose={inChargeHandlers.off}>
                    <ModalHeader onClose={inChargeHandlers.off}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "2rem",
                            height: "2rem",
                            borderRadius: "50%",
                            border: "1.4px solid var(--gray-color-200)",
                          }}
                        >
                          <RiUser6Line
                            size={"1rem"}
                            color={"var(--gray-color-600)"}
                          />
                        </span>
                        Assign Academic Incharge
                      </div>
                    </ModalHeader>
                    <ModalBody>
                      <p>Select an Academic Incharge *</p>
                      <Dropdown
                        trigger={
                          <span>
                            {selectedInCharge ? (
                              <span
                                style={{
                                  color: "var(--black-color)",
                                  textTransform: "capitalize",
                                  fontWeight: "500",
                                }}
                              >
                                {selectedInCharge.full_name}
                              </span>
                            ) : (
                              "Select a user..."
                            )}
                          </span>
                        }
                      >
                        {isLoading ? (
                          <DropdownMenu>
                            <DropdownItem>
                              <Skeleton width={150} />
                            </DropdownItem>
                            <DropdownItem>
                              <Skeleton width={150} />
                            </DropdownItem>
                            <DropdownItem>
                              <Skeleton width={150} />
                            </DropdownItem>
                          </DropdownMenu>
                        ) : (
                          <DropdownMenu>
                            {teacherData.map((inCharge, index) => (
                              <DropdownItem
                                key={index}
                                onClick={() => handleInChargeSelect(inCharge)}
                              >
                                {inCharge.full_name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        )}
                      </Dropdown>
                      <span
                        style={{
                          color: "var(--error-color)",
                          fontSize: "0.8rem",
                          textTransform: "capitalize",
                        }}
                      >
                        {submitError}
                      </span>
                    </ModalBody>
                    <ModalFooter
                      style={{
                        display: "flex",
                      }}
                    >
                      <BorderButton
                        onClick={inChargeHandlers.off}
                        style={{
                          width: "50%",
                        }}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </BorderButton>
                      <Button
                        style={{
                          width: "50%",
                        }}
                        onClick={handleInChargeSubmit}
                        disabled={isSubmitting}
                        className={isSubmitting && "button__loading"}
                      >
                        Continue
                      </Button>
                    </ModalFooter>
                  </Modal>
                </>
              ) : (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textTransform: "capitalize",
                  }}
                >
                  <img
                    src={`https://api.dicebear.com/8.x/initials/svg?seed=${sectionData.academic_incharge_name}`}
                    alt={sectionData.academic_incharge_name}
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      borderRadius: "50%",
                      objectFit: "cover",
                      marginRight: "0.5rem",
                    }}
                  />
                  {sectionData.academic_incharge_name}
                </span>
              )}
            </div>
            <div className="school-directory__class--topBar--heading">
              <span style={{color:'rgba(153, 160, 174, 1)'}}>Academic programme</span>
              <span
                className="school-directory__class--topBar--heading--assign"
                style={{
                  textTransform: "capitalize",
                  color: "var(--black-color)",
                  fontSize: "0.75rem",
                }}
              >
                {/* {sectionData.academic_programme_name === ""
                  ? "N/A"
                  : sectionData.academic_programme_name} */}
                  Telangana State Board
              </span>
            </div>
          </div>   
        </section>
        <section>
        <div className="school-directory__top-bar">
            <ul className="school-directory__nav">
              <li>
                <NavLink
                  to={`/directory/school/${school_structure_id}/students/`}
                  className={`school-directory__link ${
                    path === "students" ? "school-directory__link--active" : ""
                  }`}
                >
                  <RiUser6Line />
                  Students (
                  {sectionData.no_of_students ? sectionData.no_of_students : 0})
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/directory/school/${school_structure_id}/teachers`}
                  className={`school-directory__link ${
                    path === "teachers" ? "school-directory__link--active" : ""
                  }`}
                >
                  <RiFileUserLine />
                  Teachers (
                  {sectionData.no_of_teachers ? sectionData.no_of_teachers : 0})
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/directory/school/${school_structure_id}/academics`}
                  className={`school-directory__link ${
                    path === "academics" ? "school-directory__link--active" : ""
                  }`}
                >
                  <FiBookOpen />
                  Subjects (
                  {sectionData.no_of_subjects ? sectionData.no_of_subjects : 0})
                </NavLink>
              </li>
            </ul>
          </div>
          <Outlet />
        </section>
      </section>
    </>
  );
};

export default SchoolDirectoryLayout;
