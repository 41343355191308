import React from "react";
import { Link, useLocation } from "react-router-dom";
import FeeCollectionTable from "./FeeCollectionTable";
import "./components/Widget.css";
import "./FeeCollection.css";
import {
  BankingWidget,
  FinanceWidget,
  StudentWidget,
} from "./components/Widget";
import Search from "../components/Search";
import { useSearch } from "../FeeStructureModule/hooks";
import Pagination from "../../../home/Pagination";
import RouteHeader from "../components/RouteHeader";
import FeeHeader from "../components/FeeHeader";
import GradesDropdown from "../components/GradesDropdown";
import {
  getAllTerms,
  getDashboardStats,
  getPendingFeeDetails,
} from "../../../api/api";
import TermDropdown from "./components/TermDropDown";
import UserContext from "../../../context/UserContext";

const FeeCollection = () => {
  const [feeData, setFeeData] = React.useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeFilter = queryParams.get("filter");
  const termFilter = queryParams.get("term");
  const sortFilter = queryParams.get("sort") || "asc";
  const lowFilter = queryParams.get("low") || 0;
  const highFilter = queryParams.get("high") || 100000000;
  const [maxTerms, setMaxTerms] = React.useState(null);
  const [terms, setTerms] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDashboardLoading, setIsDashboardLoading] = React.useState(false);
  const gradesFilter = queryParams.get("grades");

  const { searchTerm, filteredItems, handleSearchChange } = useSearch(
    feeData,
    "full_name"
  );
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedFilterTerm, setSelectedFilterTerm] = React.useState();
  const [data, setData] = React.useState({
    totalAmountStats: {
      total_amount_paid: "NA",
      total_term_fee: "NA",
      total_term_concession: "NA",
    },
    countsStats: {
      completed_count: 0,
      pending_count: 0,
      overdue_count: 0,
    },

    totalAmountFilterStats: {
      today_amount_paid: "NA",
      last_week_amount_paid: "NA",
      last_month_amount_paid: "NA",
    },
  });

  const { userInfo } = React.useContext(UserContext);

  const pageSize = 6;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getFilteredData = () => {
    if (!filteredItems) return;
    switch (activeFilter) {
      case "overdue":
        return filteredItems.filter(
          (item) => item.payment_status === "overdue"
        );
      case "completed":
        return filteredItems.filter(
          (item) => item.payment_status === "completed"
        );
      case "pending":
      default:
        return filteredItems.filter(
          (item) => item.payment_status === "pending"
        );
    }
  };
  const filteredData = getFilteredData();

  React.useEffect(() => {
    setCurrentPage(1);
  }, [gradesFilter, activeFilter]);

  const totalPages = filteredData
    ? Math.ceil(filteredData.length / pageSize)
    : 0;
  const paginatedData = filteredData
    ? filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
    : [];

  React.useEffect(() => {
    if (
      userInfo.branch_id !== undefined &&
      userInfo.branch_id !== null &&
      gradesFilter !== null &&
      gradesFilter !== undefined
    ) {
      getAllTerms(userInfo.branch_id).then((res) =>
        setMaxTerms(res.response.max_terms)
      );
      if (maxTerms !== null && maxTerms !== undefined) {
        setIsDashboardLoading(true);
        getDashboardStats(
          termFilter,
          maxTerms,
          userInfo.branch_id,
          gradesFilter
        )
          .then((res) => {
            setData(res);
            setIsDashboardLoading(false);
          })
          .catch((err) => {
            console.error("Error fetching dashboard stats:", err);
            setIsDashboardLoading(false);
          });
      }
    }
  }, [maxTerms, termFilter, userInfo.branch_id, gradesFilter]);

  React.useEffect(() => {
    if (
      userInfo.branch_id !== null &&
      userInfo.branch_id !== undefined &&
      gradesFilter !== null &&
      gradesFilter !== undefined
    ) {
      setIsLoading(true);
      if (maxTerms && (termFilter === "annual" || termFilter === "term")) {
        setSelectedFilterTerm(maxTerms);
      } else {
        var regex = /^term\s\d+$/;
        if (regex.test(termFilter)) {
          setSelectedFilterTerm(termFilter.split(" ")[1]);
        }
      }
      getPendingFeeDetails(
        termFilter,
        sortFilter,
        lowFilter,
        highFilter,
        maxTerms,
        gradesFilter,
        userInfo.branch_id
      )
        .then((res) => {
          setFeeData(res);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching data:", err);
          setIsLoading(false);
        });
    }
  }, [
    termFilter,
    sortFilter,
    lowFilter,
    highFilter,
    gradesFilter,
    maxTerms,
    userInfo.branch_id,
  ]);

  React.useEffect(() => {
    const generatedTerms = Array.from(
      { length: maxTerms },
      (_, index) => `term ${index + 1}`
    );
    setTerms(generatedTerms);
  }, [maxTerms]);

  const updateQueryParams = (newParams) => {
    const currentParams = new URLSearchParams(location.search);
    Object.keys(newParams).forEach((key) => {
      currentParams.set(key, newParams[key]);
    });
    return `?${currentParams.toString()}`;
  };

  const updatedFeeData = (feeCollectionsData, termFeeData) => {
    setFeeData((prevFeeData) => {
      const feeDataCopy = [...prevFeeData];

      feeCollectionsData.feeCollections.forEach((collection) => {
        const indexToUpdate = feeDataCopy.findIndex(
          (item) => item.user_id === feeCollectionsData.student_user_id
        );

        if (indexToUpdate !== -1) {
          const originalAmountPaid = parseFloat(
            feeDataCopy[indexToUpdate].total_amount_paid
          );
          const amountPaid = parseFloat(collection.amount_paid);
          const term_fee = parseFloat(
            feeDataCopy[indexToUpdate].total_term_fee
          );

          if (!isNaN(originalAmountPaid) && !isNaN(amountPaid)) {
            feeDataCopy[indexToUpdate].total_amount_paid =
              originalAmountPaid + amountPaid;

            const term = termFeeData.find(
              (term) => term.term_id === collection.term_id
            );

            if (term && term.term_fee === amountPaid) {
              feeDataCopy[indexToUpdate].terms_completed =
                parseInt(feeDataCopy[indexToUpdate].terms_completed, 10) + 1;
            }

            if (term_fee === originalAmountPaid + amountPaid) {
              feeDataCopy[indexToUpdate].payment_status = "completed";
              const parsedTermsCompleted = parseInt(
                feeDataCopy[indexToUpdate].terms_completed,
                10
              );

              if (
                parsedTermsCompleted +
                  feeDataCopy[indexToUpdate].terms_completed >
                feeDataCopy[indexToUpdate].terms
              ) {
                feeDataCopy[indexToUpdate].terms_completed =
                  feeDataCopy[indexToUpdate].terms;
              } else {
                feeDataCopy[indexToUpdate].terms_completed +=
                  parsedTermsCompleted;
              }
            }
          } else {
            console.error(
              "Invalid amount detected. Ensure the amounts are valid numbers."
            );
          }
        } else {
          console.error(
            `User with user_id ${feeCollectionsData.student_user_id} not found in feeData.`
          );
        }
      });

      return feeDataCopy;
    });

    if (maxTerms !== null && maxTerms !== undefined) {
      setIsDashboardLoading(true);
      getDashboardStats(termFilter, maxTerms, userInfo.branch_id, gradesFilter)
        .then((res) => {
          setData(res);
          setIsDashboardLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching dashboard stats:", err);
          setIsDashboardLoading(false);
        });
    }
  };

  return (
    <section className="fee-collection">
      <RouteHeader
        routeHeader={"Fee Management"}
        routes={[{ name: "Fee Collection", link: "/fee/collection" }]}
        search
      />
      <FeeHeader
        text="Fee Collection"
        subText="Manage fee collection for your school"
      />
      <div className="fee-structure__container">
        <div className="fee-structure__dropdowns">
          <GradesDropdown />
          <TermDropdown items={terms} termFilter={termFilter} />
        </div>
        <Search
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          placeholder="Search for Student Name"
        />
      </div>
      <div className="fee-collection__widgets">
        <FinanceWidget
          amountPaid={data.totalAmountStats.total_amount_paid}
          totalAmount={data.totalAmountStats.total_term_fee}
          concession={data.totalAmountStats.total_term_concession}
          isLoading={isDashboardLoading}
          role={userInfo.role}
        />
        {userInfo.role !== "accountant" && (
          <BankingWidget
            amount_paid={data.totalAmountStats.total_amount_paid}
            total_term_fee={data.totalAmountStats.total_term_fee}
            concession={data.totalAmountStats.total_term_concession}
            isLoading={isDashboardLoading}
          />
        )}
        <StudentWidget
          pending={data.countsStats.pending_count}
          overdue={data.countsStats.overdue_count}
          completed={data.countsStats.completed_count}
          isLoading={isDashboardLoading}
        />
      </div>
      <div className="fee-collection__top-bar">
        <ul className="fee-collection__nav">
          <li>
            <Link
              to={updateQueryParams({ filter: "overdue" })}
              className={`fee-collection__link ${
                activeFilter === "overdue" ? "fee-collection__link--active" : ""
              }`}
            >
              <span className={"fee-collection__indicator--overdue"}></span>
              Overdue (
              {data && data.countsStats ? data.countsStats.overdue_count : 0})
            </Link>
          </li>
          <li>
            <Link
              to={updateQueryParams({ filter: "pending" })}
              className={`fee-collection__link ${
                activeFilter === "pending" ? "fee-collection__link--active" : ""
              }`}
            >
              <span className={"fee-collection__indicator--pending"}></span>
              Pending (
              {data && data.countsStats ? data.countsStats.pending_count : 0})
            </Link>
          </li>
          <li>
            <Link
              to={updateQueryParams({ filter: "completed" })}
              className={`fee-collection__link ${
                activeFilter === "completed"
                  ? "fee-collection__link--active"
                  : ""
              }`}
            >
              <span className={"fee-collection__indicator--completed"}></span>
              Completed (
              {data && data.countsStats ? data.countsStats.completed_count : 0})
            </Link>
          </li>
        </ul>
      </div>
      <FeeCollectionTable
        items={paginatedData}
        filterTerm={selectedFilterTerm}
        activeLink={activeFilter}
        updatedFeeData={updatedFeeData}
        isLoading={isLoading}
      />
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      )}
    </section>
  );
};

export default FeeCollection;
