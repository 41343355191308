import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./GradesDropdown.css";
import { useToggle, useOutsideClick } from "../FeeStructureModule/hooks";
import UserContext from "../../../context/UserContext";
import { getClassesBasedOnBranchId } from "../../../api/api";

const GradesDropdown = () => {
  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [list, setList] = useState([]);
  const [initialGrades, setInitialGrades] = useState({});
  const [mappingIds, setMappingIds] = useState([]);
  const dropdownRef = useRef(null);
  const { state: isOpen, handlers } = useToggle();
  useOutsideClick(dropdownRef, handlers.off);

  const { userInfo } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo.branch_id !== undefined && userInfo.branch_id !== null) {
      getClassesBasedOnBranchId(userInfo.branch_id).then((res) => {
        const sections = res.response.sections;
        const grades = sections.reduce((acc, { class: className, section }) => {
          if (!acc[className]) {
            acc[className] = [];
          }
          acc[className].push(`${className}-${section}`);
          return acc;
        }, {});

        const ids = sections.map(
          ({ class_section_mapping_id }) => class_section_mapping_id
        );
        setInitialGrades(grades);
        setMappingIds(ids);
        setCheckedList(
          Object.keys(grades).flatMap((grade) => [grade, ...grades[grade]])
        );
        console.log("intial gradesss", initialGrades);
        console.log("mappings", mappingIds);

        const url = new URL(window.location.href);
        url.searchParams.set("grades", "all");
        navigate(url.pathname + url.search);
      });
    }
    //eslint-disable-next-line
  }, [userInfo.branch_id, navigate]);

  const allOptions = useMemo(
    () => [
      "Select all",
      ...Object.keys(initialGrades).flatMap((grade) => [
        grade,
        ...initialGrades[grade],
      ]),
    ],
    [initialGrades]
  );

  useEffect(() => {
    const filteredCheckedList = checkedList.filter(
      (item) => !["Select all", ...Object.keys(initialGrades)].includes(item)
    );
    setList(filteredCheckedList);
  }, [checkedList, initialGrades]);

  useEffect(() => {
    if (checkedList.length === allOptions.length - 1) {
      setCheckAll(true);
      const url = new URL(window.location.href);
      url.searchParams.set("grades", "all");
      navigate(url.pathname + url.search);
    } else {
      setCheckAll(false);
    }
  }, [checkedList, allOptions.length, navigate]);

  const updateURLWithMappingIds = (updatedCheckedList) => {
    const filteredSections = allOptions.filter((option) =>
      option.includes("-")
    );

    const selectedIds = mappingIds.filter((_, idx) => {
      const isIncluded = updatedCheckedList.includes(filteredSections[idx]);
      return isIncluded;
    });

    const url = new URL(window.location.href);

    if (updatedCheckedList.length === 0) {
      url.searchParams.delete("grades");
    } else if (updatedCheckedList.length === allOptions.length - 1) {
      url.searchParams.set("grades", "all");
    } else {
      url.searchParams.set("grades", selectedIds.join(","));
    }

    navigate(url.pathname + url.search);
  };

  const onChange = (option, subOption) => {
    setCheckedList((prevCheckedList) => {
      let updatedCheckedList = [...prevCheckedList];

      if (subOption) {
        if (updatedCheckedList.includes(subOption)) {
          updatedCheckedList = updatedCheckedList.filter(
            (item) => item !== subOption
          );
        } else {
          updatedCheckedList.push(subOption);
        }

        const parentGrade = option;
        const allSubGradesChecked = initialGrades[parentGrade].every(
          (subGrade) => updatedCheckedList.includes(subGrade)
        );
        if (allSubGradesChecked && !updatedCheckedList.includes(parentGrade)) {
          updatedCheckedList.push(parentGrade);
        } else if (
          !allSubGradesChecked &&
          updatedCheckedList.includes(parentGrade)
        ) {
          updatedCheckedList = updatedCheckedList.filter(
            (item) => item !== parentGrade
          );
        }
      } else {
        const subGrades = initialGrades[option];
        const allSubGradesChecked = subGrades.every((subGrade) =>
          updatedCheckedList.includes(subGrade)
        );

        if (allSubGradesChecked) {
          updatedCheckedList = updatedCheckedList.filter(
            (item) => !subGrades.includes(item)
          );
          updatedCheckedList = updatedCheckedList.filter(
            (item) => item !== option
          );
        } else {
          updatedCheckedList = [
            ...updatedCheckedList,
            option,
            ...subGrades.filter(
              (subGrade) => !updatedCheckedList.includes(subGrade)
            ),
          ];
        }
      }

      const allSelected = Object.keys(initialGrades).every((grade) =>
        [grade, ...initialGrades[grade]].every((item) =>
          updatedCheckedList.includes(item)
        )
      );
      setCheckAll(allSelected);

      updateURLWithMappingIds(updatedCheckedList);
      return updatedCheckedList;
    });
  };

  const onCheckAllChange = () => {
    if (checkAll) {
      setCheckedList([]);
      setCheckAll(false);
      const url = new URL(window.location.href);
      url.searchParams.delete("grades");
      navigate(url.pathname + url.search);
    } else {
      setCheckedList(allOptions.slice(1));
      setCheckAll(true);
      const url = new URL(window.location.href);
      url.searchParams.set("grades", "all");
      navigate(url.pathname + url.search);
    }
  };

  return (
    <div className="grades__container" ref={dropdownRef}>
      <button className="grades__dropdown-button" onClick={handlers.toggle}>
        Grades
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z"
            fill="#525866"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="grades__dropdown">
          <div className="grades__checkbox-group">
            <label className="grades__checkbox-label">
              <div>
                <input
                  type="checkbox"
                  checked={checkAll}
                  className="grades__table-checkbox"
                  onChange={onCheckAllChange}
                />
                <span className="grades__label-text">Select all</span>
              </div>
              {list.length !== 0 && (
                <span className="grades__checked-count">{list.length}</span>
              )}
            </label>

            {Object.entries(initialGrades).map(([grade, subGrades]) => (
              <React.Fragment key={grade}>
                <label className="grades__checkbox-label">
                  <input
                    type="checkbox"
                    className="grades__table-checkbox grades__table-parent-checkbox"
                    checked={checkedList.includes(grade)}
                    onChange={() => onChange(grade)}
                  />
                  {grade}
                </label>
                {(checkedList.includes(grade) ||
                  subGrades.some((subGrade) =>
                    checkedList.includes(subGrade)
                  )) &&
                  subGrades.map((subGrade) => (
                    <label
                      className="grades__checkbox-child-label"
                      key={subGrade}
                    >
                      <input
                        type="checkbox"
                        className="grades__table-checkbox"
                        checked={checkedList.includes(subGrade)}
                        onChange={() => onChange(grade, subGrade)}
                      />
                      <span>{subGrade}</span>
                    </label>
                  ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default GradesDropdown;
