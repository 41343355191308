export const TermsPaid = ({ termsPaid, totalTerms }) => {
  return (
    <div className="terms-paid">
      <svg
        className="terms-paid__icon"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path d="M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM5.4018 8.4L9.6438 4.1574L8.7954 3.309L5.4018 6.7032L3.7044 5.0058L2.856 5.8542L5.4018 8.4Z" />
      </svg>
      <span className="terms-paid__status">
        {termsPaid} of {totalTerms} paid
      </span>
    </div>
  );
};

export const RemindTermsPaid = ({ term, totalTerms }) => {
  return (
    <div className="remind-terms-paid">
      <svg
        className="remind-terms-paid__icon"
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path d="M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM5.4018 8.4L9.6438 4.1574L8.7954 3.309L5.4018 6.7032L3.7044 5.0058L2.856 5.8542L5.4018 8.4Z" />
      </svg>
      <span className="remind-terms-paid__status">
        {term} of {totalTerms} paid
      </span>
    </div>
  );
};
