import PropTypes from "prop-types";

const PaymentMethod = ({ Method, ID }) => {
  return (
    <div className={`student-class`}>
      <span className="student-class__class"> {Method}</span>
      <span className="student-class__programme">{ID}</span>
    </div>
  );
};

PaymentMethod.propTypes = {
  class_Name: PropTypes.string.isRequired,
};

export default PaymentMethod;
