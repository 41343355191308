import PropTypes from "prop-types";
import RemainderMethods from "./RemainderMethods";
import "./RemindFeesSheet.css";
import { RemindDueAmount } from "./DueAmount";
import {
  formatDate,
  formatPrice,
  formatPriceWithExponential,
} from "../../FeeStructureModule/hooks";
import { Sheet, Footer, Content } from "../../components/Sheet";
import React from "react";
import { getUserFeePayment, sendBulkSMS } from "../../../../api/api";
import { toast } from "sonner";
import UserContext from "../../../../context/UserContext";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/Table";

const RemindFeesSheet = ({ isSheetOpen, handlers, items, method }) => {
  const data = items[0];
  const termsPaid = data.terms_completed;
  const totalTerms = data.terms;
  const progressDegree = (termsPaid / totalTerms) * 360;
  const [termFeeData, setTermFeeData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const timeoutRef = React.useRef(null);
  const { userInfo } = React.useContext(UserContext);

  React.useEffect(() => {
    getUserFeePayment(data.user_id, data.fee_structure_id).then((res) => {
      setTermFeeData(res.response.fee_payments);
    });
  }, [data.fee_structure_id, data.user_id]);

  const handleSubmit = () => {
    setIsLoading(true);
    const updatedMessages = items.map((item) => ({
      student_name: item.full_name,
      grade: `${item.class} ${item.section}`,
      term: `Term ${item.terms}`,
      amount_due: item.total_amount_paid,
      due_date: item.next_due_date,
      to: item.phone_number,
    }));

    const use_case =
      method === "remind" ? "bulk_remind_fee" : "bulk_request_fee";

    sendBulkSMS(updatedMessages, userInfo.branch_name, use_case).then((res) => {
      if (res.response.error) {
        toast.error(res.response.error, {
          position: "top-right",
          autoClose: 5000,
        });
        setIsLoading(false);
      }

      if (res.response.message === "MESSAGES_SENT") {
        toast.success("Messages sent successfully", {
          position: "top-right",
          autoClose: 5000,
        });
        setIsLoading(false);
        timeoutRef.current = setTimeout(() => {
          handlers.toggle();
        }, 1000);
      }
    });
  };

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Sheet
      width={45}
      isSheetOpen={isSheetOpen}
      headerTitle={
        method === "remind" ? "Remind Fee Collection" : "Request Fee Collection"
      }
      handlers={handlers}
    >
      <Content className="request-fee__container">
        <div className="collect-fee__content">
          <img
            className="collect-fee__avatar"
            src={`https://api.dicebear.com/8.x/initials/svg?seed=${data.full_name}`}
            alt={data.full_name}
          />
          <div className="collect-fee__details">
            <div className="collect-fee__student-info">
              <span className="collect-fee__student-name">
                {data.full_name}
              </span>
              {data.student_id !== null && (
                <span className="collect-fee__student-id">
                  #{data.student_id}
                </span>
              )}
            </div>
            <span className="collect-fee__student-class">
              {data.class}-{data.section}
            </span>
          </div>
        </div>
        <div className="collect-fee__subheading">net to be collected</div>
        <div className="collect-fee__due-info">
          <div className="collect-fee__due-info--div">
            <span className="collect-fee__total-due">Total Due</span>
            <span className="collect-fee__price">
              {formatPrice(Number(data.due_amount))}
            </span>
          </div>
          <span className="collect-fee__terms-paid">
            <div
              className="circular__progress"
              style={{
                background: `conic-gradient(var(--green-color) ${progressDegree}deg, var(--gray-color-200) ${progressDegree}deg)`,
              }}
            ></div>
            <span className="collect-fee__terms-paid--text">
              {termsPaid} of {totalTerms} terms paid
            </span>
          </span>
        </div>
        <div className="remind-fee__table">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Term Name</TableHead>
                <TableHead>Amount Paid</TableHead>
                <TableHead>Due Date</TableHead>
                <TableHead>Status</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {termFeeData?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell className="remind-fees__table-data">
                    <span>Term {item.term}</span>
                  </TableCell>
                  <TableCell>
                    <RemindDueAmount
                      amount={item.amount_paid}
                      total={item.term_fee}
                      concession={item.term_concession}
                    />
                  </TableCell>
                  <TableCell>{formatDate(item.due_date)}</TableCell>
                  <TableCell>
                    <div className="terms-paid remind_terms_paid">
                      {item.payment_completed ? (
                        <svg
                          className="terms-paid__icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path d="M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM5.4018 8.4L9.6438 4.1574L8.7954 3.309L5.4018 6.7032L3.7044 5.0058L2.856 5.8542L5.4018 8.4Z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                          fill="none"
                          className="terms-paid__icon"
                        >
                          <path
                            d="M6.49364 0.622248L11.9236 10.1358C11.9737 10.2234 12 10.3229 12 10.4241C12 10.5253 11.9737 10.6247 11.9236 10.7124C11.8736 10.8 11.8017 10.8728 11.715 10.9234C11.6283 10.974 11.5301 11.0006 11.43 11.0007H0.570004C0.469946 11.0006 0.371652 10.974 0.285 10.9234C0.198348 10.8728 0.126392 10.8 0.0763644 10.7124C0.0263365 10.6247 -6.35474e-07 10.5253 0 10.4241C6.35497e-07 10.3229 0.0263391 10.2234 0.076368 10.1358L5.50636 0.622248C5.5564 0.534604 5.62835 0.461824 5.715 0.411224C5.80165 0.360623 5.89995 0.333984 6 0.333984C6.10005 0.333984 6.19835 0.360623 6.285 0.411224C6.37165 0.461824 6.4436 0.534604 6.49364 0.622248ZM5.42998 8.11776V9.27092H6.57002V8.11776H5.42998ZM5.42998 4.08172V6.96461H6.57002V4.08172H5.42998Z"
                            fill="#FF8447"
                          />
                        </svg>
                      )}
                      <span className="terms-paid__status">
                        {item.payment_completed ? "Partial" : "Pending"}
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow className="remind-fees__table-last">
                <TableCell className="remind-fees__table-last--row" colSpan={3}>
                  Total Fee amount
                </TableCell>
                <TableCell className="remind-fees__table-data">
                  {formatPriceWithExponential(
                    termFeeData.reduce((acc, curr) => acc + curr.term_fee, 0)
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <RemainderMethods />
      </Content>
      <Footer className={"request-fee__footer"}>
        <button
          className={`collect-fee__footer--btn ${
            isLoading ? "btn-loading" : ""
          }`}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </Footer>
    </Sheet>
  );
};

RemindFeesSheet.propTypes = {
  isSheetOpen: PropTypes.bool.isRequired,
  handlers: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default RemindFeesSheet;
