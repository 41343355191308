import React from "react";
import "./TermDropDown.css";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
import {
  useToggle,
  useSelectState,
  useOutsideClick,
} from "../../FeeStructureModule/hooks";

const TermDropDown = ({ items, termFilter }) => {
  const { state: isDropdownOpen, handlers } = useToggle();
  const dropdownRef = React.useRef(null);
  useOutsideClick(dropdownRef, handlers.off);
  const defaultText = termFilter === "annual" ? "" : items[0];
  const { value: selectedTerm, handleSelectChange } =
    useSelectState(defaultText);
  const location = useLocation();

  const handleUPIAppClick = (app) => {
    handleSelectChange(app);
    handlers.off();
  };

  const buildLinkWithQuery = (item) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("term", item);
    return `${location.pathname}?${searchParams.toString()}`;
  };

  return (
    <div>
      <div
        className={`term__dropdown ${
          termFilter === "annual" && "term__dropdown--disabled"
        }`}
        ref={dropdownRef}
      >
        <div className="term__dropdown--btn" onClick={handlers.toggle}>
          {termFilter === "annual" ? "Terms" : selectedTerm || items[0]}
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="term__dropdown--icon"
          >
            <path
              d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z"
              fill="#99A0AE"
            ></path>
          </svg>
        </div>
        {isDropdownOpen && (
          <div className="term__dropdown--menu">
            {items.map((item) => (
              <NavLink
                key={item}
                className="term__dropdown--item"
                onClick={() => handleUPIAppClick(item)}
                to={buildLinkWithQuery(item)}
              >
                {item}
                {selectedTerm === item && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    fill="none"
                  >
                    <path
                      d="M5.50005 7.37912L12.3941 0.484375L13.4553 1.54488L5.50005 9.50012L0.727051 4.72712L1.78755 3.66663L5.50005 7.37912Z"
                      fill="#335CFF"
                    />
                  </svg>
                )}
              </NavLink>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

TermDropDown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
};

export default TermDropDown;
