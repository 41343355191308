import React, { useState, useEffect, useContext } from "react";
// import { SlArrowDown } from "react-icons/sl";
import { Classsectiondetailsdropdown } from "../Components/Classsectiondetailsdropdown";
import DateSelector from "../Components/DateDropDown";
import { CustomDropdownWithCustomStyle } from "../Components/CustomDropdownWithCustomStyle";
// import { addstudentinfo } from "../../../api/api";
import UserContext from "../../../context/UserContext";
import { getStudentsDetailsStudentDirectory } from "../../../api/api";

export const StudentInfo = ({ findmandatorychecks }) => {
  // const { userInfo } = React.useContext(UserContext);
  // useEffect(() => {
  //   if (userInfo.branch_id !== null && userInfo.branch_id !== undefined) {
  //     editsetdata({ ...editdata, branch_id: userInfo.branch_id });
  //   }
  // }, [userInfo.branch_id]);

  // const handlesavechanges = async () => {
  //   console.log(editdata);
  //   const res = await addstudentinfo(editdata);
  //   progressstate(1);
  // };
  const [siblingsdata,setsiblingsdata]=useState([]);
  const [editdata, setEditdata] = useState({
    first_name: "",
    last_name: "",
    Class_Section: "",
    pen_number: "",
    admission_date: "",
    enrolment_status: "Active",
    sibling_name: "",
    school_name: "",
    last_passed_class: "",
    last_grade_marks: "",
    school_address: "",
  });

  const handledatachange = (field, value) => {
    console.log(field, value);

    if (field==="pen_number") {
      if(/^\d*\.?\d*$/.test(value)){}
        else return;
      
    }
    if (field === "class_section_mapping_id") {
      const d = sessionStorage.getItem("Class_Section");
      //eslint-disable-next-line
      const updatedData = { ...editdata, ["class_section"]: d, [field]: value };
      console.log(updatedData);
      setEditdata(updatedData);
      sessionStorage.setItem("studentInfo", JSON.stringify(updatedData));
    } else {
      const updatedData = { ...editdata, [field]: value };
      setEditdata(updatedData);
      sessionStorage.setItem("studentInfo", JSON.stringify(updatedData));
    }
    findmandatorychecks();
    // console.log(await findmandatorychecks())
  };
  const { userInfo } = useContext(UserContext);
  useEffect(() => {
    const savedData = sessionStorage.getItem("studentInfo");
    if (savedData) {
      setEditdata(JSON.parse(savedData));
    }
    const getdata=async()=>{
      const data = await getStudentsDetailsStudentDirectory(userInfo.branch_id);
      let temp=[];
    
      for(let i=0;i<data.students.length;i++){
        temp.push(data.students[i].full_name)
      }
      // console.log(temp)
      setsiblingsdata(temp);
      // console.log(data.students);
    }
    if(userInfo.branch_id)
    getdata();
  }, [userInfo.branch_id]);
  return (
    <div>
      <div>
        <div className="detailsHeader">StudentInfo</div>
        <div className="detailsHeaderspan">Enter the details to proceed </div>
      </div>
      <div
        className="StudentInfoEditSilderdivbody"
        style={{ paddingBottom: "10rem" }}
      >
        <div style={{ display: "flex", gap: "1rem" }}>
          <div className="Details">
            <div style={{ display: "flex" }}>
              First Name
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </div>
            <input
              type="text"
              value={editdata.first_name}
              className="inputbox"
              placeholder="Chaitanya"
              onChange={(event) => {
                handledatachange("first_name", event.target.value);
              }}
            />
          </div>
          <div className="Details">
            <div style={{ display: "flex" }}>
              Last Name{" "}
              <span style={{ color: "rgb(153, 160, 174)" }}>(Sur Name)</span>
            </div>
            <input
              type="text"
              value={editdata.last_name}
              className="inputbox"
              placeholder="Rao"
              onChange={(event) => {
                handledatachange("last_name", event.target.value);
              }}
            />
          </div>
        </div>
        <div className="Details">
          <label style={{ display: "flex" }}>
            Select Class to assign{" "}
            {/* <div
              className="mandatory"
              style={{ color: "rgba(251, 55, 72, 1)" }}
            >
              *
            </div> */}
          </label>
          <Classsectiondetailsdropdown
            defaultText={
              (editdata.class_section === undefined || editdata.class_section===null ||editdata.class_section === "")
               
                  ? "Select a Class-Section"
                  : editdata.class_section.slice(
                      1,
                      editdata.class_section.length - 1
                    )
              
            }
            handledatachange={handledatachange}
            // field={}
            width="28.5rem"
          />
        </div>
        {/* <div className="Details">
          <label style={{ display: "flex" }}>
            Roll Number
            <div
              className="mandatory"
              style={{ color: "rgba(251, 55, 72, 1)" }}
            >
              *
            </div>
          </label>
          <input
            type="text"
            className="inputbox"
            style={{ width: "28.5rem" }}
            placeholder="pls dont enter not present in table"
          />
        </div> */}
        <div className="Details">
          <label style={{ display: "flex" }}>Pen Number</label>
          <input
            type="text"
            className="inputbox"
            value={editdata.pen_number}
            style={{ width: "28.5rem" }}
            placeholder="123456789"
            onChange={(event) => {
              handledatachange("pen_number", event.target.value);
            }}
          />
        </div>
        <div className="Details">
          <label style={{ display: "flex" }}>
            Admission Date
            <div
              className="mandatory"
              style={{ color: "rgba(251, 55, 72, 1)" }}
            >
              *
            </div>
          </label>
          <DateSelector
            handledatachange={handledatachange}
            field={"admission_date"}
            width={"28.5rem"}
            value={editdata.admission_date}
          />
        </div>
        <div className="Details">
          <label style={{ display: "flex" }}>
            Enrollement Status
            {/* <div
              className="mandatory"
              style={{ color: "rgba(251, 55, 72, 1)" }}
            >
              *
            </div> */}
          </label>
          <CustomDropdownWithCustomStyle
            defaultText={
              editdata.enrolment_status === ""
                ? "Select enrollment Status"
                : editdata.enrolment_status
            }
            // icon={<FilterIcon />}
            handledatachange={handledatachange}
            items={["Active", "Not Active"]}
            width={"28.5rem"}
            dropdownName={"enrolment_status"}
          />
        </div>
        <div className="Details">
          <label style={{ display: "flex" }}>
            Siblings
            <div style={{ color: "rgba(153, 160, 174, 1)" }}>(Optional)</div>
            <div
              className="mandatory"
              style={{ color: "rgba(51, 92, 255, 1)" }}
            >
              *
            </div>
          </label>
          <div className="select-classes-container">
            
          <CustomDropdownWithCustomStyle
            defaultText={
              editdata.sibling_name  === ""
                ? "Select Sibling Name"
                : editdata.sibling_name

            }
            // icon={<FilterIcon />}
            handledatachange={handledatachange}
            items={siblingsdata}
            width={"28.5rem"}
            dropdownName={"sibling_name"}
            scrollenable={true}
            search={true}
          />
          </div>
        </div>
        <div className="relationspanitem">
          <div className="line" style={{ width: "7rem" }}></div>
          <div className="relationspan">PREVIOUS SCHOOL DETAILS</div>
          <div className="line"></div>
        </div>
        <div style={{ display: "flex", gap: "1rem" }}>
          <div className="Details">
            <div style={{ display: "flex" }}>
              School Name{" "}
              {/* <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div> */}
            </div>
            <input
              type="text"
              value={editdata.school_name}
              className="inputbox"
              placeholder="Chaitanya"
              onChange={(event) => {
                handledatachange("school_name", event.target.value);
              }}
            />
          </div>
          <div className="Details">
            <div style={{ display: "flex" }}>
              Last Passed Class{" "}
             
            </div>
            {/* <input
              type="text"
              value={editdata.last_passed_class}
              className="inputbox"
              placeholder="Chaitanya"
              onChange={(event) => {
                handledatachange("last_passed_class", event.target.value);
              }}
            /> */}
            <CustomDropdownWithCustomStyle
                defaultText={
                  editdata.last_passed_class === "" ? "Select Class" : editdata.last_passed_class
                }
                handledatachange={handledatachange}
                items={[
                  "Nursery",
                  "LKG",
                  "UKG",
                  "Class 1",
                  "Class 2",
                  "Class 3",
                  "Class 4",
                  "Class 5",
                  "Class 6",
                  "Class 7",
                  "Class 8",
                  "Class 9",
                  "Class 10"
                ]}
                width={"12.9rem"}
                dropdownName={"last_passed_class"}
                height="1.1rem"
                scrollenable={true}
              />
          </div>
          
        </div>
        <div style={{ display: "flex", gap: "1rem" }}>
        <div className="Details">
            <div style={{ display: "flex" }}>Address</div>
            <input
              type="text"
              value={editdata.school_address}
              className="inputbox"
              placeholder="Rao"
              onChange={(event) => {
                handledatachange("school_address", event.target.value);
              }}
            />
          </div>
          <div className="Details">
            <div style={{ display: "flex" }}>Percentage/Grade</div>
            <input
              type="text"
              value={editdata.last_grade_marks}
              className="inputbox"
              placeholder="Rao"
              onChange={(event) => {
                handledatachange("last_grade_marks", event.target.value);
              }}
            />
          </div>
        </div>
        {/* <button
          className="continue-button"
          style={{ marginLeft: "140px" }}
          onClick={(e) => {
            handlesavechanges();
            e.preventDefault();
          }}
        >
          Save and Continue
          <div className="righticon" style={{ marginLeft: "6px" }}>
            <SlArrowRight size={9} strokeWidth={70} />
          </div>
        </button> */}
      </div>
    </div>
  );
};
