import React, { useEffect, useState } from "react";
// import { SlArrowDown, SlArrowRight } from "react-icons/sl";
import { CustomDropdownWithCustomStyle } from "../Components/CustomDropdownWithCustomStyle";

export const GuardianInfo = ({ findmandatorychecks }) => {
  const [editdata, setEditdata] = useState({
    father_communication: "",
    mother_communication: "",
    guardian_communication: "",
    father_occupation: "",
    mother_occupation: "",
    guardian_occupation: "",
    father_income: "",
    mother_income: "",
    guardian_income: "",
    father: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      mark_as_emergency_contact: false,
      communication_preferences: "",
      mark_as_guardian: false,
    },
    mother: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      communication_preferences: "",
      mark_as_emergency_contact: false,
      mark_as_guardian: false,
    },
    guardian: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      mark_as_emergency_contact: false,
      communication_preferences: "",
      relationship: "",
      mark_as_guardian: false,
    },
  });
  useEffect(() => {
    const func = async () => {
      await findmandatorychecks();
    };
    func();
    //eslint-disable-next-line
  }, [editdata]);

  const handledatachange = (relation, field, value) => {
    console.log(relation, field);
    if (field==="phone_number" || relation.endsWith("income")) {
      if(relation.endsWith("income")){
        if(!(/^\d*\.?\d*$/.test(field)))
        return
      }
      else if(/^\d*\.?\d*$/.test(value)){}
        else return;
      
    }
    if (
      relation.endsWith("communication") ||
      relation.endsWith("occupation") ||
      relation.endsWith("income")
    ) {
      console.log("relationss");
      setEditdata((prevData) => {
        const updatedData = {
          ...prevData,
          [relation]: field,
        };
        sessionStorage.setItem("studentInfo", JSON.stringify(updatedData));
        findmandatorychecks(updatedData);
        return updatedData;
      });
      return;
    }
    console.log(relation, field, value);
    if (relation === "relation") {
      setEditdata((prevData) => {
        const updatedData = {
          ...prevData,
          //eslint-disable-next-line
          ["guardian"]: {
            ...prevData["guardian"],
            //eslint-disable-next-line
            ["relationship"]: field,
          },
        };
        findmandatorychecks(updatedData);
        sessionStorage.setItem("studentInfo", JSON.stringify(updatedData));
        return updatedData;
      });
      return;
    }
    if (field === "mark_as_emergency_contact" || field === "mark_as_guardian") {
      console.log("dfdfdfd");
      let isProcessing = false;
      setEditdata((prevData) => {
        if (isProcessing) return prevData; // Skip if already processing

        isProcessing = true;
        const contactTypes = ["father", "mother", "guardian"];
        const newData = { ...prevData };
        const markAs = !newData[relation][[field]];
        console.log(markAs);
        // Reset all mark_as_emergency_contact and mark_as_guardian flags to false
        if (markAs) {
          contactTypes.forEach((type) => {
            if (type !== relation) {
              newData[type][[field]] = false;
            }
          });
        }

        // Update the selected contact's status
        newData[relation][field] = markAs;

        findmandatorychecks(newData);
        sessionStorage.setItem("studentInfo", JSON.stringify(newData));
        return newData;
      });
    } else {
      console.log(value);
      setEditdata((prevData) => {
        const updatedData = {
          ...prevData,
          [relation]: { ...prevData[relation], [field]: value },
        };
        findmandatorychecks(updatedData);
        sessionStorage.setItem("studentInfo", JSON.stringify(updatedData));
        return updatedData;
      });
    }
    findmandatorychecks();
  };

  useEffect(() => {
    const savedData = sessionStorage.getItem("studentInfo");
    const savedData1 = JSON.parse(savedData);
    if (savedData1.father !== undefined) {
      setEditdata(JSON.parse(savedData));
    } else {
      console.log("filling");
      setEditdata({ ...JSON.parse(savedData), ...editdata });
    }
    // console.log({ ...JSON.parse(savedData), ...editdata });
    //eslint-disable-next-line
  }, []);
  return (
    <div>
      <div className="detailsHeader">Guardian Info</div>
      <div className="detailsHeaderspan">Enter the details to proceed </div>
      <div className="relationspanitem">
        <div className="line"></div>
        <div className="relationspan">FATHER'S DETAILS</div>
        <div className="line"></div>
      </div>
      <div className="StudentInfoEditSilderdivbody">
        <div style={{ display: "flex", gap: "1rem" }}>
          <div className="Details">
            <div style={{ display: "flex" }}>
              First Name
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </div>
            <input
              type="text"
              className="inputbox"
              placeholder="Chaitanya"
              value={editdata.father.first_name}
              onChange={(event) => {
                handledatachange("father", "first_name", event.target.value);
              }}
            />
          </div>
          <div className="Details">
            <div style={{ display: "flex" }}>
              Last Name
              {/* <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div> */}
            </div>
            <input
              type="text"
              value={editdata.father.last_name}
              onChange={(event) => {
                handledatachange("father", "last_name", event.target.value);
              }}
              className="inputbox"
              placeholder="Rao"
            />
          </div>
        </div>

        <div style={{ display: "flex", gap: "1rem" }}>
          <div className="Details">
            <div style={{ display: "flex" }}>
              Phone Number
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </div>
            <input
              type="text"
              value={editdata.father.phone_number}
              onChange={(event) => {
                handledatachange("father", "phone_number", event.target.value);
              }}
              className="inputbox"
              placeholder="Chaitanya"
            />
          </div>
          <div className="Details">
            <div style={{ display: "flex" }}>
              Email ID
              {/* <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div> */}
            </div>
            <input
              type="text"
              className="inputbox"
              value={editdata.father.email}
              onChange={(event) => {
                handledatachange("father", "email", event.target.value);
              }}
              placeholder="Rao@grahith.com"
            />
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "0.7rem" }}>
          <input
            type="checkbox"
            checked={editdata.father.mark_as_emergency_contact}
            onChange={(event) => {
              handledatachange(
                "father",
                "mark_as_emergency_contact",
                event.target.value
              );
            }}
            name=""
            id=""
          />
          Mark as Emergency Contact
        </div>
        <div style={{ display: "flex", gap: "1rem" }}>
          <div className="Details">
            <div style={{ display: "flex" }}>
              Occupation
              {/* <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div> */}
            </div>
            <input
              type="text"
              value={editdata.father_occupation}
              onChange={(event) => {
                handledatachange("father_occupation", event.target.value);
                console.log(event.target.value);
              }}
              className="inputbox"
              placeholder="Chaitanya"
            />
          </div>
          <div className="Details">
            <div style={{ display: "flex" }}>
              Annual Income
              {/* <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div> */}
            </div>
            <input
              type="text"
              className="inputbox"
              value={editdata.father_income}
              onChange={(event) => {
                handledatachange(
                  "father_income",

                  event.target.value
                );
              }}
              placeholder="1234"
            />
          </div>
        </div>
        <div className="Details">
          <label style={{ display: "flex" }}>
            Communication Preferences
            {/* <div
              className="mandatory"
              style={{ color: "rgba(251, 55, 72, 1)" }}
            >
              *
            </div> */}
          </label>
          <div className="select-classes-container">
            <CustomDropdownWithCustomStyle
              defaultText={
                editdata.father_communication === ""
                  ? "Communication Preferences"
                  : editdata.father_communication
              }
              handledatachange={handledatachange}
              items={["Phone", "WhatsApp", "Email"]}
              width={"28.5rem"}
              dropdownName={"father_communication"}
              height="1.1rem"
            />
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "0.7rem" }}>
          <input
            type="checkbox"
            name=""
            checked={editdata.father.mark_as_guardian}
            onChange={(event) => {
              handledatachange(
                "father",
                "mark_as_guardian",
                event.target.value
              );
            }}
            id=""
          />
          Mark as Guardian
        </div>
      </div>
      <div className="relationspanitem">
        <div className="line"></div>
        <div className="relationspan">MOTHER'S DETAILS</div>
        <div className="line"></div>
      </div>
      <div
        className="StudentInfoEditSilderdivbody"
        // style={{ paddingBottom: "5rem" }}
      >
        <div style={{ display: "flex", gap: "1rem" }}>
          <div className="Details">
            <div style={{ display: "flex" }}>
              First Name
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </div>
            <input
              type="text"
              className="inputbox"
              value={editdata.mother.first_name}
              onChange={(event) => {
                handledatachange("mother", "first_name", event.target.value);
              }}
              placeholder="Chaitanya"
            />
          </div>
          <div className="Details">
            <div style={{ display: "flex" }}>
              Last Name
              {/* <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div> */}
            </div>
            <input
              type="text"
              className="inputbox"
              value={editdata.mother.last_name}
              onChange={(event) => {
                handledatachange("mother", "last_name", event.target.value);
              }}
              placeholder="Rao"
            />
          </div>
        </div>

        <div style={{ display: "flex", gap: "1rem" }}>
          <div className="Details">
            <div style={{ display: "flex" }}>
              Phone Number
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </div>
            <input
              type="text"
              className="inputbox"
              value={editdata.mother.phone_number}
              onChange={(event) => {
                handledatachange("mother", "phone_number", event.target.value);
              }}
              placeholder="Chaitanya"
            />
          </div>
          <div className="Details">
            <div style={{ display: "flex" }}>
              Email ID
              {/* <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div> */}
            </div>
            <input
              type="text"
              className="inputbox"
              placeholder="Rao@grahith.com"
              value={editdata.mother.email}
              onChange={(event) => {
                handledatachange("mother", "email", event.target.value);
              }}
            />
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "0.7rem" }}>
          <input
            type="checkbox"
            name=""
            id=""
            checked={editdata.mother.mark_as_emergency_contact}
            onChange={(event) => {
              handledatachange(
                "mother",
                "mark_as_emergency_contact",
                event.target.value
              );
            }}
          />
          Mark as Emergency Contact
        </div>
        <div style={{ display: "flex", gap: "1rem" }}>
          <div className="Details">
            <div style={{ display: "flex" }}>
              Occupation
              {/* <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div> */}
            </div>
            <input
              type="text"
              value={editdata.mother_occupation}
              onChange={(event) => {
                handledatachange(
                  "mother_occupation",

                  event.target.value
                );
              }}
              className="inputbox"
              placeholder="Chaitanya"
            />
          </div>
          <div className="Details">
            <div style={{ display: "flex" }}>
              Annual Income
              {/* <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div> */}
            </div>
            <input
              type="text"
              className="inputbox"
              value={editdata.mother_income}
              onChange={(event) => {
                handledatachange(
                  "mother_income",

                  event.target.value
                );
              }}
              placeholder="1234"
            />
          </div>
        </div>
        <div className="Details">
          <label style={{ display: "flex" }}>Communication Preferences</label>
          <CustomDropdownWithCustomStyle
            defaultText={
              editdata.mother_communication === ""
                ? "Communication Preferences"
                : editdata.mother_communication
            }
            handledatachange={handledatachange}
            items={["Phone", "WhatsApp", "Email"]}
            width={"28.5rem"}
            dropdownName={"mother_communication"}
            height="1.1rem"
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "0.7rem" }}>
          <input
            type="checkbox"
            name=""
            checked={editdata.mother.mark_as_guardian}
            onChange={(event) => {
              handledatachange(
                "mother",
                "mark_as_guardian",
                event.target.value
              );
            }}
            id=""
          />
          Mark as Guardian
        </div>
      </div>
      <div className="relationspanitem">
        <div className="line"></div>
        <div className="relationspan">GUARDIAN DETAILS</div>
        <div className="line"></div>
      </div>
      <div
        className="StudentInfoEditSilderdivbody"
        style={{
          paddingBottom: "5rem",
          filter:
            editdata.mother.mark_as_guardian || editdata.father.mark_as_guardian
              ? "blur(5px)"
              : "",
          pointerEvents:
            editdata.mother.mark_as_guardian || editdata.father.mark_as_guardian
              ? "none"
              : "",
        }}
      >
        <div style={{ display: "flex", gap: "1rem" }}>
          <div className="Details">
            <div style={{ display: "flex" }}>
              First Name
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </div>
            <input
              type="text"
              className="inputbox"
              value={editdata.guardian.first_name}
              onChange={(event) => {
                handledatachange("guardian", "first_name", event.target.value);
              }}
              placeholder="Chaitanya"
            />
          </div>
          <div className="Details">
            <div style={{ display: "flex" }}>
              Last Name
              {/* <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div> */}
            </div>
            <input
              type="text"
              value={editdata.guardian.last_name}
              onChange={(event) => {
                handledatachange("guardian", "last_name", event.target.value);
              }}
              className="inputbox"
              placeholder="Rao"
            />
          </div>
        </div>
        <div className="Details">
          <label style={{ display: "flex" }}>
            Relation
            <div
              className="mandatory"
              style={{ color: "rgba(251, 55, 72, 1)" }}
            >
              *
            </div>
          </label>
          <div className="select-classes-container">
            <CustomDropdownWithCustomStyle
              defaultText={
                editdata.guardian.relationship === ""
                  ? "Relation"
                  : editdata.guardian.relationship
              }
              handledatachange={handledatachange}
              items={[
                "Husband",
                "Wife",
                "Father",
                "Mother",
                "Brother",
                "Sister",
                "Brother in law",
                "Sister in law",
                "Family Friend",
              ]}
              width={"28.5rem"}
              dropdownName={"relation"}
              height="1.1rem"
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: "1rem" }}>
          <div className="Details">
            <div style={{ display: "flex" }}>
              Phone Number
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </div>
            <input
              type="text"
              value={editdata.guardian.phone_number}
              onChange={(event) => {
                handledatachange(
                  "guardian",
                  "phone_number",
                  event.target.value
                );
              }}
              className="inputbox"
              placeholder="Chaitanya"
            />
          </div>
          <div className="Details">
            <div style={{ display: "flex" }}>
              Email ID
              {/* <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div> */}
            </div>
            <input
              type="text"
              className="inputbox"
              placeholder="Rao@grahith.com"
              value={editdata.guardian.email}
              onChange={(event) => {
                handledatachange("guardian", "email", event.target.value);
              }}
            />
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "0.7rem" }}>
          <input
            type="checkbox"
            name=""
            checked={editdata.guardian.mark_as_emergency_contact}
            onChange={(event) => {
              handledatachange(
                "guardian",
                "mark_as_emergency_contact",
                event.target.value
              );
            }}
            id=""
          />
          Mark as Emergency Contact
        </div>
        <div style={{ display: "flex", gap: "1rem" }}>
          <div className="Details">
            <div style={{ display: "flex" }}>
              Occupation
              {/* <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div> */}
            </div>
            <input
              type="text"
              value={editdata.guardian_occupation}
              onChange={(event) => {
                handledatachange(
                  "guardian_occupation",

                  event.target.value
                );
              }}
              className="inputbox"
              placeholder="Chaitanya"
            />
          </div>
          <div className="Details">
            <div style={{ display: "flex" }}>
              Annual Income
              {/* <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div> */}
            </div>
            <input
              type="text"
              className="inputbox"
              value={editdata.guardian_income}
              onChange={(event) => {
                handledatachange(
                  "guardian_income",

                  event.target.value
                );
              }}
              placeholder="1234"
            />
          </div>
        </div>
        <div className="Details">
          <label style={{ display: "flex" }}>Communication Preferences</label>

          <CustomDropdownWithCustomStyle
            defaultText={
              editdata.guardian_communication === ""
                ? "Communication Preferences"
                : editdata.guardian_communication
            }
            handledatachange={handledatachange}
            items={["Phone", "WhatsApp", "Email"]}
            width={"28.5rem"}
            dropdownName={"guardian_communication"}
            height="1.1rem"
          />
        </div>

        <div style={{ display: "flex", alignItems: "center", gap: "0.7rem" }}>
          <input
            type="checkbox"
            name=""
            checked={editdata.guardian.mark_as_guardian}
            onChange={(event) => {
              handledatachange(
                "guardian",
                "mark_as_guardian",
                event.target.value
              );
            }}
            id=""
          />
          Mark as Guardian
        </div>
      </div>
    </div>
  );
};
