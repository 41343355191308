import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./CheckBoxDropDown.css";
// import { useToggle, useOutsideClick } from "../FeeStructureModule/hooks";
import UserContext from "../../../context/UserContext";
// import  { getClassesBasedOnBranchId } from "../../../api/api";
import {
  useOutsideClick,
  useToggle,
} from "../../../modules/FeeManagementModule/FeeStructureModule/hooks";

export const CheckBoxDropDown = ({
  Items = {
    "Profile Status": ["Completed Profiles", "InCompleted Profiles"],
    "Class Status": ["Classes assigned", "Classes not assigned"],
  },
  righticon,
  lefticon,
  dropdownname,
  handledatachange = () => {},
}) => {
  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [list, setList] = useState([]);
  const [dropdownItems, setdropdownItems] = useState({});
  //   const [mappingIds, setMappingIds] = useState([]);
  const dropdownRef = useRef(null);
  const { state: isOpen, handlers } = useToggle();
  useOutsideClick(dropdownRef, handlers.off);

  const { userInfo } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo.branch_id !== undefined && userInfo.branch_id !== null) {
      //   getClassesBasedOnBranchId(userInfo.branch_id).then((res) => {
      //     const sections = res.response.sections;
      //     const grades = sections.reduce((acc, { class: className, section }) => {
      //       if (!acc[className]) {
      //         acc[className] = [];
      //       }
      //       acc[className].push(`${className}-${section}`);
      //       return acc;
      //     }, {});

      //     const ids = sections.map(
      //       ({ class_section_mapping_id }) => class_section_mapping_id
      //     );
      setdropdownItems(Items);
      // setMappingIds(ids);
      setCheckedList(
        Object.keys(Items).flatMap((item) => [item, ...Items[item]])
      );
      console.log("intial gradesss", dropdownItems);
      // console.log("mappings", mappingIds);

      const url = new URL(window.location.href);
      url.searchParams.set("grades", "all");
      navigate(url.pathname + url.search);
    }
    //eslint-disable-next-line
  }, [userInfo.branch_id, navigate]);

  const allOptions = useMemo(
    () => [
      "Select all",
      ...Object.keys(dropdownItems).flatMap((grade) => [
        grade,
        ...dropdownItems[grade],
      ]),
    ],
    [dropdownItems]
  );

  useEffect(() => {
    const filteredCheckedList = checkedList.filter(
      (item) => !["Select all", ...Object.keys(dropdownItems)].includes(item)
    );
    setList(filteredCheckedList);
  }, [checkedList, dropdownItems]);

  useEffect(() => {
    if (checkedList.length === allOptions.length - 1) {
      setCheckAll(true);
      const url = new URL(window.location.href);
      url.searchParams.set("grades", "all");
      navigate(url.pathname + url.search);
    } else {
      setCheckAll(false);
    }
  }, [checkedList, allOptions.length, navigate]);

  //   const updateURLWithMappingIds = (updatedCheckedList) => {
  //     const filteredSections = allOptions.filter((option) =>
  //       option.includes("-")
  //     );

  //     const selectedIds = mappingIds.filter((_, idx) => {
  //       const isIncluded = updatedCheckedList.includes(filteredSections[idx]);
  //       return isIncluded;
  //     });

  //     const url = new URL(window.location.href);

  //     if (updatedCheckedList.length === 0) {
  //       url.searchParams.delete("grades");
  //     } else if (updatedCheckedList.length === allOptions.length - 1) {
  //       url.searchParams.set("grades", "all");
  //     } else {
  //       url.searchParams.set("grades", selectedIds.join(","));
  //     }

  //     navigate(url.pathname + url.search);
  //   };

  const onChange = (option, subOption) => {
   
    setCheckedList((prevCheckedList) => {
      let updatedCheckedList = [...prevCheckedList];

      if (subOption) {
        if (updatedCheckedList.includes(subOption)) {
          updatedCheckedList = updatedCheckedList.filter(
            (item) => item !== subOption
          );
        } else {
          updatedCheckedList.push(subOption);
        }

        const parentGrade = option;
        const allSubGradesChecked = dropdownItems[parentGrade].every(
          (subGrade) => updatedCheckedList.includes(subGrade)
        );
        if (allSubGradesChecked && !updatedCheckedList.includes(parentGrade)) {
          updatedCheckedList.push(parentGrade);
        } else if (
          !allSubGradesChecked &&
          updatedCheckedList.includes(parentGrade)
        ) {
          updatedCheckedList = updatedCheckedList.filter(
            (item) => item !== parentGrade
          );
        }
      } else {
        const subGrades = dropdownItems[option];
        const allSubGradesChecked = subGrades.every((subGrade) =>
          updatedCheckedList.includes(subGrade)
        );

        if (allSubGradesChecked) {
          updatedCheckedList = updatedCheckedList.filter(
            (item) => !subGrades.includes(item)
          );
          updatedCheckedList = updatedCheckedList.filter(
            (item) => item !== option
          );
        } else {
          updatedCheckedList = [
            ...updatedCheckedList,
            option,
            ...subGrades.filter(
              (subGrade) => !updatedCheckedList.includes(subGrade)
            ),
          ];
        }
      }

      const allSelected = Object.keys(dropdownItems).every((grade) =>
        [grade, ...dropdownItems[grade]].every((item) =>
          updatedCheckedList.includes(item)
        )
      );
      setCheckAll(allSelected);
  
       handledatachange("filter", updatedCheckedList);
      //   updateURLWithMappingIds(updatedCheckedList);
      return updatedCheckedList;
    });
  };

  const onCheckAllChange = () => {
    if (checkAll) {
      setCheckedList([]);
      setCheckAll(false);
      const url = new URL(window.location.href);
      url.searchParams.delete("grades");
      navigate(url.pathname + url.search);
    } else {
      setCheckedList(allOptions.slice(1));
      setCheckAll(true);
      const url = new URL(window.location.href);
      url.searchParams.set("grades", "all");
      navigate(url.pathname + url.search);
    }
  };
  return (
    <div className="dropdown_container" ref={dropdownRef}>
      <button
        className="dropdown_dropdown-button"
        style={{ gap: "0.5rem" }}
        onClick={handlers.toggle}
      >
        {lefticon}
        <>
          {dropdownname}
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="custom__dropdown--icon"
          >
            <path
              d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z"
              fill="#99A0AE"
            ></path>
          </svg>
        </>
      </button>
      {isOpen && (
        <div className="dropdown_dropdown">
          <div className="dropdown_checkbox-group">
            <label className="dropdown_checkbox-label">
              <div>
                <input
                  type="checkbox"
                  checked={checkAll}
                  className="dropdown_table-checkbox"
                  onChange={onCheckAllChange}
                />
                <span className="dropdown_label-text">Select all</span>
              </div>
              {list.length !== 0 && (
                <span className="dropdown_checked-count">{list.length}</span>
              )}
            </label>

            {Object.entries(dropdownItems).map(([grade, subGrades]) => (
              <React.Fragment key={grade}>
                <label className="dropdown_checkbox-label">
                  <input
                    type="checkbox"
                    className="dropdown_table-checkbox dropdown_table-parent-checkbox"
                    checked={checkedList.includes(grade)}
                    onChange={() => onChange(grade)}
                  />
                  {grade}
                </label>
                {(checkedList.includes(grade) ||
                  subGrades.some((subGrade) =>
                    checkedList.includes(subGrade)
                  )) &&
                  subGrades.map((subGrade) => (
                    <label
                      className="dropdown_checkbox-child-label"
                      key={subGrade}
                    >
                      <input
                        type="checkbox"
                        className="dropdown_table-checkbox"
                        checked={checkedList.includes(subGrade)}
                        onChange={() => onChange(grade, subGrade)}
                      />
                      <span>{subGrade}</span>
                    </label>
                  ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
