import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
import { SearchBar } from "./SearchBar";
import { useOutsideClick, useToggle } from "../../FeeManagementModule/FeeStructureModule/hooks";

export const CustomDropdownWithCustomStyle = ({
  defaultText = "Select an Item",
  items,
  icon,
  width,
  dropdownName,
  padding,
  height = '',
  dropdownwidth = "",
  scrollenable = false,
  handledatachange = () => {},
  search = false,
}) => {
  const { state: isDropdownOpen, handlers } = useToggle();
  const dropdownRef = useRef(null);
  const [selectedTerm, setSelectedTerm] = useState(defaultText);
  const [filteredItems, setFilteredItems] = useState(items);

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  useEffect(() => {
    console.log("Filtered items updated:", filteredItems);
  }, [filteredItems]);

  useOutsideClick(dropdownRef, handlers.off);
  const location = useLocation();

  useEffect(() => {
    setSelectedTerm(defaultText);
  }, [defaultText]);

  const handleSelectedItem = (item) => {
    handlers.toggle();
    if (selectedTerm === item) {
      setSelectedTerm(defaultText);
    } else {
      setSelectedTerm(item);
    }
    if (dropdownName === "Class-Section") {
      sessionStorage.setItem("Class_Section", JSON.stringify(item));
    }
    handledatachange(dropdownName, item);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("term", item === "Term-wise" ? "term" : "annual");
  };

  const buildLinkWithQuery = (index) => {
    if (defaultText.includes("Class")) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("index", index);
      return `${location.pathname}?${searchParams.toString()}`;
    }
  };

  const handleSearchData = (itemss) => {
    setFilteredItems(itemss);
  };

  return (
    <div>
      <div className="custom__dropdown" ref={dropdownRef}>
        <div
          className="custom__dropdown--btn"
          style={{ width, padding, height }}
          onClick={handlers.toggle}
        >
          {icon}
          {selectedTerm}
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="custom__dropdown--icon"
          >
            <path
              d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z"
              fill="#99A0AE"
            ></path>
          </svg>
        </div>
        {isDropdownOpen && (
          <div
            className="custom__dropdown--menu"
            style={scrollenable ? { maxHeight: "10rem", overflow: "auto", width: dropdownwidth } : {}}
          >
            {search && (
              <div style={{ padding: '1rem' }}>
                <SearchBar data={items} width={dropdownwidth} handlesearchdata={handleSearchData} />
              </div>
            )}
            {filteredItems.length > 0 && filteredItems.map((item, index) => (
              <NavLink
                key={index} 
                className="custom__dropdown--item"
                onClick={() => handleSelectedItem(item)}
                to={buildLinkWithQuery(index)}
              >
                {item}
                {selectedTerm === item && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    fill="none"
                  >
                    <path
                      d="M5.50005 7.37912L12.3941 0.484375L13.4553 1.54488L5.50005 9.50012L0.727051 4.72712L1.78755 3.66663L5.50005 7.37912Z"
                      fill="#335CFF"
                    />
                  </svg>
                )}
              </NavLink>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

CustomDropdownWithCustomStyle.propTypes = {
  defaultText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.element,
  width: PropTypes.string,
  dropdownName: PropTypes.string,
  padding: PropTypes.string,
  height: PropTypes.string,
  dropdownwidth: PropTypes.string,
  scrollenable: PropTypes.bool,
  handledatachange: PropTypes.func,
  search: PropTypes.bool,
};
