import PropTypes from "prop-types";
import "./FeeHeader.css";
import { NavLink, useLocation } from "react-router-dom";
import React from "react";
import CustomDropdown from "./Custom-Dropdown";
import { FilterIcon } from "../../../assets/svgs";
import { useSelectState } from "../FeeStructureModule/hooks";

const FeeHeader = ({ text, subText, showButton, button1, button2 }) => {
  const location = useLocation();
  const requestFullScreen = () => {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };
  const queryParams = new URLSearchParams(location.search);
  const term = queryParams.get("term");

  const { value: selectedTerm } = useSelectState(
    term === "annual" ? "Annual-wise" : "Term-wise"
  );

  return (
    <div className="feeHeader">
      <div className="feeHeader__info">
        <p className="feeHeader__title">{text}</p>
        <p className="feeHeader__subtitle">{subText}</p>
      </div>
      <div className="feeHeader__buttons">
        {button1}
        {button2}
      </div>
      {location.pathname.includes("/fee") && (
        <>
          {showButton ? (
            <button
              className="feeHeader__button"
              onClick={() => {
                requestFullScreen();
                sessionStorage.clear();
              }}
            >
              <NavLink to="/create/fee" className={"feeHeader__link"}>
                + Create Fee Structure
              </NavLink>
            </button>
          ) : (
            <CustomDropdown
              defaultText={selectedTerm}
              icon={<FilterIcon />}
              items={["Annual-wise", "Term-wise"]}
              width={"fit-content"}
              dropdownName={"term"}
            />
          )}
        </>
      )}
    </div>
  );
};

FeeHeader.propTypes = {
  text: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
};

export default FeeHeader;
