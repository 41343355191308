import PropTypes from "prop-types";
import { formatPrice } from "../../FeeStructureModule/hooks";

const AmountPaid = ({ AmountPaid, Term }) => {
  return (
    <div className={`student-class`}>
      <span className="student-class__class">
        {" "}
        {formatPrice(Number(AmountPaid))}
      </span>
      <span className="student-class__programme">Term {Term}</span>
    </div>
  );
};

AmountPaid.propTypes = {
  class_Name: PropTypes.string.isRequired,
};

export default AmountPaid;
