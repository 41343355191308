import React from "react";
import PropTypes from "prop-types";
import { RemindFees, RequestFees } from "../../../../assets/RequestRemindFees";
import "../../components/ActionsButton.css";
import { useSelectState } from "../../FeeStructureModule/hooks";

const RequestRemindFees = ({ isOpen, onClick, handlers, setMethod }) => {
  const actionsRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const { handleSelectChange: setMenuPosition } = useSelectState("");

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        onClick();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);

      const buttonRect = buttonRef.current.getBoundingClientRect();
      const menuRect = actionsRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (buttonRect.bottom + menuRect.height > viewportHeight) {
        setMenuPosition("request__menu--top");
      }
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClick, setMenuPosition]);

  const requestOnClick = () => {
    setMethod("request");
    handlers.toggle();
  };
  const remindOnClick = () => {
    setMethod("remind");
    handlers.toggle();
  };

  return (
    <>
      <button onClick={onClick} className="actions__button" ref={buttonRef}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
        </svg>
      </button>
      {isOpen && (
        <div className={`actions-menu`} ref={actionsRef}>
          <button
            className="fee-structure__actions-menu-item-btn"
            onClick={remindOnClick}
          >
            <RemindFees />
            Remind for Fees
          </button>
          <button
            className="fee-structure__actions-menu-item-btn"
            onClick={requestOnClick}
          >
            <RequestFees />
            Request for Fees
          </button>
        </div>
      )}
    </>
  );
};

RequestRemindFees.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  handlers: PropTypes.object.isRequired,
};

export default RequestRemindFees;
