import React, { useEffect, useState } from 'react';
import "./Preview.css";
import { CiDiscount1 } from "react-icons/ci";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import { GoArrowRight } from "react-icons/go";
import loading from "../../../../assets/loading.gif"
import { formatDate} from '../hooks';
import { getFeeStructure, getFeeShedule, getFeeComponent, getCategories2, 
  // getSectionsBasedOnclassID 
  getClasses} from '../../../../api/api';
import { useNavigate } from 'react-router-dom';
import UserContext from "../../../../context/UserContext";
const handleExitFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) { // Firefox
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) { // Chrome, Safari, and Opera
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { // IE/Edge
    document.msExitFullscreen();
  }
};

export const Previewandfinalise = ({ handleprogress,feedata,total,selectedclasssection}) => {
  const navigate=useNavigate()
  const [feeSheduledata, setfeesheduledata] = useState([]);
  const [feecomponentdata, setfeecomponentdata] = useState([]);
  const [feeStructuredata, setfeestructuredata] = useState(null);
  const [categoriesdata, setcategoriesdata] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [secdic, setSecDic] = useState({});

  // const getsectiondata = async (id) => {
  //   const data1 = await getSectionsBasedOnclassID(id);
  //   return data1;
  // };
  // const [classNames, setClassNames] = useState([]);
  const { userInfo } = React.useContext(UserContext);
  const fetchData = async () => {
    try { 
      const feeStructureResponse = await getFeeStructure(userInfo.branch_id);
      const feeComponentResponse = await getFeeComponent();
      const feeScheduleResponse = await getFeeShedule();
      const categoriesResponse = await getCategories2();
      const classesResponse = await getClasses(userInfo.branch_id);
      console.log(feeStructureResponse.classes,classesResponse);
      // const classNamesArray = new Set(feeStructureResponse.classes.map(item1 => {
      //   const matchingClass = classesResponse.find(item2 => item2.id === item1.class_id);
      //   return matchingClass ? matchingClass.class : null;
      // }).filter(className => className !== null)); 
      // setClassNames( Array.from(classNamesArray));
      // console.log(Array.from(classNamesArray));
      setfeestructuredata(feeStructureResponse);
      setfeecomponentdata(feeComponentResponse);
      setfeesheduledata(feeScheduleResponse);
      setcategoriesdata(categoriesResponse);

      // let tempSecDic = {};
      // console.log(feeStructureResponse.classes);
      // for (let item of feeStructureResponse.classes) {
      //   const sectionData = await getsectiondata(item.class_id);
      //   const sectionsMap = sectionData.reduce((acc, section) => {
      //     acc[section.section_id] = section;
      //     return acc;
      //   }, {});
      //   tempSecDic[item.class_id] = sectionsMap;
      // }
      // setSecDic(tempSecDic);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  },[]);

  if (isLoading) {
    return   <img className='LoadImage' src={loading} alt='LoadImage' style={{width:'50px',height:'50px',paddingTop:'140px'}} />

  }
  const chunkSize = 3; // Number of items per row

  // Function to chunk the array into arrays of specified size
  const chunkArray = (arr, size) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (_, index) =>
      arr.slice(index * size, index * size + size)
    );
  };

  // Chunk the classNames array
  const classChunks = chunkArray(selectedclasssection, chunkSize);
  return (
    <>
    <div className="container1" style={{ marginTop: '60px' }}>
      <div className="fee-structure1" style={{overflow:'hidden'}}>
        <div className="header">
          <h1>{feeStructuredata.name} <span>ID: {feeStructuredata.id}</span></h1>
          <div className="secondheader" style={{marginBottom:'10px'}}>
            <div className="classes-covered">
              <span>Classes Covered</span>
              <div className="classes">
              <div className="classes-container">
        {classChunks.map((chunk, rowIndex) => (
          <div key={rowIndex} className="class-row">
            {chunk.map((className, colIndex) => (
              <div key={colIndex} className="class-tag1">
                {className}
              </div>
            ))}
          </div>
        ))}
      </div>
              </div>
            </div>
            <div className="term-due-dates" style={{marginLeft:'100px'}}>
              <span style={{ marginLeft: '-38px' }}>Term Due Dates</span>
              <div style={{ display: 'flex' }}>
                {feeSheduledata.map((item, index) => (
             
                  < > {/* Make sure to provide a unique key for each element */}
                    Term{item["term"]}<span > •{formatDate(item["due_date"])} {index<feeSheduledata.length-1 ?<span style={{marginTop:'-10px'}}><GoArrowRight size={28}/></span>:<></>}
                    </span>
                  </>
             
              ))}
              </div>
            </div>
          </div>
        </div>
        <div className="fee-components" >
              <h3>₹{total} <span>Total Fee amount</span></h3>
          <h2>Fee Components</h2>
          
          <div className="wholegrid1">
            <div className="grid-header" style={{ gridTemplateColumns: 'repeat(6, 1fr)', width: "846px" }}>
              <div className="grid-item1"  style={{ width: "386px" }}>Name of Fee Category</div>
              <div className="grid-item2">Fee Amount</div>
              <div className="grid-item3" style={{ marginLeft: '15px' }}>Tax</div>
              <div className="grid-item4" style={{ marginLeft: '70px' }}>GST</div>
              <div className="grid-item5">Surcharge</div>
            </div>
            {feecomponentdata.map((fee, index) => (
              <div key={index} className="grid" style={{ gridTemplateColumns: 'repeat(6, 1fr)' }}>
                <div className="grid-item1" style={{ width: "386px" }}>
                  <span>{categoriesdata[fee.category_id]}</span>
                </div>
                <div className="grid-item2">
                  Rs. {(fee.fee_amount).toFixed(2)}
                </div>
                <div className="grid-item3" style={{ color: fee.tax_percent === null ? 'rgba(153, 160, 174, 1)' : 'inherit' }}>
                  {fee.tax_percent === null ? "NA" : fee.tax_percent}
                </div>
                <div className="grid-item4" style={{ color: fee.gst === null ? 'rgba(153, 160, 174, 1)' : 'inherit' }}>
                  {fee.gst === null ? "NA" : fee.gst}
                </div>
                <div className="grid-item5" style={{ color: fee.surcharge === null ? 'rgba(153, 160, 174, 1)' : 'inherit' }}>
                  {fee.surcharge === null ? "NA" : fee.surcharge}
                </div>
              </div>
            ))}
          </div>
        </div>
      { feeStructuredata.allowed_concession!==0 && (<div className="footer-concession">
          <span><CiDiscount1 size={26} /></span>
          <span>Concession of {feeStructuredata.concession_percentage}% is applicable to this Fee Structure</span>
        </div>)}
        <div className="fee-components" style={{  marginTop: '20px' }}>
          <h2>Fee Schedule</h2>
          <div className="wholegrid1">
            <div className="grid-header" style={{ width: "846px" }}>
              <div className="grid-item1" style={{width:'340px'}}>Term Name</div>
              <div className="grid-item2"  >Fee Percentage</div>
              <div className="grid-item3" style={{marginLeft:'-80px'}}>Fee Amount</div>
              <div className="grid-item4" style={{marginLeft:'-30px'}}>Due Date</div>
            </div>
            {feeSheduledata.map((item) => (
              <div key={item.id} className="grid">
                <div className="grid-item1" style={{width:'342px'}}>
                  <span>Term {item.term}</span>
                </div>
                <div className="grid-item2">
                  {item.fee_percent}%
                </div>
                <div className="grid-item3" style={{marginLeft:'-70px'}}>
                  Rs. {(item.fee_amount).toFixed(2)}
                </div>
                <div className="grid-item4" style={{marginLeft:'-30px'}}>
                  {formatDate(item.due_date)}
                </div>
              </div>
            ))}
            <div className="total-row" style={{ borderTop: '0'}}>
              <span className='Totalfee' style={{width:'158px'}}>Total Fee Amount</span>
              <span className='Totalper' style={{ width:'65px' }}>{feedata[0]}%</span>
              <span className='TotalRupees' > Rs. {Number(feedata[1]).toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='buttons' style={{ width: '850px', marginTop: '40px', display: 'flex'}}>
        <div className="backbutton" style={{ marginRight: '-100px' }}>
          <button type='submit' className='back-button' onClick={() => { handleprogress(2,true) }}>
            <div className="lefticon"><SlArrowLeft size={10} strokeWidth={90} /></div>
            Back
          </button>
        </div>
        <button type="submit"  onClick={()=>{console.log("hello submit");navigate("/fee/structure"); sessionStorage.clear();handleExitFullScreen();}}className="continue-button1" style={{ background: 'rgba(51, 92, 255, 1)', color: 'rgba(255, 255, 255, 1)' }} >
          Finalise & Save
          <div>
            <SlArrowRight size={9} strokeWidth={70} />
          </div>
        </button>
      </div>
    </div>
      </>
  );
};
