import React from "react";
import PropTypes from "prop-types";
import "./Table.css";

const Table = React.forwardRef(({ className, ...props }, ref) => (
  <div className="custom-table--container">
    <table ref={ref} className={`custom-table ${className || ""}`} {...props} />
  </div>
));
Table.displayName = "Table";

const TableHeader = React.forwardRef(({ className, ...props }, ref) => (
  <thead ref={ref} className={`table-header ${className || ""}`} {...props} />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef(({ className, ...props }, ref) => (
  <tbody ref={ref} className={`table-body ${className || ""}`} {...props} />
));
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef(({ className, ...props }, ref) => (
  <tfoot ref={ref} className={`table-footer ${className || ""}`} {...props} />
));
TableFooter.displayName = "TableFooter";

const TableRow = React.forwardRef(({ className, ...props }, ref) => (
  <tr ref={ref} className={`table-row ${className || ""}`} {...props} />
));
TableRow.displayName = "TableRow";

const TableHead = React.forwardRef(({ className, ...props }, ref) => (
  <th ref={ref} className={`table-head ${className || ""}`} {...props} />
));
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef(({ className, ...props }, ref) => (
  <td ref={ref} className={`table-cell ${className || ""}`} {...props} />
));
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={`table-caption ${className || ""}`}
    {...props}
  />
));
TableCaption.displayName = "TableCaption";

Table.propTypes = {
  className: PropTypes.string,
};

TableHeader.propTypes = {
  className: PropTypes.string,
};

TableBody.propTypes = {
  className: PropTypes.string,
};

TableFooter.propTypes = {
  className: PropTypes.string,
};

TableRow.propTypes = {
  className: PropTypes.string,
};

TableHead.propTypes = {
  className: PropTypes.string,
};

TableCell.propTypes = {
  className: PropTypes.string,
};

TableCaption.propTypes = {
  className: PropTypes.string,
};

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
};
