import React from "react";
import "./UserTransactionLog.css";
import { useLocation, useNavigate } from "react-router-dom";
import CustomDropdown from "../FeeManagementModule/components/Custom-Dropdown";
import { FilterIcon, SortIcon } from "../../assets/svgs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../FeeManagementModule/components/Table";
import CircularProgressBar from "../FeeManagementModule/components/ProgressBar";
import { formatPriceWithExponential } from "../FeeManagementModule/FeeStructureModule/hooks";
import {
  createFeeConcession,
  // getClassesById,
  getConcessionRefereeNames,
  getExistingReference,
  getFeeConcessionById,
  getFeeStructureBySectionID,
  getFeeTransactionAPI,
  getPendingFeeDetailsById,
  getReceiptDetails,
  getStudent,
  getUserFeePayment,
} from "../../api/api";

import UserContext from "../../context/UserContext";
import {  FiEdit3 } from "react-icons/fi";
// import { RxCross2 } from "react-icons/rx";
import { SecondaryButton } from "../FeeManagementModule/components/Button";
import { Dropdown } from "./components/Dropdown";
import PrintPDF from "../FeeManagementModule/FeeCollectionModule/components/Receipt";
import { toast } from "sonner";
import Pagination from "../../home/Pagination";
import { FeeConcessionEditSlider } from "./FeeConcessionEditSlider";
import CollectFee from "../FeeManagementModule/FeeCollectionModule/components/CollectFee";
import Skeleton from "react-loading-skeleton";

const UserTransactionLog = () => {
  const location = useLocation();
  const navigate =useNavigate();
  const userId = location.pathname.split("/")[3];
  const { userInfo } = React.useContext(UserContext);
  const [feeStructureData, setFeeStructureData] = React.useState([]);
  // const [isEditing, setIsEditing] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [concessionAmount, setConcessionAmount] = React.useState();
  const [editConcessionAmount, setEditConcessionAmount] = React.useState();
  const [feeData, setFeeData] = React.useState([]);
  const [studentData, setStudentData] = React.useState([]);
  // const [sectionData, setSectionData] = React.useState([]);
  const [termFeeData, setTermFeeData] = React.useState([]);
  const [feePendingDetails,setfeePendingDetails] = React.useState([])
  const [editsliderstate, seteditsliderstate] = React.useState(
    false
  );
  const [currentPage, setCurrentPage] = React.useState(1);
  const queryParams = new URLSearchParams(location.search);
  const lowFilter = queryParams.get("low");
  const highFilter = queryParams.get("high");
  const sortFilter = queryParams.get("sort");
  const [refresh,setrefresh] =React.useState(false);
  const handlerefresh=()=>{
   setrefresh(!refresh);
   navigate(`/student/transactions/${userId}/fee?refresh`);
  
}  
const [concessiontype,setconcessiontype]=React.useState(null);
const [refereee,setreferee]=React.useState(null);


React.useEffect(() => {
    const fetchData = async() => {
      setIsLoading(true);
        try{

    
          await getStudent(userId).then((res) => {
            setStudentData(res.response.students[0]);
          });

          await getUserFeePayment(userId, "").then((res) => {
            setTermFeeData(res.response.fee_payments);
          });

          await getFeeConcessionById(userId).then((res) => {
            const concession =
              res.response.fee_concessions[0]?.concession_amount ?? 0;
            setConcessionAmount(concession);
            console.log(concession);
            
            
            setEditConcessionAmount(concession);
          });
          await getExistingReference(userId).then((res)=>{
            setconcessiontype(res);
            if(res.length && res[0].reference_id!==null)
            { getConcessionRefereeNames(res.length ?res[0].reference_id:'null').then((res)=>{
              console.log(res);
              if(res.length)
                setreferee(res[0].first_name);
              else setreferee('-')
              }  
            );}
            else setreferee('-')
       
            
          });
          // console.log("concession update")
          await getPendingFeeDetailsById(userId).then((res) => {
            setfeePendingDetails(res.transactions)
            console.log("This is the res", res);
          });
               
              
              } 

          
        catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
          
        }
      
    };

    fetchData();
  }, [userId, userInfo.branch_id,refresh]);

  React.useEffect(() => {
    const fetchData = () => {
      if (
        userId !== null &&
        userId !== undefined &&
        userId !== "" &&
        userInfo.branch_id !== null &&
        userInfo.branch_id !== undefined &&
        userInfo.branch_id !== ""
      ) {
        getFeeTransactionAPI(
          userInfo.branch_id,
          userId,
          lowFilter,
          highFilter,
          sortFilter
        ).then((res) => {
          setFeeData(res.response.transactions);
        });
      }
    };
    fetchData();
  }, [userId, userInfo.branch_id, lowFilter, highFilter, sortFilter,refresh]);

  React.useEffect(() => {
    const fetchData = () => {
      if (
        studentData.class_id !== null &&
        studentData.class_id !== undefined &&
        studentData.class_id !== "" &&
        studentData.section_id !== null &&
        studentData.section_id !== undefined &&
        studentData.section_id !== ""
      ) {
        getFeeStructureBySectionID(
          studentData.section_id,
          studentData.class_id
        ).then((res) => {
          console.log(res)
          setFeeStructureData(res.response.fee_structures[0]);
        });
      }
    };
    fetchData();
  }, [
    studentData.class_id,
    studentData.section_id,
    termFeeData,refresh
  ]);

  // React.useEffect(() => {
  //   const fetchData = () => {
  //     if (
  //       studentData.current_class_id !== null &&
  //       studentData.current_class_id !== undefined &&
  //       studentData.current_section_id !== null &&
  //       studentData.current_section_id !== undefined
  //     ) {
  //       // getClassesById(
  //       //   studentData.current_class_id,
  //       //   studentData.current_section_id
  //       // ).then((res) => {
  //       //   setSectionData(res.response.sections[0]);
  //       // });
  //     }
  //   };
  //   fetchData();
  // }, [studentData.current_class_id, studentData.current_section_id,refresh]);

  const handlePrintPDF = async (receiptData) => {
    try {
      await getReceiptDetails(receiptData).then((res) => {
        PrintPDF(res.response);
      });
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Error generating PDF");
    }
  };

  const pageSize = 6;
  const totalPages = Math.ceil(feeData.length / pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedFeeData = feeData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const dropdownOptions = [{ value: "2024-2025", label: "2024-2025" }];

  // {
  //   "user_id": 1217,
  //   "full_name": "Nallagantula Manoj",
  //   "phone_number": "8096145644",
  //   "fee_structure_id": 388,
  //   "student_id": null,
  //   "class": "Class 6",
  //   "section": "A",
  //   "next_due_date": "2024-12-01",
  //   "terms": 2,
  //   "terms_completed": "0",
  //   "total_amount_paid": "0",
  //   "total_term_fee": "10000",
  //   "total_term_concession": "0",
  //   "due_amount": "10000",
  //   "payment_status": "pending"
  // }

  // if (termFeeData.length===0 || isLoading) {
  //   return <>{console.log("loading")}Loading...</>;
  // }

  const createConcession = async() => {
    // setIsLoading(true);
    if(concessionAmount>editConcessionAmount){
      toast.error("Concession cannot be decreased!!");
       return "!done"
    }
    try{
      const totalFee = termFeeData.reduce(
      (acc, data) => acc + data.term_fee - data.term_concession,
      0
    );

    const Percentage = (concessionAmount / totalFee) * 100;
    if (
      concessionAmount === undefined ||
      concessionAmount === null ||
      concessionAmount === "" ||
      concessionAmount === 0
    ) {
      if (editConcessionAmount === 0) {
        toast.error("Concession amount cannot be 0");
        return;
      }
      const payload = {
        fee_concessions: [
          {
            user_id: userId,
            academic_year: "2024-2025",
            concession_percentage: Percentage,
            concession_amount: editConcessionAmount,
          },
        ],
      };
      await createFeeConcession(payload, "post").then((res) => {
        if (res.response.message === "CREATION_SUCCESS") {
          setConcessionAmount(editConcessionAmount);
          toast.success("Concession added!!");
          // setIsLoading(false)
          
        }

        if (res.errors) {
          toast.error("Concession not added!!");

        }
        return "done"
        // setIsLoading(false);
      });
    } else if(concessionAmount<editConcessionAmount){
      const payload = {
        user_id: userId,
        academic_year: "2024-2025",
        concession_percentage: Percentage,
        concession_amount: editConcessionAmount,
      };
      await createFeeConcession(payload, "put").then((res) => {
        console.log(res);
        if (res.response.message === "RESOURCE_UPDATED_SUCCESSFULLY") {
          toast.success("Concession updated!!");
          setConcessionAmount(editConcessionAmount);
          // setrefresh(!refresh);
          return "done"
          // setIsLoading(false);
        }
        else{
          toast.error("Concession not updated!!");
          // setIsLoading(false);
          

        }
        return "done"
      });
    }
    else{
      return "done"
    }
  }
    finally{
      // setIsLoading(false);
       return "done"
    }
  
  };

  // const handleEditClick = () => {
  //   setIsEditing(true);
  // };

  const handleInputChange = (e) => {
    setEditConcessionAmount(parseFloat(e.target.value) || 0);
  };

  // const handleCancelClick = () => {
  //   setIsEditing(false);
  // };
// if(isLoading)
//   return(<>
//   "Loading"
//   </>);
  return (
    <>
      <div className="user-transaction-log__fee-heading">
        {!isLoading && <p>Student Fee Amount </p>}
        {!isLoading &&<Dropdown options={dropdownOptions} defaultText={"2024-2025"} />}
        
       {isLoading && <>
        <Skeleton width={'7rem'}/>
        <Skeleton width={'7rem'} height={'1.5rem'}/>
        </>}
      </div>
      <div className="user-transaction-log__fee-details user-transaction-log__fee-details--first ">
        <div
          style={{
            width: "55%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          {isLoading && <Skeleton width={'10rem'} height={'6rem'} borderRadius={"5rem 5rem 0rem 0rem"}/>}
          {!isLoading && <CircularProgressBar
            value={
              ((termFeeData.reduce(
                (acc, data) =>
                  acc + data.term_fee - data.term_concession - data.amount_paid,
                0
              ) -
                concessionAmount) *
                100) /
              (termFeeData.reduce(
                (acc, data) => acc + data.term_fee - data.term_concession,
                0
              ) -
                concessionAmount)
            }
            total={100}
            fee={
              termFeeData.reduce(
                (acc, data) => acc + data.term_fee - data.term_concession,
                0
              )
            }
          />}
          {isLoading && <div>
            <Skeleton width={'5rem'}/>
            <Skeleton height={'1.5rem'}/>
          </div>}
          {!isLoading && <div>
            <p className="transaction__log--title-first">Amount Due</p>
            <p className="transaction__log--due">
              {formatPriceWithExponential(
                termFeeData.reduce(
                  (acc, data) =>
                    acc +
                    data.term_fee -
                    data.term_concession -
                    data.amount_paid,
                  0
                ) 
              )}
            </p>
          </div>}
        </div>
          {isLoading &&
          <>
          <Skeleton width={'7rem'} height={'1.8rem'}/></>}
        {!isLoading && termFeeData.reduce(
                  (acc, data) =>
                    acc +
                    data.term_fee -
                    data.term_concession -
                    data.amount_paid,
                  0
                ) 
               >0 && <div className="user-transaction-log__btn-box">
        <CollectFee item={feePendingDetails[0]} handlerefresh={handlerefresh}/>
          {/* <button>Remind</button> */}
        </div>}
      </div>
      <div className="user-transaction-log__fee-details">
       {!isLoading && <p className="transaction-log--heading" style={{display:'flex',alignContent:'center',gap:'0.2rem'}}>Fee & Concession Details  <FiEdit3
                  onClick={()=>{seteditsliderstate(true);}}
                  size={"0.9rem"}
                  className="edit-icon"
                  color="#335CFF"
                  style={{marginTop:'0.2rem'}}
                /></p>}
                {isLoading && <Skeleton width={'12rem'}/>}

        <div
          className="
              user-transaction-log__fee-details--section"
        >
          {!isLoading && <div>
            <p className="transaction__log--title">Applied Fee Structure</p>
            <p className="transaction__log--subHeading">
              {feeStructureData?.name || "No Fee Structure Available"}
              {/* <span className="class--idiidi">
                {studentData?.class || "N/A"}-{studentData?.section || "N/A"}
              </span> */}
            </p>
          </div>}
          {isLoading && <div>
          <Skeleton  width={'6rem'}/>
          <Skeleton width={'6rem'}/>
          </div>}
          {isLoading && <div>
          <Skeleton  width={'6rem'}/>
          <Skeleton width={'6rem'}/>
          </div>}
          {isLoading && <div>
          <Skeleton  width={'6rem'}/>
          <Skeleton width={'6rem'}/>
          </div>}
          {!isLoading &&<div>
            <p className="transaction__log--title">Academic year</p>
            <p className="transaction__log--subHeading">2024-2025</p>
          </div>}
          
          {!isLoading && <div>
            <p className="transaction__log--title">Concession Amount</p>
          
              <div className="user-transaction-log__fee-details--concession">
                <p className="transaction__log--subHeading">
                  {concessionAmount || 0}
                </p>
                {/* <FiEdit3
                  onClick={handleEditClick}
                  size={"0.8rem"}
                  className="edit-icon"
                  color="#335CFF"
                /> */}
              </div>
            
          </div>}
        </div>
        {isLoading && <div style={{display:'flex',justifyContent:'space-between'}}>
           <div>
          <Skeleton  width={'6rem'}/>
          <Skeleton width={'6rem'}/>
          </div>
           <div>
          <Skeleton  width={'6rem'}/>
          <Skeleton width={'6rem'}/>
          </div>
          <div>
          <Skeleton  width={'6rem'}/>
          <Skeleton width={'6rem'}/>
          </div>
          </div>}
        <div className="user-transaction-log__fee-details--subsection">
        
         {!isLoading &&  <div>
            <p className="transaction__log--title">concession Type</p>
            <p className="transaction__log--subHeading">
              {concessiontype.length ?concessiontype[0].concession_type!=null?concessiontype[0].concession_type:'-':'-'
}
              {/* <CustomDropdown
                defaultText={concessiontype}
                // icon={<FilterIcon />}
                items={["Merit", "Local Influence","Good Will"]}
                width={"fit-content"}
                dropdownName={"term"}
              /> */}
            </p>
          </div>}
         {!isLoading &&  <div>
            <p className="transaction__log--title">Reference</p>
            <p className="transaction__log--subHeading">{refereee}</p>
          </div>}
        </div>
      </div>
      {isLoading && <div className="user-transaction-log__fee-details" >
        <div style={{display:'flex',justifyContent:'space-between'}}>

        <Skeleton width={'10rem'}/>
        <div style={{display:'flex',justifyContent:'space-between',gap:'1rem'}}>

        <Skeleton width={'10rem'}/>
        <Skeleton width={'10rem'}/>
        </div>
        
        </div>
        <div style={{marginTop:'1.3rem'}}>

          <Skeleton width={'100%'} height={'10rem'}/>
        </div>
      </div>}

      
     { !isLoading && <div className="user-transaction-log__fee-details">
        <div className="user-transaction-log__fee-details--header">
          <p className="transaction-log--heading">Transaction History</p>
          <div className="filter__dropdowns">
            <CustomDropdown
              defaultText={"Filter amount"}
              icon={<SortIcon />}
              padding={"0.5rem"}
              items={[
                "Less than ₹10,000",
                "₹10,000 - ₹20,000",
                "₹20,000 - ₹30,000",
                "₹30,000 - ₹40,000",
                "More than ₹40,000",
              ]}
              dropdownName={"filter"}
            />
            <CustomDropdown
              defaultText={"low to high"}
              icon={<FilterIcon />}
              padding={"0.5rem"}
              items={["low to high", "high to low"]}
              width={"fit-content"}
              dropdownName={"sort"}
            />
          </div>
        </div>
        <Table className="transaction--log_table">
          <TableHeader>
            <TableRow>
              <TableHead>Payment Method</TableHead>
              <TableHead>Amount Paid</TableHead>
              <TableHead>Received by & Date</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedFeeData.map((data) => (
              <TableRow key={data.id}>
                <TableCell>
                  <div className="table-cell--log">
                    <span
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {data.payment_method === "cash"
                        ? data.payment_method
                        : `UPI- ${data.payment_method}`}
                    </span>
                    {data.payment_method !== "cash" && (
                      <span
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: 400,
                        }}
                      >
                        #{data.reference_id}
                      </span>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="table-cell--log">
                    <span>{formatPriceWithExponential(data.amount_paid)}</span>
                    <span
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: 400,
                      }}
                    >
                      Term {data.term}
                    </span>
                  </div>
                </TableCell>
                <TableCell className="table-cell--log">
                  <span
                    style={{
                      textWrap: "nowrap",
                    }}
                  >
                    {data.receiver_name}
                  </span>
                  <span
                    style={{
                      textWrap: "nowrap",
                      fontSize: "0.8rem",
                      fontWeight: 400,
                    }}
                  >
                    {new Date(data.received_date).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })}
                  </span>
                </TableCell>
                <TableCell>
                  <SecondaryButton
                    style={{
                      padding: "0.5rem",
                    }}
                    onClick={() => handlePrintPDF(data.receipt_id)}
                  >
                    Download
                  </SecondaryButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        
        {editsliderstate && <FeeConcessionEditSlider sliderstate={seteditsliderstate} editConcessionAmount={editConcessionAmount}
                  concessionAmount={concessionAmount} concessiontype={concessiontype}
                  handleInputChange={handleInputChange} refereee={refereee}createConcession={createConcession}handlerefresh={handlerefresh} userid={userId} setIsLoading={setIsLoading} />}
      </div>}
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={handlePageChange}
          pageSize={pageSize}
          totalPages={totalPages}
        />
      )}
    </>
  );
};

export default UserTransactionLog;