import React from "react";
import { useLocation } from "react-router-dom";
import { getTeacherDetailsWithID } from "../../../api/api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  GrayBox,
  TextBox,
} from "../../../auth/SetupAdminPage/components/SetupComponents";

const SchoolTeacher = () => {
  const [teacherData, setTeacherData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const location = useLocation();
  const school_structure_id = location.pathname.split("/")[3];

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getTeacherDetailsWithID(school_structure_id);
        setTeacherData(response.response.users);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [school_structure_id]);

  return (
    <div className="table-container" style={{ marginTop: "1rem" }}>
      <div className="table-container-header">
        <div className="header-cell-container" style={{ flex: "2" }}>
          Name of the teacher
        </div>
        <div className="header-cell-container">Teacher ID</div>
        <div className="header-cell-container" style={{ flex: "1.5" }}>
          Contact Details
        </div>
        <div className="header-cell-container" style={{ flex: "1.5" }}>
          Role
        </div>
        <div className="header-cell-container">Actions</div>
      </div>
      <div className="table-container-body">
        {isLoading ? (
          <>
            {Array(5)
              .fill()
              .map((_, index) => (
                <div className="row-container" key={index}>
                  <div className="cell-container" style={{ flex: "2" }}>
                    <Skeleton width={150} height={20} />
                  </div>
                  <div className="cell-container">
                    <Skeleton width={100} height={20} />
                  </div>
                  <div className="cell-container">
                    <Skeleton height={20} width={120} count={2} />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <Skeleton width={80} height={20} />
                  </div>
                  <div className="cell-container">
                    <Skeleton width={80} height={20} />
                  </div>
                </div>
              ))}
          </>
        ) : (
          <>
            {teacherData.map((teacher) => (
              <div className="row-container" key={teacher.id}>
                <div className="cell-container" style={{ flex: "2" }}>
                  <div
                    className="borderbox"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      gap: "0.5rem",
                      textTransform: "capitalize",
                    }}
                  >
                    <img
                      src={`https://api.dicebear.com/8.x/initials/svg?seed=${teacher.full_name}`}
                      alt="teacher"
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        borderRadius: "50%",
                      }}
                    />
                    {teacher.full_name}
                  </div>
                </div>
                <div className="cell-container">
                  <div className="StudentId">
                    <GrayBox text={`#${teacher.id}`} />
                  </div>
                </div>
                <div className="cell-container" style={{ flex: "1.5" }}>
                  <div
                    className="borderbox"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span>+91-{teacher.phone_number}</span>
                    <span
                      style={{
                        color: "var(--gray-color-600)",
                        fontSize: "0.75rem",
                        fontWeight: "500",
                      }}
                    >
                      {teacher.email}
                    </span>
                  </div>
                </div>
                <div className="cell-container" style={{ flex: "1.5" }}>
                  <div className="borderbox">
                    <TextBox text={teacher.role} />
                  </div>
                </div>
                <div className="cell-container">
                  <div className="borderbox">Edit</div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default SchoolTeacher;
