import React from "react";
import "./ToggleButton.css";

import PropTypes from "prop-types";

const ToggleButton = ({ checked }) => {
  return (
    <React.Fragment>
      <label className="toggle-button__switch switch">
        <input
          type="checkbox"
          className="toggle-button__input"
          checked={checked}
          readOnly
        />
        <span className="toggle-button__slider"></span>
      </label>
    </React.Fragment>
  );
};

ToggleButton.propTypes = {
  checked: PropTypes.bool.isRequired,
};

export default ToggleButton;
