import { useState } from "react";
import { toast } from "sonner";
import "./DeleteModal.css";
import { useToggle } from "../modules/FeeManagementModule/FeeStructureModule/hooks";
import { deleteFeeStructure } from "../api/api";

function DeleteModal({ id, onDelete }) {
  const { state: isOpen, handlers } = useToggle();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteFeeStructure(id);
      showMessage();
      onDelete(id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const showMessage = () => {
    toast(
      <div className="modal__success">
        <div className="modal__success-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8 15.5C3.85775 15.5 0.5 12.1423 0.5 8C0.5 3.85775 3.85775 0.5 8 0.5C12.1423 0.5 15.5 3.85775 15.5 8C15.5 12.1423 12.1423 15.5 8 15.5ZM7.25225 11L12.5548 5.69675L11.4943 4.63625L7.25225 8.879L5.1305 6.75725L4.07 7.81775L7.25225 11Z"
              fill="#1FC16B"
            />
          </svg>
        </div>
        <div className="modal__success-message">
          <span className="modal__success-text">
            Fee Structure Deleted successfully!
          </span>
          <span className="modal__success-subtext">
            {/* Please insert delete__modal description here. */}
          </span>
        </div>
        <button
          className="modal__cancel-toast-button"
          onClick={() => toast.dismiss()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <path
              d="M4.99956 3.93955L8.71206 0.227051L9.77256 1.28755L6.06006 5.00005L9.77256 8.71255L8.71206 9.77305L4.99956 6.06055L1.28706 9.77305L0.226562 8.71255L3.93906 5.00005L0.226562 1.28755L1.28706 0.227051L4.99956 3.93955Z"
              fill="#525866"
            />
          </svg>
        </button>
      </div>,
      {
        position: "top-right",
      }
    );
    handlers.off();
  };

  return (
    <div>
      <button
        onClick={handlers.toggle}
        className="fee-structure__actions-menu-item-btn"
        disabled={isLoading}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.5 6.6H21V8.4H19.2V20.1C19.2 20.3387 19.1052 20.5676 18.9364 20.7364C18.7676 20.9052 18.5387 21 18.3 21H5.7C5.46131 21 5.23239 20.9052 5.0636 20.7364C4.89482 20.5676 4.8 20.3387 4.8 20.1V8.4H3V6.6H7.5V3.9C7.5 3.66131 7.59482 3.43239 7.7636 3.2636C7.93239 3.09482 8.1613 3 8.4 3H15.6C15.8387 3 16.0676 3.09482 16.2364 3.2636C16.4052 3.43239 16.5 3.66131 16.5 3.9V6.6ZM17.4 8.4H6.6V19.2H17.4V8.4ZM13.2726 13.8L14.8638 15.3912L13.5912 16.6638L12 15.0726L10.4088 16.6638L9.1362 15.3912L10.7274 13.8L9.1362 12.2088L10.4088 10.9362L12 12.5274L13.5912 10.9362L14.8638 12.2088L13.2726 13.8ZM9.3 4.8V6.6H14.7V4.8H9.3Z"
            fill="#0E121B"
          />
        </svg>
        Delete
      </button>
      {isOpen && (
        <div className="delete__modal">
          <div className="delete__modal__content">
            <div className="delete__modal__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M9 18C4.0293 18 0 13.9707 0 9C0 4.0293 4.0293 0 9 0C13.9707 0 18 4.0293 18 9C18 13.9707 13.9707 18 9 18ZM8.1 11.7V13.5H9.9V11.7H8.1ZM8.1 4.5V9.9H9.9V4.5H8.1Z"
                  fill="#FB3748"
                />
              </svg>
            </div>
            <p className="delete__modal__text">
              Are you sure you want to delete?{" "}
            </p>
            <p className="delete__modal__subtext5">
              All classes assigned to this fee structure will be affected. It’s
              recommended to review before making the changes{" "}
            </p>
            <div className="delete__modal__delete-text">
              <p>This action can cause issues in fee collection</p>
            </div>
            <div className="delete__modal__divider"></div>
            <div className="delete__modal__buttons">
              <button
                onClick={handlers.off}
                className="delete__modal__delete-btn"
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                className="delete__modal__delete-btn"
                onClick={handleDelete}
                disabled={isLoading}
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DeleteModal;
