import React from 'react'
import "./CancelButton.css" 
import DangerImage from "../../../../assets/danger.png"
import { useNavigate } from 'react-router-dom'
import { deleteFeeStructure } from '../../../../api/api'
export const CancelButton = ({
  isOpen,
  onClose,
  onConfirm,
  navto = "/fee/structure",
}) => {
  const handleExitFullScreen = () => {
    if (document.fullscreenElement)
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari, and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
      }
  };
  const navigate = useNavigate();
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        {/* <button className="close-button" onClick={onClose}>Cancel ✕</button> */}
        <div className="modal-content1">
          <div className="modal-icon">
            <img
              className="DangeImage"
              src={DangerImage}
              alt="DangeImage"
              style={{ width: "40px", height: "40px" }}
            />
            {/* <span>⚠️</span> */}
          </div>
          <div className="boxheadertext">Are you sure you want to cancel?</div>
          <div className="boxfoootertext">
            Also saved progress until now will be erased if you continue with
            this action
          </div>

          <div className="boxfooter">
            <button className="btn-resume" onClick={onClose}>
              No, resume
            </button>
            <button
              className="btn-cancel"
              onClick={() => {
                navigate(navto);
                if(navto === "/fee/structure")
                deleteFeeStructure();
                sessionStorage.clear();
                handleExitFullScreen();
              }}
              //  onClick={onConfirm}
            >
              Yes, Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
