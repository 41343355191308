import React from "react";
import { useSelectState } from "../../FeeStructureModule/hooks";

const RemainderMethods = () => {
  const { value: selectedOption, handleSelectChange: setSelectedOption } =
    useSelectState("sms");
  return (
    <div className="method__bulk--fee">
      <p className="method__bulk--text">Select method of reminders</p>
      <div className="remind-option--container">
        <div
          className={`payment-option ${
            selectedOption === "sms" ? "payment-option__selected" : ""
          }`}
        >
          <input
            type="checkbox"
            id="sms"
            name="payment"
            value="sms"
            checked={selectedOption === "sms"}
            onChange={(e) => setSelectedOption(e.target.value)}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              d="M14 8.875V15.625L11.5002 13.75H9.5V10.75H11.5002L14 8.875ZM14.75 0.25C14.9489 0.25 15.1397 0.329018 15.2803 0.46967C15.421 0.610322 15.5 0.801088 15.5 1V7.75H14V3.42775L8.054 8.7535L2 3.41125V12.25H8V13.75H1.25C1.05109 13.75 0.860322 13.671 0.71967 13.5303C0.579018 13.3897 0.5 13.1989 0.5 13V1C0.5 0.801088 0.579018 0.610322 0.71967 0.46967C0.860322 0.329018 1.05109 0.25 1.25 0.25H14.75ZM14.75 10.75C15.1392 10.7483 15.5139 10.8979 15.7949 11.1673C16.0758 11.4367 16.2411 11.8047 16.2557 12.1937C16.2703 12.5827 16.1331 12.9621 15.8731 13.2518C15.6132 13.5415 15.2508 13.7188 14.8625 13.7463L14.75 13.75V10.75ZM13.625 1.75H2.38325L8.04575 6.7465L13.625 1.75Z"
              fill="#525866"
            />
          </svg>
          <label htmlFor="sms" className="payment--option__text">
            Send via SMS
          </label>
        </div>
        {/* <div
          className={`payment-option ${
            selectedOption === "whatsapp" ? "payment-option__selected" : ""
          }`}
        >
          <input
            type="checkbox"
            id="online"
            name="payment"
            value="whatsapp"
            checked={selectedOption === "whatsapp"}
            onChange={(e) => setSelectedOption(e.target.value)}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C8.4201 17.5 6.9543 17.0115 5.74541 16.1773L3.18182 16.8182L3.8536 14.299C3.00058 13.0817 2.5 11.5993 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
              fill="#525866"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 11.5693 1.66312 13.0421 2.38655 14.3156L1.25 18.75L5.82179 17.6899C7.06336 18.3659 8.48682 18.75 10 18.75ZM10 17.4038C14.089 17.4038 17.4038 14.089 17.4038 10C17.4038 5.91097 14.089 2.59615 10 2.59615C5.91097 2.59615 2.59615 5.91097 2.59615 10C2.59615 11.5788 3.09031 13.0422 3.9324 14.2439L3.26923 16.7308L5.79996 16.0981C6.99335 16.9216 8.44036 17.4038 10 17.4038Z"
              fill="white"
            />
            <path
              d="M7.81251 5.93834C7.60447 5.52048 7.28533 5.55748 6.96292 5.55748C6.38673 5.55748 5.48828 6.24765 5.48828 7.53214C5.48828 8.58484 5.95216 9.73718 7.51526 11.461C9.02378 13.1246 11.0059 13.9852 12.6514 13.9559C14.2969 13.9266 14.6354 12.5106 14.6354 12.0324C14.6354 11.8204 14.5039 11.7147 14.4133 11.6859C13.8525 11.4168 12.8183 10.9154 12.583 10.8212C12.3477 10.727 12.2248 10.8544 12.1484 10.9237C11.935 11.1271 11.512 11.7264 11.3672 11.8612C11.2224 11.996 11.0064 11.9278 10.9166 11.8768C10.5859 11.7441 9.68933 11.3453 8.97467 10.6526C8.09083 9.79578 8.03896 9.501 7.87244 9.23861C7.73922 9.02869 7.83698 8.8999 7.88576 8.84362C8.07619 8.62389 8.33913 8.28466 8.45705 8.11608C8.57496 7.9475 8.48135 7.69155 8.42518 7.53214C8.18361 6.84655 7.97895 6.27263 7.81251 5.93834Z"
              fill="white"
            />
          </svg>
          <label htmlFor="whatsapp" className="payment--option__text">
            Send via Whatsapp
          </label>
        </div> */}
      </div>
    </div>
  );
};

export default RemainderMethods;
