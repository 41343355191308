import React, { useEffect, useState } from "react";
import "./EditDetailsSlider.css";
// import { SlArrowDown } from "react-icons/sl";
// import  DateSelector from "./DateDropDown";
import { CustomDropdownWithCustomStyle } from "./CustomDropdownWithCustomStyle";
import { editguardianinfo } from "../../../api/api";

export const GuardianInfoEditSlider = ({ sliderstate, data }) => {
  //eslint-disable-next-line
  const [loading, setloading] = useState(true);
  const [editdata, editsetdata] = useState({});
  useEffect(() => {
    try {
      setloading(true);
      let temp = { ...data };
      if (data.guardian_first_name === null) {
        temp["name"] = "";
      } else
        temp["name"] = `${data.guardian_first_name} ${data.guardian_last_name}`;

      editsetdata(temp);
      // setenrollstatus(data.enrolment_status)
    } catch (error) {
      setloading(false);
    }
  }, [data]);
  const handledatachange = async (key, value) => {
    let temp = { ...editdata };
    console.log(key, value);
    temp[key] = value;
    if (value[0] == null) temp[key] = "";
    console.log(temp);
    await editsetdata(temp);
  };
  useEffect(() => {
    console.log(editdata);
  }, [editdata]);

  const handlesavechanges = async () => {
    let tempdata = {
      user_id: editdata.id,
      relationship: editdata.guardian_relationship,
      occupation: editdata.guardian_occupation,
      contact_number: editdata.guardian_contact_number,
      email: editdata.guardian_email,
    };

    // console.log(editdata.name.split(" "));
    // const [names] = []
    if (editdata.name) {
      const [first_name, last_name] = editdata.name.split(" ");
      tempdata["first_name"] = first_name;
      tempdata["last_name"] = last_name;
    }
    // console.log(last_name);
    console.log(tempdata);
    await editguardianinfo(tempdata);

    sliderstate(false);
  };
  return (
    <div className="EditSlider">
      <div className="EditSliderdiv">
        <div className="EditSliderdivheader">
          Edit Guardian Info
          <svg
            onClick={() => {
              sliderstate(false);
            }}
            style={{ cursor: "pointer" }}
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <path
              d="M4.99999 3.93906L8.71249 0.226562L9.77299 1.28706L6.06049 4.99956L9.77299 8.71206L8.71249 9.77256L4.99999 6.06006L1.28749 9.77256L0.22699 8.71206L3.93949 4.99956L0.22699 1.28706L1.28749 0.226562L4.99999 3.93906Z"
              fill="#525866"
            ></path>
          </svg>
        </div>
        <div className="StudentInfoEditSilderdivbody">
          <div className="Details">
            <label style={{ display: "flex" }}>
              Name
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </label>
            <input
              type="text"
              className="inputbox"
              style={{ width: "32.5rem" }}
              placeholder="123456789"
              value={editdata.name}
              onChange={(event) => {
                handledatachange("name", event.target.value);
              }}
            />
          </div>
          <div className="Details">
            <label style={{ display: "flex" }}>
              Relation
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </label>
            <CustomDropdownWithCustomStyle
              defaultText={
                editdata.guardian_relationship
                  ? editdata.guardian_relationship
                  : "Select Relation Type"
              }
              // icon={<FilterIcon />}
              handledatachange={handledatachange}
              width={"32.5rem"}
              dropdownwidth="32.5rem"
              scrollenable={true}
              items={[
                "Parent",
                "Child",
                "Sibling",
                "Grandparent",
                "Grandchild",
                "Aunt",
                "Uncle",
                "Nephew",
                "Niece",
                "Cousin",
                "Great-Grandparent",
                "Great-Grandchild",
              ]}
              dropdownName={"guardian_relationship"}
            />
          </div>
          <div className="Details">
            <label style={{ display: "flex" }}>
              Occupation
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </label>
            <input
              type="text"
              className="inputbox"
              style={{ width: "32.5rem" }}
              placeholder="select guardian occupation"
              value={editdata.guardian_occupation}
              onChange={(event) => {
                handledatachange("guardian_occupation", event.target.value);
              }}
            />
          </div>

          <div className="Details">
            <label style={{ display: "flex" }}>
              Contact Number
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </label>
            <input
              type="text"
              className="inputbox"
              style={{ width: "32.5rem" }}
              placeholder="123456789"
              value={editdata.guardian_contact_number}
              onChange={(event) => {
                handledatachange("guardian_contact_number", event.target.value);
              }}
            />
          </div>
          <div className="Details">
            <label style={{ display: "flex" }}>
              Email Id
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </label>
            <input
              type="text"
              className="inputbox"
              style={{ width: "32.5rem" }}
              value={editdata.guardian_email}
              onChange={(event) => {
                handledatachange("guardian_email", event.target.value);
              }}
              placeholder="grahith@gmail.com"
            />
          </div>

          {/* <div className="Details">
            <label style={{ display: "flex" }}>
              Communication Preferences
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </label>
            <div className="select-classes-container">
              <div
                className="AcademicDropdown"
                // onClick={() => setIsDropdownOpen2(!isDropdownOpen2)}
                style={{ marginBottom: "0" }}
              >
                {"Select Type"}
                <span className="dropdown-icon1">
                  <SlArrowDown size={10} strokeWidth={78} />
                </span>
              </div>
              
            </div>
          </div> */}
        </div>
        <div className="footerbutton">
          <div
            className="cancel"
            //   onClick={() => {
            //     buttonstate(false);
            //   }}
          >
            Cancel
          </div>
          <div
            className="Proceed"
            onClick={() => {
              console.log("hello continuing");
              handlesavechanges();
            }}
          >
            Save Changes
          </div>
        </div>
      </div>
    </div>
  );
};
