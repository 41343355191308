import React from "react";
// import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import "./ActionsButton.css";
import DeleteModal from "../../../home/DeleteModal";
import Modal from "../../../home/Modal";
import { useSelectState } from "../FeeStructureModule/hooks";

const ActionsButton = ({ status, isOpen, onClick, id, onDelete }) => {
  const actionsRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const { value: menuPosition, handleSelectChange } = useSelectState("");

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        onClick();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);

      const buttonRect = buttonRef.current.getBoundingClientRect();
      const menuRect = actionsRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (buttonRect.bottom + menuRect.height > viewportHeight) {
        handleSelectChange("action__menu--top");
      }
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleSelectChange, isOpen, onClick]);

  return (
    <>
      <button onClick={onClick} className="actions__button" ref={buttonRef}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
        </svg>
      </button>
      {isOpen && (
        <div className={`actions-menu ${menuPosition}`} ref={actionsRef}>
          <Modal status={status} id={id} />
          <span className="fee-structure__actions-divider"></span>
          {/* <NavLink
            to={"/fee/dashboard"}
            className="fee-structure__actions-menu-item"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.97299 12.6001L13.1008 3.47228L11.8282 2.19968L2.70039 11.3275V12.6001H3.97299ZM4.71909 14.4001H0.900391V10.5814L11.1919 0.289875C11.3607 0.121151 11.5895 0.0263672 11.8282 0.0263672C12.0668 0.0263672 12.2957 0.121151 12.4645 0.289875L15.0106 2.83598C15.1793 3.00475 15.2741 3.23363 15.2741 3.47228C15.2741 3.71092 15.1793 3.9398 15.0106 4.10858L4.71909 14.4001ZM0.900391 16.2001H17.1004V18.0001H0.900391V16.2001Z"
                fill="#0E121B"
              />
            </svg>
            Edit
          </NavLink> */}
          <DeleteModal
            id={id}
            onDelete={onDelete}
            ref={actionsRef}
            isOpen={isOpen}
          />
        </div>
      )}
    </>
  );
};

ActionsButton.propTypes = {
  status: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ActionsButton;
