import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import ActionsMenu from "../../StudentDirectoryModule/Components/ActionsMenu";
import UserContext from "../../../context/UserContext";
import { CheckBoxDropDown } from "../../../home/components/CheckBoxDropdown/CheckBoxDropDown";
import { CustomDropdownWithCustomStyle } from "../../StudentDirectoryModule/Components/CustomDropdownWithCustomStyle";
import Pagination from "../../../home/Pagination";
import { FIlterbarSkeleton } from "../../../home/components/FIlterbarSkeleton";
import { getStudentsDetailsStudentDirectoryWithClassSectionMappingId } from "../../../api/api";
import "../../StudentDirectoryModule/StudentDirectory.css";
import {
  formatRomanNumber,
  useOutsideClick,
  useToggle,
} from "../../FeeManagementModule/FeeStructureModule/hooks";
import { NameAvatar } from "../../FeeManagementModule/FeeCollectionModule/components/NameAvatar";
import { DeletePopup } from "../../StudentDirectoryModule/Components/DeletePopup";
import { AssignandMoveClassPopup } from "../../StudentDirectoryModule/Components/AssignandMoveClassPopup";
import Details from "../../StudentDirectoryModule/Components/Deatails";
import { SearchBar } from "../../StudentDirectoryModule/Components/SearchBar";
import { FilterIcon, SortIcon } from "../../../assets/svgs";
import "../../../home/components/TableComponent/Table.css";
import { useLocation } from "react-router-dom";
import { AiOutlineInfoCircle } from "react-icons/ai";

const SchoolStudent = () => {
  const { userInfo } = useContext(UserContext);
  const [assignclasspopup, setassignclasspopup] = useState(false);
  const [moveclasspopup, setmoveclasspopup] = useState(false);
  const [deleteprofilepopup, setdeleteprofilepopup] = useState(false);
  const [studentdata, setstudentdata] = useState([]);
  const [totalstudentdata, settotalstudentdata] = useState([]);
  // const navigate = useNavigate();
  const [bulk, setbulk] = useState(false);
  // const [bukmenu, setbulkmenu] = useState(false);
  const [loading, setloading] = useState(true);
  const dropdownRef = useRef(null);
  const { state: isOpen, handlers } = useToggle();
  const [paginateddata, setpaginateddata] = useState([]);
  const [totalpages, settotalpages] = useState(0);
  const pageSize = 8;
  // const [incompleteprofilecount, setincompleteprofilecount] = useState(0);
  // const [checkboxselectall,setcheckboxselectall]=useState(false);
  useOutsideClick(dropdownRef, handlers.off);
  const [selectedItemsCount, setselectedItemsCount] = useState(0);
  const [curpage, setcurpage] = useState(1);
  const [checkeditem, setcheckeditem] = useState([]);
  const [onlytableloading, setonlytableloading] = useState(false);
  const [asclist, setasclist] = useState(null);
  const [bulkdataforbulkactions, setbulkdataforbulkactions] = useState([]);
  const location = useLocation();
  const class_section_mapping_id = location.pathname.split("/")[3];
  const handlesearchdata = (page, data = studentdata) => {
    setcurpage(page);
    settotalpages(Math.ceil(data.length / pageSize));
    console.log((page - 1) * pageSize, pageSize);
    setstudentdata(data);
    setpaginateddata(
      data.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
    );
  };
  const handlePageChange = (page, data = studentdata) => {
    setcurpage(page);
    console.log((page - 1) * pageSize, pageSize);
    setpaginateddata(
      data.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
    );
  };
  const [actionstudentid, setactionstudentid] = useState("no one");
  const selectmenuitems = (value, id) => {
    setbulk(false);
    console.log(value, id);
    setactionstudentid(id);
    if (value === 1) {
      setassignclasspopup(true);
    } else if (value === 2) {
      setmoveclasspopup(true);
    } else {
      setactionstudentid([id]);
      setdeleteprofilepopup(true);
    }
  };
  const selectmenubulkitems = async (value) => {
    console.log(value);
    setbulk(true);
    const trueKeys = Object.entries(checkeditem)
      .filter(([key, value]) =>(key!=="all") && value === true) // Only keep entries with value true
      .map(([key]) => Number(key));
    console.log(trueKeys);
    if (value === "Delete") {
      setactionstudentid(trueKeys);
      setdeleteprofilepopup(true);
    } else {
      const extractedObjects = await studentdata.filter((item) =>
        trueKeys.includes(item.id)
      );
      console.log(extractedObjects);
      setbulkdataforbulkactions(extractedObjects);
      if (value === "Move") setmoveclasspopup(true);
      else setassignclasspopup(true);
    }
  };
  const paginationhandling=(data)=>{
    if (data.students.length >= pageSize) {
      setpaginateddata(data.students.slice(0, pageSize));
    } else setpaginateddata(data.students);
    let items = {};
    data.students.forEach((item) => {
      items[item.id] = false;
    });
    items["all"] = false;
    console.log("items", items);
    setcheckeditem(items);

    settotalpages(Math.ceil(data.students.length / pageSize));
  }
  const handledatachange = async (...value) => {
    console.log(value);
    let data = paginateddata;

    try {
      setonlytableloading(true);

      if (value[0] === "sort") {
        if (value[1] === "Classes↑") {
          setasclist(true);
          data = await getStudentsDetailsStudentDirectoryWithClassSectionMappingId(
            ["sort_by_class", "asc"],
            userInfo.branch_id,
            class_section_mapping_id
          );
          setstudentdata(data.students);
          settotalstudentdata(data.students);
          console.log(value);
        } else if (value[1] === "Classes↓") {
          setasclist(false);
          data = getStudentsDetailsStudentDirectoryWithClassSectionMappingId(
            ["sort_by_class", "desc"],
            userInfo.branch_id,
            class_section_mapping_id
          );
          setstudentdata(data.students);
          console.log(value);
        }
      } else if (value[0] === "filter" && value[1].length) {
        console.log("filter found");

        let filters = [];
        for (let i = 0; i < value[1].length; i++) {
          if (value[1][i] === "Classes assigned") {
            filters.push("class_status");
            filters.push("Assigned");
          } else if (value[1][i] === "Classes not assigned") {
            filters.push("class_status");
            filters.push("NotAssigned");
          } else if (value[1][i] === "InCompleted Profiles") {
            filters.push("profile_status");
            filters.push("Incomplete");
          } else if (value[1][i] === "Completed Profiles") {
            filters.push("profile_status");
            filters.push("Complete");
          }
        }
        if (asclist !== null) {
          filters.push("sort_by_class");
          if (asclist) {
            filters.push("asc");
          } else {
            filters.push("desc");
          }
        }
        data =await getStudentsDetailsStudentDirectoryWithClassSectionMappingId(
          filters,
          userInfo.branch_id,
          class_section_mapping_id
        );
        console.log(filters);
      } else data = await getStudentsDetailsStudentDirectoryWithClassSectionMappingId(
        [],
        userInfo.branch_id,
        class_section_mapping_id
      );
    } finally {
      setstudentdata(data.students);
      settotalstudentdata(data.students);
      await paginationhandling(data);
      setonlytableloading(false);
    }
  };
  //  const handlecheckboxchange = () => {};

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        setloading(true);
        const data = await getStudentsDetailsStudentDirectoryWithClassSectionMappingId(
          [],
          userInfo.branch_id,
          class_section_mapping_id
        );
        // setincompleteprofilecount(data.total_incomplete_profiles);
        setstudentdata(data.students);
        settotalstudentdata(data.students);
        await paginationhandling(data);
      } finally {
        setloading(false);
      }
    };
    fetchStudentDetails();
  }, [userInfo.branch_id,class_section_mapping_id]);
  const refreshdata = async () => {
    try {
      setloading(true);
      setselectedItemsCount(0);
      const data = await getStudentsDetailsStudentDirectoryWithClassSectionMappingId(
        [],
        userInfo.branch_id,
        class_section_mapping_id
      );
      // setincompleteprofilecount(data.total_incomplete_profiles);
      setstudentdata(data.students);
      settotalstudentdata(data.students);
      await paginationhandling(data);
    } finally {
      setloading(false);
    }
  };
  const findstudentitem = (id) => {
    if (id === "no one") return "no one selected";
    const item = studentdata.find((item) => item.id === id);

    return item;
  };
  // useEffect(() => {
  //   console.log(selectedItemsCount);
  // }, [selectedItemsCount]);

  const handleCheckboxChange = async(id) => {
    let temp = { ...checkeditem };
    console.log(studentdata);
    temp[id] = !temp[id];
    const value = temp[id];

    setselectedItemsCount(0);
    if (id === "all") {
      for(let i=0;i<studentdata.length;i++){
        temp[studentdata[i].id] = value;
      
      }
      temp["all"] = value;
      console.log(temp);
      // setcheckeditem(temp);
    }
    for(let i=0;i<studentdata.length;i++){

      if( temp[studentdata[i].id]){
        setselectedItemsCount((prevCount) => prevCount + 1);
      }
    }
    setcheckeditem(temp);
  };



  return (
    <div>
      <div className="StudentDirectory">
        <div className="StudentDirectoryBody">
          {/* <div></div> */}
          {loading && (
            <div className="reqmargins" style={{ marginBottom: "0.5rem" }}>
              <FIlterbarSkeleton />
            </div>
          )}
          {!loading && (
            <section
              className="dropdowns--section"
              style={{ paddingLeft: "-10rem" }}
            >
              <div className="bulk--actions__dropdown" ref={dropdownRef}>
                <button
                  className={`bulk-actions ${
                    (selectedItemsCount > 1 || checkeditem["all"]) &&
                    "bulk-actions--btn"
                  }`}
                  disabled={
                    selectedItemsCount > 1 || checkeditem["all"] ? false : true
                  }
                  onClick={handlers.toggle}
                >
                  Bulk actions
                  {(selectedItemsCount > 1 || checkeditem["all"]) && (
                    <span className="bulk-actions__count">
                      (
                      {checkeditem["all"]
                        ? studentdata.length
                        : selectedItemsCount}
                      )
                    </span>
                  )}
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon--arrow"
                  >
                    <path d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z"></path>
                  </svg>
                </button>

                <div
                  className={`bulk__actions ${
                    isOpen ? "" : "bulk__actions--open"
                  }`}
                >
                  <div className="bulk__actions-group">
                    <div
                      className="bulk__actions--btn"
                      style={{ width: "12rem", padding: "0" }}
                    >
                      <div
                        className="menu-item"
                        onClick={() => {
                          selectmenubulkitems("Move");
                        }}
                      >
                        {" "}
                        <div className="menu-icon">
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.129 5.24952L5.106 1.22652L6.1665 0.166016L12 5.99952L6.1665 11.833L5.106 10.7725L9.129 6.74952H0V5.24952H9.129Z"
                              fill="#0E121B"
                            />
                          </svg>
                        </div>{" "}
                        Assign class
                      </div>
                    </div>
                    <div
                      className="bulk__actions--btn"
                      style={{ width: "12rem", padding: "0" }}
                    >
                      <div
                        className="menu-item"
                        onClick={() => {
                          selectmenubulkitems("Move");
                        }}
                      >
                        {" "}
                        <div className="menu-icon">
                          <svg
                            width="15"
                            height="17"
                            viewBox="0 0 15 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.5 10.689V12.2565C6.82119 12.0165 6.09471 11.9429 5.38155 12.0418C4.6684 12.1408 3.9894 12.4094 3.40157 12.8252C2.81375 13.2409 2.33426 13.7916 2.00337 14.4311C1.67249 15.0705 1.49986 15.78 1.5 16.5L1.93842e-07 16.4993C-0.000232586 15.5834 0.209191 14.6797 0.612224 13.8573C1.01526 13.0349 1.6012 12.3157 2.32516 11.7548C3.04912 11.1939 3.89188 10.8061 4.78887 10.6212C5.68585 10.4364 6.61325 10.4593 7.5 10.6883V10.689ZM6 9.75C3.51375 9.75 1.5 7.73625 1.5 5.25C1.5 2.76375 3.51375 0.75 6 0.75C8.48625 0.75 10.5 2.76375 10.5 5.25C10.5 7.73625 8.48625 9.75 6 9.75ZM6 8.25C7.6575 8.25 9 6.9075 9 5.25C9 3.5925 7.6575 2.25 6 2.25C4.3425 2.25 3 3.5925 3 5.25C3 6.9075 4.3425 8.25 6 8.25ZM10.5 12.75V10.125L14.25 13.5L10.5 16.875V14.25H8.25V12.75H10.5Z"
                              fill="#0E121B"
                            />
                          </svg>
                        </div>{" "}
                        Move between class
                      </div>
                    </div>
                    <div
                      className="bulk__actions--btn"
                      style={{ width: "12rem", padding: "0" }}
                    >
                      <div
                        className="menu-item"
                        // onClick={() => {
                        //   selectmenuitems(3, id);
                        // }}
                        onClick={() => {
                          selectmenubulkitems("Delete");
                        }}
                      >
                        <div className="menu-icon">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.75 3.5H15.5V5H14V14.75C14 14.9489 13.921 15.1397 13.7803 15.2803C13.6397 15.421 13.4489 15.5 13.25 15.5H2.75C2.55109 15.5 2.36032 15.421 2.21967 15.2803C2.07902 15.1397 2 14.9489 2 14.75V5H0.5V3.5H4.25V1.25C4.25 1.05109 4.32902 0.860322 4.46967 0.71967C4.61032 0.579018 4.80109 0.5 5 0.5H11C11.1989 0.5 11.3897 0.579018 11.5303 0.71967C11.671 0.860322 11.75 1.05109 11.75 1.25V3.5ZM12.5 5H3.5V14H12.5V5ZM9.0605 9.5L10.3865 10.826L9.326 11.8865L8 10.5605L6.674 11.8865L5.6135 10.826L6.9395 9.5L5.6135 8.174L6.674 7.1135L8 8.4395L9.326 7.1135L10.3865 8.174L9.0605 9.5ZM5.75 2V3.5H10.25V2H5.75Z"
                              fill="#0E121B"
                            />
                          </svg>
                        </div>{" "}
                        Delete Profile
                      </div>
                    </div>
                    {/* {
                      <div
                        className="actions-menu"
                        style={{
                          position: "absolute", // or 'fixed' depending on your use case
                          top: "13rem",
                          left: "18rem",
                          zIndex: 10,
                          transition:
                            "background-color 0.5s ease, color 0.5s ease, transform 0.5s ease",
                        }}
                      >
                        {}
                        {}
                        <div className="menu-item">
                      {" "}
                      <div className="menu-icon">
                        <svg
                          width="14"
                          height="15"
                          viewBox="0 0 14 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.25 13.25H13.75V14.75H0.25V13.25ZM7.75 8.879L12.3032 4.325L13.3638 5.3855L7 11.75L0.63625 5.38625L1.69675 4.325L6.25 8.8775V0.5H7.75V8.879Z"
                            fill="#0E121B"
                          />
                        </svg>
                      </div>{" "}
                      Export Profile
                    </div>
                      </div>
                    } */}
                  </div>
                </div>
              </div>

              <div className="filter__dropdowns">
                {/* <GradesDropdown/> */}
                <CheckBoxDropDown
                  lefticon={<SortIcon />}
                  dropdownname={"Filter by"}
                  handledatachange={handledatachange}
                />
                <CustomDropdownWithCustomStyle
                  defaultText={"Sort By"}
                  icon={<FilterIcon />}
                  items={["Classes↑", "Classes↓"]}
                  width={"fit-content"}
                  dropdownName={"sort"}
                  handledatachange={handledatachange}
                />
                <div style={{ marginTop: "0.5rem" }}>
                  <SearchBar
                    data={totalstudentdata}
                    handlesearchdata={handlesearchdata}
                    placeholder={"Search for Student"}
                  />
                </div>
                {/* <div className="settingbox">
                  <CiSettings size={22} />
                </div> */}
              </div>
            </section>
          )}
          {(loading || onlytableloading) && (
            <div className="StudentDirectoryBodyTable">
              <div className="table-container">
                <div className="table-container-header">
                  <div className="cell-container" style={{ flex: "2" }}>
                    <Skeleton width={"10rem"} height={"100%"} />
                  </div>
                  <div className="cell-container">
                    <Skeleton width={"5rem"} height={"100%"} />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <Skeleton width={"7rem"} height={"100%"} />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <Skeleton width={"8rem"} height={"100%"} />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <Skeleton width={"7rem"} height={"100%"} />
                  </div>
                  {/* <div className="cell-container">
                    <Skeleton width={"2rem"} height={"100%"} />
                  </div> */}
                  <div className="cell-container">
                    <Skeleton width={"3rem"} height={"100%"} />
                  </div>
                </div>

                <div className="table-container-body">
                  {Array.from({ length: 8 }, (_, index) => (
                    <div className="row-container">
                      <div className="cell-container" style={{ flex: "2" }}>
                        <Skeleton width={"10rem"} height={"100%"} />
                      </div>
                      <div className="cell-container">
                        <Skeleton width={"5rem"} height={"100%"} />
                      </div>
                      <div className="cell-container" style={{ flex: "1.5" }}>
                        <Skeleton width={"7rem"} height={"100%"} />
                      </div>
                      <div className="cell-container" style={{ flex: "1.5" }}>
                        <Skeleton width={"8rem"} height={"100%"} />
                      </div>
                      <div className="cell-container" style={{ flex: "1.5" }}>
                        <Skeleton width={"7rem"} height={"100%"} />
                      </div>
                      {/* <div className="cell-container">
                        <Skeleton width={"2rem"} height={"100%"} />
                      </div> */}
                      <div className="cell-container">
                        <Skeleton width={"3rem"} height={"100%"} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {/* <Table columns={columns} data={data} checkbox={true} /> */}

          {!loading && !onlytableloading && (
            <div
              className="StudentDirectoryBodyTable"
              style={{ marginLeft: "0.8rem", paddingRight: "0.35rem" }}
            >
              <div className="table-container">
                <div className="table-container-header">
                  <div className="header-cell-container" style={{ flex: "2" }}>
                    <input
                      type="checkbox"
                      checked={checkeditem["all"]}
                      onChange={() => {
                        handleCheckboxChange("all");
                      }}
                    />
                    Student Name
                  </div>
                  {/* <div className="header-cell-container">Student ID</div> */}
                  <div
                    className="header-cell-container"
                    style={{ flex: "1.5" }}
                  >
                    Class & Section
                  </div>
                  <div
                    className="header-cell-container"
                    style={{ flex: "1.5" }}
                  >
                    Guardian Contact
                  </div>
                  <div
                    className="header-cell-container"
                    style={{ flex: "1.5" }}
                  >
                    Admission Date
                  </div>
                  {/* <div className="header-cell-container">GPA</div> */}
                  <div className="header-cell-container">Actions</div>
                </div>

                <div className="table-container-body">
                  {paginateddata.length > 0 &&
                    // eslint-disable-next-line
                    paginateddata.map((item) => {
                      return (
                        <div className="row-container">
                          <div className="cell-container" style={{ flex: "2" }}>
                            <input
                              type="checkbox"
                              onChange={() => {
                                handleCheckboxChange(item.id);
                              }}
                              checked={checkeditem[item.id]}
                            />
                            <NameAvatar
                              name={item.full_name}
                              image={`https://api.dicebear.com/8.x/initials/svg?seed=${item.full_name}`}
                              id={null}
                              user_id={item.id}
                              endpoint="general"
                            />
                          </div>
                          {/* <div className="cell-container">
                            <div className="StudentId">
                              {item.student_id === null
                                ? "null"
                                : item.student_id}
                            </div>
                          </div> */}
                          <div
                            className="cell-container"
                            style={{ flex: "1.5" }}
                          >
                          {item.class?`${formatRomanNumber(
                                  item.class.split(" ")[1]
                                )}-${item.section}`: <>
                                <AiOutlineInfoCircle size={18} color="rgba(251, 55, 72, 1)"/>
                                Not Assigned
                                </>}
                               
                          </div>
                          <div
                            className="cell-container"
                            style={{ flex: "1.5" }}
                          >
                            {" "}
                            <Details
                              Method={`${item.guardian_first_name} ${item.guardian_last_name}`}
                              ID={item.guardian_contact_number}
                            />
                          </div>
                          <div
                            className="cell-container"
                            style={{ flex: "1.5" }}
                          >
                            {item.admission_date === null
                              ? ""
                              : item.admission_date}
                          </div>

                          {/* <div className="cell-container">9.5</div> */}
                          <div className="cell-container">
                            <ActionsMenu
                              selectmenuitems={selectmenuitems}
                              id={item.id}
                              classname={item.class}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
          <Pagination
            currentPage={curpage}
            totalPages={totalpages}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      {assignclasspopup && (
        <AssignandMoveClassPopup
          dropdownlabeltext="Select a class to assign"
          headertext="Assign Class to Student"
          data={
            bulk ? bulkdataforbulkactions : findstudentitem(actionstudentid)
          }
          setpopup={setassignclasspopup}
          task="assign"
          refreshdata={refreshdata}
          bulk={bulk}
        />
      )}
      {moveclasspopup && (
        <AssignandMoveClassPopup
          findstudentitem
          dropdownlabeltext="Select a class to move"
          headertext="Move Student to another class"
          data={
            bulk ? bulkdataforbulkactions : findstudentitem(actionstudentid)
          }
          bulk={bulk}
          setpopup={setmoveclasspopup}
          task="move"
          refreshdata={refreshdata}
        />
      )}
      {deleteprofilepopup && (
        <DeletePopup
          refreshdata={refreshdata}
          actionstudentids={actionstudentid}
          setpopup={setdeleteprofilepopup}
        />
      )}

      {/* <Classsectiondetailsdropdown/> */}
    </div>
  );
};

export default SchoolStudent;
