import { MoneyToolTip, Tooltip } from "../../../../home/ToolTip";
import { formatNumber, formatPrice } from "../../FeeStructureModule/hooks";
import "./Widget.css";
import loading from "../../../../assets/loading.gif";

export const FinanceWidget = ({
  amountPaid,
  totalAmount,
  concession,
  isLoading,
  role,
}) => {
  const termFee = totalAmount - concession;

  let progressDegree = 0;
  if (termFee > 0) {
    progressDegree = Math.ceil((amountPaid / termFee) * 360);
    if (progressDegree < 0) {
      progressDegree = 0;
    } else if (progressDegree > 360) {
      progressDegree = 360;
    }
  }

  const percentage =
    isNaN(amountPaid) || isNaN(termFee) || termFee === 0
      ? 0
      : Math.ceil((amountPaid / termFee) * 100);

  return (
    <div className="financeWidget">
      <div className="financeWidget-heading">
        <span className="financeWidget-text">Net Collected</span>
        <Tooltip
          TooltipText={
            "The percentage of the total fees collected so far and the amount collected out of the total due amount"
          }
        />
      </div>
      {isLoading ? (
        <img
          className="LoadImage"
          src={loading}
          alt="LoadImage"
          style={{ width: "15%", height: "35%", paddingLeft: "40%" }}
        />
      ) : (
        <div className="financeWidget--container">
          <div className="financeWidget--main">
            <span
              className="circular__progress"
              style={{
                background: `conic-gradient(var(--green-color) ${progressDegree}deg, var(--gray-color-200) 0deg)`,
              }}
            ></span>
            <span className="financeWidget-percent">{percentage} %</span>
          </div>
          {role !== "accountant" && (
            <span className="financeWidget-amount">
              <MoneyToolTip
                className={""}
                ToolTipMoney={formatPrice(Number(amountPaid))}
                money={formatNumber(amountPaid)}
              />{" "}
              out of{" "}
              <MoneyToolTip
                className={""}
                ToolTipMoney={formatPrice(termFee)}
                money={formatNumber(termFee)}
              />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default FinanceWidget;

export const BankingWidget = ({
  amount_paid,
  total_term_fee,
  concession,
  isLoading,
}) => {
  return (
    <div className="financeWidget">
      <div className="financeWidget-heading">
        <span className="financeWidget-text">Outstanding amount</span>
        <Tooltip
          TooltipText={
            "The total amount that is still due from students, which has not yet been collected. Including both pending and overdue fees."
          }
        />
      </div>
      {isLoading ? (
        <img
          className="LoadImage"
          src={loading}
          alt="Loading"
          style={{ width: "15%", height: "35%", paddingLeft: "40%" }}
        />
      ) : (
        <div className="financeWidget--container">
          <>
            <span className="financeWidget-percent">
              <MoneyToolTip
                money={formatNumber(total_term_fee - amount_paid - concession)}
                ToolTipMoney={formatPrice(
                  total_term_fee - amount_paid - concession
                )}
              />{" "}
            </span>
            {/* <span className="financeWidget-date">
              Due by {formatDate(Date.now())}
            </span> */}
          </>
        </div>
      )}
    </div>
  );
};

export const StudentWidget = ({ pending, overdue, completed, isLoading }) => {
  return (
    <div className="financeWidget">
      <div className="financeWidget-heading">
        <span className="financeWidget-text">Outstanding by students</span>
        <Tooltip
          TooltipText={
            "Total number of students who are yet to pay complete fee, brokend down into pending and overdue"
          }
        />
      </div>
      {isLoading ? (
        <img
          className="LoadImage"
          src={loading}
          alt="LoadImage"
          style={{ width: "15%", height: "35%", paddingLeft: "40%" }}
        />
      ) : (
        <div className="widget--container">
          <div className="financeWidget-percent">
            <span>{pending + overdue} </span>
            <span className="total_students">
              of {Number(pending + overdue + completed)}
            </span>
          </div>
          <div className="widget--text">
            <span className="pending-button__indicator"></span>
            {pending} pending
          </div>
          <div className="widget--text">
            <span className="overdue-button__indicator"></span>
            {overdue} overdue
          </div>
        </div>
      )}
    </div>
  );
};
