import "./App.css";
// import { AddFeeDetails } from './components/AddFeeStructure/AddFeeDetails';
// import { DetailsCard } from './components/DetailsCard/DetailsCard';
// import { CreateFeeStructure } from './modules/FeeManagementModule/FeeStructureModule/components/AddFeeStructure/CreateFeeStructure';
// import { CreateFeeStructure } from './components/AddFeeStructure/CreateFeeStructure';
import { CreateFeeStructure } from "./modules/FeeManagementModule/FeeStructureModule/components/CreateFeeStructure";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./auth/Login";
import Layout from "./Layout";
import FeeCollection from "./modules/FeeManagementModule/FeeCollectionModule/FeeCollection";
import FeeStructure from "./modules/FeeManagementModule/FeeStructureModule/FeeStructure";
// import { EditFeeStructure } from "./modules/FeeManagementModule/FeeStructureModule/components/EditFeeStructure";
// import FeeCollectionEmpty from "./modules/FeeManagementModule/FeeCollectionModule/FeeCollection-empty";
// import FeeStructureEmpty from "./modules/FeeManagementModule/FeeStructureModule/FeeStructure-Emtpy";
import FeeDashBoard from "./modules/FeeManagementModule/FeeDashBoard";
import ViewFeeStructure from "./modules/FeeManagementModule/FeeStructureModule/FeeStructure-view";
import StudentLayout from "./modules/SchoolDirectoryModule/StudentLayout";
import UserTransactionLog from "./modules/SchoolDirectoryModule/UserTransactionLog";
import SetupAdminLayout from "./auth/SetupAdminPage/SetupAdminLayout";
import SetupAdmin from "./auth/SetupAdminPage/SetupAdmin";
import SetupSchool from "./auth/SetupAdminPage/SetupSchool";
import SetupAcademic from "./auth/SetupAdminPage/SetupAcademic";
import SetupFinalize from "./auth/SetupAdminPage/SetupFinalize";
import Classroom from "./modules/SchoolDirectoryModule/ClassroomModule/Classroom";
import SchoolDirectoryLayout from "./modules/SchoolDirectoryModule/SchoolDirectoryModule/SchoolDirectoryLayout";
import SchoolStudentEmpty from "./modules/SchoolDirectoryModule/SchoolDirectoryModule/SchoolStudent";
import SchoolSubject from "./modules/SchoolDirectoryModule/SchoolDirectoryModule/SchoolSubject";
import "react-loading-skeleton/dist/skeleton.css";
import SchoolTeacher from "./modules/SchoolDirectoryModule/SchoolDirectoryModule/SchoolTeacher";
import { StudentDirectory } from "./modules/StudentDirectoryModule/StudentDirectory";
import { StudentGeneralInfo } from "./modules/StudentDirectoryModule/StudentGeneralInfo";
import { AddStudent } from "./modules/StudentDirectoryModule/AddStudent/AddStudent";
// import { StaffDirMainPage } from "./modules/StaffDirectoryModule/StaffDirMainPage";
// import { StaffProfileLayout } from "./modules/StaffDirectoryModule/components/StaffProfileLayout";
// import { StaffGeneralInfo } from "./modules/StaffDirectoryModule/components/StaffGeneralInfo";
// import { AddAcademicStaff } from "./modules/StaffDirectoryModule/AddAcademicStaff/AddAcademicStaff";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SetupAdminLayout />}>
          <Route path="setup/admin" element={<SetupAdmin />} />
          <Route path="setup/academic" element={<SetupAcademic />} />
          <Route path="setup/school" element={<SetupSchool />} />
          <Route path="finalise" element={<SetupFinalize />} />
        </Route>
        <Route path="/" element={<Layout />}>
          <Route index element={<div>Dashboard</div>} />
          <Route path="/admission" element={<div>Admission</div>} />
          <Route path="/fee/dashboard" element={<FeeDashBoard />} />
          <Route path="/fee/structure" element={<FeeStructure />} />
          {/* <Route path="/fee/structure/edit" element={<EditFeeStructure/>} /> */}
          <Route path="/fee/collection/" element={<FeeCollection />} />
          <Route path="/student/transactions/:id" element={<StudentLayout />}>
            <Route path="general" element={<StudentGeneralInfo />} />
            <Route path="fee" element={<UserTransactionLog />} />
            <Route path="academics" element={<>Grahith next google</>} />
            <Route
              path="feedback"
              element={<>Chaitu annaya thopu kinguuu kinguuu kinguuu</>}
            />
          </Route>
          <Route path="/staff/transactions/:id" element={<StudentLayout />}>
            <Route path="general" element={<StudentGeneralInfo />} />
            <Route path="fee" element={<UserTransactionLog />} />
            <Route path="academics" element={<>Grahith next google</>} />
            <Route
              path="feedback"
              element={<>Chaitu annaya thopu kinguuu kinguuu kinguuu</>}
            />
          </Route>
          <Route
            path="/fee-structure/details/:id"
            element={<ViewFeeStructure />}
          />
          <Route path="/classroom" element={<Classroom />} />
          <Route
            path="/directory/school/:id/"
            element={<SchoolDirectoryLayout />}
          >
            <Route path="students" element={<SchoolStudentEmpty />} />
            <Route path="teachers" element={<SchoolTeacher />} />
            <Route path="academics" element={<SchoolSubject />} />
          </Route>
          <Route path="/teams" element={<>Teams</>} />
          <Route path="/integrations" element={<>Integrations</>} />
          <Route path="/benefits" element={<>Benefits</>} />
          <Route path="/documents" element={<>Documents</>} />
          <Route path="/settings" element={<>Settings</>} />
          <Route path="/support" element={<>Support</>} />
          {/* Student Directory*/}
          <Route path="/studentdirectory" element={<StudentDirectory />} />
          <Route path="/studentdirectory/addstudent" element={<AddStudent />} />
          {/* Staff Directory */}
          {/* <Route path="/staffdirectory" element={<StaffDirMainPage />} />
          <Route path="/staffprofile" element={<StaffProfileLayout />}>
            <Route path="generalinfo" element={<StaffGeneralInfo />} />
          </Route> 
          <Route
            path="/staffdirectory/addacademicstaff"
            element={<AddAcademicStaff />}
          />*/}
        </Route>
        <Route path="/create/fee" element={<CreateFeeStructure />} />
        <Route
          path="*"
          element={
            <>Under construction your prohibited!!!! english or spanish</>
          }
        />
      </Routes>
      {/* <CreateFeeStructure /> */}
    </div>
  );
}

export default App;
