import React from "react";
import PropTypes from "prop-types";
import "./Modal.css";

const Modal = ({ isOpen, children, className }) => {
  const modalRef = React.useRef(null);

  if (!isOpen) return null;
  return (
    <div className={`modal-overlay ${className || ""}`} ref={modalRef}>
      <div className="modal-content">{children}</div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  handlersOff: PropTypes.any,
};

const ModalHeader = ({ children, className, onClose }) => (
  <header className={`modal-header ${className || ""}`}>
    <div>{children}</div>
    <button className="modal-close" onClick={onClose}>
      &times;
    </button>
  </header>
);

ModalHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const ModalBody = ({ children, className }) => (
  <div className={`modal-body ${className || ""}`}>{children}</div>
);

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const ModalFooter = ({ children, className }) => (
  <div className={`modal-footer ${className || ""}`}>{children}</div>
);

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const ModalTrigger = ({ onClick, children, className, ...props }) => (
  <button
    className={`modal-trigger ${className || ""}`}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);

ModalTrigger.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export { Modal, ModalHeader, ModalBody, ModalFooter, ModalTrigger };
