import React, { useEffect, useRef, useState } from 'react';
import { Feeicon } from './Feeicon';
import { SlArrowRight, SlArrowLeft, SlArrowDown } from "react-icons/sl";
import { FaInfoCircle } from 'react-icons/fa';
import "./AddFeeStructure.css";
import { useFilled, usePopup, useCategories, useDropdown, useConcessionBox, useMoreInfo, useNewFeeType, useConcessionData, useMoreRow, useFirstRender, useIntialiseData } from '../hooks';
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { Tooltip } from "react-tooltip";
import { createFeeComponent, handleContinue, handleconcession, getCategories ,EditFeeComponentDetails} from '../../../../api/api';
import loading from "../../../../assets/loading.gif"
import UserContext from '../../../../context/UserContext';
const saveTosessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

const loadFromsessionStorage = (key, initialValue) => {
  const savedValue = sessionStorage.getItem(key);
  const id=sessionStorage.getItem('fee_component_id');
  
  return id ? savedValue ? JSON.parse(savedValue) : initialValue :  initialValue;

  
}; 

export const AddFeeStructure = ({ handleprogress, value2, handletotal }) => {
  // console.log(value2);
  const [nextpageloading,setnextpageloading]=useState(false);
  const [filled, setFilled] = useFilled(loadFromsessionStorage('filled', 0));
  const [displaycategories,setdisplaycategories]=useState(loadFromsessionStorage('displaycategories', []));
  const [isPopupOpen, setIsPopupOpen] = usePopup(loadFromsessionStorage('isPopupOpen', false));
  const [categories, setcategories] = useCategories(loadFromsessionStorage('categories', []));
  const [toggles, settoggles] = useState(loadFromsessionStorage('toggles', new Array(categories.length + 1).fill({ "gst": false, "surcharge": false })));
  const [isDropdownOpen, setIsDropdownOpen] = useDropdown(loadFromsessionStorage('isDropdownOpen', false));
  const [checkedItems1, setcheckedItems1] = useState(loadFromsessionStorage('checkedItems1', new Array(categories.length + 1).fill(false)));
  const [concessionBox, setConcessionBox] = useConcessionBox(loadFromsessionStorage('concessionBox', false));
  const [moreInfo, setMoreInfo] = useMoreInfo(loadFromsessionStorage('moreInfo', new Array(categories.length + 1).fill(false)));
  const [newFeeType, setNewFeeType] = useNewFeeType(loadFromsessionStorage('newFeeType', ''));
  const [concessiondata, setconcessiondata] = useConcessionData(loadFromsessionStorage('concessiondata', null));
  const [addnewcategoryrow, setaddnewcategoryrow] = useMoreRow(loadFromsessionStorage('addnewcategoryrow', true));
  const [firstRender, setFirstRender] = useFirstRender();
  const [data, setdata] = useIntialiseData(loadFromsessionStorage('data', []));
  const [showbutton, setshowbutton] = useState(false);
  const [newcategory,setnewcategory]=useState(false);
  

  useEffect(() => {
    if (data.length) {
      const allNonZero = data.every(item => item.fee_amount !== 0);
      setshowbutton(allNonZero);
    } else {
      setshowbutton(false);
    }
  }, [data]);

  useEffect(() => {
    saveTosessionStorage('concessiondata', concessiondata);
  }, [concessiondata]);

  useEffect(() => {
    saveTosessionStorage('filled', filled);
  }, [filled]);
  useEffect(() => {
    saveTosessionStorage('isPopupOpen', isPopupOpen);
  }, [isPopupOpen]);

  useEffect(() => {
    saveTosessionStorage('categories', categories);
  }, [categories]);

  useEffect(() => {
    saveTosessionStorage('toggles', toggles);
  }, [toggles]);

  useEffect(() => {
    saveTosessionStorage('isDropdownOpen', isDropdownOpen);
  }, [isDropdownOpen]);

  useEffect(() => {
    saveTosessionStorage('checkedItems1', checkedItems1);
  }, [checkedItems1]);

  useEffect(() => {
    saveTosessionStorage('concessionBox', concessionBox);
  }, [concessionBox]);

  useEffect(() => {
    saveTosessionStorage('moreInfo', moreInfo);
  }, [moreInfo]);

  useEffect(() => {
    saveTosessionStorage('newFeeType', newFeeType);
  }, [newFeeType]);

  useEffect(() => {
    saveTosessionStorage('concessiondata', concessiondata);
  }, [concessiondata]);

  useEffect(() => {
    saveTosessionStorage('addnewcategoryrow', addnewcategoryrow);
  }, [addnewcategoryrow]);

  useEffect(() => {
    saveTosessionStorage('data', data);
  }, [data]);
  useEffect(() => {
    saveTosessionStorage('displaycategories', displaycategories);
  }, [displaycategories]);

  useEffect(() => {
    const loadData = async () => {
      if(!value2)
     { const fetchedCategories = await getCategories();
      setcategories(fetchedCategories);
      console.log("categories ",fetchedCategories);
      setcheckedItems1(loadFromsessionStorage('checkedItems1', new Array(fetchedCategories.length + 1).fill(false)));
      setMoreInfo(loadFromsessionStorage('moreInfo', new Array(fetchedCategories.length + 1).fill(false)));
      settoggles(loadFromsessionStorage('toggles', new Array(fetchedCategories.length + 1).fill({ "gst": false, "surcharge": false })));}
    };
    loadData();
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (!firstRender) {
      if (!isDropdownOpen && filled !== 0) {
        setaddnewcategoryrow(false);
      }
    } else {
      setFirstRender(false);
    }
    // eslint-disable-next-line
  }, [isDropdownOpen]);

  const handleInputChange = (index, field, value) => {
    const newdata1 = data.map(item => {
      if (item.category_id === index) {
        item[field] = Number(value);
        item['gst'] = item['gst'] === 0 ? null : item['gst'];
        item['gst_amount'] = item['gst'] === null ? null : item['fee_amount'] * (item['gst']) / 100;
        item['surcharge'] = item['surcharge'] === 0 ? null : item['surcharge'];
        item['surcharge_amount'] = item['surcharge'] === null ? null : item['fee_amount'] * (item['surcharge']) / 100;
        item['tax_percent'] = item['tax_percent'] === 0 ? null : item['tax_percent'];
        item['tax_amount'] = item['tax_percent'] === null ? null : item['fee_amount'] * (item['tax_percent']) / 100;
      }
      return { ...item };
    });
    setdata(newdata1);
  };

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = async (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  const { userInfo } = React.useContext(UserContext);
  const handlePopup = async (value) => {
    setIsDropdownOpen(false);
    setnewcategory(true);
    if (value === "save") {
      const resdata=await createFeeComponent({ "category": newFeeType, "organization_id":userInfo.organization_id });
      await setcategories(await getCategories());
      const newCheckedItems = {...checkedItems1};
      newCheckedItems[resdata.response.fee_category_id]=false;

      setcheckedItems1(newCheckedItems);
      saveTosessionStorage('checkedItems1', newCheckedItems);
      const newtoggles = {...toggles};
      newtoggles[resdata.response.fee_category_id]={ "gst": false, "surcharge": false };
      // { "gst": false, "surcharge": false }

      settoggles(newtoggles);
      saveTosessionStorage('toggles', newtoggles);
      const newmoreinfo ={...moreInfo};
      newmoreinfo[resdata.response.fee_category_id]=false;
      setMoreInfo(newmoreinfo);
      saveTosessionStorage('moreInfo', newmoreinfo);
    }
    setIsPopupOpen(!isPopupOpen);
  };

  const handletoggels = async (field, index1) => {
    const newtoggles = {...toggles};
    newtoggles[index1][field] = !newtoggles[index1][field];
   
    await settoggles(newtoggles);
  };

  const handleconcessionBox = async () => {
    setConcessionBox(!concessionBox);
  };

  const handlecomponentdelete = async (index) => {
    await setdisplaycategories(prevState => prevState.filter(item => item.id !== index));
    await setdata(prevState => prevState.filter(item => item.category_id !== index));
  };
  const handleClickCheckbox = async (index, value) => {
    console.log(categories)
    const newObject = categories.find(item => item.id === index);
    setdisplaycategories(prevState => [...prevState, newObject]);
    console.log(displaycategories);
    console.log(index, value);
    console.log(checkedItems1);
    
    const newData = {
        "category_id": index,
        "fee_amount": 0,
        "tax_percent": 0,
        "tax_amount": 0,
        "gst": 0,
        "gst_amount": 0,
        "surcharge": 0,
        "surcharge_amount": 0
    };
    
    // Create a copy of checkedItems1
    const updatedCheckedItems = checkedItems1;
    
    if (!updatedCheckedItems[index]) {
        
        updatedCheckedItems[index] = true;
        await setFilled(filled + 1);
        await setdata(prevState => [...prevState, newData]);
    } else {
        
        updatedCheckedItems[index] = false;
        await setFilled(filled - 1);
        await setdata(prevState => prevState.filter(item => item.category_id !== index));
        await setdisplaycategories(prevState => prevState.filter(item => item.id !== index));
    }
   
    await setcheckedItems1(updatedCheckedItems);
    saveTosessionStorage('checkedItems1', checkedItems1);
    console.log(updatedCheckedItems);
    console.log(data);
};


  const handlemoreoption = async(index) => {
    const updatedmoreinfo = {...moreInfo};
    updatedmoreinfo[index]=!updatedmoreinfo[index];
    await setMoreInfo(updatedmoreinfo);
  };
  function findvalue(categoryId,field) {
    console.log(field)
    for (let i = 0; i < data.length; i++) {
        if (data[i].category_id === categoryId) {
          console.log("field data",data[i][field]);
          if(data[i][field]===0)return null;
            return data[i][field];

        }
    }
    return null; // Return null if the category_id is not found
}

  useEffect(() => {
    console.log(categories);
    if(!value2 && !newcategory)
   { console.log(categories.length,"hello");
          const idObject = {};
      categories.forEach(item => {
          idObject[item.id] = false;
      });
      const id2object={};
      categories.forEach(item => {
        id2object[item.id] = { "gst": false, "surcharge": false };
    });

    setcheckedItems1(idObject);
    setMoreInfo(idObject);
    settoggles(id2object);}
    // eslint-disable-next-line
  }, [categories.length]);

  const calculateTotalAmount = () => {
    return data.reduce((total, item) => total + item.fee_amount + (item.gst_amount || 0) + (item.surcharge_amount || 0) + (item.tax_amount || 0), 0);
  };
  if(nextpageloading){
    return <img className='LoadImage' src={loading} alt='LoadImage' style={{width:'50px',height:'50px',paddingTop:'140px'}} />
  }
  return (
    <div className="container">
      <div className="header">
        <div>
          <Feeicon />
        </div>
        <div className="headertextbody">
          <div className="headertext1">
            Define Fee Components
          </div>
          <div className="headertext2">
            Specify fee components, amounts, and applicable taxes.
          </div>
        </div>
      </div>
      <div className="wholegrid">
        <div className="grid-header" style={{ width: '900px' }}>
          <div className="grid-item1">Category</div>
          <div className="grid-item">Fee Amount</div>
          <div className="grid-item">Tax <span className="optional">(optional)</span></div>
          <div className="grid-item">More options</div>
        </div>
        {displaycategories && displaycategories.map((classItem,index) => (
          <div className="tablebody" key={classItem.id} style={{ background: moreInfo[classItem.id] ? 'rgba(253, 253, 253, 1)' : '' }}>
            <div className="grid">
              <div className="grid-item">
                <div className="select-classes-container">
                  <div className="selected-classes1">
                    <div className='componentselect' style={{ marginTop: '14px' }}>
                      <label htmlFor="">{classItem.category}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-item">
                 <input
                type="text"
                  placeholder="Enter amount"
                  pattern="[0-9]*"
                  value={findvalue(classItem.id,"fee_amount")}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    handleInputChange(classItem.id, 'fee_amount', e.target.value);
                  }}
                />
               
              </div>
              <div className="grid-item">
                <input
                  type="text"
                  placeholder="Enter amount"
                  pattern="[0-9]*"
               
                  value={findvalue(classItem.id,"tax_percent")}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    handleInputChange(classItem.id, 'tax_percent', e.target.value);
                  }}
                />
              </div>
              <div className="grid-item">
                {!moreInfo[classItem.id] && <span className="dropdown-icon1"><SlArrowDown size={10} strokeWidth={80} onClick={() => handlemoreoption(classItem.id)} /></span>}
                {moreInfo[classItem.id] && <SlArrowRight size={9} strokeWidth={70} onClick={() => handlemoreoption(classItem.id)} />}
                <svg style={{ marginLeft: '25px', cursor: 'pointer' }} onClick={() => { handlecomponentdelete(classItem.id); handleClickCheckbox(classItem.id, "delete") }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.99956 8.93955L13.7121 5.22705L14.7726 6.28755L11.0601 10.0001L14.7726 13.7126L13.7121 14.7731L9.99956 11.0606L6.28706 14.7731L5.22656 13.7126L8.93906 10.0001L5.22656 6.28755L6.28706 5.22705L9.99956 8.93955Z" fill="#FB3748" />
                </svg>
              </div>
            </div>
            {moreInfo[classItem.id] && <div className='GSTrow' style={{ width: '882px', height: (toggles[classItem.id]["gst"] || toggles[classItem.id]["surcharge"]) ? '' : '40px' }}>
              <div className="gstcol">
                <div className="toggletext">
                  <label className="toggle-switch">
                    <input type="checkbox" checked={toggles[classItem.id]["gst"]} onClick={() => { handletoggels("gst", classItem.id) }} />
                    <span className="slider"></span>
                  </label>
                  Add GST to this category?
                </div>
                {toggles[classItem.id]["gst"] && <div className="grid-item">
                  <input
                    type="text"
                    placeholder="Ex. 8%"
                    pattern="[0-9]*"
                    value={findvalue(classItem.id,"gst")}
                   
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      handleInputChange(classItem.id, 'gst', e.target.value);
                    }}
                  />
                </div>}
              </div>
              <div className="gstcol">
                <div className="toggletext">
                  <label className="toggle-switch">
                    <input type="checkbox" checked={toggles[classItem.id]["surcharge"]} onClick={() => { handletoggels("surcharge", classItem.id) }} />
                    <span className="slider"></span>
                  </label>
                  Add Surcharge
                </div>
                {toggles[classItem.id]["surcharge"] && <div className="grid-item">
                  <input
                    type="text"
                    placeholder="Ex. 8%"
                    pattern="[0-9]*"
                    value={findvalue(classItem.id,"surcharge")}
                    
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      handleInputChange(classItem.id, 'surcharge', e.target.value);
                    }}
                  />
                </div>}
              </div>
            </div>}
          </div>
        ))}
        {addnewcategoryrow && <div>
          <div className="grid" style={{ background: moreInfo[categories.length + 1] ? 'rgba(253, 253, 253, 1)' : '' }}>
            <div className="grid-item">
              <div className="select-classes-container">
                <div className="selected-classes1" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                  <div className='componentselect' style={{ marginTop: '14px' }}>
                    <label htmlFor="">Select a Component</label>
                    <span className="dropdown-icon"><SlArrowDown size={10} strokeWidth={80} /></span>
                  </div>
                </div>
                {isDropdownOpen && (
                  <div className="dropdown-menu" ref={dropdownRef} style={{ width: '300px', height: '296px', marginTop: '-20px' }}>
                    <div className="add-component-button" onClick={handlePopup}>
                      <span className="icon">+</span>
                      <span>
                        Create New Fee Component
                      </span>
                    </div>
                    {categories.map(classItem => (
                      <div key={classItem.id} className="dropdown-item">
                        {!checkedItems1[classItem.id] && (
                          <div className="emptycheckbox" onClick={() => {console.log("handle empty click");handleClickCheckbox(classItem.id, classItem.category)}}>
                          </div>
                        )}
                        {checkedItems1[classItem.id] && (
                          <span className={'checkbox checked'} style={{ marginTop: '4px' }} ></span>
                        )}
                        <div className='dropdownrightelements'>
                          <label style={{ fontWeight: 400, width: '212px' }}>{classItem.category}</label>
                          <div className="righticon1" style={{ marginLeft: '170px' }}>
                          </div>
                        </div>
                      </div>
                    ))}
                    
                  </div>
                )}
              </div>
            </div>
            <div className="grid-item">
              <input
                type="text"
                placeholder="Enter amount"
                pattern="[0-9]*"
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                }}
              />
            </div>
            <div className="grid-item">
              <input
                type="text"
                placeholder="Enter amount"
                pattern="[0-9]*"
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                }}
              />
            </div>
            <div className="grid-item">
              {!moreInfo[categories.length + 1] && <span className="dropdown-icon1" onClick={() => handlemoreoption(categories.length + 1)}><SlArrowDown size={10} strokeWidth={80} /></span>}
              {moreInfo[categories.length + 1] && <SlArrowRight size={9} strokeWidth={70} onClick={() => handlemoreoption(categories.length + 1)} />}
            </div>
          </div>
          {moreInfo[categories.length + 1] && <div className='GSTrow' style={{ width: '882px' }}>
            <div className="gstcol">
              <div className="toggletext">
                <label className="toggle-switch">
                  <input type="checkbox" />
                  <span className="slider"></span>
                </label>
                Add GST to this category?
              </div>
              <div className="grid-item">
                <input
                  type="text"
                  placeholder="Ex. 8%"
                  pattern="[0-9]*"
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                />
              </div>
            </div>
            <div className="gstcol">
              <div className="toggletext">
                <label className="toggle-switch">
                  <input type="checkbox" />
                  <span className="slider"></span>
                </label>
                Add Surcharge
              </div>
              <div className="grid-item">
                <input
                  type="text"
                  placeholder="Enter amount"
                  pattern="[0-9]*"
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                />
              </div>
            </div>
          </div>}
        </div>}
        <div className="total-row">
          <span className='Totalfee'>Calculated Total Fee Amount</span>
          <span className='Notavailable'>{calculateTotalAmount()}</span>
        </div>
      </div>
    
      <div className="concession">
        <label className="toggle-switch">
          <input type="checkbox" onClick={handleconcessionBox} />
          <span className="slider"></span>
        </label>
        <Tooltip
                id="my-tooltip"
                style={{
                  maxWidth: "371px",
                  position: "fixed",
                  marginLeft: "0px",
                }}
              />
        <span className="label1">
        Add max concession to this Fee structure?
          <FaInfoCircle  data-tooltip-id="my-tooltip"
                data-tooltip-content="The total amount that needs to be collected from students."
                data-tooltip-place="right" size={15} className="info-icon" />
        </span>
      </div>
      {concessionBox && <div className="concession-input">
        <input type="text" placeholder="Enter amount" value={concessiondata} onChange={(event) => { setconcessiondata(event.target.value) }} />
      </div>}
      <div className="footer">
        <div className="add-category-button" onClick={() => setaddnewcategoryrow(true)}>
          <span className="icon">+</span>
          <span>Add a Category</span>
        </div>
        <div className='buttons'>
          <div className="backbutton" onClick={() => handleprogress(0,true)}>
            <button type='submit' className='back-button'>
              <div className="lefticon"><SlArrowLeft size={10} strokeWidth={90} /> </div>
              Back
            </button>
          </div>
          {!showbutton && <button type="submit" className="continue-button1" >Save and Continue
            <div>
              <SlArrowRight size={9} strokeWidth={70} />
            </div>
          </button>}
          {showbutton && <button type="submit" className="continue-button1" style={{ background: 'rgba(51, 92, 255, 1)',color: 'rgba(255, 255, 255, 1)' }}  onClick={async(e) => {e.preventDefault();
         setnextpageloading(true);
        handletotal(calculateTotalAmount());
        if (value2) {
          await EditFeeComponentDetails(data);
          concessiondata===null? setconcessiondata(null): await handleconcession({"allowed_concession": 1,
            "concession_percentage":Number(concessiondata)
        });
          handleprogress(2);
      } 
       else {
        handleContinue(handleprogress,"FeeComponentDetails",data);
        concessiondata===null? setconcessiondata(null): await handleconcession({"allowed_concession": 1,
          "concession_percentage":Number(concessiondata)
      });
       }}}>Save and Continue
            <div>
              <SlArrowRight size={9} strokeWidth={70} />
            </div>
          </button>}
        </div>
      </div>
      {isPopupOpen && <div className="popup-overlay">
        <div className="popup">
          <div className="popup-header">
            <span>
              <div className="icon-container">
                <HiOutlinePlusSmall className="icon" />
              </div>
              Add New Fee Component
            </span>
            <button className="close-button" onClick={() => handlePopup("cancel")}>×</button>
          </div>
          <div className="popup-body">
            <label>
              Enter Name of New Fee Component <span>*</span>
            </label>
            <input type="text" placeholder="Ex. Maintenance Fee" onChange={e => setNewFeeType(e.target.value)} />
          </div>
          <div className="popup-footer">
            <button className="cancel-button" onClick={() => handlePopup("cancel")}>Cancel</button>
            <button className="save-button" onClick={() => handlePopup("save")}>Save</button>
          </div>
        </div>
      </div>}
    </div>
  );
};
