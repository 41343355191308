import React, { useState } from "react";
import "./FeeStructure.css";

// import { FaBookOpen } from "react-icons/fa";
// import { RxCross2 } from "react-icons/rx";
// import { Link} from "react-router-dom";
import { AddFeeDetails } from "./AddFeeDetails";
import { CancelButton } from "./CancelButton";
import { RxCross1 } from "react-icons/rx";
import { AddFeeStructure } from "./AddFeeStructure";
import { AddFeeShedule } from "./AddFeeShedule";
import { Previewandfinalise } from "./Previewandfinalise";

// export const  = createContext();
export const CreateFeeStructure = () => {
  // sessionStorage.clear();
  // const navigate = useNavigate();
  const [progress, setprogress] = useState(0);
  const [edit ,setedit] =useState([false,false,false,false])
  const [term,setterm]=useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feedata,setfeedata]=useState();
  const [total,settotal]=useState();
  const [selectedclasssection,setselectedclasssection]=useState([]);
  const handletotal=(value)=>{
    settotal(value);
  }
  const handlefeedata=(value)=>{
    setfeedata(value);
  }
  const handleprogress = (value,value2=false) => {

    if(!edit[value])
    {  const temp=edit;
      temp[value]=value2;
      setedit(temp);}
    console.log(edit);
    setprogress(value);
    console.log(value);
  };
  const handletermdata=(value,selectedclasssection) =>{
    console.log("term data",value);
    setterm(value);
    setselectedclasssection(selectedclasssection);
    // console.log("selected class section ",selectedclasssection);
  }
  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    console.log("Action confirmed");
    setIsModalOpen(false);
  };
  // let step=0;
  // let index=0;
  // let currentStep=0;
  return (
    <div className="CreateFeeStructure">
      <div className="sidebar">
        <div className="sidebarheader">
          <div className="sidebarheadertext">EDIT NEW FEE STRUCTURE</div>
          <div className="stepper">
            <div className={progress === 0 ? "step active" : "step"}>
              <div
                className="circle"
                style={{
                  background: progress > 0 ? "rgba(31, 193, 107, 1)" : "",
                }}
              >
                {progress > 0 ? (
                  <span
                    className={"checkbox checked"}
                    style={{ background: "rgba(31, 193, 107, 1)" }}
                  ></span>
                ) : (
                  1
                )}
              </div>
              <div className="label" style={{cursor:progress > 0 ?'pointer':''}} onClick={()=>{progress > 0 ?handleprogress(0,true):setprogress(progress)}}>Enter Fee Details</div>
            </div>
            <div className={progress === 1 ? "step active" : "step"}>
              <div
                className="circle"
                style={{
                  background: progress > 1 ? "rgba(31, 193, 107, 1)" : "",
                }}
              >
                {progress > 1 ? (
                  <span
                    className={"checkbox checked"}
                    style={{ background: "rgba(31, 193, 107, 1)" }}
                  ></span>
                ) : (
                  2
                )}
              </div>
              <div className="label" style={{display: "flex",cursor:progress > 1 ?'pointer':''}} onClick={()=>{progress > 1 ?handleprogress(1,true):setprogress(progress,true)}}>
                {" "}
                Add Fee Components
              </div>
            </div>
            <div className={progress === 2 ? "step active" : "step"}>
              <div
                className="circle"
                style={{
                  background: progress > 2 ? "rgba(31, 193, 107, 1)" : "",
                }}
              >
                {progress > 2 ? (
                  <span
                    className={"checkbox checked"}
                    style={{ background: "rgba(31, 193, 107, 1)" }}
                  ></span>
                ) : (
                  3
                )}
              </div>

              <div className="label" style={{cursor:progress > 2 ?'pointer':''}} onClick={()=>{progress > 2 ?handleprogress(2,true):setprogress(progress)}}>Set Fee Schedule</div>
            </div>
            <div className={progress === 3 ? "step active" : "step "}>
              <div
                className="circle"
                style={{
                  background: progress > 3 ? "rgba(31, 193, 107, 1)" : "",
                }}
              >
                {progress > 3 ? (
                  <span
                    className={"checkbox checked"}
                    style={{ background: "rgba(31, 193, 107, 1)" }}
                  ></span>
                ) : (
                  4
                )}
              </div>

              <div className="label">Preview and Finalize</div>
            </div>
          </div>
        </div>

        {/* <div className="helpbox">
          <div className="helpboxheader">
            <FaBookOpen size={20} />
            Need Help
            <div className="crossmark">
              <RxCross2 size={17} />
            </div>
          </div>
          <div className="helpboxbody">
            Learn how to
            <span className="subtext"> Create a Fee Structure </span>
            with our guide
          </div>

          <div className="helpbodyfooter">
            <Link to="/docs" style={{ color: "#335CFF" }}>
              Go to Docs
            </Link>
          </div>
        </div> */}
      </div>
      <div
        style={{
          flex: "1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginLeft: "272px",
          // marginTop: '20px',
          position: "relative",
          // background:'red'
        }}
      >
        {/* hhhh */} 
        {progress === 0 && <AddFeeDetails handleprogress={handleprogress} handletermdata={handletermdata} value2={edit[0]}/>}
        {progress === 1 && <AddFeeStructure handleprogress={handleprogress} value2={edit[1]} handletotal={handletotal}/>}
        {progress === 2 && <AddFeeShedule handleprogress={handleprogress} terms={term} value2={edit[2]} handlefeedata={handlefeedata} total={total}/>}
        {progress === 3 && (
          <Previewandfinalise total={total} handleprogress={handleprogress} feedata={feedata} selectedclasssection={selectedclasssection}/>
        )}
      </div>
      <div style={{ padding: "20px"
       }}>
        {/* <h1>React Confirmation Modal Example</h1> */}
        <button onClick={handleModal} style={{cursor:'pointer'}}className="Cancel">
          Cancel <RxCross1 className="crossicon" size={11} strokeWidth={0.5} />
        </button>
        <CancelButton
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={handleConfirm}
        />
      </div>
    </div>
  );
};
