import React, { useEffect, useState } from "react";
import "./EditDetailsSlider.css";
// import { SlArrowDown } from "react-icons/sl";
// import CustomDropdown from "../../FeeManagementModule/components/Custom-Dropdown";
import { Classsectiondetailsdropdown } from "./Classsectiondetailsdropdown";
import { CustomDropdownWithCustomStyle } from "./CustomDropdownWithCustomStyle";
import DateSelector from "./DateDropDown";
import { editstudentinfo } from "../../../api/api";
// import { useNavigate } from "react-router-dom";

export const StudentInfoEditSilder = ({ data, sliderstate }) => {
  // const navigate = useNavigate();
  // const [loading, setloading] = useState(true);
  // const [enrollstatus, setenrollstatus] = useState("Not Active");
  const [editdata, editsetdata] = useState([]);
  useEffect(() => {
    try {
      // setloading(true);
      editsetdata(data);
      // setenrollstatus(data.enrolment_status)
    } catch (error) {
      // setloading(false);
    }
  }, [data]);
  // useEffect(() => {
  //   setenrollstatus(editdata.enrolment_status);
  //   console.log(enrollstatus);
  // }, [editdata.enrolment_status]);

  const handledatachange = async (key, value) => {
    let temp = { ...editdata };
    console.log(key, value);
    // let temp = { ...editdata };
    temp[key] = value;

    console.log(temp);
    await editsetdata(temp);
  };
  const handlesavechanges = async () => {
    console.log(editdata);
    let tempdata = {
      user_id: editdata.id,
      first_name: editdata.first_name,
      last_name: editdata.last_name,
      pen_number: editdata.pen_number,
      admission_date: editdata.admission_date,
      class_section_mapping_id: editdata.class_section_mapping_id,
      enrolment_status: editdata.enrolment_status,
    };
    console.log(tempdata);
    await editstudentinfo(tempdata);
    // navigate("/student/transactions//general");
    sliderstate(false);
  };
  //  loading && "aaa"
  return (
    <>
      <div className="EditSlider">
        <div className="EditSliderdiv">
          <div className="EditSliderdivheader">
            Edit Student Info
            <svg
              onClick={() => {
                sliderstate(false);
              }}
              style={{ cursor: "pointer" }}
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <path
                d="M4.99999 3.93906L8.71249 0.226562L9.77299 1.28706L6.06049 4.99956L9.77299 8.71206L8.71249 9.77256L4.99999 6.06006L1.28749 9.77256L0.22699 8.71206L3.93949 4.99956L0.22699 1.28706L1.28749 0.226562L4.99999 3.93906Z"
                fill="#525866"
              ></path>
            </svg>
          </div>
          <div className="StudentInfoEditSilderdivbody">
            <div style={{ display: "flex", gap: "1rem" }}>
              <div className="Details">
                First Name
                <input
                  type="text"
                  value={editdata.first_name}
                  className="inputbox"
                  placeholder="Chaitanya"
                  onChange={(event) => {
                    handledatachange("first_name", event.target.value);
                  }}
                />
              </div>
              <div className="Details">
                Last Name
                <input
                  type="text"
                  value={editdata.last_name}
                  className="inputbox"
                  placeholder="Rao"
                  onChange={(event) => {
                    handledatachange("last_name", event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="Details">
              <label style={{ display: "flex" }}>
                Select Class & Section{" "}
                <div
                  className="mandatory"
                  style={{ color: "rgba(251, 55, 72, 1)" }}
                >
                  *
                </div>
              </label>
              <Classsectiondetailsdropdown
                defaultText={
                  editdata.class
                    ? `${editdata.class}-${editdata.section}`
                    : "Select a Class-Section"
                }
                handledatachange={handledatachange}
                // field={}
              />
            </div>
            {/* <div className="Details">
              <label style={{ display: "flex" }}>
                Roll Number
                <div
                  className="mandatory"
                  style={{ color: "rgba(251, 55, 72, 1)" }}
                >
                  *
                </div>
              </label>
              <input
                type="text"
                className="inputbox"
                style={{ width: "32.5rem" }}
                placeholder="pls dont enternot present in table"
              />
            </div> */}
            <div className="Details">
              <label style={{ display: "flex" }}>
                Pen Number
                <div
                  className="mandatory"
                  style={{ color: "rgba(251, 55, 72, 1)" }}
                >
                  *
                </div>
              </label>
              <input
                type="text"
                className="inputbox"
                value={editdata.pen_number}
                style={{ width: "32.5rem" }}
                placeholder="123456789"
                onChange={(event) => {
                  handledatachange("pen_number", event.target.value);
                }}
              />
            </div>
            <div className="Details">
              <label style={{ display: "flex" }}>
                Admission Date
                <div
                  className="mandatory"
                  style={{ color: "rgba(251, 55, 72, 1)" }}
                >
                  *
                </div>
              </label>
              <DateSelector
                handledatachange={handledatachange}
                field={"admission_date"}
                width={"32.5rem"}
                value={editdata.admission_date}
              />
            </div>
            <div className="Details">
              <label style={{ display: "flex" }}>
                Enrollement Status
                <div
                  className="mandatory"
                  style={{ color: "rgba(251, 55, 72, 1)" }}
                >
                  *
                </div>
              </label>
              <CustomDropdownWithCustomStyle
                defaultText={editdata.enrolment_status}
                // icon={<FilterIcon />}
                handledatachange={handledatachange}
                items={["Active", "Not Active"]}
                width={"32.5rem"}
                dropdownName={"enrolment_status"}
              />
            </div>
            {/* <div className="Details">
          <label style={{ display: "flex" }}>
            Siblings
            <div style={{ color: "rgba(153, 160, 174, 1)" }}>(Optional)</div>
            <div
              className="mandatory"
              style={{ color: "rgba(51, 92, 255, 1)" }}
            >
              *
            </div>
          </label>
          <div className="select-classes-container">
            <div
              className="AcademicDropdown"
              // onClick={() => setIsDropdownOpen2(!isDropdownOpen2)}
              style={{ marginBottom: "0" }}
            >
              {"Search or select for a student"}
              <span className="dropdown-icon1">
                <SlArrowDown size={10} strokeWidth={78} />
              </span>
            </div>
            {isDropdownOpen2 && (
                <div className="dropdown-menu" ref={dropdownRef}>
                  {terms.map((termName, index) => (
                    <div key={index} className="dropdown-item">
                      <label
                        style={{ fontWeight: 400, width: "212px" }}
                        onClick={() => {
                          handleOptionSelect(termName);
                        }}
                      >
                        {termName}{" "}
                      </label>
                    </div>
                  ))}
                </div>
              )} 
          </div>
        </div> */}
          </div>
          <div className="footerbutton">
            <div
              className="cancel"
              //   onClick={() => {
              //     buttonstate(false);
              //   }}
            >
              Cancel
            </div>
            <div
              className="Proceed"
              //   onClick={() => {
              //     navigate("./createquestions");
              //   }}
              onClick={() => {
                console.log("hello continuing");
                handlesavechanges();
              }}
            >
              Save Changes
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
