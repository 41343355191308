import React from "react";
import "./FeeTransactionTable.css";
import { NameAvatar } from "./FeeCollectionModule/components/NameAvatar";
import { StudentClass } from "./FeeCollectionModule/components/StudentClass";
import AmountPaid from "./FeeCollectionModule/components/AmountPaid";
import { TermsPaid } from "./FeeCollectionModule/components/TermsPaid";
import PaymentMethod from "./FeeCollectionModule/components/PaymentMethod";
// import loading from "../../assets/loading.gif";
import { SecondaryButton } from "./components/Button";
import { getReceiptDetails } from "../../api/api";
import PrintPDF from "./FeeCollectionModule/components/Receipt";
import { toast } from "sonner";
import Skeleton from "react-loading-skeleton";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableHeader,
//   TableRow,
// } from "../FeeManagementModule/components/Table";

export const FeeTransactionTable = ({
  annualData,
  annual,
  transactionData,
  isLoading,
}) => {
  const handlePrintPDF = async (receiptData) => {
    try {
      await getReceiptDetails(receiptData).then((res) => {
        PrintPDF(res.response);
      });
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Error generating PDF");
    }
  };

  return (
    <>
      {isLoading && (
        <div className="StudentDirectoryBodyTable">
          <div className="table-container">
            <div className="table-container-header">
              <div className="cell-container" style={{ flex: "2" }}>
                <Skeleton width={"10rem"} height={"100%"} />
              </div>
              <div className="cell-container">
                <Skeleton width={"5rem"} height={"100%"} />
              </div>
              <div className="cell-container" style={{ flex: "1.5" }}>
                <Skeleton width={"7rem"} height={"100%"} />
              </div>
              <div className="cell-container" style={{ flex: "1.5" }}>
                <Skeleton width={"8rem"} height={"100%"} />
              </div>
              <div className="cell-container" style={{ flex: "1.5" }}>
                <Skeleton width={"7rem"} height={"100%"} />
              </div>
              {/* <div className="cell-container">
                    <Skeleton width={"2rem"} height={"100%"} />
                  </div> */}
              <div className="cell-container">
                <Skeleton width={"3rem"} height={"100%"} />
              </div>
            </div>

            <div className="table-container-body">
              {Array.from({ length: 8 }, (_, index) => (
                <div className="row-container">
                  <div className="cell-container" style={{ flex: "2" }}>
                    <Skeleton width={"10rem"} height={"100%"} />
                  </div>
                  <div className="cell-container">
                    <Skeleton width={"5rem"} height={"100%"} />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <Skeleton width={"7rem"} height={"100%"} />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <Skeleton width={"8rem"} height={"100%"} />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <Skeleton width={"7rem"} height={"100%"} />
                  </div>
                  {/* <div className="cell-container">
                        <Skeleton width={"2rem"} height={"100%"} />
                      </div> */}
                  <div className="cell-container">
                    <Skeleton width={"3rem"} height={"100%"} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {!isLoading && (
        <div className="table-container">
          <div className="row-container1" style={{ padding: "0.5rem",borderBottom:'1px solid var(--stroke-soft-200, rgba(225, 228, 234, 1))' }}>
            <div
              className="cell-container"
              style={{
                fontSize: "0.9rem",
                fontWeight: 500,
                lineHeight: "1.25rem",
                letterSpacing: "-0.006em",
                textAlign: "left",
              }}
            >
              Transaction Log
            </div>
          </div>
          <div className="table-container-header">
            <div className="header-cell-container" style={{ flex: "1.8" }}>
              Student Name
            </div>
            <div className="header-cell-container" style={{ flex: "1" }}>
              Class
            </div>
            <div className="header-cell-container" style={{ flex: "1.2" }}>
              Amount Paid
            </div>
            <div className="header-cell-container" style={{ flex: "1.5" }}>
              Terms Paid
            </div>
            <div className="header-cell-container" style={{ flex: "1.5" }}>
              Payment Method
            </div>
            <div className="header-cell-container" style={{ flex: "1.5" }}>
              Received by & Date
            </div>
            <div
              className="header-cell-container"
              style={{ display: "flex", flex: "1", justifyContent: "center" }}
            >
              Actions
            </div>
          </div>

          <div className="table-container-body">
            {annual &&
              annualData.map((item, index) => (
                <div key={index} className="row-container">
                  <div className="cell-container" style={{ flex: "1.8" }}>
                    <NameAvatar
                      name={item.full_name}
                      image={`https://api.dicebear.com/8.x/initials/svg?seed=${item.full_name}`}
                      id={item.student_id}
                      user_id={item.user_id}
                    />
                  </div>
                  <div className="cell-container" style={{ flex: "1" }}>
                    <StudentClass
                      class_Name={item.class}
                      section_name={item.section}
                    />
                  </div>
                  <div className="cell-container" style={{ flex: "1.2" }}>
                    <AmountPaid
                      AmountPaid={item.amount_paid}
                      Term={item.term}
                    />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <TermsPaid
                      termsPaid={item.terms_completed}
                      totalTerms={item.total_terms}
                    />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <PaymentMethod
                      Method={item.payment_method}
                      ID={item.reference_id}
                    />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <PaymentMethod
                      Method={item.receiver_name}
                      ID={item.received_date}
                    />
                  </div>
                  <div
                    className="cell-container"
                    style={{
                      display: "flex",
                      flex: "1",
                      justifyContent: "center",
                    }}
                  >
                    <SecondaryButton
                      onClick={() => handlePrintPDF(item.receipt_id)}
                      style={{ padding: "0.4rem" }}
                    >
                      Download
                    </SecondaryButton>
                  </div>
                </div>
              ))}
            {!annual &&
              transactionData.map((item, index) => (
                <div key={index} className="row-container">
                  <div className="cell-container" style={{ flex: "1.8" }}>
                    <NameAvatar
                      name={item.full_name}
                      image={`https://api.dicebear.com/8.x/initials/svg?seed=${item.full_name}`}
                      id={item.student_id}
                      user_id={item.user_id}
                    />
                  </div>
                  <div className="cell-container" style={{ flex: "1" }}>
                    <StudentClass
                      class_Name={item.class}
                      section_name={item.section}
                    />
                  </div>
                  <div className="cell-container" style={{ flex: "1.2" }}>
                    <AmountPaid
                      AmountPaid={item.amount_paid}
                      Term={item.term}
                    />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <TermsPaid
                      termsPaid={item.terms_completed}
                      totalTerms={item.total_terms}
                    />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <PaymentMethod
                      Method={item.payment_method}
                      ID={item.reference_id}
                    />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <PaymentMethod
                      Method={item.receiver_name}
                      ID={item.received_date}
                    />
                  </div>
                  <div
                    className="cell-container"
                    style={{
                      display: "flex",
                      flex: "1",
                      justifyContent: "center",
                    }}
                  >
                    <SecondaryButton
                      onClick={() => handlePrintPDF(item.receipt_id)}
                      style={{ padding: "0.4rem" }}
                    >
                      Download
                    </SecondaryButton>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      {/* <Table className="fee-collection__table">
        <TableHeader className="fee-collection__table-header">
          <TableRow className="transaction__log--heading">
            <TableCell colSpan={7} style={{ textAlign: "left" }}>
              Transaction Log
            </TableCell>
          </TableRow>
          <TableRow className="fee-collection__table-row">
            <TableHead className="fee-collection__table-heading">
              Student Name{" "}
            </TableHead>
            <TableHead className="fee-collection__table-heading">
              Class
            </TableHead>
            <TableHead className="fee-collection__table-heading">
              Amount Paid
            </TableHead>
            <TableHead className="fee-collection__table-heading">
              Terms Paid
            </TableHead>
            <TableHead className="fee-collection__table-heading">
              Payment Method
            </TableHead>
            <TableHead className="fee-collection__table-heading">
              Received by & Date{" "}
            </TableHead>
            <th>Actions</th>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isLoading ? (
            <img
              className="LoadImage"
              src={loading}
              alt="LoadImage"
              style={{
                width: "70px",
                height: "70px",
                paddingLeft: "520px",
                paddingTop: "40px",
              }}
            />
          ) : (
            annual &&
            annualData.map((item, index) => (
              <TableRow key={index} className="fee-collection__table-row">
                <TableCell className="fee-collection__table-data">
                  <NameAvatar
                    name={item.full_name}
                    image={`https://api.dicebear.com/8.x/initials/svg?seed=${item.full_name}`}
                    id={item.student_id}
                    user_id={item.user_id}
                  />
                </TableCell>
                <TableCell className="fee-collection__table-data">
                  <StudentClass
                    class_Name={item.class}
                    section_name={item.section}
                  />
                </TableCell>
                <TableCell className="fee-collection__table-data">
                  <AmountPaid AmountPaid={item.amount_paid} Term={item.term} />
                </TableCell>
                <TableCell className="fee-collection__table-data">
                  <TermsPaid
                    termsPaid={item.terms_completed}
                    totalTerms={item.total_terms}
                  />
                </TableCell>
                <TableCell className="fee-collection__table-data">
                  <PaymentMethod
                    Method={item.payment_method}
                    ID={item.reference_id}
                  />
                </TableCell>
                <TableCell className="fee-collection__table-data">
                  <PaymentMethod
                    Method={item.receiver_name}
                    ID={item.received_date}
                  />
                </TableCell>
                <TableCell>
                  <SecondaryButton
                    onClick={() => handlePrintPDF(item.receipt_id)}
                    style={{
                      padding: "0.4rem",
                    }}
                  >
                    Download
                  </SecondaryButton>
                </TableCell>
              </TableRow>
            ))
          )}
          {!annual &&
            transactionData?.map((item, index) => (
              <TableRow key={index} className="fee-collection__table-row">
                <TableCell className="fee-collection__table-data">
                  <NameAvatar
                    name={item.full_name}
                    image={`https://api.dicebear.com/8.x/initials/svg?seed=${item.full_name}`}
                    id={item.student_id}
                  />
                </TableCell>
                <TableCell className="fee-collection__table-data">
                  <StudentClass
                    class_Name={item.class}
                    section_name={item.section}
                  />
                </TableCell>
                <TableCell className="fee-collection__table-data">
                  <AmountPaid AmountPaid={item.amount_paid} Term={item.term} />
                </TableCell>
                <TableCell className="fee-collection__table-data">
                  <TermsPaid
                    termsPaid={item.terms_completed}
                    totalTerms={item.total_terms}
                  />
                </TableCell>
                <TableCell className="fee-collection__table-data">
                  <PaymentMethod
                    Method={item.payment_method}
                    ID={item.reference_id}
                  />
                </TableCell>
                <TableCell className="fee-collection__table-data">
                  <PaymentMethod
                    Method={item.receiver_name}
                    ID={item.received_date}
                  />
                </TableCell>
                <TableCell>
                  <SecondaryButton
                    onClick={() => handlePrintPDF(item.receipt_id)}
                    style={{
                      padding: "0.4rem",
                    }}
                  >
                    Download
                  </SecondaryButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table> */}
    </>
  );
};
