import { formatPriceWithExponential } from "../../FeeStructureModule/hooks";

export const DueAmount = ({ amount, total, concession }) => {
  return (
    <div className="due-amount-container">
      <span className="amount">
        {formatPriceWithExponential(
          Number(total) - Number(concession) - Number(amount)
        )}
      </span>
      <span className="total">
        out of {formatPriceWithExponential(total - concession)}
      </span>
    </div>
  );
};

export const RemindDueAmount = ({ amount, total, concession }) => {
  const formattedAmount = formatPriceWithExponential(Number(amount));
  const formattedTotal = formatPriceWithExponential(
    Number(total) - Number(concession)
  );

  return (
    <div className="remind-due-amount-container">
      <span className="remind-amount">
        {formattedAmount} out of {formattedTotal}
      </span>
    </div>
  );
};
