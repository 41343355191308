import React, { useEffect, useState } from "react";
import "./FeeDashBoard.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Tooltip } from "react-tooltip";

import {
  getFeeDashBoardStats,
  getAllTerms,
  getTransactionDetails,
} from "../../api/api";
import { FeeTransactionTable } from "./FeeTransactionTable";
import RouteHeader from "./components/RouteHeader";
import { useOutsideClick } from "./FeeStructureModule/hooks";
import Pagination from "../../home/Pagination";
import UserContext from "../../context/UserContext";
// import loading from "../../assets/loading.gif";

const FeeDashBoard = () => {
  const [maxTerms, setMaxTerms] = useState();
  const dropdownRef = React.useRef(null);
  // const today = new Date();
  // const monthsOfYear = [
  //   "Jan",
  //   "Feb",
  //   "Mar",
  //   "Apr",
  //   "May",
  //   "Jun",
  //   "Jul",
  //   "Aug",
  //   "Sep",
  //   "Oct",
  //   "Nov",
  //   "Dec",
  // ];
  const { userInfo } = React.useContext(UserContext);
  const [activeTab, setActiveTab] = useState("Annual");
  const [terms, setTerms] = useState(maxTerms);
  const [statsData, setStatsData] = useState({
    totalAmountStats: {
      total_amount_paid: "NaN",
      total_term_fee: "NaN",
      total_term_concession: "NaN",
    },
    countsStats: {
      completed_count: "NaN",
      pending_count: "NaN",
      overdue_count: "NaN",
    },

    totalAmountFilterStats: {
      today_amount_paid: "NaN",
      last_week_amount_paid: "NaN",
      last_month_amount_paid: "NaN",
    },
  });

  const pageSize = 6;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [annualData, setAnnualData] = useState({ transactions: [], count: 0 });
  const [transactionData, setTransactionData] = useState({
    transactions: [],
    count: 0,
  });
  const [paginatedAnnualData, setPaginatedAnnualData] = useState([]);
  const [paginatedTransactionData, setPaginatedTransactionData] = useState([]);

  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      if (userInfo.branch_id !== null && userInfo.branch_id !== undefined) {
        let annual = activeTab === "Annual" ? true : false;

        if (!annual) {
          try {
            const data = await getTransactionDetails(
              terms,
              annual,
              userInfo.branch_id,
              activeTab
            );
            setTransactionData(data);
          } catch (error) {
            console.error("Error fetching transaction details:", error);
          } finally {
            setIsLoading(false);
          }
        } else {
          try {
            const d1 = await getTransactionDetails(
              terms,
              annual,
              userInfo.branch_id,
              activeTab
            );
            setAnnualData(d1);
            console.log("annual", d1);
          } catch (error) {
            console.error("Error fetching annual transaction details:", error);
          } finally {
            setIsLoading(false);
          }
        }
      }
    };

    loadData();
  }, [terms, activeTab, userInfo.branch_id]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  let count;
  if (activeTab === "Annual") {
    count = annualData.count;
  } else {
    count = transactionData.count;
  }
  const totalPages = Math.ceil(count / pageSize);

  React.useEffect(() => {
    const loadData = async () => {
      const res = await getAllTerms(userInfo.branch_id);
      setMaxTerms(res.response.max_terms);
    };

    if (userInfo.branch_id !== null && userInfo.branch_id !== undefined) {
      loadData();
    }
  }, [userInfo.branch_id]);
  useEffect(() => {
    setTerms(maxTerms);
  }, [maxTerms]);

  useEffect(() => {
    if (activeTab === "Annual" && annualData.transactions) {
      setPaginatedAnnualData(
        annualData.transactions.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize
        )
      );
    } else if (transactionData.transactions) {
      setPaginatedTransactionData(
        transactionData.transactions.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize
        )
      );
    }
  }, [activeTab, annualData, transactionData, currentPage, pageSize]);
  const convertNumberToKOrLac = (number) => {
    if (number === null) return "0";
    if (number >= 10000000) {
      return (number / 10000000).toFixed(2) + " Cr";
    } else if (number >= 100000) {
      return (number / 100000).toFixed(2) + " L";
    } else if (number >= 1000) {
      return (number / 1000).toFixed(2) + " K";
    } else {
      return number.toString();
    }
  };
  useEffect(() => {
    const loadData = async () => {
      try {
        if (terms) {
          const data = await getFeeDashBoardStats(terms, userInfo.branch_id);
          setStatsData(data);
          if (data.length === 0)
            setStatsData({
              totalAmountStats: {
                total_amount_paid: "NaN",
                total_term_fee: "NaN",
                total_term_concession: "NaN",
              },
              countsStats: {
                completed_count: "NaN",
                pending_count: "NaN",
                overdue_count: "NaN",
              },

              totalAmountFilterStats: {
                today_amount_paid: "NaN",
                last_week_amount_paid: "NaN",
                last_month_amount_paid: "NaN",
              },
            });
        }
      } catch {
        console.log("Error");
      }
    };

    loadData();
  }, [terms, userInfo.branch_id]);

  const findRange = (value) => {
    if (!statsData || !statsData.groupedStats || !statsData.groupedStats.length)
      return "NaN";
    let minClass = "NaN";
    let maxClass = "NaN";
    const classNumbers = statsData.groupedStats.map((item, index) => {
      const classNumber = parseInt(item.class.split(" ")[1], 10);
      return { name: item.class, number: classNumber };
    });

    minClass = classNumbers.reduce((prev, current) =>
      prev.number < current.number ? prev : current
    );
    maxClass = classNumbers.reduce((prev, current) =>
      prev.number > current.number ? prev : current
    );
    console.log(minClass);
    if (value === "min") return `Class ${minClass.number}`;
    else return `Class ${maxClass.number}`;
  };
  const [termDropdown, setTermDropdown] = useState(false);
  const [tabs] = useState(["Day", "Weekly", "Monthly", "Term", "Annual"]);

  useOutsideClick(dropdownRef, () => {
    setTermDropdown(false);
  });

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <RouteHeader
        routeHeader="Fee Management"
        routes={[{ name: "Fee DashBoard", link: "/" }]}
        search
      />
      <div className="tabs" style={{ marginTop: "1.25rem", marginLeft: "0.6875rem" }}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab ${activeTab === tab ? "active" : ""}`}
            onClick={() => {
              setActiveTab(tab);
              if (index === 3) setTermDropdown(!termDropdown);
              if (index === 4) setTerms(maxTerms);
            }}
          >
            {tab}
          </div>
        ))}
        {termDropdown && (
          <div
            className="dropdown-content"
            ref={dropdownRef}
            style={{ marginLeft: '17%', marginTop: "2.5rem" }}
          >
            {Array.from({ length: maxTerms }, (_, i) => (
              // eslint-disable-next-line
              <div
              className="dropdown-content-item"
                key={i}
                href="#"
                onClick={() => {
                  setTerms(i + 1);
                  tabs[3] = `Term ${i + 1}`;
                  setActiveTab(`Term ${i + 1}`);
                  setTermDropdown(false);
                }}
              >
                Term {i + 1}
              </div>
            ))}
          </div>
        )}
      </div>
      <div
        className="headercards"
        style={{ display: "flex", gap: "0.7rem", marginTop: "1.5rem" }}
      >
        <div className="fee-container">
          <div className="header">
            Overall Fee Collected
            <div className="icon">
              <Tooltip
                id="my-tooltip"
                style={{
                  maxWidth: "371px",
                  position: "absolute",
                  marginLeft: "0px",
                }}
              />
              <Tooltip
                id="my-tooltip-bar"
                style={{
                  maxWidth: "371px",
                  position: "absolute",
                  marginLeft: "0px",
                  height: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              />
              <Tooltip
                id="my-tooltip1"
                style={{
                  maxWidth: "371px",
                  position: "absolute",
                  marginLeft: "0px",
                }}
              />
              <svg
                data-tooltip-id="my-tooltip1"
                data-tooltip-content="The total fee amount that is collected"
                data-tooltip-place="right"
                width="16"
                height="16"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7 13.25C10.4518 13.25 13.25 10.4518 13.25 7C13.25 3.54822 10.4518 0.75 7 0.75C3.54822 0.75 0.75 3.54822 0.75 7C0.75 10.4518 3.54822 13.25 7 13.25ZM8.11584 10.2086L8.21565 9.8006C8.16399 9.82486 8.0807 9.85258 7.96649 9.88414C7.85197 9.9157 7.7489 9.93177 7.65831 9.93177C7.46536 9.93177 7.32952 9.90014 7.25065 9.83657C7.17236 9.773 7.13331 9.65342 7.13331 9.47827C7.13331 9.40887 7.14509 9.30542 7.16973 9.17003C7.19361 9.03373 7.22108 8.91261 7.25161 8.80666L7.62419 7.48759C7.66068 7.36653 7.68571 7.23344 7.69916 7.08818C7.71292 6.94325 7.71932 6.84185 7.71932 6.78429C7.71932 6.50614 7.62182 6.28041 7.42676 6.10629C7.2317 5.93229 6.95393 5.84529 6.59396 5.84529C6.39365 5.84529 6.18188 5.88088 5.95776 5.952C5.73363 6.02294 5.49933 6.1084 5.25421 6.2082L5.15415 6.6165C5.22719 6.58949 5.31419 6.56043 5.41598 6.53034C5.51732 6.50038 5.61674 6.48489 5.71347 6.48489C5.91096 6.48489 6.04399 6.51856 6.1137 6.58488C6.18342 6.65139 6.21844 6.7697 6.21844 6.93883C6.21844 7.03236 6.20736 7.13626 6.18438 7.24919C6.16172 7.36282 6.13342 7.48298 6.10013 7.6098L5.72595 8.93419C5.69266 9.07336 5.66834 9.19787 5.65304 9.30843C5.63786 9.41886 5.63057 9.52724 5.63057 9.63262C5.63057 9.90482 5.73114 10.1292 5.93222 10.3063C6.13329 10.4826 6.41523 10.5714 6.77769 10.5714C7.01372 10.5714 7.22088 10.5406 7.39917 10.4785C7.57727 10.4167 7.81644 10.3268 8.11584 10.2086ZM8.04946 4.8502C8.22352 4.68882 8.31014 4.49254 8.31014 4.26272C8.31014 4.03341 8.22365 3.83675 8.04946 3.67331C7.87584 3.51032 7.66657 3.42857 7.4219 3.42857C7.17646 3.42857 6.96635 3.51013 6.79107 3.67331C6.61579 3.83675 6.52796 4.03334 6.52796 4.26272C6.52796 4.49254 6.61579 4.68875 6.79107 4.8502C6.96667 5.01217 7.17639 5.09321 7.4219 5.09321C7.66664 5.09321 7.87584 5.01217 8.04946 4.8502Z"
                  fill="#CACFD8"
                />
              </svg>
            </div>
          </div>
          {(
            <>
              <div
                className="amount-collected"
                style={{ whiteSpace: "nowrap" }}
              >
                {activeTab !== "Day" &&
                  activeTab !== "Weekly" &&
                  activeTab !== "Monthly" && (
                    <>
                    
                   { isLoading ?<Skeleton height={'2rem'} width={'8rem'}/> : <>
    
                      Rs.{" "}
                      {convertNumberToKOrLac(
                        statsData.totalAmountStats.total_amount_paid
                      ) || "NaN"}
                      </>}
                      {userInfo.role !== "accountant" && (
                     
                        isLoading ?<Skeleton height={'1rem'} width={'8rem'}/> : <div className="total">
                          of{" "}
                          {convertNumberToKOrLac(
                            statsData.totalAmountStats.total_term_fee -
                              statsData.totalAmountStats.total_term_concession
                          ) || "NaN"}{" "}
                          collected
                        </div>
                      
                      )}
                    </>
                  )}
                {activeTab === "Day" && (
                  <>
                    Rs.{" "}
                    {convertNumberToKOrLac(
                      statsData.totalAmountFilterStats.today_amount_paid
                    ) || "NaN"}
                  </>
                )}
                {activeTab === "Weekly" && (
                  <>
                    Rs.{" "}
                    {convertNumberToKOrLac(
                      statsData.totalAmountFilterStats.last_week_amount_paid
                    ) || "NaN"}
                  </>
                )}
                {activeTab === "Monthly" && (
                  <>
                    Rs.{" "}
                    {convertNumberToKOrLac(
                      statsData.totalAmountFilterStats.last_month_amount_paid
                    ) || "NaN"}
                  </>
                )}
              </div>
              {userInfo.role !== "accountant" && (
                <div className="progress-bar-container">
    
                  {isLoading ?<Skeleton/> :<div
                    className="progress-bar"
                    style={{
                      width: `${(
                        (statsData.totalAmountStats.total_amount_paid /
                          (statsData.totalAmountStats.total_term_fee -
                            statsData.totalAmountStats.total_term_concession)) *
                        100
                      ).toFixed(2)}%`,
                    }}
                  ></div>}
                </div>
              )}

              {userInfo.role !== "accountant" && (
               <div className="status">
                   {isLoading ? <Skeleton height={"1rem"} width={'9rem'}/>: <div>
                    <div className="fee-dashboard__circle paid"></div>
                    <span
                      style={{
                        textWrap: "nowrap",
                      }}
                    >
                      {(
                        (statsData.totalAmountStats.total_amount_paid /
                          (statsData.totalAmountStats.total_term_fee -
                            statsData.totalAmountStats.total_term_concession)) *
                        100
                      ).toFixed(2)}
                      % amount paid
                    </span>
                  </div>}
                  {isLoading ? <Skeleton height={"1rem"} width={'9rem'}/>: <div>
                    <div className="fee-dashboard__circle outstanding"></div>
                    <span
                      style={{
                        textWrap: "nowrap",
                      }}
                    >
                      {(
                        100 -
                        (statsData.totalAmountStats.total_amount_paid /
                          (statsData.totalAmountStats.total_term_fee -
                            statsData.totalAmountStats.total_term_concession)) *
                          100
                      ).toFixed(2)}
                      % amount outstanding
                    </span>
                  </div>}
                </div>
              )}
              {/* <div className="due-date">
                DUE BY {today.getDate()} {monthsOfYear[today.getMonth()]},
                {String(today.getFullYear()).slice(-2)}
              </div> */}
            </>
          )}
        </div>
        <div className="fee-container">
          <div className="header">
            Overall Fee Status by students
            <div className="icon">
              <svg
                data-tooltip-id="my-tooltip"
                data-tooltip-content="The percentage and count of students who have paid their fees, those who still owe fees, and those who have overdue fees."
                data-tooltip-place="right"
                width="16"
                height="16"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7 13.25C10.4518 13.25 13.25 10.4518 13.25 7C13.25 3.54822 10.4518 0.75 7 0.75C3.54822 0.75 0.75 3.54822 0.75 7C0.75 10.4518 3.54822 13.25 7 13.25ZM8.11584 10.2086L8.21565 9.8006C8.16399 9.82486 8.0807 9.85258 7.96649 9.88414C7.85197 9.9157 7.7489 9.93177 7.65831 9.93177C7.46536 9.93177 7.32952 9.90014 7.25065 9.83657C7.17236 9.773 7.13331 9.65342 7.13331 9.47827C7.13331 9.40887 7.14509 9.30542 7.16973 9.17003C7.19361 9.03373 7.22108 8.91261 7.25161 8.80666L7.62419 7.48759C7.66068 7.36653 7.68571 7.23344 7.69916 7.08818C7.71292 6.94325 7.71932 6.84185 7.71932 6.78429C7.71932 6.50614 7.62182 6.28041 7.42676 6.10629C7.2317 5.93229 6.95393 5.84529 6.59396 5.84529C6.39365 5.84529 6.18188 5.88088 5.95776 5.952C5.73363 6.02294 5.49933 6.1084 5.25421 6.2082L5.15415 6.6165C5.22719 6.58949 5.31419 6.56043 5.41598 6.53034C5.51732 6.50038 5.61674 6.48489 5.71347 6.48489C5.91096 6.48489 6.04399 6.51856 6.1137 6.58488C6.18342 6.65139 6.21844 6.7697 6.21844 6.93883C6.21844 7.03236 6.20736 7.13626 6.18438 7.24919C6.16172 7.36282 6.13342 7.48298 6.10013 7.6098L5.72595 8.93419C5.69266 9.07336 5.66834 9.19787 5.65304 9.30843C5.63786 9.41886 5.63057 9.52724 5.63057 9.63262C5.63057 9.90482 5.73114 10.1292 5.93222 10.3063C6.13329 10.4826 6.41523 10.5714 6.77769 10.5714C7.01372 10.5714 7.22088 10.5406 7.39917 10.4785C7.57727 10.4167 7.81644 10.3268 8.11584 10.2086ZM8.04946 4.8502C8.22352 4.68882 8.31014 4.49254 8.31014 4.26272C8.31014 4.03341 8.22365 3.83675 8.04946 3.67331C7.87584 3.51032 7.66657 3.42857 7.4219 3.42857C7.17646 3.42857 6.96635 3.51013 6.79107 3.67331C6.61579 3.83675 6.52796 4.03334 6.52796 4.26272C6.52796 4.49254 6.61579 4.68875 6.79107 4.8502C6.96667 5.01217 7.17639 5.09321 7.4219 5.09321C7.66664 5.09321 7.87584 5.01217 8.04946 4.8502Z"
                  fill="#CACFD8"
                />
              </svg>
            </div>
          </div>

          {(
            <>
              <div className="amount-collected">
              { isLoading ?<Skeleton height={'2rem'} width={'7rem'}/> :
                <>
                  {(((statsData["countsStats"]["pending_count"] +
                    statsData["countsStats"]["overdue_count"]) /
                    (statsData["countsStats"]["pending_count"] +
                      statsData["countsStats"]["overdue_count"] +
                      statsData["countsStats"]["completed_count"])) *
                  100
                ).toFixed(2)}
                %
                
                </>
                }
                <div className="total">{ isLoading ?<Skeleton height={'1rem'} width={'9rem'}/> :"students fee pending"}</div>
              </div>
              <div className="line"></div>
              <div className="status">
                { ( isLoading ) ? <Skeleton height={'1rem'} width={'6rem'}/>:<div>
                  <div className="fee-dashboard__circle paid"></div>
              
                  <span>
                    {statsData["countsStats"]["completed_count"]} paid
                  </span>
                </div>}
                { ( isLoading ) ? <Skeleton height={'1rem'} width={'6rem'}/>:<div>
                  <div className="fee-dashboard__circle pending"></div>
                  <span>
                    {statsData["countsStats"]["pending_count"]} pending
                  </span>
                </div>}
                { ( isLoading ) ? <Skeleton height={'1rem'} width={'6rem'}/>:<div>
                  <div className="fee-dashboard__circle overdue"></div>
                  <span>
                    {statsData["countsStats"]["overdue_count"]} overdue
                  </span>
                </div>}
              </div>
              {/* {false? <Skeleton width={"6rem"} height={'1.5rem'}/>: */}
                 <div className="due-date">
                {isLoading ?<Skeleton />: <>
                {(Number(statsData?.countsStats?.completed_count) || 0) +
                  (Number(statsData?.countsStats?.pending_count) || 0) +
                  (Number(statsData?.countsStats?.overdue_count) || 0)}{" "}
                Total Students
                  </>}
               
              </div>
            </>
          )}
        </div>
        <div className="fee-container">
          <div className="header">Fee level actions</div>
          { (
            <>
              <div className="pendingdetails" style={{ marginTop: "-10px" }}>
                <div className="amount-collected">
                {isLoading ?<Skeleton height={'2rem'} width={'4rem'}/> : <>{statsData["countsStats"]["pending_count"]}</>}
                  {isLoading?<Skeleton height={'1rem'} width={'8rem'}/> : <div className="total" style={{ whiteSpace: "nowrap" }}>
                    students pending
                  </div>}
                </div>
                {/* <div className="bultremindfee" >
                  Bulk Remind Fee
                </div> */}
              </div>
              <div className="line"></div>

              <div className="pendingdetails">
                <div className="amount-collected">
                {isLoading ?<Skeleton height={'2rem'} width={'4rem'}/> : <> {statsData["countsStats"]["overdue_count"]}</>}
                {isLoading?<Skeleton height={'1rem'} width={'8rem'}/> : <div className="total">students overdue</div>}
                </div>
                {/* <div className="bultremindfee" style={{ marginLeft: "4px" }}>
                  Bulk Request Fee
                </div> */}
              </div>
              <div
                className="due-date"
                style={{ marginTop: "5px", width: "70px" }}
              >
               {isLoading?<Skeleton /> : "2 Actions"}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="middlebody">
        <div className="Grades-header">
          Overall dues by Grades
          <div className="status">
            <div>
              <div className="fee-dashboard__circle paid"></div>
              <span>Paid</span>
            </div>
            <div>
              <div className="fee-dashboard__circle pending"></div>
              <span>Pending</span>
            </div>
            <div>
              <div className="fee-dashboard__circle overdue"></div>
              <span>Overdue</span>
            </div>
          </div>
        </div>
        { (
          <>
            <div className="classes">
            <div className="item" style={{ whiteSpace: "nowrap" }}>
            {isLoading?<Skeleton height={'1.4rem'} width={'4rem'}/> :findRange("min")}
                <div className="highest">LOWEST</div>
              </div>
              <div className="item" style={{ whiteSpace: "nowrap" }}>
              {isLoading?<Skeleton height={'1.4rem'} width={'4rem'}/> :findRange("max")}
                <div className="highest">HIGHEST</div>
              </div>
            </div>
            <div className="barsbody">
              <div className="percentages">
                <div className="item">60%</div>
                <div className="item">30%</div>
                <div className="item">10%</div>
                <div className="item">0%</div>
              </div>
              {isLoading && <div className="bars"> 
                <div className="item"> <div className="top"><Skeleton height={'100%'}/></div></div>
                <div className="item"> <div className="top"><Skeleton height={'100%'}/></div></div>
                <div className="item"> <div className="top"><Skeleton height={'100%'}/></div></div>
                <div className="item"> <div className="top"><Skeleton height={'100%'}/></div></div>
                </div>}
              {!isLoading && <div className="bars">
                {statsData &&
                  statsData.groupedStats &&
                  statsData.groupedStats.map((item, index) => {
                    //     "completed_count": 1,
                    // "pending_count": 2,
                    // "overdue_count": 3,const { completed_count, pending_count, overdue_count } = statsData.countsStats;
                    const { completed_count, pending_count, overdue_count } =
                      item;
                    const total_count =
                      completed_count + pending_count + overdue_count;
                    const completed_percentage = (
                      (completed_count / total_count) *
                      100
                    ).toFixed(2);
                    const pending_percentage = (
                      (pending_count / total_count) *
                      100
                    ).toFixed(2);
                    const overdue_percentage = (
                      (overdue_count / total_count) *
                      100
                    ).toFixed(2);
                    const overdueHeight = `${overdue_percentage}%`;
                    const pendingDueHeight = `${pending_percentage}%`;
                    const completedDueHeight = `${completed_percentage}%`;
                    return (
                      <div className="item">
                        <div
                          className="top"
                          style={{ height: overdueHeight }}
                          data-tooltip-id={overdue_count}
                          data-tooltip-content="123"
                          data-tooltip-place="right"
                        ></div>
                        <div
                          className="middle"
                          data-tooltip-id="my-tooltip-bar"
                          data-tooltip-content={pending_count}
                          data-tooltip-place="right"
                          style={{ height: pendingDueHeight }}
                        ></div>
                        <div
                          className="bottom"
                          data-tooltip-id="my-tooltip-bar"
                          data-tooltip-content={completed_count}
                          data-tooltip-place="right"
                          style={{ height: completedDueHeight }}
                        ></div>
                        <div className="bottomclassname">{item.class}</div>
                      </div>
                    );
                  })}
              </div>}
            </div>{" "}
          </>
        )}
      </div>
      <div style={{ marginTop: "1.3rem", marginLeft: "11px" }}>
        <FeeTransactionTable
          transactionData={paginatedTransactionData}
          annual={activeTab === "Annual" ? true : false}
          annualData={paginatedAnnualData}
          isLoading={isLoading}
        />
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};
export default FeeDashBoard;
