import { NameToolTip } from "../../../../home/ToolTip";
import "./NameAvatar.css";

export const NameAvatar = ({ name, id, image, route, user_id ,endpoint="fee"}) => {
  const idExists = id !== null;
  return (
    <div
      className={`fee-collection--avatar ${
        route === "remind" && "bulk-remind--avatar"
      }`}
    >
      <img
        src={image}
        alt={name}
        className={`fee-collection__avatar ${
          route === "remind" && "bulk-remind__avatar"
        }`}
        style={{ width: "30px", height: "30px" }}
      />
      <div className="name-avatar--info">
        <NameToolTip
          route={route}
          name={name}
          user_id={user_id}
          endpoint={endpoint}
        />
        {idExists && (
          <span
            className={`fee-collection__id ${
              route === "remind" ? "bulk-remind--id" : ""
            }`}
          >
            {`${id}`}
          </span>
        )}
      </div>
    </div>
  );
};
