import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import Grahith_logo from "../../../assets/Grahith_Logo.png";
import "./SetupHeader.css";
import { IoIosArrowForward } from "react-icons/io";
import { FaCheck } from "react-icons/fa";

const SetupAdminHeader = () => {
  const location = useLocation();

  const routes = [
    { number: 1, text: "Set up Admin Details", path: "/signup/setup/admin" },
    { number: 2, text: "Set up School Details", path: "/signup/setup/school" },
    {
      number: 3,
      text: "Set up Academic Details",
      path: "/signup/setup/academic",
    },
    { number: 4, text: "Finalise", path: "/signup/finalise" },
  ];

  const currentRouteIndex = routes.findIndex((route) =>
    location.pathname.includes(route.path)
  );

  return (
    <header className="setup-admin-header">
      <img
        src={Grahith_logo}
        alt="Grahith"
        className="setup-admin-header__logo"
        width={40}
        height={40}
      />
      <div className="setup-admin-header__routes">
        {routes.map((route, index) => {
          const isCompleted = index < currentRouteIndex;
          const isCurrentRoute = index === currentRouteIndex;

          return (
            <div
              key={route.number}
              className={`setup-admin-header__route ${
                isCurrentRoute ? "current-route" : ""
              }`}
            >
              {isCompleted ? (
                <span className="setup-admin-header__route-number route--checked">
                  <FaCheck size={"0.65rem"} />
                </span>
              ) : (
                <span
                  className={`setup-admin-header__route-number ${
                    isCurrentRoute ? "current-route-number" : ""
                  }`}
                >
                  {route.number}
                </span>
              )}
              <NavLink
                to={route.path}
                className="setup-admin-header__route-text"
              >
                {route.text}
              </NavLink>
              {index < routes.length - 1 && (
                <IoIosArrowForward
                  size={"0.85rem"}
                  className="setup-admin-header__route-arrow"
                />
              )}
            </div>
          );
        })}
      </div>
    </header>
  );
};

export default SetupAdminHeader;
