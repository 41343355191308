import { formatDate } from "../../FeeStructureModule/hooks";
import "../FeeCollectionTable.css";

export const DueDate = ({ date, term, route }) => {
  return (
    <div
      className={`fee-collection__due-date--table ${
        route === "remind" && "due-date--remind"
      }`}
    >
      <span className={`due-date__date ${route === "remind" && "remind-date"}`}>
        {formatDate(date)}
      </span>
      <span className={`due-date__term ${route === "remind" && "remind-term"}`}>
        {/* Term {term} */}
        
      </span>
    </div>
  );
};
